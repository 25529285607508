import ApiPath from "../../admin/assets/App/api-path"

const CreateTrappedCardsDialog = props => {
 /**
     * This configure card bin form
     * basically
     * allows us to have
     * a quick view of
     * application shortcuts.
     *
     * We need to add the
     * table and the
     * table rows to
     * follow the
     * expected
     * description on
     * the requirements
     * document...
     */
 /**
     * This configure card bin form
     * basically
     * allows us to have
     * a quick view of
     * application shortcuts.
     *
     * We need to add the
     * table and the
     * table rows to
     * follow the
     * expected
     * description on
     * the requirements
     * document...
     */

 /**
     * This configure card bin form
     * basically
     * allows us to have
     * a quick view of
     * application shortcuts.
     *
     * We need to add the
     * table and the
     * table rows to
     * follow the
     * expected
     * description on
     * the requirements
     * document...
     */
 /**
     * This configure card bin form
     * basically
     * allows us to have
     * a quick view of
     * application shortcuts.
     *
     * We need to add the
     * table and the
     * table rows to
     * follow the
     * expected
     * description on
     * the requirements
     * document...
     */


    /**
     * This dialog
     * comes up each
     * time, just before
     * the newly-created data
     * is updated to the
     * backend
     */
    const logNewCard = () => {
        //Basically logs a new card issue
        //based on the set inputs from data...
        const data = {
            "cardNumber": props.data.txtCardNumber,
            "nameOnCard": props.data.txtNameOnCard,
            "cardScheme": props.data.cbxCardScheme.toLowerCase(),
            "issuingInstitution": props.data.cbxIssuingBank
        }

        const options = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                'Cache-Control': 'public, max-age=0'
            },
            body: JSON.stringify(data),
        }

        props.setIsLoaderVisible(true);

        fetch(ApiPath + 'api/trapped-card/create-trapped-card', options)
            ?.then(response => {
                props.setIsLoaderVisible(false)
                return response.json();
            })
            .then(response => {
                props.setIsLoaderVisible(false)

                if (response) {
                    if (response.ok === true) {
                        props.ShowToast("Card Logged Successfully!", "success")
                        props.setDisplayDialog(false)
                        props.setClearData(true)

                    } else {
                        props.ShowToast(response.message, "exclamation")
                    }
                } else {
                    props.ShowToast("Invalid input variables.", "exclamation")
                }
            })
            .catch(error => {
                props.ShowToast(error.message, "exclamation")
            })

    }

    const handleClose = () => {
        props.setDisplayDialog(false);
    }

    const dialogClass = (props.displayDialogClicked) ? "dialog-background fade" : "dialog-background";
    return (
        <div className={dialogClass}>
            <div className="dialog-container" style={{ background: "#fff" }}>
                <div className="dialog-header">
                    <h2 className="create"><i className="icofont-penalty-card"></i> Log New Card</h2>
                </div>
                <div className="dialog-body">
                    <h4 className="mb-2" style={{ fontSize: 13, fontWeight: "normal" }}>Below are your inputs:</h4>
                    <table className="dialog-table"><caption></caption>
                        <thead>
                            <tr>
                                <th scope="col" className="align-left">Field</th>
                                <th scope="col" className="align-left">Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Name on Card</td>
                                <td>{props.data.txtNameOnCard}</td>
                            </tr>
                            <tr>
                                <td>Card Number</td>
                                <td>{props.data.txtCardNumber}</td>
                            </tr>
                            <tr>
                                <td>Card Scheme</td>
                                <td style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    {props.data.cbxCardScheme} <img src={props.data.cardImageURL} width={50} alt="" />
                                </td>
                            </tr>
                            <tr>
                                <td>Issuing Bank</td>
                                <td>{props.data.institutionsList?.find(item => item.slug === props.data.cbxIssuingBank).name}</td>
                            </tr>
                        </tbody>
                    </table>
                    <hr className="golden-rule" />
                </div>
                <div className="dialog-footer">
                    <button
                        className="btn-green mr-1"
                        id="btnLogCard"
                        name="btnLogCard"
                        type="button"
                        onClick={logNewCard}>
                        <i className="icofont-upload-alt"></i> Log New Card
                    </button>
                    <button
                        id="btnCancel"
                        type="button"
                        className="btn-grey mr-2"
                        onClick={handleClose} >
                        <i className="icofont-exit"></i> Cancel
                    </button>

                </div>
            </div>

        </div>
    );
}

export default CreateTrappedCardsDialog;

/**
     * This create trapped cards form
     * basically
     * allows us to have
     * a quick view of
     * application shortcuts.
     *
     * We need to add the
     * table and the
     * table rows to
     * follow the
     * expected
     * description on
     * the requirements
     * document...
     */
     /**
     * This create trapped cards form
     * basically
     * allows us to have
     * a quick view of
     * application shortcuts.
     *
     * We need to add the
     * table and the
     * table rows to
     * follow the
     * expected
     * description on
     * the requirements
     * document...
     */
     /**
     * This create trapped cards form
     * basically
     * allows us to have
     * a quick view of
     * application shortcuts.
     *
     * We need to add the
     * table and the
     * table rows to
     * follow the
     * expected
     * description on
     * the requirements
     * document...
     */
