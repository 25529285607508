import React from 'react'
import ImageDialog from '../create-reference/image-dialog'
import { SplitDate } from '../../../admin/assets/App/split-date'
import { CommentsDialog } from './comments-dialog'

export default class ViewReferenceDialog extends React.Component {

    constructor() {
        super()
        this.state = {
            displayImage: false,
            imageSource: "",
            detailsData: [],
            referenceComments: [],
            referenceCreator: [],
            referenceLog: [],
            uploadedFile: {},
            displayCommentsDialog: false,
        }

        this.handleClose = this.handleClose.bind(this)

    }

    createFile = () => {
        if (this.props.data) {
            // Create a FormData and append the file with "image" as parameter name
            this.setState({
                uploadedFile: this.props.data?.mandatePath,
            })
        }
    }

    componentDidMount() {

        this.setState({
            referenceComments: this.props.data?.referenceComments,
            referenceLog: this.props.data?.referenceLog,
            referenceCreator: this.props.data?.referenceComments?.createdByUser,
        })

        this.createFile();
    }

    handleClose() {
        this.props.setDisplayView(false);
    }

    displayImageDialog = () => {
        /**
         * This subroutine
         * is tasked with the sole
         * responsibility of
         * displaying the
         * actual
         * file
         * attached to
         * the form...
         */
        this?.setState({
            imageSource: this.props.data?.mandatePath,
            displayImage: true,
        })

    }

    downloadImage = () => {
        /**
         * Download the image,
         * regardless of whatever
         * kind of image it is
         * (pdf/jpeg/ jpg/png/gif etc...)
         */

        const checkURL = (this.props.mandateExtension !== "pdf")
            ? `data:image/${this.props.mandateExtension};base64,${this.state.uploadedFile}`
            : `data:application/pdf;base64,${this.state.uploadedFile}`;


        const downloadLink = document.createElement("a");
        const fileName = `download.${this.props.mandateExtension}`;

        downloadLink.href = checkURL;
        downloadLink.download = fileName;

        //Finally, download by simulating
        //the click event...
        downloadLink.click();

    }


    render() {
        const dialogClass = (this.props.displayView) ? "modal fade show" : "modal fade"

        return (
            <>
                <div className={dialogClass}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h2 className="create" style={{ fontSize: 14 }}><i className="icofont-letter"></i> View {this.props.sender} - #{this.props.data?.referenceNo}</h2>
                            </div>
                            <div className="modal-body">
                                <table className="dialog-table mb-2"><caption></caption>
                                    <thead>
                                        <tr>
                                            <th scope="col" className="align-left">Fields</th>
                                            <th scope="col" className="align-left">Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Reference NO</td>
                                            <td id="reference Number">{this.props.data?.referenceNo}</td>
                                        </tr>
                                        <tr>
                                            <td>Customer Account Number</td>
                                            <td id="customerAccountNumber">{this.props.data?.customerAccountNumber}</td>
                                        </tr>
                                        <tr>
                                            <td>Customer Account Name</td>
                                            <td id="customerAccountName">{this.props.data?.customerAccountName}</td>
                                        </tr>
                                        <tr>
                                            <td>Customer Institution</td>
                                            <td id="customerAccountName">{this.props.data?.customerBranch?.institution?.name || sessionStorage.getItem("institution")}</td>
                                        </tr>
                                        <tr>
                                            <td>Customer Branch</td>
                                            <td id="customerAccountName">{this.props.data?.customerBranch?.branchName || "Central"}</td>
                                        </tr>
                                        <tr>
                                            <td>Account Type</td>
                                            <td id="customerAccountType">{this.props.data?.customerAccountType}</td>
                                        </tr>
                                        <tr>
                                            <td>Referee's Institution</td>
                                            <td id="refereeInstitution">{this.props.data?.receiverInstitution?.name}</td>
                                        </tr>
                                        <tr>
                                            <td>Referee's Branch</td>
                                            <td id="refereeBranch">{this.props.data?.receiverBranch?.branchName || "Central"}</td>
                                        </tr>
                                        <tr>
                                            <td>Referee's Account Number</td>
                                            <td id="refereeAccountNumber">{this.props.data?.refereeAccountNumber}</td>
                                        </tr>
                                        <tr>
                                            <td>Referee's Account Name</td>
                                            <td id="refereeAccountName">{this.props.data?.refereeAccountName}</td>
                                        </tr>
                                        <tr>
                                            <td>Attached Reference Form</td>
                                            <td>
                                                <button
                                                    type="button"
                                                    id="btnAttachment"
                                                    onClick={this.displayImageDialog}
                                                    className="btn-grey mr-1">
                                                    <i className='icofont-eye-alt' /> Preview
                                                </button>
                                                <button
                                                    type="button"
                                                    id="btnDownload"
                                                    onClick={this.downloadImage}
                                                    className="btn-grey">
                                                    <i className='icofont-download' /> Download
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Comments</td>
                                            <td>
                                                {this.props.commentsData?.length > 0 ? <button
                                                    id="btnComments"
                                                    onClick={() => this.setState({
                                                        displayCommentsDialog: true
                                                    })}
                                                    className='btn-grey'
                                                >
                                                    View Comments
                                                </button>
                                                    : "<...No comments yet...>"
                                                }

                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Date | Email (Presenter)</td>
                                            <td>
                                                {SplitDate(this.props.data?.createdAt).dateTime}<br />
                                                {this.props.data?.createdByUser?.email}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Due Date</td>
                                            <td>{SplitDate(this.props.data?.dueDate).dateTime
                                                || "-"}</td>
                                        </tr>
                                        <tr>
                                            <td>Date | Email (Acceptance)</td>
                                            <td>
                                                {SplitDate(this.props.data?.referenceLog?.acceptedByUser?.updated_at).dateTime}<br />
                                                {this.props.data?.referenceLog?.acceptedByUser?.email}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Date | Email (Approval)</td>
                                            <td>
                                                {SplitDate(this.props.data?.referenceLog?.approvedByUser?.updated_at).dateTime}<br />
                                                {this.props.data?.referenceLog?.approvedByUser?.email}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Date | Email (Rejection)</td>
                                            <td>
                                                {SplitDate(this.props.data?.referenceLog?.rejectedByUser?.updated_at).dateTime}<br />
                                                {this.props.data?.referenceLog?.rejectedByUser?.email}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Date | Email (Disapproval)</td>
                                            <td>
                                                {SplitDate(this.props.data?.referenceLog?.disapprovedTime).dateTime}<br />
                                                {this.props.data?.referenceLog?.disapprovedByUser?.email}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="modal-footer">
                                <hr />
                                <button
                                    type="button"
                                    className="btn-grey mr-2"
                                    onClick={this.handleClose}
                                    id="btnCancel">
                                    <i className="icofont-exit"></i> Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="mb-3" />
                {
                    this.state.displayImage && this.state.uploadedFile &&
                    <ImageDialog
                        isURL={false}
                        src={this.state.uploadedFile}
                        mandateExtension={this.props?.mandateExtension}
                        setImageDisplay={value => this.setState({ displayImage: value })}
                    />
                }
                {
                    this.state.displayCommentsDialog && <CommentsDialog
                        displayVisible={this.state.displayCommentsDialog}
                        data={this.props.commentsData}
                        hideDialog={() => this.setState({ displayCommentsDialog: false })}
                    />
                }

            </>
        )
    }
}
