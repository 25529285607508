import React from 'react'
import ApiPath from '../../admin/assets/App/api-path'
import { Enums } from "../../_common/enums";

export const AdminEmailsTableRows = (props) => {

  function handleDelete(keyIDToUse) {
    //Delete the selected item
    const confirmDelete = window?.confirm(
      "Do you wish to delete the selected email?"
    );

    if (!confirmDelete) {
      return;
    }

    const val = keyIDToUse;

    //Delete where this criteria is met...
    props.setIsLoaderVisible(true);

    const data = { id: val };
    const options = {
      method: "DELETE",
      headers: {
        "Content-Type": Enums.applicationJson,
        Authorization: `Bearer ${sessionStorage.getItem("session_token")}`,
      },
      body: JSON.stringify(data),
    };

    fetch(`${ApiPath}api/admin/group-email/${val}`, options)
      ?.then((response) => {
        return response.json();
      })
      .then((response) => {
        props.setIsLoaderVisible(false);
        if (response) {
          if (response.ok === true) {
            props.ShowToast("Email Deleted successfully!", "success");
            props.setResetEmails(true);
          } else {
            props.ShowToast(response.message, "exclamation");
            return;
          }
        } else {
          props.setIsLoaderVisible(false);
          props.ShowToast("Connection / Server Error. Please try again after some time.", "exclamation");
        }
      })
      .catch((error) => {
        throw error;
      });
  }

  return (
    props.keyID && (
      <tr>
        <td className="align-left">{props.emailValue}</td>
        <td className="align-right">
          <button
            className="btn-danger"
            onClick={() => handleDelete(props.keyID)}
            title="Delete"
          >
            <i className="icofont-ui-delete"></i> Delete
          </button>
        </td>
      </tr>
    )
  );
};

export default AdminEmailsTableRows;
