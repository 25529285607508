const Badges = (inputSelector) => {

    let output = "";
    inputSelector = inputSelector?.toLowerCase();

    switch (inputSelector) {
        case "cancelled":
            output = "badge-cancel align-center"
            break;
        case "approved":
            output = "badge-approved align-center"
            break;
        case "rejected":
            output = "badge-danger align-center"
            break;
        case "pending": case "submitted": case "payment-pending": case "processing":
            output = "badge-warning align-center"
            break;
        case "disapproved": case "declined":
            output = "badge-disapproved align-center"
            break;
        case "not-available": case "new": case "accepted":
            output = "badge-info align-center"
            break;
        default:
            output = "badge-success align-center"
            break;
    }

    return (output);
}

export default Badges;
