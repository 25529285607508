import { useEffect, useState } from "react";

const UploadFile = props => {

    const [uploadFile, setUploadFile] = useState([]);
    const [outputText, setOutputText] = useState("");

    const handleChange = e => {
        setUploadFile(e.target.files || [])
    }

    useEffect(() => {
        //Use this to watch the uploads to the file object...
        props.setUploadedFile(uploadFile);

        //Write the value of the file names...
        setOutputText(fileValues)

        // eslint-disable-next-line
    }, [uploadFile])

    const fileValues = () => {
        let output = "";
        const fileLength = uploadFile?.length || 0;

        let counter = 0;
        while (counter < fileLength) {
            output += uploadFile[counter]?.name + ", "
            counter++;
        }

        return output;

    }

    return (
        <>
            <div className="upload-file-area" draggable="true">
                <button className="btn-upload">Upload File
                    <input
                        type="file"
                        name="uploadFile"
                        id="uploadFile"
                        multiple={props.multipleFiles}
                        accept={props.acceptFormats || ".csv, .xls, .xlsx"}
                        onChange={handleChange}
                    />
                </button>
                <label className="control-info"><i className="icofont-warning-alt"></i> Formats:- {props.acceptFormats || ".csv, .xls, .xlsx"}</label>
                <span className="d-none">or</span>
                <span className="d-none">Drop File Here</span>
                <label><span>Attached File(s): </span>{outputText}</label>
            </div>

        </>

    );
}

export default UploadFile;
