import React from "react";
import Badges from "../../admin/assets/App/Badges";
import CapitalizeFirstLetter from "../../admin/assets/App/CapitalizeFirstLetter";
import MaskCardNumber from "../../admin/assets/App/MaskCardNumber";
import { SplitDate } from "../../admin/assets/App/split-date";


/**
* This incoming cards form
* basically
* allows us to have
* a quick view of
* application shortcuts.
*
* We need to add the
* table and the
* table rows to
* follow the
* expected
* description on
* the requirements
* document...
*/
/**
* This incoming cards form
* basically
* allows us to have
* a quick view of
* application shortcuts.
*
* We need to add the
* table and the
* table rows to
* follow the
* expected
* description on
* the requirements
* document...
*/

export default class IncomingCardsTableRows extends React.Component {

    approveReject = () => {
        this.props.setEditData(this.props.item)
        this.props.setDisplayApproveDialog(true);
    }

    render() {

        const actionStatus = CapitalizeFirstLetter(this.props.item.status);
        const statusClass = Badges(this.props.item.status);
        const cardSchemeImageURL = () => {
            let retVal = "";
            switch (this.props.item.cardScheme?.toLowerCase()) {
                case "visa":
                    retVal = "/assets/img/jpg/v-card.jpg";
                    break;
                case "mastercard":
                    retVal = "/assets/img/jpg/m-card.jpg";
                    break;
                case "afrigo":
                    retVal = "/assets/img/jpg/afri-go-card.jpg";
                    break;
                default:
                    retVal = "/assets/img/jpg/ve-card.jpg";
                    break;
            }

            return retVal;
        }

        const approveBtnClass = this.props.item.status !== "pending" ? "bx bxs-band-aid d-none" : "bx bxs-band-aid";

        return (
            <tr>
                <td className="align-center">{this.props.counter}.</td>
                <td>{this.props.item.trappedCardNo}</td>
                <td>{MaskCardNumber(this.props.item.cardNumber)}</td>
                <td>{this.props.item.nameOnCard}</td>
                <td>{this.props.item.issuingInstitution?.name}</td>
                <td>{this.props.item.acquiringInstitution?.name}</td>
                <td>{this.props.item.createdByUser?.branch?.branchName || "-"}</td>
                <td className="align-center"><img src={cardSchemeImageURL()} alt="" width={40} /></td>
                <td>{SplitDate(this.props.item.createdAt).dateTime}</td>
                <td>{this.props.item.trappedCardLog?.acceptedByUser?.email
                    || this.props.item.trappedCardLog?.rejectedByUser?.email || "-"}</td>
                <td><span className={statusClass}>{actionStatus}</span></td>
                <td className="align-center">
                    <i
                        className={approveBtnClass}
                        style={{ color: "var(--main-green)", fontSize: 20, cursor: 'pointer' }}
                        title="Treat"
                        onClick={this.approveReject}
                    ></i>
                    {/* <button
                        className={approveBtnClass}
                        onClick={this.approveReject}>
                        <i className="icofont-injection-syringe"></i> Treat...
                    </button> */}
                </td>
            </tr>
        )
    }
}

/**
     * This create trapped cards form
     * basically
     * allows us to have
     * a quick view of
     * application shortcuts.
     *
     * We need to add the
     * table and the
     * table rows to
     * follow the
     * expected
     * description on
     * the requirements
     * document...
     */
/**
* This create trapped cards form
* basically
* allows us to have
* a quick view of
* application shortcuts.
*
* We need to add the
* table and the
* table rows to
* follow the
* expected
* description on
* the requirements
* document...
*/
/**
* This create trapped cards form
* basically
* allows us to have
* a quick view of
* application shortcuts.
*
* We need to add the
* table and the
* table rows to
* follow the
* expected
* description on
* the requirements
* document...
*/
