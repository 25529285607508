import React from "react"
import ApiPath from "../../admin/assets/App/api-path";

export default class EDMMSCompaniesFormData extends React.Component {
    constructor() {
        super();
        this.state = {
            txtCompany: "",
            txtRCNumber: "",
            cbxRegistrar: "",
            txtCompanyNumber: "",
        }

        this.headersData = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${sessionStorage.getItem("session_token")}`,
            'Cache-Control': 'public, max-age=0'
        }
    }

    handleChange = e => {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
        })
    }

    componentDidMount() {
        //First, decie on wheter the mode is create or edit...
        if (this.props.mode === "Create") {
            //Reset all the values..
            this.setState({
                txtCompany: "",
                txtRCNumber: "",
                cbxRegistrar: "",
                txtCompanyNumber: "",
            })
        } else {
            //Set the input values...
            this.setState({
                txtCompany: this.props.data.companyName,
                txtRCNumber: this.props.data.rcNo,
                cbxRegistrar: this.props.data.registrar?.slug,
                txtCompanyNumber: this.props.data.companyNumber
            })
        }
    }

    addCompany = () => {
        /**
         * This saves the company data to the database
         * using the provided enpoint.
         * It will double as a create/edit/udpate button, depending
         * on the mode props...
         */

        // Validate first...
        if (this.state.txtCompany === "") {
            this.props.ShowToast("Enter company name to proceed", "exclamation");
            return;
        } else if (this.state.txtCompanyNumber === "") {
            this.props.ShowToast("Enter company number to proceed!", "exclamation");
            return;
        } else if (this.state.cbxRegistrar === "") {
            this.props.ShowToast("Select Registrar from list to proceed!", "exclamation");
            return;
        }

        // Otherwise, we're good to go...

        const body = {
            "companyName": this.state.txtCompany,
            "companyNumber": this.state.txtCompanyNumber,
            "rcNo": this.state.txtRCNumber,
            "registrar": this.state.cbxRegistrar,
            "slug": this.props.data?.slug
        }

        const options = {
            method: "POST",
            headers: this.headersData,
            body: JSON.stringify(body)
        }

        const api = (this.props.mode === "Create") ? "api/edmms-company/create-edmms-company" : "api/edmms-company/edit-edmms-company";

        // Loading...
        this.props.setIsLoaderVisible(true);

        fetch(`${ApiPath}${api}`, options)
            .then(res => (res.json()))
            .then(response => {
                this.props.setIsLoaderVisible(false);

                if (response) {
                    if (response.ok) {
                        // Success!
                        this.props.ShowToast(this.props.mode + " successful!", "success");
                        this.props.updateIsSuccessful();
                        this.props.hideDialog();

                    } else {
                        this.props.ShowToast(response.message, "exclamation");
                    }
                }
            })
            .catch(err => {
                this.props.setIsLoaderVisible(false);
                this.props.ShowToast(err.message, "exclamation");
            })
    }

    render() {

        const listOfRegistrars = this.props.registrarsList.map(item => {
            return (
                <option key={item.slug} value={item.slug}>{item.name}</option>
            )
        })

        return (
            <div className="dialog-background fade">
                <div className="dialog-container">
                    <div className="dialog-header">
                        <h2 className={this.props.mode === "Create" ? "create" : "edit"}><i className="icofont-id-card" /> {this.props.mode} eDMMS Company</h2>
                    </div>
                    <div className="dialog-body">
                        <div>
                            <hr className="golden-rule" /><h3 className="row-title">Company Information</h3>
                        </div>
                        <div className="form-row-1-2 mb-2">
                            <div className="input-group">
                                <label><span>*</span>Company Number</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="txtCompanyNumber"
                                    name="txtCompanyNumber"
                                    value={this.state.txtCompanyNumber}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="input-group">
                                <label><span>*</span>Company Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="txtCompany"
                                    name="txtCompany"
                                    value={this.state.txtCompany}
                                    onChange={this.handleChange}
                                    maxLength={32}
                                />
                            </div>
                        </div>
                        <div className="form-row-1-2 mb-2">
                            <div className="input-group">
                                <label>RC Number</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="txtRCNumber"
                                    name="txtRCNumber"
                                    value={this.state.txtRCNumber}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="input-group">
                                <label><span>*</span>Registrar</label>
                                <select
                                    className="form-control"
                                    id="cbxRegistrar"
                                    name="cbxRegistrar"
                                    value={this.state.cbxRegistrar}
                                    onChange={this.handleChange}
                                >
                                    <option value="">Select</option>
                                    {listOfRegistrars}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="dialog-footer">
                        <button
                            id="btnAddCompany"
                            onClick={this.addCompany}
                            className="btn-green mr-1"
                        >
                            <i className={this.props.mode === "Create" ? "icofont-plus" : "icofont-upload-alt"} /> {this.props.mode === "Edit" ? "Update" : "Add"} Company
                        </button>
                        <button
                            id="btnCancel"
                            onClick={() => this.props.hideDialog()}
                            className="btn-grey"
                        >
                            <i className="icofont-logout" /> Cancel
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}
