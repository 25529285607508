import React from 'react';
import ApiPath from './../../admin/assets/App/api-path'
import ValidateEmail from '../../admin/assets/App/validate-email';

class LoginFormData extends React.Component {

  constructor() {
    super()
    this.state = {
      email: "",
      password: "",
      errorBorderPresent: false,
    }
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    })
  }

  handleKeyDown = e => {
      if (e.key === 'Enter') {
        this.handleFormSubmit();
      }
  }

  handleFormSubmit = () => {
    const msgName = !this.props.isNibssUser ? "email" : "username";

    if (this.state.email === "") {
      this.props.ShowToast(
        `Enter a valid ${msgName} before proceeding`,
        "exclamation"
      );
      this.setState({ errorBorderPresent: true });
      return;
    }

    if (!this.props.isNibssUser && !ValidateEmail(this.state.email)) {
      this.props.ShowToast("Valid Email address required!", "exclamation");
      this.setState({ errorBorderPresent: true });
      return;
    }

    if (this.state.password === "") {
      this.props.ShowToast("Password required!", "exclamation");
      this.setState({ errorBorderPresent: true });
      return;
    }

    const data = this.props.isNibssUser
      ? { username: this.state.email, password: this.state.password }
      : { email: this.state.email, password: this.state.password };

    this.props.setIsLoaderVisible(true); //Show toast

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Cache-Control': 'public, max-age=0'
      },
      body: JSON.stringify(data),
    };

    const loginPath = this.props.isNibssUser
      ? "api/auth/nibss-login"
      : "api/auth/login";

    fetch(ApiPath + loginPath, options)
      .then((response) => {
        this.props.setIsLoaderVisible(false);
        return response.json();
      })
      .then((response) => {
        this.props.setIsLoaderVisible(false);

        if (response && response.ok === true) {
          const output = response.body;
          sessionStorage.setItem("session_valid_user", this.state.email);

          if (this.props.isNibssUser) {
            const $timeStamp = new Date().getTime();
            sessionStorage.setItem("session_token", output.data.bearer_token.token);
            sessionStorage.setItem("session_token_timestamp", $timeStamp);
            sessionStorage.setItem("session_locked_state", "unlocked");
            sessionStorage.setItem("session_timeout_period", 1000 * 60 * 10); //Timeout = 10 mins

            //Data
            sessionStorage.setItem("session_first_name", output.data.firstName);
            sessionStorage.setItem("session_last_name", output.data.lastName);
            sessionStorage.setItem("session_user_type", output.data.userRole);
            sessionStorage.setItem("is_nibss_user", "NIBSS");
            sessionStorage.setItem("toggle_state", "toggle");

            window.location.href = "/general/quick-access";


          } else {
            this.props.ShowToast(response.message, "information");
            sessionStorage.setItem("email", this.state.email)
            sessionStorage.setItem("password", this.state.password)

            window.location.href = "/login-validate";

          }
        } else {
          this.props.ShowToast(response.message, "exclamation");
        }
      })
      .catch((error) => {
        this.props.setIsLoaderVisible(false);
        this.props.ShowToast(error.message, "exclamation");
      });
  }

  render() {

    return (
      <div className="login-data">
        <h3>
          <i className="icofont-mr icofont-lock"></i>Secure Login{this.props.isNibssUser ? " - NIBSS User" : ""}
        </h3>
        <div className="login-details">
          <div className="controls">
            <span className="bx bx-mail-send"></span>
            <input
              type={this.props.isNibssUser ? "text" : "email"}
              className={this.state.errorBorderPresent ? "error-border" : ""}
              id="email"
              name="email"
              placeholder={this.props.isNibssUser ? "Username" : "Email Address"}
              onChange={this.handleChange}
              value={this.state.email}
              onKeyDown={this.handleKeyDown}
              required
            />
            <label className="control-info d-none" id="emailError">
              <span className="icofont-warning-alt"></span> Enter valid and
              registered email
            </label>
          </div>
          <div className="controls">
            <span className="bx bx-key"></span>
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Password"
              onChange={this.handleChange}
              value={this.state.password}
              required
              onKeyDown={this.handleKeyDown}
            />
            <label className="control-info d-none" id="passwordError">
              <span className="icofont-warning-alt"></span> Enter valid
              password
            </label>
          </div>

          <button type="submit" onClick={this.handleFormSubmit} id="btnLogin">
            Login
          </button>

          <a href="forgot-password" className="forgot-password">
            Forgot Password?
          </a>
        </div>
        <div className="cant-access">
          <a href="mailto:admin@spexesconsulting.com">
            Can't access your account?
          </a>
        </div>
      </div>

    );
  }

}

export default LoginFormData
