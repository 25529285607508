import { SplitDate } from "../../admin/assets/App/split-date";
import CapitalizeFirstLetter from "../../admin/assets/App/CapitalizeFirstLetter"
import Badges from "../../admin/assets/App/Badges";

export const OutgoingEClipsTableRows = (props) => {

    const displayPublicURLForm = () => {
        /**
         * Set the display state
         * of the form in question to
         * true
         */
        props.setRowData(props.item);
        props.setDisplayPublicURL(true);

    }

    const viewMoreDetails = () => {
        props.setRowData(props.item);
        props.showViewMoreDetails();
    }

    const statusClass = Badges(props.item?.status);
    const actionStatus = CapitalizeFirstLetter(props.item?.status);

    return (
        <tr>
            <td>{props.counter}.</td>
            <td>{props.item?.requestId}</td>
            <td>{props.item?.referenceType}</td>
            <td>{props.item?.acquiringInstitution?.name}</td>
            <td>{SplitDate(props.item?.createdAt).dateTime}</td>
            <td>{props.item?.reference}</td>
            <td>{SplitDate(props.item?.updatedAt).dateTime}</td>
            <td className="align-center">{CapitalizeFirstLetter(props.item?.isOverDue)}</td>
            <td><span className={statusClass}>{actionStatus}</span></td>
            <td className="align-center" style={{ width: 120 }}>
                <i
                    id="btnDisplay"
                    className="bx bx-world mr-1"
                    style={{ color: "darkgreen", fontSize: 20, cursor: 'pointer' }}
                    title="Public URL"
                    onClick={displayPublicURLForm}
                ></i>
                <i
                    id="btnViewDetails"
                    className="bx bx-news mr-1"
                    title="View details..."
                    style={{ color: "darkblue", fontSize: 20, cursor: 'pointer' }}
                    onClick={viewMoreDetails}
                ></i>

            </td>

        </tr>
     );
}
