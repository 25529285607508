import React from "react";
import ApiPath from "../../admin/assets/App/api-path";

class CreateCardBinDialog extends React.Component {
    /**
        * This configure card bin dialog
        * basically
        * allows us to have
        * a quick view of
        * application shortcuts.
        *
        *
        * We need to add the
        * table and the
        * table rows to
        * follow the
        * expected
        * description on
        * the requirements
        * document...
        */

    constructor() {
        super()
        this.state = {
            txtCardBin: "",
            cbxInstitutions: "",
        }

        this.txtAccountUser = `${sessionStorage.getItem("session_first_name")} ${sessionStorage.getItem("session_last_name")}`;
    }

    closeForm = () => {
        this.props.setDisplayCreateDialog(false);
    }

    handleChange = e => {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
        })
    }

    handleSubmit = () => {
        //Implement API post later...
        const txtBin = document.getElementById("txtCardBin")

        if (parseInt(this.state.txtCardBin) <= 0 || this.state.txtCardBin === "") {
            this.props.ShowToast("You must enter a numeric value more than zero (0) to proceed", "exclamation");
            txtBin?.classList.add("error-border");
            txtBin?.focus();
            return;

        } else if (this.state.txtCardBin.length < 6) {
            this.props.ShowToast("You must enter numeric value of between 6 and 8 digits.", "exclamation");
            txtBin?.classList.add("errorBorder");
            txtBin?.focus();
            return;
        }

        const cardData = {
            institutionId: this.state.cbxInstitutions,
            bin: this.state.txtCardBin
        }

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                'Cache-Control': 'public, max-age=0'
            },
            body: JSON.stringify(cardData),
        }

        fetch(`${ApiPath}api/bin/create-bin`, options)
            ?.then(response => {
                this.props.setIsLoaderVisible(false)
                return response.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false)
                if (response) {
                    if (response.ok === true) {
                        this.props.ShowToast("Card Bin record created successfully!", "success")
                        this.props.setCreateSuccessful(true);
                        this.props.setDisplayCreateDialog(false);

                    } else {
                        this.props.ShowToast(response.message, "exclamation")
                    }
                }
            })
            .catch(error => {
                this.props.ShowToast(error.message, "exclamation")
            })

    }

    render() {
        const DisplayClass = this.props.displayCreateDialog ? "dialog-background fade" : "dialog-background";

        return (
            <div className={DisplayClass}>
                <div className="dialog-container">
                    <div className="dialog-header">
                        <h2 id="dialog-title" className="create"><i className="bx bxs-timer"></i> Create Card Bin</h2>
                    </div>
                    <div className="dialog-body">
                        <div className="form-row-2-1">
                            <div className="input-group">
                                <label><i className="icofont-user-alt-3"></i> Account User</label>
                                <input type="text" name="txtAccountUser" className="form-control"
                                    value={this.txtAccountUser} disabled />
                            </div>
                        </div>
                        <hr className="golden-rule" /><h3 className="row-title">Card Bin Details</h3>
                        <div className="form-row-2-1 mb-2">
                            <div className="input-group col-md">
                                <label><span>*</span>Card Bin</label>
                                <input
                                    id="txtCardBin"
                                    name="txtCardBin"
                                    className="form-control"
                                    value={this.state.txtCardBin}
                                    type="text"
                                    maxLength="8"
                                    onChange={this.handleChange}
                                />
                                <label className="control-info"><i className="icofont-warning-alt"></i> Enter 6-8 digits code</label>
                            </div>
                        </div>
                        <div className="form-row-2-1 mb-2">
                            <div className="input-group col-md">
                                <label><span>*</span>Institution</label>
                                <select
                                    id="cbxInstitutions"
                                    name="cbxInstitutions"
                                    className="custom-select"
                                    onChange={this.handleChange}
                                    value={this.state.cbxInstitutions}>
                                    <option value=""></option>
                                    {
                                        this.props.institutionsData?.map(item => {
                                            return (<option
                                                key={item.slug}
                                                value={item.slug}>
                                                {item.name}</option>)
                                        })
                                    }
                                </select>
                                <label className="control-info"><i className="icofont-warning-alt"></i> Select Institution From List</label>
                            </div>
                        </div>
                    </div>

                    <hr className="mb-2" />
                    <div className="dialog-footer">
                        <button
                            id="btnSubmit"
                            className="btn-green mr-1"
                            name="btnUpdate"
                            type="submit"
                            onClick={this.handleSubmit}>
                            <i className="icofont-plus"></i> Create Card Bin
                        </button>
                        <button
                            type="button"
                            className="btn-grey mr-2"
                            id="btnCancel"
                            onClick={this.closeForm}>
                            <i className="icofont-exit" title="Cancel"></i> Cancel
                        </button>
                    </div>
                </div>
            </div>

        );
    }

}

export default CreateCardBinDialog;
