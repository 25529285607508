import { SplitDate } from "../../admin/assets/App/split-date";
import CapitalizeFirstLetter from "../../admin/assets/App/CapitalizeFirstLetter"
import Badges from "../../admin/assets/App/Badges";

export const EClipsReportsTableRows = props => {

    const showPublicURLForm = () => {
        /**
         * Set the display state
         * of the form in question to
         * true
         */
        props.setRowData(props.item);
        props.setDisplayPublicURL(true);

    }

    const showMoreDetails = () => {
        props.setRowData(props.item);
        props.showViewMoreDetails();
    }

    const actionStatus = CapitalizeFirstLetter(props.item?.status);
    const statusClass = Badges(props.item?.status);

    return (
        <tr>
            <td>{props.counter}.</td>
            <td>{props.item?.requestId}</td>
            <td>{props.item?.referenceType}</td>
            <td>{props.item?.issuingInstitution?.name}</td>
            <td>{props.item?.acquiringInstitution?.name}</td>
            <td>{SplitDate(props.item?.createdAt).dateTime}</td>
            <td>{props.item?.reference}</td>
            <td>{SplitDate(props.item?.updatedAt).dateTime}</td>
            <td className="align-center">{CapitalizeFirstLetter(props.item?.isOverDue)}</td>
            <td><span className={statusClass}>{actionStatus}</span></td>
            <td style={{ width: 120 }} className="align-center">
                <i
                    className="icofont-wifi-router mr-1"
                    id="btnDisplay"
                    style={{ color: "darkgreen", fontSize: 20, cursor: 'pointer', textDecoration: 'underline' }}
                    title="Public URL"
                    onClick={showPublicURLForm}
                ></i>
                <i
                    id="btnViewDetails"
                    className="icofont-list mr-1"
                    title="View details..."
                    style={{ color: "darkblue", fontSize: 20, cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={showMoreDetails}
                ></i>

            </td>

        </tr>
    );
}
