import { SplitDate } from "../App/split-date"

export const LoadReferencesForExport = (itemsToLoad, dataToExport) => {
    //Load and prepare items for export
    // Some updates were included
    // to this file,
    // to improve the date
    // output for displaying
    // the expected formats...

    const customerBank = sessionStorage.getItem("institution");

    itemsToLoad.forEach(item => {
        //First, get individual reference details

        const thisObject = {
            "Reference No": `${item?.referenceNo}\t`,
            "Status": item?.actionStatus,
            "Customer Account": item?.customerAccountNumber,
            "Customer Name": item?.customerAccountName,
            "Customer Bank": item?.customerBranch?.institution?.name || customerBank,
            "Customer Branch": item?.customerBranch?.branchName,
            "Referee Account Name": item?.refereeAccountName,
            "Referee Bank": item?.receiverInstitution?.name,
            "Referee Branch": item?.receiverBranch?.branchName || 'Central',
            "Referee A/C No": item?.refereeAccountNumber,
            "Created By": item?.createdByUser?.email || "-",
            "Creation Date": SplitDate(item?.createdAt).dateTime,
            "Submitted/Rejected": item?.referenceLog?.submittedByUser ? "Submitted" : "-",
            "Submitted/Rejected Presenting Time": item?.referenceLog?.submittedByUser
                ? SplitDate(item?.referenceLog?.submittedByUser?.updated_at).dateTime
                : SplitDate(item?.referenceLog?.rejectedByUser?.updated_at).dateTime || "-",
            "Accepted/Rejected Receiving By": item?.referenceLog?.acceptedByUser
                ? item?.referenceLog?.acceptedByUser?.email
                : item?.referenceLog?.rejectedByUser?.email || "-",
            "Accepted/Rejected Receiving Time": item?.referenceLog?.acceptedByUser
                ? SplitDate(item?.referenceLog?.acceptedByUser?.updated_at).dateTime
                : SplitDate(item?.referenceLog?.rejectedByUser?.updated_at).dateTime || "-",
            "Approved/Disapproved/Cancelled by": item?.referenceLog?.approvedByUser
                ? item?.referenceLog?.approvedByUser?.email
                : item?.referenceLog?.cancelledByUser?.email || "-",
            "Approved/Disapproved/Canceled Receiving time": SplitDate(item?.referenceLog?.updatedAt).dateTime || "-",
            "Due Date": SplitDate(item?.dueDate).dateTime || "-",
        }

        dataToExport.push(thisObject)

    })

}
