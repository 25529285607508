const eDMMSSearchList = [
    {
        id: 1,
        idVal: "status",
        value: "Status",
        itemName: "Status",
    },
    {
        id: 2,
        idVal: "institution",
        value: "Fin. Institution",
        itemName: "Financial Institution"
    },
    {
        id: 4,
        idVal: "mandateCode",
        value: "Mandate Code",
        itemName: "Mandate Code",
    },
    // {
    //     id: 5,
    //     idVal: "registrar",
    //     value: "Registrar",
    //     itemName: "Registrar",
    // },
    {
        id: 6,
        idVal: "bvn",
        value: "BVN",
        itemName: "Bank Verification Number",
    },
    {
        id: 7,
        idVal: "chn",
        value: "CHN",
        itemName: "Clearing House Number",
    },
    {
        id: 8,
        idVal: 'paymentAccountNo',
        value: 'Account No',
        itemName: 'Shareholder Account No'
    }
]

export default eDMMSSearchList
