import React from 'react'
import ApiPath from '../../admin/assets/App/api-path'

class EditInstitutionTypesDialog extends React.Component {
    constructor() {
        super()
        this.state = {
            txtAccountUser: `${sessionStorage.getItem("session_first_name")} ${sessionStorage.getItem("session_last_name")}`,
            txtInstitutionType: "",
            txtInstitutionTypeCode: "",
            cbxEClips: false,
            cbxEReference: false,
            cbxEDMMS: false,
            cbxTrappedCards: false,
            editValues: [],
            eDMMSType: '',
        }

        this.handleChange = this.handleChange.bind(this)
        this.closeForm = this.closeForm.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)

    }

    componentDidMount() {
        this.setState({
            txtInstitutionType: this.props.rowData?.name,
            txtInstitutionTypeCode: this.props.rowData?.code,
        })

        //Use a loop to set the services for the others
        const services = this.props.rowData?.services;
        services?.forEach(item => {
            switch (item.id) {
                case 1:
                    this.setState({ cbxEReference: true });
                    break;
                case 2:
                    this.setState({ cbxTrappedCards: true });
                    break;
                case 3:
                    this.setState({ cbxEDMMS: true });
                    break;
                case 4:
                    this.setState({ cbxEClips: true });
                    break;
                default:
                    break;
            }
        })

        // Filter the service types to get the data for eDMMS service type, if selected...
        const serviceType = this.props.rowData?.serviceTypes?.find(item => item.service_id === 3)?.service_type;

        console.log('service type is', serviceType);
        // Set this value...
        this.setState({
            eDMMSType: serviceType,
        })
    }

    handleChange(event) {
        const { name, value, type, checked } = event.target;
        type === "checkbox" ? this.setState({ [name]: checked }) : this.setState({ [name]: value })
    }

    handleSubmit() {
        //Use the provided API to update the records after validating...
        try {

            if (this.state.txtInstitutionType === "") {
                this.props.ShowToast("Institution type required!", "exclamation");
                return
            }
            if (this.state.txtInstitutionTypeCode === "" || this.state.txtInstitutionTypeCode.length > 10) {
                this.props.ShowToast("Institution Type Code is required and should be of a maximum of 10-digits", "exclamation");
                return;
            }

            //Else, good to go
            this.props.setIsLoaderVisible(true)

            const svArray = [];
            if (this.state.cbxEReference) {
                svArray.push(1)
            }
            if (this.state.cbxTrappedCards) {
                svArray.push(2)
            }
            if (this.state.cbxEDMMS) {
                svArray.push(3)
            }
            if (this.state.cbxEClips) {
                svArray.push(4)
            }

            //Set the data first...
            const data = {
                "name": this.state.txtInstitutionType,
                "code": this.state.txtInstitutionTypeCode,
                "services": svArray,
                "slug": this.props.rowData.slug,
                "edmmsType": this.state.cbxEDMMS ? this.state.eDMMSType : '',

            };
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                    'Cache-Control': 'public, max-age=0'
                },
                body: JSON.stringify(data),
            }

            fetch(ApiPath + 'api/institution-type/update-institution-type', options)
                .then(response => {
                    this.props.setIsLoaderVisible(false);
                    return response.json();
                })
                .then(response => {
                    this.props.setIsLoaderVisible(false)
                    if (response) {
                        if (response.ok === true) {
                            //Success
                            this.props.ShowToast("Institution type updated successfully!", "success")
                            this.props.setIsEditSuccessful(true)
                            this.props.setEditClicked(false)
                        } else {
                            this.props.ShowToast("Internal error. Please retry after some time", "exclamation")
                            return;
                        }

                    } else {
                        this.props.ShowToast("Invalid input value", "exclamation")
                    }
                })
                .catch(error => {
                    this.props.setIsLoaderVisible(false)
                    this.props.ShowToast(error.message, "exclamation")
                })

        } catch (err) {
            this.props.setIsLoaderVisible(false)
            this.props.ShowToast(err, "exclamation")
        }
    }

    closeForm() {
        this.props.setEditClicked(false)
    }

    render() {
        const displayClass = (this.props.editClicked) ? "dialog-background edit fade" : "dialog-background edit";

        return (
            <div className={displayClass}>
                <div className="dialog-container">
                    <div className="dialog-header">
                        <h2 className="create"><i className="icofont-building-alt"></i> Edit Institution Type</h2>
                    </div>
                    <div className="dialog-body">
                        <div className="form-row">
                            <div className="input-group">
                                <label><i className="icofont-user-alt-3"></i> Account User</label>
                                <input type="text" id="txtAccountUser" name="txtAccountUser" className="form-control" value={this.state.txtAccountUser}
                                    onChange={this.handleChange} disabled />
                            </div>
                        </div>
                        <hr className="golden-rule" />
                        <div className="form-row-2-1 mb-3">
                            <div className="input-group col-md">
                                <label><span>*</span>Institution Type</label>
                                <input
                                    type="text"
                                    id="txtInstitutionType"
                                    name="txtInstitutionType"
                                    className="form-control"
                                    value={this.state.txtInstitutionType}
                                    onChange={this.handleChange}
                                    required />
                            </div>
                            <div className="input-group col-md">
                                <label><span>*</span>Type Code</label>
                                <input
                                    type="text"
                                    id="txtInstitutionTypeCode"
                                    name="txtInstitutionTypeCode"
                                    className="form-control"
                                    value={this.state.txtInstitutionTypeCode}
                                    onChange={this.handleChange}
                                    required />
                            </div>
                        </div>
                        <div className="form-row-3 mb-3">
                            <div className="input-group">
                                <label>Services</label>
                                <div className="checkbox-group">
                                    <div className="checkbox-container">
                                        <label><input
                                            type="checkbox"
                                            id="cbxEReference"
                                            name="cbxEReference"
                                            checked={this.state.cbxEReference}
                                            onChange={this.handleChange} />
                                            e-Reference
                                        </label>
                                    </div>
                                    <div className="checkbox-container">
                                        <label><input
                                            type="checkbox"
                                            id="cbxTrappedCards"
                                            name="cbxTrappedCards"
                                            checked={this.state.cbxTrappedCards}
                                            onChange={this.handleChange} />Trapped Cards
                                        </label>
                                    </div>
                                    <div className="checkbox-container">
                                        <label>
                                            <input
                                                type="checkbox"
                                                id="cbxEDMMS"
                                                name="cbxEDMMS"
                                                checked={this.state.cbxEDMMS}
                                                onChange={this.handleChange} /> e-DMMS
                                        </label>
                                    </div>
                                    <div className="checkbox-container">
                                        <label>
                                            <input
                                                type="checkbox"
                                                id="cbxEClips"
                                                name="cbxEClips"
                                                checked={this.state.cbxEClips}
                                                onChange={this.handleChange} /> e-Clips
                                        </label>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className={this.state.cbxEDMMS ? "input-group" : "d-none"}>
                            <div className="role-group">
                                <h3 className="role-group-title">e-DMMS Service Type</h3>
                                <div className="role-group-inputs">
                                    <label>
                                        <input
                                            id="eDMMSRegistrar"
                                            name="e_DMMS"
                                            type="radio"
                                            checked={this.state.eDMMSType === "registrar"}
                                            onChange={() => this.setState({ eDMMSType: "registrar" })}
                                        /> Registrar
                                    </label>
                                    <label>
                                        <input
                                            name="e_DMMS"
                                            type="radio"
                                            id="edMMSInstitution"
                                            checked={this.state.eDMMSType === "financial institution"}
                                            onChange={() => this.setState({ eDMMSType: "financial institution" })}
                                        /> Financial Institution
                                    </label>
                                </div>
                            </div>
                        </div>
                        <hr className="mb-2" />
                    </div>
                    <div className="dialog-footer">
                        <button
                            className="btn-green mr-1"
                            id="btnUpdateInstitution"
                            name="btnUpdateInstitution"
                            type="button" onClick={this.handleSubmit}>
                            <i className="icofont-upload-alt"></i> Update</button>
                        <button
                            type="button" className="btn-grey mr-2"
                            id="btnCancel" onClick={this.closeForm}>
                            <i className="icofont-exit"></i> Cancel
                        </button>
                        <button id="testButton" className="d-none" onChange={this.handleChange}></button>
                    </div>
                </div>
            </div>
        )
    }
}
export default EditInstitutionTypesDialog
