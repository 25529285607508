import NavBar from "../../admin/assets/App/nav-bar"
import TopBar from "../../admin/assets/App/topbar"
import LockedScreen from "../../admin/assets/App/LockedScreen";
import CreateEClipsForm from "./create-e-clips-form";
import Footer from "../../admin/assets/App/footer";
import { useState } from 'react';
import { Helmet } from "react-helmet";

export const CreateEClips = (props) => {
    /**
     * THIS IS MORE OR LESS
     * THE HOST OF THE
     * CREATE E-CLIPS
     * FORM, CONTAINING
     * THE PAGE'S BASIC STRUCTURE
     * AND COMPONENTS
     */
    const [mainClass, setMainClass] = useState("main");

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>New Clip Request | NIBSS Document Management Hub Portal</title>
            </Helmet>
            <LockedScreen
                setIsLoaderVisible={props.setIsLoaderVisible}
                ShowToast={props.ShowToast}
            />

            <div className="cont-ainer">
                <NavBar sender="New Clip Request" />
                <div className={mainClass}>
                    <TopBar
                        setMainClass={value => setMainClass(value)}
                    />
                    <div className="page-title">
                        <h2><i className="icofont-mr ion-ios-film-outline"></i>New Clip Request</h2>
                    </div>
                    <div className="branch-summaries">
                        <div className="summaries">

                            <CreateEClipsForm
                                ShowToast={props.ShowToast}
                                setIsLoaderVisible={props.setIsLoaderVisible}
                            />
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>

        </>
    )
}
