import DateDiff from "./DateDiff";
import { useState } from "react";

const DateRangePicker = (props) => {

    const [cbxDateFrom, setCbxDateFrom] = useState("");
    const [cbxDateTo, setCbxDateTo] = useState("");

    const displayClass = props.displayDateRangePicker ? "dialog-background fade" : "dialog-background";

    const maxDays = props.maxDays || 30;

    const SetDateDisplay = () => {
        //Always check the difference before outputting...
        if (DateDiff(cbxDateFrom, cbxDateTo).days > maxDays) {
            props.ShowToast(`You cannot select a date range of more than ${maxDays} days.`, "exclamation");
            return;
        }

        props.setOutput(`${cbxDateFrom} to ${cbxDateTo}`);
        props.setDisplayDateRangePicker(false)
    }

    return (
        <div className={displayClass}>
            <div className="lock-container">
                <div className="dialog-header">
                    <h3 className="nully"><i className="icofont-ui-calendar"></i> Select Date Range</h3>
                </div>
                <div className="dialog-body">
                    <p style={{ marginTop: "-20px" }}>Choose a date range from the inputs:</p>
                    <div className="form-row mb-1">
                        <div className="input-group">
                            <label>From</label>
                            <input
                                type="date"
                                name="cbxDateFrom"
                                id="cbxDateFrom"
                                className="form-control"
                                value={cbxDateFrom}
                                onChange={e => setCbxDateFrom(e.target.value)} />
                        </div>
                        <div className="input-group">
                            <label>To</label>
                            <input
                                type="date"
                                name="cbxDateTo"
                                id="cbxDateTo"
                                className="form-control"
                                value={cbxDateTo}
                                onChange={e => setCbxDateTo(e.target.value)} />
                        </div>
                    </div>
                </div>
                <div className="dialog-footer">
                    <button
                        id="btnSelect"
                        name="btnSelect"
                        onClick={SetDateDisplay}
                        className="btn-green mr-1">
                        <i className="icofont-tick-mark"></i> Select
                    </button>
                    <button
                        onClick={() => props.setDisplayDateRangePicker(false)}
                        id="btnCancel"
                        className="btn-grey">
                        <i className="icofont-close"></i> Cancel
                    </button>
                </div>
            </div>
        </div>
    )
}

export default DateRangePicker
