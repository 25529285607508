import React from "react";
import DateToLocal from "../../../admin/assets/App/DateToLocal";
import DateDiff from "../../../admin/assets/App/DateDiff"
import GetServices from "../../../admin/assets/App/GetServices";
import EditBillingsDialog from "./edit-billings-dialog";
import DownloadBillingsDialog from "./download-billings-dialog";
import ApiPath from "../../../admin/assets/App/api-path";
import BillingDetailsDialog from "./billing-details-dialog";
import SendEmailDialog from "./send-email-dialog";
import { SplitDate } from "../../../admin/assets/App/split-date";
import { DownloadEClipsBillingsDialog } from "./download-eclips-billings-dialog";

export default class BillingsFormTableRow extends React.Component {
    constructor() {
        super()
        this.state = {
            displayEditDialog: false,
            displayDownloadDialog: false,
            displayBillingDetailsDialog: false,
            displayEmailDialog: false,
            displayEClipsDownloadDialog: false,
            detailsData: [],
            aggregateData: [],
        }
    }

    DisplayBillingDetails = () => {
        //Fetch the details before setting the display property to true...
        const thisSlug = this.props.data.slug;

        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                'Cache-Control': 'public, max-age=0'
            }
        }

        fetch(`${ApiPath}api/billing/billing-detail/${thisSlug}`, options)    //Change the endpoint when ready...
            ?.then(response => {
                this.props.setIsLoaderVisible(false)
                return response.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false)

                if (response) {
                    if (response.ok === true) {

                        this.setState({
                            detailsData: response.body.billings.data,
                            aggregateData: response.body.billings.aggregate,
                            displayBillingDetailsDialog: true
                        })
                    } else {
                        this.props.ShowToast(response.message, "exclamation")
                    }
                }
            })
    }

    EditItem = () => {
        //Opens the edit dialog...
        this.setState({
            displayEditDialog: true,
        })
    }

    DeleteItem = () => {
        //Always display the confirm to verify deletion
        const confirmDelete = window.confirm("Are you sure you wish to delete the selected billing record?");

        if (confirmDelete) {
            //Go ahead and delete....
            const deleteSlug = { slug: this.props.data.slug }

            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                    'Cache-Control': 'public, max-age=0'
                },
                body: JSON.stringify(deleteSlug),
            }

            fetch(`${ApiPath}api/billing/remove-billing`, options)    //Change the endpoint when ready...
                ?.then(response => {
                    this.props.setIsLoaderVisible(false)
                    return response.json();
                })
                .then(response => {
                    if (response && response.ok === true) {
                        this.props.ShowToast("Billings Record deleted successfully!", "success")
                        this.props.setActionSuccessful(true)
                    } else {
                        this.props.ShowToast(response.message, "exclamation")
                    }

                })
                .catch(error => {
                    this.props.ShowToast(error.message, "exclamation")
                })

        }
    }

    displayDownload = () => {
        // display the right download dialog...
        if (GetServices(this.props.roleServices?.find(item => this.props.data.service?.slug === item.slug)?.name) === 'e-Clips') {
            this.setState({ displayEClipsDownloadDialog: true })
        } else {
            this.setState({ displayDownloadDialog: true })
        }
    }

    render() {

        const roleName = this.props.roleServices?.find(item => this.props.data.service?.slug === item.slug)?.name;
        return (
            <>
                <tr>
                    <td className="align-center">{this.props.counter}.</td>
                    <td>{SplitDate(this.props.data.createdAt).date}</td>
                    <td>{DateToLocal(this.props.data.endDate)}</td>
                    <td className="align-center">
                        {DateDiff(this.props.data.startDate, this.props.data.endDate).days}
                    </td>
                    <td>{GetServices(roleName)}</td>
                    <td className="align-center">{this.props.data.meta?.referenceVolume}</td>
                    <td className="align-center">
                        {/* Downloads column */}
                        <button
                            id="btnDownload"
                            className="btn-grey"
                            onClick={this.displayDownload}
                        >
                            <i className="icofont-download"></i> Download...
                        </button>

                    </td>
                    <td className="align-center">
                        <button
                            id="btnDetails"
                            className="btn-info"
                            onClick={this.DisplayBillingDetails}
                        >
                            <i className="icofont-listine-dots"></i> Details...
                        </button>
                    </td>
                    <td className="align-center">
                        <button
                            id="btnSendToMail"
                            className="btn-grey"
                            onClick={() => this.setState({ displayEmailDialog: true })} >
                            <i className="icofont-email"></i> Send Emails...
                        </button>
                    </td>
                    <td className="align-center">
                        <button
                            id="btnEdit"
                            className="btn-grey mr-1"
                            onClick={this.EditItem}
                        >
                            <i className="icofont-edit-alt"></i> Edit
                        </button>
                        <button
                            id="btnDelete"
                            className="btn-danger mr-1"
                            onClick={this.DeleteItem}
                        >
                            <i className="icofont-ui-delete"></i> Delete
                        </button>
                    </td>
                </tr>

                {this.state.displayEditDialog && <EditBillingsDialog
                    data={this.props.data}
                    roleServices={this.props.roleServices}
                    setDisplayEditDialog={value => this.setState({ displayEditDialog: value })}
                    displayEditDialog={this.state.displayEditDialog}
                    ShowToast={this.props.ShowToast}
                    setIsLoaderVisible={this.props.setIsLoaderVisible}
                    setEditSuccessful={this.props.setActionSuccessful}

                />}

                {
                    this.state.displayDownloadDialog && <DownloadBillingsDialog
                        data={this.props.data}
                        displayDownloadDialog={this.state.displayDownloadDialog}
                        setDisplayDownloadDialog={val => this.setState({ displayDownloadDialog: val })}
                        ShowToast={this.props.ShowToast}
                        setIsLoaderVisible={this.props.setIsLoaderVisible}
                    />
                }

                {
                    this.state.displayBillingDetailsDialog && <BillingDetailsDialog
                        data={this.state.detailsData}
                        aggregateData={this.state.aggregateData}
                        displayBillingDetailsDialog={this.state.displayBillingDetailsDialog}
                        setDisplayBillingDetailsDialog={val => this.setState({ displayBillingDetailsDialog: val })}
                    />
                }

                {
                    this.state.displayEmailDialog && <SendEmailDialog
                        data={this.props.data}
                        ShowToast={this.props.ShowToast}
                        setIsLoaderVisible={this.props.setIsLoaderVisible}
                        displayEmailDialog={this.state.displayEmailDialog}
                        setDisplayEmailDialog={val => this.setState({ displayEmailDialog: val })}
                    />
                }
                {
                    this.state.displayEClipsDownloadDialog && <DownloadEClipsBillingsDialog
                        displayDownloadDialog={this.state.displayEClipsDownloadDialog}
                        closeForm={() => this.setState({ displayEClipsDownloadDialog: false })}
                        fileTitle={'Some random title'}
                        inputData={this.props.data}
                        ShowToast={this.props.showToast}
                        setIsLoaderVisible={this.props.setIsLoaderVisible}
                    />
                }

            </>
        )
    }
}
