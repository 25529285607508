
const Loader = ({ isVisible }) => {
    const displayClass = (isVisible) ? 'loader' : 'loader d-none';
    //This loader is displayed
    // each time
    // 1. a form is loading.
    // 2. an action is taken that will
    // require sometime for the response to come
    // 3. a fetch/async function is awaiting a promise, or
    // 4. a request is sent to the backend
    return (
        <div className={displayClass} id="loader">
            <div className="roller"></div>
            <div className="wavy">
                <span style={{ "--i": 1 }}>N</span>
                <span style={{ "--i": 2 }}>I</span>
                <span style={{ "--i": 3 }}>B</span>
                <span style={{ "--i": 4 }}>S</span>
                <span style={{ "--i": 5 }}>S</span>
                <span style={{ "--i": 6 }}>-</span>
                <span style={{ "--i": 7 }}>(D</span>
                <span style={{ "--i": 8 }}>M</span>
                <span style={{ "--i": 9 }}>H)</span>
                <span style={{ "--i": 10 }}>.</span>
                <span style={{ "--i": 11 }}>.</span>
                <span style={{ "--i": 12 }}>.</span>
            </div>
        </div>
    );
}

export default Loader
