import React, { useState, useEffect } from 'react';

const Pagination = props => {
    const [sibblingCount] = useState(1);
    const [navButtons, setNavButtons] = useState([]);
    const [gotoNumber, setGotoNumber] = useState(1);
    const { currentSelectedPage, setCurrentSelectedPage, arrayPages, perPage, setPerPage, setRefreshForm } = props;

    const range = (start, end) => {
        let length = end - start + 1;
        /*
            Create an array of certain length and set the elements within it from
          start value to end value.
        */
        return Array.from({ length }, (_, idx) => idx + start);
    };

    const handleItemsCount = e => {
        localStorage.setItem("items_per_page", e.target.value)
        setPerPage(e.target.value)
        setRefreshForm(true)
    }

    const handleSelectedPage = pageNo => {
        setCurrentSelectedPage(pageNo)
        setRefreshForm(true);
    }

    const handleGotoPage = pageNo => {
        if (pageNo > arrayPages?.length) {
            pageNo = arrayPages?.length;
        } else if (!pageNo) {
            pageNo = 1;
        }

        setGotoNumber(pageNo);
        setCurrentSelectedPage(pageNo);
        setRefreshForm(true);
    }

    const getCollection = () => {
        // Cases...
        const totalPageCount = arrayPages?.length;
        const totalPageNumbers = sibblingCount + 5;

        // 1.
        if (totalPageNumbers >= arrayPages?.length) {
            return range(1, totalPageCount);
        }

        // Definitions
        const leftSibblingIndex = Math.max(currentSelectedPage - sibblingCount, 1);
        const rightSibblingIndex = Math.min(currentSelectedPage + sibblingCount, totalPageCount);

        const shouldShowLeftDots = leftSibblingIndex > 2;
        const shouldShowRightDots = rightSibblingIndex < totalPageCount - 2;

        const firstPageIndex = 1;
        const lastPageIndex = totalPageCount;

        // Case 2 - No left dots to show, but right dots to be shown...
        if (!shouldShowLeftDots && shouldShowRightDots) {
            const leftItemCount = 3 + 2 * sibblingCount;
            const leftRange = range(1, leftItemCount);

            return [...leftRange, DOTS, totalPageCount];
        }

        /**
         * Case 3: No right dots to show, but left dots to be shown...
         */
        if (shouldShowLeftDots && !shouldShowRightDots) {
            const rightItemCount = 3 + 2 * sibblingCount;
            const rightRange = range(
                totalPageCount - rightItemCount + 1,
                totalPageCount
            );
            return [firstPageIndex, DOTS, ...rightRange];
        }

        /**
         * Case 4: Both left and right dots to be shown..
         */
        if (shouldShowLeftDots && shouldShowRightDots) {
            const middleRange = range(leftSibblingIndex, rightSibblingIndex);
            return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
        }

    }

    const onNavigate = pageNo => {
        setCurrentSelectedPage(pageNo)
        setRefreshForm(true);
    }

    useEffect(() => {
        const collection = getCollection();

        // Set the output...
        const output = collection?.map((item, index) => {
            if (item === DOTS) {
                return <DOTS key={index} />
            }

            // Render the nav array by default...
            const active = currentSelectedPage === item ? 'active cls' : 'cls';
            return (<button
                key={index}
                onClick={() => onNavigate(item)}
                className={active}
            >
                {item}
            </button>)
        });

        setNavButtons(output);

        // eslint-disable-next-line
    }, [currentSelectedPage, arrayPages, perPage, gotoNumber])

    return (
        <>
            <div style={{ display: 'flex' }}>
                <div className="input-form-inline mr-2">
                    <label>Items per page</label>
                    <select
                        type="text"
                        id="perPage"
                        name="perPage"
                        value={props.perPage}
                        onChange={handleItemsCount}
                        className="form-control"
                    >
                        <option value="20">20</option>
                        <option value="40">40</option>
                        <option value="60">60</option>
                        <option value="80">80</option>
                        <option value="100">100</option>
                    </select>
                </div>
                <div className='input-form-inline'>
                    <label>Goto</label>
                    <input
                        className='form-control'
                        type='text'
                        placeholder='Go to'
                        value={gotoNumber}
                        onChange={e => setGotoNumber(e.target.value)}
                        onKeyDown={e => e.key === 'Enter' && handleGotoPage(e.target.value)}
                    />
                </div>
            </div>

            <div className="pagination">
                <button
                    className="pag"
                    onClick={() => handleSelectedPage(1)}>
                    <i className="bx bx-chevrons-left"></i>
                </button>
                {navButtons}
                <button
                    className="pag1"
                    onClick={() => handleSelectedPage(props.lastPage)}>
                    <i className="bx bx-chevrons-right"></i>
                </button>
            </div>
        </>
    );
}

const DOTS = () => {
    return (<span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '40px', pointerEvents: 'none' }}>...</span>)
}

export default Pagination
