import NavBar from "../../admin/assets/App/nav-bar"
import TopBar from "../../admin/assets/App/topbar"
import { Helmet } from "react-helmet";
import LockedScreen from "../assets/App/LockedScreen";

import React, { useState } from 'react'
import { AuditLogsForm } from "./audit-logs-form";
import Footer from "../assets/App/footer";

export const AuditLogs = (props) => {
    const [mainClass, setMainClass] = useState("main");

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Audit Logs | NIBSS Document Management Hub Portal</title>
            </Helmet>
            <LockedScreen
                setIsLoaderVisible={props.setIsLoaderVisible}
                ShowToast={props.ShowToast}
            />
            <div className="cont-ainer">
                <NavBar sender="Audit Logs" />
                <div className={mainClass}>
                    <TopBar
                        setMainClass={value => setMainClass(value)}
                    />
                    <div className="page-title">
                        <h2><i className="icofont-mr icofont-data"></i>Audit Logs</h2>
                    </div>
                    <AuditLogsForm
                        setIsLoaderVisible={props.setIsLoaderVisible}
                        ShowToast={props.ShowToast}
                        visibleState={props.visibleState}
                        domNode={props.domNode}
                    />
                    <Footer />
                </div>

            </div>

        </>
    )
}
