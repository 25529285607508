import { Link } from "react-router-dom";


const NavLinks = (props) => {
    const showLine = (props.drawLine) ? "" : "d-none"

    /**
     * The intention
     * here is to get a way to
     * display only the
     * items that concern the
     * logged-in user
     * whether as a
     * NIBSS user
     * or as a regular
     * institution user
     */

    return (
        <li className={props.navActive}>
            <Link to={props.to} title={props.linkName}>
                <i className={props.iconClass}></i>
                <span className="title">{props.linkName}</span>
            </Link>
            <hr className={showLine} />
        </li>
    )
}

export default NavLinks
