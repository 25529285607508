import React from 'react'
import { Link } from 'react-router-dom'
import { Enums } from "../../../_common/enums";
import ApiPath from "./api-path";

class TopBar extends React.Component {

  constructor() {
    super();
    this.state = {
      userMenu: Enums.userDetails,
      onToggleMenu: sessionStorage.getItem("menu_state"),
      toggleState: sessionStorage.getItem("toggle_state")
    };

    this.handleSignOut = this.handleSignOut.bind(this);
    this.ToggleUserMenu = this.ToggleUserMenu.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
  }

  componentDidMount() {
    //Each time it mounts, set these default values baed
    //on what is obtainable at the time
    //of load...
    if (this.state.toggleState === "toggle") {
      this.props?.setMainClass("main")
    } else {
      this.props?.setMainClass("main active")
    }
  }

  handleToggle() {
    if (this.state.toggleState === "toggle") {
      this.setState({
        onToggleMenu: false,
        toggleState: "toggle active",
      })
      this.props?.setMainClass("main active")
      sessionStorage.setItem("menu_state", `closed ${this.state.onToggleMenu}`);
      sessionStorage.setItem("toggle_state", "toggle active")

    } else {
      this.setState({
        onToggleMenu: true,
        toggleState: "toggle",
      })

      this.props?.setMainClass("main")
      sessionStorage.setItem("menu_state", `open ${this.state.onToggleMenu}`);
      sessionStorage.setItem("toggle_state", "toggle")

    }
  }

  ToggleUserMenu() {
    if (this.state.userMenu === Enums.userDetails) {
      this.setState({
        userMenu: `${Enums.userDetails} active`,
      });
    } else {
      this.setState({
        userMenu: Enums.userDetails,
      });
    }
  }

  SignOut() {

    //Sign out
    /**
     * This is determined by the kind of user logged in
     * NIBSS user
     * or
     */
    const options = {
      method: "POST",
      headers: {
        "Content-Type": Enums.applicationJson,
        "Authorization": `Bearer ${sessionStorage.getItem("session_token")}`,
        'Cache-Control': 'public, max-age=0'
      }
    };

    //Capture signout in the audit logs...
    fetch(ApiPath + "api/auth/logout", options)
      ?.then(response => (response.json()))
      ?.then(resp => {
        if (resp.ok) {

          //Clear out the other things...
          const userType = sessionStorage.getItem("is_nibss_user");

          sessionStorage.setItem("session_token_timestamp", null);
          sessionStorage.setItem("session_valid_user", null);
          sessionStorage.setItem("session_token", null);
          sessionStorage.setItem("session_locked_statopte", null);
          sessionStorage.setItem("session_first_name", null);
          sessionStorage.setItem("service_ids", null);
          sessionStorage.setItem("session_last_name", null);
          sessionStorage.setItem("session_user_type", null);
          sessionStorage.setItem("institution", null);
          sessionStorage.setItem("is_nibss_user", "-");
          sessionStorage.setItem("customer_branch", null);
          sessionStorage.setItem("customer_branch_slug", null);
          sessionStorage.setItem("institution_slug", null);

          let loc = "./../";
          if (userType !== null) {
            loc = userType.indexOf("NIBSS") > -1 ? "./../nibss-user-login" : "./../";
          }
          window.location.href = loc;

        }
      })

  }

  handleSignOut(e) {
    e.preventDefault();
    this.SignOut();
  }

  render() {
    const userType = sessionStorage.getItem("session_user_type");
    const currentUserValue = `${sessionStorage.getItem("session_first_name")} ${sessionStorage.getItem("session_last_name")}`;

    const userShortenedName = `${sessionStorage.getItem("session_first_name")?.substring(0, 1)?.toUpperCase()}` +
      `${sessionStorage.getItem("session_last_name")?.substring(0, 1)?.toUpperCase()}`

    return (
      <div className="topbar">
        <div
          id="onToggleMenu"
          className={this.state.toggleState}
          onClick={() => this.handleToggle()}
        ></div>
        <div className="user">
          <div
            id="TheToggleUserMenu"
            className={this.state.userMenu}
            onClick={this.ToggleUserMenu}
          >
            <div className="user-image">
              <div className='user_image'>
                <h3>{userShortenedName}</h3>
              </div>
              <img
                src="/assets/img/png/success.png"
                alt=""
                className="user_image d-none"
              ></img>
            </div>
            <i className="bx bx-menu-alt-right"></i>
            <span className="logged-in-user user_full_name">
              {currentUserValue}
            </span>
            <div className="menu" id="user-menu">
              <h3 className="user_full_name">
                {currentUserValue}
                <br />
                <span className="user-type user_access_level">{userType}</span>
              </h3>
              <ul>
                <li>
                  <i className="bx bx-key"></i>{" "}
                  <Link id="user_change_password" to="/config/change-password">
                    Change Password
                  </Link>
                </li>
                <li id="handleSignOut">
                  <i className="icofont-sign-out"></i>
                  <Link to="/" onClick={(e) => this.handleSignOut(e)}>
                    Sign Out
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TopBar
