/**
 * This where we define re-usable strings and constants
 * ====
 * Enums are one of the few features TypeScript has which is not a type-level extension of JavaScript.
 * Enums allow a developer to define a set of named constants. Using enums can make it easier
 * to document intent, or create a set of distinct cases. TypeScript provides both numeric and string-based enums.
 */
const appString = 'application/json';
const cacheCtrl = 'public, max-age=0';

export const Enums = {
  errorBorder: "error-border",
  userDetails: "user-details",
  txtAddEmail: "txtAddEmail",
  applicationJson: appString,
  headersData: function (token) {
    return {
      'Content-Type': appString,
      'Authorization': `Bearer ${token}`,
      'Cache-Control': cacheCtrl
    }
  },
  accept: "accept",
  reject: "reject",
  approve: "approve",
  disapprove: 'disapprove',
  getOptions: {
    method: 'GET',
    headers: {
      'Content-Type': appString,
      'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
      'Cache-Control': cacheCtrl
    },
  },
  postOptions: {
    method: 'POST',
    headers: {
      'Content-Type': appString,
      'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
      'Cache-Control': cacheCtrl
    },
  },
}
