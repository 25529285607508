import React from 'react'
import ApiPath from '../../admin/assets/App/api-path'
import { SplitDate } from '../../admin/assets/App/split-date'
import objectToCSV from '../../admin/assets/App/object-to-csv'
import ExportToCSVFile from '../../admin/assets/App/ExportToCSVFile'
import UsersSearchList from '../../admin/assets/data/UsersSearchList'
import DynamicSort from '../../admin/assets/App/DynamicSort'
import SearchComponent from '../../admin/assets/App/SearchComponent'
import Pagination from '../../admin/assets/App/Pagination'
import InstitutionUsersTableRows from './institution-users-table-rows'
import InstitutionUserFormData from './institution-user-form-data'


class InstitutionUsersForm extends React.Component {

    constructor() {
        super()
        this.state = {
            apiData: [],
            metaData: [],
            apiUserRoles: [],
            institutions: [],
            branches: [],
            cbxInstitutionSelect: "All",
            fetchSuccessful: false,
            createSuccessful: false,

            searchField: "",
            searchValue: "",

            rowData: [],
            editClicked: false,
            createClicked: false,
            currentSelectedPage: 1,     //From here is for pagination management
            arrayPages: [],
            lastPage: 1,
            perPage: localStorage.getItem("items_per_page") || 20,
        }
        this.headersData = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
            'Cache-Control': 'public, max-age=0'
        }
    }

    componentDidMount() {
        this.getInsitutions()
        this.getInsitutionUsers()
        this.getUserRoles()
        this.getBranches();
        this.setState({ userCreated: false })

        //Then ensure that the selected combo is for the institution in question, each time the
        //Loading is complete...
        const isNibssUser = sessionStorage.getItem("is_nibss_user")?.indexOf("NIBSS") > -1 ? true : false;
        if (!isNibssUser) {
            this.setState({
                cbxInstitutionSelect: sessionStorage.getItem("institution_slug"),
            })
        }
    }

    getUserRoles() {
        this.props.setIsLoaderVisible(true)
        const options = {
            method: 'GET',
            headers: this.headersData,
        }
        fetch(ApiPath + 'api/institution-user-role/fetch-institution-user-roles', options)
            ?.then(response => {
                return response.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false)
                if (response && response.ok === true) {
                    this.setState({
                        apiUserRoles: response.body.institutionUserRoles,
                        fetchSuccessful: true,
                    })
                }
            })
    }

    getBranches() {
        this.props.setIsLoaderVisible(true)
        const options = {
            method: 'GET',
            headers: this.headersData,
        }

        const queryString = this.state.cbxInstitutionSelect === "All"
            ? `${ApiPath}api/branch/fetch-branches?limit=1000`
            : `${ApiPath}api/branch/fetch-branches?limit=1000&institution=${this.state.cbxInstitutionSelect}`;

        fetch(queryString, options)
            ?.then(response => {
                return response.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false)
                if (response && response.ok === true) {
                    this.setState({
                        branches: response.body?.branches?.data,
                    })
                }
            })
    }

    EditInstitutionUser = itemID => {
        /**
         * Select a particular row of
         * interest for the purpose of
         * edititng...
         * We have decided to use the
         * slug parameter for the
         * filtering...
         */
        const selArray = this.state.apiData.filter(itemList => itemList.slug === itemID)
        this.setState({
            rowData: selArray[0],
            editClicked: true,
        })
    }

    handleExportUsers = () => {
        //First, regard the filter...
        //And set the items per page to say 1000
        const previousPerPage = this.state.perPage;

        //Reset the perPage property;
        this.setState({
            perPage: 10000,
        }, () => {
            this.getInsitutionUsers()

            const usersData = [];
            this.state.apiData.forEach(item => {
                usersData.push({
                    "email": item.email,
                    "firstName": item.firstName,
                    "lastName": item.lastName,
                    "middleName": item.middleName,
                    "institution": item.institution?.name,
                    "branch": item.branch ? item.branch.branchName : "-",
                    "userType": item.user_type,
                    "userRole": item.userRole.name,
                    "createdAt": SplitDate(item.created_at).dateTime,
                    "updatedAt": SplitDate(item.updated_at).dateTime,
                    "status": item.status,
                    "username": "-",
                    "phone_no": item.phone_no,
                    "lastLoginDate": item.lastLoginDate ? SplitDate(item.lastLoginDate).dateTime : "-",
                })
            })

            const csvData = objectToCSV(usersData)
            ExportToCSVFile(csvData, "institution_users.csv")

            this.props.ShowToast("Institution Users data exported successfully!", "information");

            //Reset to the previous value
            this.setState({ perPage: previousPerPage })
            this.getInsitutionUsers()

        })

    }

    handleChange = e => {
        const { name, value } = e.target;
        this.setState({ [name]: value }, () => {
            this.getInsitutionUsers();
            this.getBranches();
        });

        /**
         * This is used to
         * filter the list
         * according to the
         * selected institution..
         *
         */

    }

    getInsitutions = () => {
        this.props.setIsLoaderVisible(true)
        const options = {
            method: 'GET',
            headers: this.headersData,
        }
        fetch(`${ApiPath}api/institution/fetch-institutions`, options)
            ?.then(response => {
                this.props.setIsLoaderVisible(false)
                return response.json();
            })
            .then(response => {
                if (response && response.ok === true) {
                    this.setState({ institutions: response.body.data.institutions.sort(DynamicSort("name")) })
                }
            })
            .catch(error => {
                this.props.ShowToast(error.message, "exclamation");
            })
    }

    buildQueryString = (institutionToUse, searchValue) => {
        //Check and make provision for other search parameters
        let returnValue = "";

        if (this.state.cbxInstitutionSelect === "All") {
            returnValue = `${ApiPath}api/user/fetch-institution-users?page=${this.state.currentSelectedPage}` +
                `&${this.state.searchField}=${searchValue}&limit=${this.state.perPage}`
        } else {
            returnValue = `${ApiPath}api/user/fetch-institution-users?page=${this.state.currentSelectedPage}` +
                `&institution=${institutionToUse}&${this.state.searchField}=${searchValue}&limit=${this.state.perPage}`
        }

        return returnValue;
    }

    getInsitutionUsers = () => {
        this.props.setIsLoaderVisible(true)
        const options = {
            method: 'GET',
            headers: this.headersData,
        }

        const institutionToUse = (sessionStorage.getItem("is_nibss_user")?.indexOf("NIBSS") > -1)
            ? this.state.cbxInstitutionSelect
            : sessionStorage.getItem("institution_slug")

        const searchValue = (this.state.searchField === "branch")
            ? this.state.branches?.find(item => item.branchName === this.state.searchValue)?.slug
            : this.state.searchValue;

        const queryString = this.buildQueryString(institutionToUse, searchValue)

        fetch(queryString, options)
            ?.then(response => {
                this.props.setIsLoaderVisible(false)
                return response.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false)
                if (response && response.ok) {
                    this.setState({
                        apiData: response.body.data.users,
                        metaData: response.body.data.meta,
                        fetchSuccessful: true,
                        createSuccessful: false,

                    }, () => {
                        this.setState({
                            perPage: this.state.metaData?.per_page,
                            lastPage: this.state.metaData?.last_page,
                            currentSelectedPage: this.state.metaData?.current_page,
                        }, () => {
                            const arrayOfPages = [];
                            //Set this array for the looping
                            for (let i = 1; i <= this.state.lastPage; i++) {
                                arrayOfPages.push({ "id": i, "name": "Item " + i });
                            }

                            this.setState({
                                arrayPages: arrayOfPages,
                            })
                        })
                    })
                }
            })
    }

    filterRecords = () => {
        this.getInsitutionUsers();
    }

    render() {

        let counter = 0;
        const isNibssUser = sessionStorage.getItem("is_nibss_user")?.indexOf("NIBSS") > -1 ? true : false;

        /**
         * This (createSuccessful) ensures that the table
         * refreshes
         * after each time
         * a record
         * is created
         * edited
         * or deleted
         */

        if (this.state.createSuccessful) {
            this.getInsitutions()
            this.getInsitutionUsers()
            this.getUserRoles()

            this.setState({
                createSuccessful: false,
            })
        }

        const contactEmails = [];
        const contactNames = [];
        const phoneNos = [];

        const status = [{ "item_name": "Active" }, { "item_name": "Inactive" }];

        const usersList = this.state.apiData;

        usersList.forEach(item => {
            contactEmails.push({ "item_name": item.email })
            phoneNos.push({ "item_name": item.phone_no || item.phoneNo || "-" })
            contactNames.push({ "item_name": item.firstName });
            contactNames.push({ "item_name": item.lastName });

        })

        const branchNames = this.state.branches?.map(item => {
            return ({ "item_name": item.branchName });
        })

        return (
            <>
                <div className="title">
                    {!isNibssUser ? <h3>Institution Users</h3> : <div className="input-group special-select mt-2" style={{ marginTop: 30 }}>
                        <select
                            id="cbxInstitutionSelect"
                            name="cbxInstitutionSelect"
                            className="custom-select"
                            onChange={this.handleChange}
                            value={this.state.cbxInstitutionSelect}
                            style={{ width: 300, paddingTop: '7px!important', paddingBottom: '7px!important' }}>
                            <option value="All">All Institutions</option>
                            {
                                (this.state.institutions.map(item => {
                                    return (<option key={item.slug} value={item.slug}>{item.name}</option>)
                                }))
                            }
                        </select>
                        <label className="control-info"><i className="icofont-warning-alt"></i> Select institution from list</label>
                    </div>}

                    <SearchComponent
                        setSearchField={val => this.setState({ searchField: val })}
                        setSearchValue={val => this.setState({ searchValue: val })}
                        handleChange={this.handleChange}
                        ReferenceSearchList={UsersSearchList}
                        filterRecords={this.filterRecords}
                        ShowToast={this.props.ShowToast}
                        inputList={{ branchNames, status, contactEmails, contactNames, phoneNos }}
                    />

                    <div className="buttons">
                        <button
                            type="button"
                            className="btn-grey t-tip mr-1"
                            data-title="Create User"
                            id="btnCreateUser"
                            onClick={() => this.setState({ createClicked: true })}><i className="ion-person-add" /> Create Institution User
                        </button>
                        <button
                            type="button"
                            className="btn-green t-tip"
                            data-title="Export as CSV File"
                            onClick={this.handleExportUsers}
                            id="btnExport"
                        >
                            <i className="icofont-airplane-alt" /> Export
                        </button>
                    </div>
                </div>

                <div className="table-container">
                    <table className="bordered-table">
                        <caption></caption>
                        <thead>
                            <tr>
                                <th scope="col" className="align-center">#</th>
                                <th scope="col" className="align-left">EMAIL</th>
                                <th scope="col" className="align-left">FIRSTNAME</th>
                                <th scope="col" className="align-left">Lastname</th>
                                <th scope="col" className="align-left">Institution</th>
                                <th scope="col" className="align-left">Branch</th>
                                <th scope="col" className="align-left">User Type</th>
                                <th scope="col" className="align-left">User Role</th>
                                <th scope="col" className="align-left">Last Login Date</th>
                                <th scope="col" className="align-center">Status</th>
                                <th scope="col" className="align-center" style={{ width: 150 }}>Action</th>
                            </tr>
                        </thead>
                        <tbody id="tbl_users">
                            {
                                //INSTITUTION USERS ROWS DISPLAY
                                this.state.fetchSuccessful && this.state.apiData.map(userAPI => {
                                    counter++;
                                    const anotherCounter = (this.state.currentSelectedPage - 1) * this.state.perPage + counter

                                    return (
                                        <InstitutionUsersTableRows
                                            key={userAPI.slug}
                                            counter={anotherCounter}
                                            apiData={userAPI}
                                            setIsCreateSuccessful={res => this.setState({ createSuccessful: res })}
                                            EditUser={this.EditInstitutionUser}
                                            ShowToast={this.props.ShowToast}
                                            setIsLoaderVisible={this.props.setIsLoaderVisible}
                                            branchList={this.state.branches}
                                        />
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <Pagination
                    setPerPage={value => this.setState({ perPage: value })}
                    setRefreshForm={value => this.setState({ createSuccessful: value })}
                    lastPage={this.state.lastPage}
                    setCurrentSelectedPage={value => this.setState({ currentSelectedPage: value })}
                    perPage={this.state.perPage}
                    currentSelectedPage={this.state.currentSelectedPage}
                    arrayPages={this.state.arrayPages}
                />

                {this.state.createClicked && <InstitutionUserFormData
                    ShowToast={this.props.ShowToast}
                    setIsLoaderVisible={this.props.setIsLoaderVisible}
                    institutionsArray={this.state.institutions}
                    rolesArray={this.state.apiUserRoles}
                    createClicked={this.state.createClicked}
                    setCreateClicked={() => this.setState({ createClicked: false })}
                    setIsCreateSuccessful={() => this.setState({ createSuccessful: true })}
                    sender={"Create"}
                    selectedInstitution={this.state.cbxInstitutionSelect}
                    branchList={this.state.branches}
                />}

                {this.state.editClicked && <InstitutionUserFormData
                    ShowToast={this.props.ShowToast}
                    setIsLoaderVisible={this.props.setIsLoaderVisible}
                    setIsCreateSuccessful={() => this.setState({ createSuccessful: true })}
                    institutionsArray={this.state.institutions}
                    rolesArray={this.state.apiUserRoles}
                    rowData={this.state.rowData}
                    createClicked={this.state.editClicked}
                    setCreateClicked={value => this.setState({ editClicked: value })}
                    selectedInstitution={this.state.cbxInstitutionSelect}
                    sender={"Edit"}
                    branchList={this.state.branches}

                />}

                <button id="testButton" className="d-none" onChange={this.handleChange}></button>

            </>
        )

    }

}

export default InstitutionUsersForm;
