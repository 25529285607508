import { Link } from "react-router-dom";

const QuickAccessItem = (props) => {
     /**
     * This quick access form
     * basically
     * allows us to have
     * a quick view of
     * application shortcuts.
     *
     * These are the
     * items for
     * each of
     * the
     * quick access short-cuts...
     */

    return (
        <div className="access-grid-item">
            <h2>{props.id}</h2>
            <img src={props.imgURL} alt="" />
            <div className="grid-item-body">
                <h3 className="item-title">{props.title}</h3>
                <p>{props.description}</p>
                <Link to={props.pageURL} >Go &gt;&gt;</Link>
            </div>

        </div>
    );
}

export default QuickAccessItem;
