import React from "react"
import CreateScheduledBillingDialog from "./create-scheduled-billing-dialog"
import ScheduledBillingTableRow from "./scheduled-billing-table-row"
import ApiPath from "../../../admin/assets/App/api-path";
import Pagination from "../../../admin/assets/App/Pagination"

export default class ScheduledBillingForm extends React.Component {

    constructor() {
        super()
        this.state = {
            actionSuccessful: false,
            apiData: [],
            metaData: [],
            viewData: [],
            editData: [],
            fetchSuccessful: false,
            //Pagination items
            currentSelectedPage: 1,
            perPage: localStorage.getItem("items_per_page") || 20,
            arrayPages: [],
            lastPage: 1,
            totalItems: 0,
            //Display logics
            displayCreateDialog: false,
            displayEditDialog: false,
            billingServices: [],
        }
    }

    componentDidMount() {
        this.fetchBillingsData();
        this.fetchService()
    }

    fetchBillingsData = () => {
        /**
         * Get the data to be displayed on the form
         */
        this.setState({ fetchSuccessful: true })
        const Options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                'Cache-Control': 'public, max-age=0'
            },
        }

        this.props.setIsLoaderVisible(true);

        fetch(`${ApiPath}api/scheduled-billing/get-scheduled-billings?page=${this.state.currentSelectedPage}`, Options)
            ?.then(response => {
                this.props.setIsLoaderVisible(false);

                return response.json();
            })
            ?.then(response => {
                this.props.setIsLoaderVisible(false);

                if (response && response.ok) {
                    this.setState({
                        apiData: response.body.scheduledBillings.data,
                        metaData: response.body.scheduledBillings.meta,
                        fetchSuccessful: true,
                    }, () => {
                        //Pagination Details
                        const pArray = []
                        this.setState({
                            lastPage: this.state.metaData?.last_page,
                            currentSelectedPage: this.state.metaData?.current_page,
                            totalItems: this.state.metaData?.total,
                        }, () => {
                            //Set this array for the looping
                            for (let i = 1; i <= this.state.lastPage; i++) {
                                pArray.push({ "id": i, "name": "Item " + i });
                            }

                            this.setState({ arrayPages: pArray })
                        })

                    })
                } else {
                    this.props.ShowToast("Error fetching data! Please try again after some time.", "exclamation")
                }
            })
    }

    fetchService = () => {
        const Options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                'Cache-Control': 'public, max-age=0'
            },
        }
        fetch(ApiPath + 'api/billing/get-billings?limit=20000', Options)
            ?.then(response => {
                return response.json();
            })
            ?.then(response => {
                if (response && response.ok) {
                    this.setState({
                        billingServices: response.body.billings.data,
                    })
                }
            })
    }

    RefreshForm = () => {
        window.location.reload();
    }

    render() {

        if (this.state.actionSuccessful) {
            this.fetchBillingsData();
            this.setState({
                actionSuccessful: false,
            })
        }

        const ScheduledBillingRows = this.state.fetchSuccessful && this.state.apiData.map((item, index) => {
            return (
                <ScheduledBillingTableRow
                    key={item.slug}
                    data={item}
                    counter={index + 1}
                    billingServices={this.state.billingServices}
                    ShowToast={this.props.ShowToast}
                    setIsLoaderVisible={this.props.setIsLoaderVisible}
                    setActionSuccessful={value => this.setState({ actionSuccessful: value })}
                />
            )
        })

        return (
            <>
                <div className="title">
                    <h3>Scheduled Billing Summary</h3>
                    <div className="buttons">
                        <div className="form-inline">
                            <div className="form-group">

                            </div>
                        </div>
                        <button
                            id="btnCreate"
                            type="button"
                            className="btn-grey t-tip mr-1"
                            data-title="Create Scheduled Billing"
                            onClick={() => this.setState({ displayCreateDialog: true })}>
                            <i className="bx bxs-timer"></i> Create Scheduled Billing
                        </button>
                        <button
                            id="btnRefresh"
                            type="button"
                            className="btn-green t-tip mr-1"
                            data-title="Refresh"
                            onClick={this.RefreshForm}>
                            <i className="icofont-refresh"></i> Refresh
                        </button>
                    </div>
                </div>
                <div className="table-container">
                    <table className="bordered-table">
                        <caption></caption>
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col" className="align-left">SERVICE</th>
                                <th scope="col" className="align-left">TYPE</th>
                                <th scope="col" className="align-left">RUN DATE</th>
                                <th scope="col" className="align-left">DATE CREATED</th>
                                <th scope="col" className="align-left">DATE MODIFIED</th>
                                <th scope="col" className="align-left">CREATED BY</th>
                                <th scope="col" className="align-left">MODIFIED BY</th>
                                <th scope="col" className="align-left">EMAILS</th>
                                <th scope="col" className="align-center">ACTION</th>
                            </tr>
                        </thead>
                        <tbody id="tbl_billings">
                            {ScheduledBillingRows}
                        </tbody>
                    </table>
                </div>
                <Pagination
                    currentSelectedPage={this.state.currentSelectedPage}
                    setCurrentSelectedPage={value => this.setState({ currentSelectedPage: value })}
                    setRefreshForm={value => this.setState({ actionSuccessful: value })}
                    perPage={this.state.perPage}
                    setPerPage={value => this.setState({ perPage: value })}
                    lastPage={this.state.lastPage}
                    arrayPages={this.state.arrayPages}
                />

                {
                    this.state.displayCreateDialog && <CreateScheduledBillingDialog
                        setDisplayCreateDialog={value => this.setState({ displayCreateDialog: value })}
                        displayCreateDialog={this.state.displayCreateDialog}
                        ShowToast={this.props.ShowToast}
                        setIsLoaderVisible={this.props.setIsLoaderVisible}
                        setCreateSuccessful={val => this.setState({ actionSuccessful: val })}
                        billingServices={this.state.billingServices}

                    />
                }

            </>
        )
    }

}

