const TrappedCardsRoleGroup = (props) => {

    const setLoggerChanges = e => {
        props.setTrappedCards(e.target.id);
        props.setTrappedCardsCode(3);
    }

    const setReceiverChanges = e => {
        props.setTrappedCards(e.target.id);
        props.setTrappedCardsCode(4);
    }

    const setLoggerReceiverChanges = e => {
        props.setTrappedCards(e.target.id);
        props.setTrappedCardsCode(5);
    }

    const clearValues = () => {
        props.setTrappedCards('');
        props.setTrappedCardsCode('');
    }

    const logger = "Logger";
    const receiver = "Receiver";
    const loggerReceiver = "LoggerReceiver"

    return (
        <div className="role-group">
            <h3 className="role-group-title">Trapped Cards</h3>
            <div className="role-group-inputs">
                <label>
                    <input
                        type="radio"
                        id={logger}
                        name="trappedCards"
                        checked={props.trappedCards === logger}
                        onChange={setLoggerChanges}
                    /> {logger}
                </label>
                <label>
                    <input
                        type="radio"
                        id={receiver}
                        name="trappedCards"
                        checked={props.trappedCards === receiver}
                        onChange={setReceiverChanges}
                    /> {receiver}
                </label>
                <label>
                    <input
                        type="radio"
                        id={loggerReceiver}
                        name="trappedCards"
                        checked={props.trappedCards === loggerReceiver}
                        onChange={setLoggerReceiverChanges}
                    /> {loggerReceiver}
                </label>
                <button
                    className="clear-radios btn-grey"
                    id="btnClearTrappedCards"
                    onClick={clearValues}>
                    <i className="icofont-mop"></i> Clear
                </button>
            </div>
        </div>
    );
}

export default TrappedCardsRoleGroup;
