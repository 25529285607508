const toTwoDigits = (theNumber) => {
    let returnNo = "";
    if (theNumber < 10) {
        returnNo = `0${theNumber}`
    } else {
        returnNo = theNumber;
    }
    return ( returnNo );
}

export default toTwoDigits;
