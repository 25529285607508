const DateDiff = (d1, d2) => {

    const t1 = new Date(d1).getTime();
    const t2 = new Date(d2).getTime();

    const diff = Math.abs(t2 - t1);
    return (
        {
            days: (diff / 1000 / 60 / 60 / 24) + 1,
            hours: diff / 1000 / 60 / 60,
            minutes: diff / 1000 / 60
        }

     );
}

export default DateDiff
