import ApiPath from "../../admin/assets/App/api-path"
import React from 'react'
import validateEmail from '../../admin/assets/App/validate-email'

class ForgotPasswordBody extends React.Component {
    constructor() {
        super()
        this.state = {
            txtEmail: "",
        }

        this.handleClick = this.handleClick.bind(this)
        this.handleChange = this.handleChange.bind(this)

    }

    handleClick() {
        try {

            if (!validateEmail(this.state.txtEmail)) {
                this.props.ShowToast("Valid Email required!", "exclamation")
                return;
            }

            //Else proceed
            this.props.setIsLoaderVisible(true)

            const data = { "email": this.state.txtEmail };
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'public, max-age=0'
                },
                body: JSON.stringify(data)
            }
            fetch(ApiPath + 'api/auth/forgot-password', options)
                .then(response => {
                    this.props.setIsLoaderVisible(false);
                    return response.json();
                })
                .then(response => {
                    this.props.setIsLoaderVisible(false)
                    if (response) {
                        if (response.ok === true) {
                            this.props.ShowToast("An email has been sent to your mailbox. Check and follow the link to reset your password.", "information")
                        } else {
                            this.props.ShowToast(response.message, "exclamation")
                        }
                    } else {
                        this.props.ShowToast("Invalid login credentials", "exclamation");
                    }
                })
                .catch(error => {
                    this.props.setIsLoaderVisible(false)
                    this.props.ShowToast(error.message, "exclamation")
                })

        } catch (error) {
            this.props.ShowToast(error.toString(), "exclamation");
        }
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    render() {

        return (
            <div className="login-data">
                <h3><i className="icofont-mr icofont-key"></i>Forgot Password</h3>
                <div className="login-details">
                    <h4>Enter your registered email. A link will be sent to your account</h4>
                    <div className="controls">
                        <span className="bx bx-mail-send"></span>
                        <input type="email" id="txtEmail" name="txtEmail" onChange={this.handleChange} value={this.state.txtEmail} placeholder="youremailaddy@mail.com" />
                    </div>

                    <button type="button" id="btnVerify" onClick={this.handleClick}>Send Link</button>
                    <a href="login-validate">I already have code</a>
                </div>
                <div className="cant-access">
                    <label>"</label>
                </div>

            </div>

        )
    }

}


export default ForgotPasswordBody
