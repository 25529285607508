import React, { useState } from 'react';
import ApiPath from '../../admin/assets/App/api-path';
import { Enums } from '../../_common/enums';

export const NewEventModal = (props) => {
  const [title, setTitle] = useState('');
  const [error, setError] = useState(false);

  function UpdateEvent() {
    try {
      props.setIsLoaderVisible(true)
      const data = { "title": title, "holidayDate": props.thisDate }
      const options = {
        method: 'POST',
        headers: Enums.headersData(sessionStorage.getItem("session_token")),
        body: JSON.stringify(data),
      }

      fetch(ApiPath + 'api/holiday/create-holiday', options)
        .then(response => {
          props.setIsLoaderVisible(false);
          return response.json();
        })
        .then(response => {
          props.setIsLoaderVisible(false)
          if (response) {
            if (response.ok === true) {
              props.ShowToast("Holiday saved!", "success")
            } else {
              props.ShowToast(response.message, "exclamation")
              return;
            }

          } else {
            props.ShowToast("Invalid login credentials", "exclamation")
          }
        })
        .catch(err => {
          props.setIsLoaderVisible(false)
          props.ShowToast(err.message, "exclamation")
        })

    } catch (err) {
      props.setIsLoaderVisible(false)
      props.ShowToast(err, "exclamation")
    }

}

return (
  <div id="modalBackDrop">
    <div id="newEventModal" className="lock-container">
      <div className="dialog-header">
        <h3 className="create"><i className="icofont-ui-calendar"></i> Create Holiday - ({props.thisDate})</h3>
      </div>

      <div className="dialog-body">
        <p>
          Enter the title of the Public Holiday:
        </p>
        <div className="input-group">
          <span className="icofont-event"></span>
          <input
            title="EventTitleInput"
            className={error ? 'error form-control' : 'form-control'}
            value={title}
            onChange={e => setTitle(e.target.value)}
            id="eventTitleInput"
            placeholder="Holiday Title"
          />
        </div>
      </div>

      <div className="dialog-footer mt-2">
        <button
          title="SaveButton"
          onClick={() => {
            if (title) {
              UpdateEvent();
              setError(false);
              props.onSave(title);

            } else {
              setError(true);
            }
          }}
          id="saveButton" className="btn-green mr-1">
          <i className="icofont-upload-alt"></i> Save</button>
        <button title="TestButton" className="d-none" onClick={UpdateEvent}></button>
        <button onClick={props.onClose} id="cancelButton" className="btn-grey">
          <i className="icofont-close"></i> Cancel</button>

      </div>

    </div>
  </div>
);
};
