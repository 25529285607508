import React from 'react'
class ViewUserRoleDialog extends React.Component {

    closeForm = () => {
        this.props?.setDisplayViewDialog(false);
    }

    render() {

        const dialogClass = this.props.displayViewDialog ? "dialog-background fade" : "dialog-background";
        return (
            <div className={dialogClass}>
                <div className="dialog-container">
                    <div className="dialog-header">
                        <h2 className="edit"><i className="icofont-user-alt-5"></i> User Roles Services</h2>
                    </div>
                    <div className="dialog-body">

                        <table className="dialog-table"><caption></caption>
                            <thead>
                                <tr>
                                    <th scope="col" className="align-left">#</th>
                                    <th scope="col" className="align-left">Service Name</th>
                                    <th scope="col" className="align-left">Service Role</th>
                                </tr>
                            </thead>
                            <tbody id="tbl_view_services">
                                {
                                    this.props.viewData?.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="align-left">{index + 1}.</td>
                                                <td className="align-left">{item.name}</td>
                                                <td className="align-left">{item.role}</td>
                                            </tr>
                                        )
                                    })
}
                            </tbody>
                        </table>

                        <hr className="mb-2" />
                    </div>
                    <div className="dialog-footer">
                        <button type="button" className="btn-grey mr-2" id="btnCancel" onClick={this.closeForm}><i className="icofont-exit">
                            </i> Close
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default ViewUserRoleDialog
