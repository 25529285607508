import React from "react";
import ApiPath from "../../admin/assets/App/api-path";
import NigerianStates from "../../admin/assets/App/nigerian-states";

export default class CreateBranchDialog extends React.Component {
    constructor() {
        super()
        this.state = {
            cbxInstitution: "",
            txtBranch: "",
            txtBranchCode: "",
            cbxState: "",
            cbxLGA: "",
            txtAddress: "",
            txtEmail: "",
            txtPhone: "",
            LGAValues: [],
        }

        this.txtAccountUser = `${sessionStorage.getItem("session_first_name")} ${sessionStorage.getItem("session_last_name")}`;
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)

    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value })
        if (name === "cbxState") {
            this.setState({ cbxState: value }, () => {
                this.populateLGAs(this.state.cbxState)
            })
        }
    }

    populateLGAs(stateValue) {
        this.setState({ LGAValues: [] })
        const option = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                'Cache-Control': 'public, max-age=0'
            },
        }
        fetch(ApiPath + `api/misc/fetch-lgas?state=${stateValue}`, option)
            ?.then(response => (response.json()))
            .then(response => {
                if (response.ok) {
                    this.setState({ LGAValues: response.body.lgas })
                }
            }).catch(err => {
                this.props.ShowToast(err.message + " - LGAs...", "exclamation")
            })
    }

    handleSubmit() {

        if (this.state.cbxInstitution === "") {
            this.props.ShowToast("Institution Required.", "exclamation")
            return;
        } else if (this.state.txtBranch === "") {
            this.props.ShowToast("Branch Name required", "exclamation");
            return;
        } else if (this.state.txtBranchCode === "") {
            this.props.ShowToast("Branch Code required", "exclamation");
            return;
        } else if (this.state.cbxState === "") {
            this.props.ShowToast("State required", "exclamation");
            return;

        } else if (this.state.cbxLGA === "") {
            this.props.ShowToast("LGA required.", "exclamation");
            return;
        } else if (this.state.txtAddress === "") {
            this.props.ShowToast("Branch address required.", "exclamation");
            return;

        } else if (this.state.txtEmail === "") {
            this.props.ShowToast("Valid email required.", "exclamation");
            return;
        } else if (this.state.txtPhone === "" || this.state.txtPhone.length < 11) {
            this.props.ShowToast("Valid 11-digit Phone Number required.", "exclamation");
            return;
        }

        ///Else, proceed...
        this.props.setIsLoaderVisible(true);
        const data = {
            "lga": this.state.cbxLGA,
            "address": this.state.txtAddress,
            "institution": this.state.cbxInstitution,
            "branchName": this.state.txtBranch,
            "branchCode": this.state.txtBranchCode,
            "state": this.state.cbxState,
            "contactEmail": this.state.txtEmail,
            "phoneNo": this.state.txtPhone,

        };

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                'Cache-Control': 'public, max-age=0'
            },
            body: JSON.stringify(data),
        }

        fetch(ApiPath + 'api/branch/create-branch', options)
            ?.then(response => {
                this.props.setIsLoaderVisible(false)
                return response.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false)

                if (response) {
                    if (response.ok === true) {
                        this.props.ShowToast("Branch created successfully!", "success")
                        this.props.setUpdateSuccessful(true)
                        this.closeForm()
                    } else {
                        this.props.ShowToast(response.message, "exclamation")
                    }
                }
            })
            .catch(error => {
                this.props.ShowToast(error.message, "exclamation")
            })
    }

    closeForm() {
        document?.querySelector(".create-branch")?.classList.toggle("fade");
    }

    render() {
        const isNibssUser = sessionStorage?.getItem("is_nibss_user")?.indexOf("NIBSS") > -1 ? true : false;

        return (
            <div className="dialog-background create-branch">
                <div className="dialog-container">
                    <div className="dialog-header">
                        <h2 id="dialog-title" className="create"><i className="icofont-chart-flow-2"></i> Create Branch</h2>
                    </div>
                    <div className="dialog-body">
                        <div className="form-row">
                            <div className="input-group">
                                <label><i className="icofont-user-alt-3"></i> Account User</label>
                                <input type="text" name="txtAccountUser" className="form-control"
                                    value={this.txtAccountUser} disabled />
                            </div>
                        </div>
                        <hr className="golden-rule" /><h3 className="row-title">Branch Details</h3>
                        <div className={isNibssUser ? "form-row-2-1 mb-3" : "d-none"}>
                            <div className="input-group col-md">
                                <label><span>*</span>Institution Name</label>
                                <select
                                    name="cbxInstitution"
                                    className="custom-select"
                                    value={this.state.cbxInstitution}
                                    onChange={this.handleChange}>
                                    <option value=""></option>
                                    {//Return an array of institutions
                                        this.props.institutionsArray && this.props.institutionsArray.map(item => {
                                            return <option key={item.slug} value={item.slug}>{item.name}</option>
                                        })
                                    }

                                </select>
                                <label className="control-info"><i className="icofont-warning-alt"></i> Select Institution</label>
                            </div>
                        </div>
                        <div className="form-row-2-1">
                            <div className="input-group col-md">
                                <label><span>*</span>Branch Name</label>
                                <input type="text" name="txtBranch" className="form-control" required
                                    onChange={this.handleChange} value={this.state.txtBranch} autoComplete="off" />
                                <label className="control-info"><i className="icofont-warning-alt"></i> Type-in Branch Name</label>
                            </div>
                            <div className="input-group col-md">
                                <label><span>*</span>Branch Code</label>
                                <input
                                    type="text"
                                    name="txtBranchCode" className="form-control"
                                    required maxLength="14" autoComplete="off"
                                    onChange={this.handleChange}
                                    value={this.state.txtBranchCode} />
                                <label className="control-info"><i className="icofont-warning-alt"></i> Enter 10-Digit Branch Code</label>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="input-group">
                                <label><span>*</span>State</label>
                                <select
                                    name="cbxState"
                                    className="custom-select"
                                    value={this.state.cbxState}
                                    onChange={e => this.handleChange(e)}
                                >
                                    <option value=""></option>
                                    {
                                        NigerianStates.map((item, index) => {
                                            return (<option key={index} value={item}>{item}</option>)
                                        })
                                    }

                                </select>
                                <label className="control-info"><i className="icofont-warning-alt"></i> Select State from list</label>
                            </div>
                            <div className="input-group">
                                <label><span>*</span>Local Government Area</label>
                                <select
                                    name="cbxLGA"
                                    className="custom-select form-control"
                                    onChange={this.handleChange}
                                    value={this.cbxLGA}
                                >
                                    <option value=""></option>
                                    {
                                        this.state.LGAValues && this.state.LGAValues.map(item => {
                                            return (
                                                <option key={item.id} value={item.lga}>{item.lga}</option>
                                            )
                                        })
                                    }

                                </select>
                                <label className="control-info"><i className="icofont-warning-alt"></i> Select LGA of selected State</label>
                            </div>
                        </div>
                        <div className="form-row mb-3">
                            <div className="input-group">
                                <label><span>*</span>Address</label>
                                <textarea name="txtAddress" className="form-control" rows="5"
                                    onChange={this.handleChange} value={this.state.txtAddress}></textarea>
                                <label className="control-info"><i className="icofont-warning-alt"></i> Enter Branch Address</label>
                            </div>
                            <div className="input-group">
                                <label><span>*</span>Contact Email</label>
                                <input type="email" name="txtEmail" className="form-control"
                                    onChange={this.handleChange} value={this.state.txtEmail} required />
                                <label className="control-info"><i className="icofont-warning-alt"></i> Branch Email Address</label>

                                <label><span>*</span>Phone</label>
                                <input type="text" name="txtPhone" className="form-control" onChange={this.handleChange}
                                    value={this.state.txtPhone} required maxLength="11" />
                                <label className="control-info"><i className="icofont-warning-alt"></i> Branch contact Phone Number</label>
                            </div>
                        </div>
                    </div>

                    <hr className="mb-2" />
                    <div className="dialog-footer">
                        <button className="btn-green mr-1" name="btnUpdateInstitution" type="button"
                            onClick={this.handleSubmit} title="Create">
                            <i className="icofont-upload-alt"></i> Create
                        </button>
                        <button type="button" className="btn-grey mr-2" id="btnCancel" onClick={this.closeForm}>
                            <i className="icofont-exit" title="Cancel"></i> Cancel
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}
