import React from "react"
import { Enums } from "../../_common/enums";
import ApiPath from "../../admin/assets/App/api-path";
import { MessageBoxType as MsgBoxStyle } from "../../_common/constants"

export class OverDueTimeForm extends React.Component {

    constructor() {
        super();
        this.state = {
            txtOverdueTime: "",
            txtRequestFee: "",
            txtViewsFee: ""
        }

        this.headersData = {
            "Content-Type": Enums.applicationJson,
            "Authorization": `Bearer ${sessionStorage.getItem("session_token")}`,
            'Cache-Control': 'public, max-age=0'
        }
    }

    componentDidMount() {
        this.fetchClipOverdueTime();
    }

    fetchClipOverdueTime = () => {
        this?.props.setIsLoaderVisible(true);
        const options = {
            method: "GET",
            headers: this.headersData
        };

        fetch(ApiPath + "api/eclip-fee/get-eclip-fee", options)
            ?.then((response) => {
                this?.props.setIsLoaderVisible(false);
                return response.json();
            })
            ?.then((response) => {
                this?.props.setIsLoaderVisible(false);
                if (response && response.ok === true) {
                    //Success
                    this.setState({
                        txtOverdueTime: response.body.eclipFee?.overDueTime,
                        txtRequestFee: response.body.eclipFee?.eclipRequestFee,
                        txtViewsFee: response.body.eclipFee.eclipViewFee
                    });
                } else {
                    this.props.ShowToast(response.message, MsgBoxStyle.EXCLAMATION);
                }
            })
            ?.catch((error) => {
                this?.props.setIsLoaderVisible(false);
                this?.props.ShowToast(error.messsage, MsgBoxStyle.EXCLAMATION);
            });
    }

    handleSubmit = () => {
        /**
         * Updates the value
         * set to the txtOverdueTime
         * state to become the
         * new value for
         * overdue time
         */

        const errorMessage = "Amount must be numeric and cannot be empty!"

        //First, validate...
        if (this.state.txtRequestFee === "") {
            this.props.ShowToast(errorMessage, MsgBoxStyle.EXCLAMATION)
            document.getElementById("txtRequestFee")?.classList.add(Enums.errorBorder);
            return;
        }
        if (this.state.txtViewsFee === "") {
            this.props.ShowToast(errorMessage, MsgBoxStyle.EXCLAMATION)
            document.getElementById("txtViewsFee")?.classList.add(Enums.errorBorder);
            return;
        }
        if (this.state.txtOverdueTime === "") {
            this.props.ShowToast(errorMessage, MsgBoxStyle.EXCLAMATION)
            document.getElementById("txtOverdueTime")?.classList.add(Enums.errorBorder);
            return;
        }

        //Else, proceed to update
        const data = {
            "eclipViewFee": parseFloat(this.state.txtViewsFee),
            "eclipRequestFee": parseFloat(this.state.txtRequestFee),
            "overDueTimeHour": parseInt(this.state.txtOverdueTime)
        }

        const options = {
            headers: this.headersData,
            method: "POST",
            body: JSON.stringify(data),
        }

        this.props.setIsLoaderVisible(true);

        fetch(`${ApiPath}api/eclip-fee/create-eclip-fee`, options)
            ?.then(res => {
                this.props.setIsLoaderVisible(false);
                return res.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false);
                if (response) {
                    if (response.ok) {
                        this.props.ShowToast("Overdue Time updated successfully!", MsgBoxStyle.SUCCESS);
                    } else {
                        this.props.ShowToast(response.message, MsgBoxStyle.EXCLAMATION);
                    }
                }
            })
            .catch(err => {
                this.props.setIsLoaderVisible(false);
                this.props.ShowToast(err.message, MsgBoxStyle.EXCLAMATION);
            })

    }

    handleChange = e => {
        const { name, value, id } = e.target;
        this.setState({
            [name]: value,
        }, () => document.getElementById([id])?.classList.remove(Enums.errorBorder));

        //clear the error border while the value is being changed
    }

    render() {

        return (
            <>
                <div className="form-row-1-1-2 mb-2 mt-2">
                    <div className="input-group">
                        <label><span>*</span>Request Fee</label>
                    </div>
                    <div className="input-group">
                        <input
                            className="form-control align-right"
                            id="txtRequestFee"
                            name="txtRequestFee"
                            value={this.state.txtRequestFee}
                            onChange={this.handleChange}
                            type="number"
                        />
                        <label className="control-info align-right"><i className="icofont-warning-alt"></i> (Naira and Kobo)</label>
                    </div>
                </div>
                <div className="form-row-1-1-2 mb-2">
                    <div className="input-group">
                        <label><span>*</span>Views Fee</label>
                    </div>
                    <div className="input-group">
                        <input
                            className="form-control align-right"
                            id="txtViewsFee"
                            name="txtViewsFee"
                            value={this.state.txtViewsFee}
                            onChange={this.handleChange}
                            type="number"
                        />
                        <label className="control-info align-right"><i className="icofont-warning-alt"></i> (Naira and Kobo)</label>
                    </div>
                </div>
                <div className="form-row-1-1-2 mb-2">
                    <div className="input-group">
                        <label><span>*</span>Overdue Time Fee</label>
                    </div>
                    <div className="input-group">
                        <input
                            className="form-control align-right"
                            id="txtOverdueTime"
                            name="txtOverdueTime"
                            value={this.state.txtOverdueTime}
                            onChange={this.handleChange}
                            type="number"
                        />
                        <label className="control-info align-right"><i className="icofont-warning-alt"></i> (Hours)</label>
                    </div>
                </div>
                <div className="form-row-1-1-2">
                    <div></div>
                    <button
                        type="button"
                        id="btnSubmit"
                        className="btn-green"
                        onClick={this.handleSubmit}
                    >
                        <i className="icofont-upload-alt" /> Update
                    </button>
                </div>
            </>
        )
    }

}
