import React from "react"
import ApiPath from "../../admin/assets/App/api-path";
import CapitalizeFirstLetter from "../../admin/assets/App/CapitalizeFirstLetter";
import ValidateEmail from "../../admin/assets/App/validate-email"

class NIBSSUserFormData extends React.Component {

    constructor() {
        super()
        this.state = {
            txtAccountUser: `${sessionStorage.getItem("session_first_name")} ${sessionStorage.getItem("session_last_name")}`,
            txtEmail: "",
            txtUsername: "",
            txtLastname: "",
            txtFirstname: "",
            cbxNibssUserType: "",
            userTypes: [],
            slug: "",
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.closeForm = this.closeForm.bind(this)

        this.headerData = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
            'Cache-Control': 'public, max-age=0'
        }
    }

    componentDidMount() {
        this.FetchNIBSSUserTypes()

        if (this.props.rowData) {
            //Set the initial
            //values based on the
            //input values...
            this.setState({
                txtEmail: this.props.rowData.email,
                txtUsername: this.props.rowData.username,
                txtLastname: this.props.rowData.lastName,
                txtFirstname: this.props.rowData.firstName,
                cbxNibssUserType: this.props.rowData.userRole?.slug,
                slug: this.props.rowData.slug,
            })
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value })
    }

    handleSubmit() {

        //Handles the posting of new/edited data
        //To the database, based on the
        //specified and required inputs...
        if (this.state.cbxNibssUserType === "") {
            this.props.ShowToast("Select a valid NIBSS User type before proceeding.", "exclamation")
            return;
        }
        if (!ValidateEmail(this.state.txtEmail)) {
            this.props.ShowToast("Enter a valid email before proceeding", "exclamation");
            return;
        }
        if (this.state.txtFirstname === "") {
            this.props.ShowToast("Firstname required!", "exclamation");
            return;
        }
        if (this.state.txtLastname === "") {
            this.props.ShowToast("Lastname required!", "exclamation");
            return;
        }
        if (this.state.txtUsername === "") {
            this.props.ShowToast("Username required!", "exclamation");
            return;
        }

        ///Else, proceed...
        this.props.setIsLoaderVisible(true);
        const data = {
            "userRole": this.state.cbxNibssUserType,
            "firstName": this.state.txtFirstname,
            "lastName": this.state.txtLastname,
            "email": this.state.txtEmail,
            "username": this.state.txtUsername,
            "slug": this.props.rowData?.slug,
        };

        const options = {
            method: 'POST',
            headers: this.headerData,
            body: JSON.stringify(data),
        }

        const endpoint = this.props.sender === "Edit" ? 'api/nibbs-user/update-nibbs-user' : 'api/nibbs-user/create-nibbs-user';

        fetch(ApiPath + endpoint, options)
            ?.then(response => {
                return response.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false)
                if (response) {
                    if (response.ok === true) {
                        //Success
                        this.props.ShowToast("NIBSS User updated successfully!", "success")
                        this.props.setIsCreateSuccessful(true)
                        this.props.setCreateClicked();

                    } else {
                        this.props.ShowToast(response.message, "exclamation")
                        return;
                    }

                } else {
                    this.props.ShowToast("Invalid input value(s)", "exclamation")
                }
            })
            .catch(error => {
                this.props.setIsLoaderVisible(false)
                this.props.ShowToast(error.message, "exclamation")
            })

    }

    FetchNIBSSUserTypes() {
        const options = {
            method: 'GET',
            headers: this.headerData,
        }

        fetch(ApiPath + 'api/nibbs-user/fetch-nibbs-user-roles', options)
            ?.then(response => {
                return response.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false)
                if (response) {
                    if (response.ok === true) {
                        this.setState({ userTypes: response.body.nibbsUserRoles })
                    }
                }
            })
    }

    closeForm() {
        this.props.setCreateClicked();
    }

    render() {

        const dialogClass = this.props.createClicked ? "dialog-background fade" : "dialog-background d-none";
        const buttonValue = this.props.sender === "Create" ? "Create" : "Update";

        return (
            <div className={dialogClass}>
                <div className="dialog-container">
                    <div className="dialog-header">
                        <h2 className={this.props.sender.toLowerCase()}><i className="icofont-users"></i> {this.props.sender} NIBSS User</h2>
                    </div>
                    <div className="dialog-body">
                        <div className="form-row">
                            <div className="input-group">
                                <label><i className="icofont-user-alt-3"></i> Account User</label>
                                <input type="text" name="txtAccountUser" className="form-control" value={this.state.txtAccountUser} disabled />
                            </div>
                        </div>
                        <hr className="golden-rule" /><h3 className="row-title">User Details</h3>
                        <div className="form-row-2-1">
                            <div className="input-group col-md">
                                <label><span>*</span>NIBSS User Type</label>
                                <select
                                    id="cbxNibssUserType"
                                    name="cbxNibssUserType"
                                    className="custom-select"
                                    value={this.state.cbxNibssUserType}
                                    onChange={this.handleChange}
                                >
                                    <option value=""></option>
                                    {
                                        this.state.userTypes.map(item => {
                                            return <option key={item.slug} value={item.slug}>{CapitalizeFirstLetter(item.name)}</option>
                                        })
                                    }

                                </select>
                            </div>

                        </div>

                        <div className="form-row">
                            <div className="input-group col-md">
                                <label><span>*</span>Firstname</label>
                                <input
                                    type="text"
                                    name="txtFirstname"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.txtFirstname}
                                    required />
                            </div>
                            <div className="input-group col-md">
                                <label><span>*</span>Lastname</label>
                                <input
                                    type="text"
                                    name="txtLastname"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.txtLastname}
                                    required />
                            </div>
                        </div>
                        <div className="form-row-2-1">
                            <div className="input-group col-md">
                                <label><span>*</span>Enter Email</label>
                                <input
                                    type="email"
                                    name="txtEmail"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.txtEmail}
                                    required />
                            </div>
                        </div>
                        <div className="form-row mb-3">
                            <div className="input-group col-md">
                                <label><span>*</span>Choose Username</label>
                                <input
                                    type="text"
                                    id="txtUsername"
                                    name="txtUsername"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.txtUsername}
                                    required />
                            </div>
                        </div>

                        <hr className="mb-2" />
                    </div>
                    <div className="dialog-footer">
                        <button
                            className="btn-green mr-1"
                            id="btnUpdateInstitution"
                            name="btnUpdateInstitution"
                            type="button"
                            onClick={this.handleSubmit}
                            title="Create">
                            <i className="icofont-upload-alt"></i> {buttonValue} NIBSS User
                        </button>
                        <button
                            type="button"
                            className="btn-grey mr-2"
                            id="btnCancel"
                            onClick={this.closeForm}>
                            <i className="icofont-exit" title="Cancel"></i> Cancel
                        </button>
                    </div>
                </div>
            </div>
        )
    }

}

export default NIBSSUserFormData;
