import React from "react";
import OutgoingReferencesTableRow from "./outgoing-references-table-row";
import ViewReferenceDialog from "./view-reference-dialog";
import ApiPath from "../../../admin/assets/App/api-path";
import EditOutgoingReference from "./edit-outgoing-reference";
import objectToCSV from "../../../admin/assets/App/object-to-csv";
import ExportToCSVFile from "../../../admin/assets/App/ExportToCSVFile";
import ReferenceSearchList from "../../../admin/assets/data/ReferenceSearchList"
import SearchComponent from "../../../admin/assets/App/SearchComponent";
import Pagination from "../../../admin/assets/App/Pagination";
import CancelReferenceDialog from "./cancel-reference-dialog";
import { LoadReferencesForExport } from "../../../admin/assets/functions/load-references";
import { DateRange } from "../../../components/date-range";

class OutgoingReferenceForm extends React.Component {
    constructor() {
        super()
        this.state = {
            actionSuccessful: false,
            apiData: [],
            apiStaticData: [],
            metaData: {},
            viewData: [],
            editData: [],
            refComments: [],
            exportData: [],
            institutions: [],
            fetchSuccessful: false,
            showCancelDialog: false,
            mandateExtension: "",

            searchField: "",
            searchValue: "",
            startDate: "",
            endDate: "",

            displayView: false,
            displayEdit: false,
            currentSelectedPage: 1,
            arrayPages: [],
            lastPage: 1,
            perPage: localStorage.getItem("items_per_page") || 20,
            totalItems: 0,
        }

        this.RefreshForm = this.RefreshForm.bind(this)
        this.options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                'Cache-Control': 'public, max-age=0'
            },
        };

    }

    ExportData = async () => {
        /**
         * Export the data
         * to an MSExcel
         * file after
         * filtering and
         * saving in a pre-defined
         * format... Consult the
         * requirements document(s)
         * We need to obtain the data then do the necessary...
         */
        //always first set the items to 1000, then
        //revert it after the export is done...
        const prevPerPage = this.state.perPage;
        this.setState({ perPage: 200000 }, () => {

            this.props.setIsLoaderVisible(true)
            const dateRangeFilter = (this.state.startDate !== '') ? `&createdAt=${this.state.startDate}&createdAt=${this.state.endDate}` : '';

            fetch(`${ApiPath}api/e-reference/view-outgoing-e-reference?page=${this.state.currentSelectedPage}` +
                `&${this.state.searchField}=${this.state.searchValue}&limit=${this.state.perPage}${dateRangeFilter}`, this.options)
                ?.then(response => {
                    return response.json();
                })
                .then(async response => {
                    this.props.setIsLoaderVisible(false)
                    if (response && response.ok === true) {
                        const apiOutput = response.body.references.data

                        //The export proper...
                        const DataToExport = [];
                        LoadReferencesForExport(apiOutput, DataToExport);

                        const csvData = objectToCSV(DataToExport);
                        ExportToCSVFile(csvData, "outgoing-references.csv");

                        this.props.ShowToast("Outgoing References exported successfully! Open downloads folder to preview...", "information");

                        //Return the number of items per page
                        this.setState({ perPage: prevPerPage }, () => this.getOutgoingReferences())

                    }
                })
        })
    }

    getOutgoingReferences = () => {
        /**
         * This contains
         * the API endpoint
         * for fetching
         * the outgoing
         * references for
         * any particular
         * institution
         */

        this.props.setIsLoaderVisible(true);
        const dateRangeFilter = (this.state.startDate !== '') ? `&createdAt=${this.state.startDate}&createdAt=${this.state.endDate}` : '';

        fetch(`${ApiPath}api/e-reference/view-outgoing-e-reference?page=${this.state.currentSelectedPage}` +
            `&${this.state.searchField}=${this.state.searchValue}&limit=${this.state.perPage}${dateRangeFilter}`, this.options)
            ?.then(response => {
                return response.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false)
                if (response && response.ok === true) {
                    this?.setState({
                        apiData: response.body.references.data,
                        metaData: response.body.references.meta,
                        fetchSuccessful: true,
                        actionSuccessful: false,
                        arrayPages: [],

                    }, () => {
                        const arrayOfPages = [];
                        this.setState({
                            totalItems: this.state.metaData?.total,
                            lastPage: this.state.metaData?.last_page,
                            currentSelectedPage: this.state.metaData?.current_page,

                        }, () => {
                            //Set this array for the looping
                            for (let i = 1; i <= this.state.lastPage; i++) {
                                arrayOfPages.push({ "id": i, "name": "Item " + i });
                            }

                            this.setState({ arrayPages: arrayOfPages })
                        })
                    })
                } else {
                    this.props.setIsLoaderVisible(false);
                    this.props.ShowToast(response?.message, "exclamation");
                }
            })
    }

    filterRecords = () => {
        this.getOutgoingReferences();
    }

    RefreshForm() {
        window.location.reload();
    }

    componentDidMount() {
        this.getOutgoingReferences()
    }

    render() {

        let counter = 0;
        const TableRows = this.state.fetchSuccessful && this.state.apiData.map(item => {
            counter++;
            const itemCounter = (this.state.currentSelectedPage - 1) * this.state.perPage + counter

            return (<OutgoingReferencesTableRow
                key={counter}
                counter={itemCounter}
                ShowToast={this.props.ShowToast}
                item={item}
                showCancelDialog={value => this.setState({ showCancelDialog: value })}
                setEditData={value => this.setState({ editData: value })}
                setViewData={value => this.setState({ viewData: value })}
                setCommentsData={value => this.setState({ refComments: value })}
                setIsLoaderVisible={this.props.setIsLoaderVisible}
                setActionSuccessful={value => this.setState({ actionSuccessful: value })}
                setDisplayEdit={value => this.setState({ displayEdit: value })}
                setDisplayView={value => this.setState({ displayView: value })}
                setMandateExtension={value => this.setState({ mandateExtension: value })}

            />)
        })

        if (this.state.actionSuccessful) {
            this.getOutgoingReferences()
            this.setState({
                actionSuccessful: false,
            })
        }

        //Search/filter data...

        const referenceNo = [];
        const customerAccountName = [];
        const customerAccountNo = [];
        const refereeAccountName = [];
        const refereeAccountNo = [];

        this.state.apiData.forEach(item => {
            referenceNo.push({ item_name: item.referenceNo })
            customerAccountName.push({ item_name: item.customerAccountName })
            customerAccountNo.push({ item_name: item.customerAccountNumber })
            refereeAccountName.push({ item_name: item.refereeAccountName })
            refereeAccountNo.push({ item_name: item.refereeAccountNumber })
        })

        const actionStatus = [
            { "item_name": "rejected" },
            { "item_name": "pending" },
            { "item_name": "accepted" },
            { "item_name": "approved" },
            { "item_name": "disapproved" },
        ]

        const serviceIds = sessionStorage.getItem("service_ids")?.split(",") || ['2', '5', '8'];

        return (
            <>
                <div className="title">
                    <DateRange
                        ShowToast={this.props.ShowToast}
                        callback={this.filterRecords}
                        setDateFrom={val => this.setState({ startDate: val })}
                        setDateTo={val => this.setState({ endDate: val })}
                    />
                    <SearchComponent
                        setSearchField={val => this.setState({ searchField: val })}
                        setSearchValue={val => this.setState({ searchValue: val })}
                        ReferenceSearchList={ReferenceSearchList}
                        filterRecords={this.filterRecords}
                        ShowToast={this.props.ShowToast}
                        inputList={{
                            referenceNo,
                            customerAccountName,
                            customerAccountNo,
                            refereeAccountName,
                            refereeAccountNo,
                            actionStatus
                        }}
                    />
                    <div className="buttons">
                        <button
                            id="btnRefresh"
                            data-title="Refresh"
                            type="button"
                            className="btn-grey t-tip mr-1"
                            onClick={this.RefreshForm}>
                            <i className="icofont-refresh"></i> Refresh
                        </button>
                        <button
                            id="btnExport"
                            data-title="Export records to MsExcel file"
                            type="button"
                            className="btn-green t-tip"
                            onClick={this.ExportData}>
                            <i className="icofont-airplane-alt"></i> Export
                        </button>
                    </div>
                </div>
                <div className="table-container">
                    <table className="bordered-table"><caption></caption>
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col" className="align-left">REFERENCE NO</th>
                                <th scope="col" className="align-left">CUSTOMER A/C NO</th>
                                <th scope="col" className="align-left">CUSTOMER A/C NAME</th>
                                <th scope="col" className="align-left">REFEREE INSTITUTION</th>
                                <th scope="col" className="align-left">REFEREE BRANCH</th>
                                <th scope="col" className="align-left">REFEREE A/C NO</th>
                                <th scope="col" className="align-left">REFEREE A/C NAME</th>
                                <th scope="col" className="align-center">OVERDUE?</th>
                                <th scope="col" className="align-center" width="120">STATUS</th>
                                <th scope="col" className="align-center" width="140">ACTION</th>
                                {serviceIds?.indexOf('2') === -1 && <th scope="col" className="align-center" width="80">CANCEL</th>}
                            </tr>
                        </thead>
                        <tbody id="tbl_outgoing_references">
                            {TableRows}
                        </tbody>
                    </table>
                </div>
                <Pagination
                    setRefreshForm={value => this.setState({ actionSuccessful: value })}
                    setPerPage={value => this.setState({ perPage: value })}
                    perPage={this.state.perPage}
                    currentSelectedPage={this.state.currentSelectedPage}
                    setCurrentSelectedPage={value => this.setState({ currentSelectedPage: value })}
                    lastPage={this.state.lastPage}
                    arrayPages={this.state.arrayPages}
                />
                {
                    this.state.displayView && <ViewReferenceDialog
                        data={this.state.viewData}
                        commentsData={this.state.refComments}
                        displayView={this.state.displayView}
                        ShowToast={this.props.ShowToast}
                        setIsLoaderVisible={this.props.setIsLoaderVisible}
                        setDisplayView={value => this.setState({ displayView: value })}
                        setActionSuccessful={value => this.setState({ actionSuccessful: value })}
                        sender={"Outgoing Reference"}
                        mandateExtension={this.state.mandateExtension}

                    />
                }

                {
                    this.state.displayEdit && <EditOutgoingReference
                        data={this.state.editData}
                        ShowToast={this.props.ShowToast}
                        setIsLoaderVisible={this.props.setIsLoaderVisible}
                        displayEdit={this.state.displayEdit}
                        setDisplayEdit={value => this.setState({ displayEdit: value })}
                        setActionSuccessful={value => this.setState({ actionSuccessful: value })}
                    />
                }

                {
                    this.state.showCancelDialog && <CancelReferenceDialog
                        displayCancelDialog={this.state.showCancelDialog}
                        ShowToast={this.props.ShowToast}
                        data={this.state.editData}
                        setDisplayCancelDialog={value => this.setState({ showCancelDialog: value })}
                        setIsLoaderVisible={this.props.setIsLoaderVisible}
                        setActionSuccessful={value => this.setState({ actionSuccessful: value })}
                    />
                }
            </>
        )
    }
}

export default OutgoingReferenceForm;
