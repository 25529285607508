import React from "react";
import ScheduledBillingFormData from "./scheduled-billing-form-data";

export default class CreateScheduledBillingDialog extends React.Component {

    closeForm = () => {
        this.props.setDisplayCreateDialog(false)
    }

    render() {

        const dialogClass = this.props.displayCreateDialog ? "dialog-background fade" : "dialog-background";

        return (
            <div className={dialogClass}>
                <ScheduledBillingFormData
                    ShowToast={this.props.ShowToast}
                    setIsLoaderVisible={this.props.setIsLoaderVisible}
                    billingServices={this.props.billingServices}
                    closeForm={this.closeForm}
                    nameTag={"Create"}
                    setActionSuccessful={this.props.setCreateSuccessful}
                    setDisplayCreateDialog={this.props.setDisplayCreateDialog}
                />

                {/* For testing alone... Doesn't display... */}
                <input
                    type="checkbox"
                    className="d-none"
                    id="chkTest"
                    onChange={this.handleChange}
                />
                <input
                    type="text"
                    className="d-none"
                    id="txtTest"
                    name="cbxBillingType"
                    onChange={this.handleChange}
                />
            </div>
        )
    }
}
