import Badges from "../../admin/assets/App/Badges";
import CapitalizeFirstLetter from "../../admin/assets/App/CapitalizeFirstLetter";
import MaskCardNumber from "../../admin/assets/App/MaskCardNumber";
import { SplitDate } from "../../admin/assets/App/split-date";

const OutgoingCardsTableRows = (props) => {

    /**
    * This outgoing cards form
    * basically
    * allows us to have
    * a quick view of
    * application shortcuts.
    *
    * We need to add the
    * table and the
    * table rows to
    * follow the
    * expected
    * description on
    * the requirements
    * document...
    */

    /**
    * This outgoing cards form
    * basically
    * allows us to have
    * a quick view of
    * application shortcuts.
    *
    * We need to add the
    * table and the
    * table rows to
    * follow the
    * expected
    * description on
    * the requirements
    * document...
    */

    const statusClass = Badges(props.item.status);
    const actionStatus = CapitalizeFirstLetter(props.item.status);
    const cardSchemeImageURL = () => {
        let retVal = "";
        switch (props.item.cardScheme) {
            case "mastercard":
                retVal = "/assets/img/jpg/m-card.jpg";
                break;
            case "visa":
                retVal = "/assets/img/jpg/v-card.jpg";
                break;
            case "afrigo":
                retVal = "/assets/img/jpg/afri-go-card.jpg";
                break;
            default:
                retVal = "/assets/img/jpg/ve-card.jpg";
                break;
        }

        return retVal;
    }

    return (
        <>
            <tr>
                <td className="align-center">{props.counter}.</td>
                <td>{props.item.trappedCardNo}</td>
                <td>{MaskCardNumber(props.item.cardNumber)}</td>
                <td>{props.item.nameOnCard}</td>
                <td>{props.item.issuingInstitution?.name}</td>
                <td>{props.item.acquiringInstitution?.name}</td>
                <td>{props.item.createdByUser?.branch?.branchName || "-"}</td>
                <td className="align-center"><img src={cardSchemeImageURL()} alt="" width={40} /></td>
                <td>{SplitDate(props.item.createdAt).dateTime}</td>
                <td>{props.item.trappedCardLog?.acceptedByUser?.email
                    || props.item.trappedCardLog?.rejectedByUser?.email
                    || "-"}</td>
                <td><span className={statusClass}>{actionStatus}</span></td>
                <td className="align-center d-none">
                    <button
                        className="btn-green">
                        <i className="icofont-tick-mark"></i> Approve
                    </button>
                </td>


            </tr>

            <button
                className="d-none"
                id="testButton"
                onClick={cardSchemeImageURL}></button>
        </>
    );
}

export default OutgoingCardsTableRows;

/**
     * This create trapped cards form
     * basically
     * allows us to have
     * a quick view of
     * application shortcuts.
     *
     * We need to add the
     * table and the
     * table rows to
     * follow the
     * expected
     * description on
     * the requirements
     * document...
     */
/**
* This create trapped cards form
* basically
* allows us to have
* a quick view of
* application shortcuts.
*
* We need to add the
* table and the
* table rows to
* follow the
* expected
* description on
* the requirements
* document...
*/
/**
* This create trapped cards form
* basically
* allows us to have
* a quick view of
* application shortcuts.
*
* We need to add the
* table and the
* table rows to
* follow the
* expected
* description on
* the requirements
* document...
*/
