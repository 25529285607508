import React from "react"
import ApiPath from "../../admin/assets/App/api-path";
import NigerianStates from "../../admin/assets/App/nigerian-states"
import ValidateEmail from "../../admin/assets/App/validate-email"

class EditBranchDialog extends React.Component {

    constructor() {
        super()
        this.state = {
            txtAccountUser: `${sessionStorage.getItem("session_first_name")} ${sessionStorage.getItem("session_last_name")}`,
            cbxInstitution: "",
            txtBranch: "",
            txtAddress: "",
            txtBranchCode: "",
            cbxState: "",
            cbxLGA: "",
            txtEmail: "",
            txtPhone: "",
            institutionsArray: [],
            LGAValues: [],
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.closeForm = this.closeForm.bind(this)

        this.bearerData = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
            'Cache-Control': 'public, max-age=0'
        }

    }

    componentDidMount() {
        //For institution users, ensure only particular institution
        // is visible...
        if (sessionStorage.getItem("is_nibss_user")?.indexOf("NIBSS") > -1) {
            this.setState({
                institutionsArray: this.props.institutionsArray,
            })
        } else {
            this.setState({
                institutionsArray: [{ "slug": sessionStorage.getItem("institution_slug"), "name": sessionStorage.getItem("institution") }]
            })
        }

        if (this.props.rowData) {
            this.setState({
                txtBranch: this.props.rowData.branchName,
                txtBranchCode: this.props.rowData.branchCode,
                txtAddress: this.props.rowData.address,
                txtEmail: this.props.rowData.contactEmail,
                txtPhone: this.props.rowData.phoneNo,
            })
        }

        //Set the other data...
        this.setState({ cbxState: this.props.rowData?.state }, () => {
            this.populateLGAs(this.state.cbxState)
        })

        //Afterwards, set the LGA
        this.setState({
            cbxLGA: this.props.rowData?.lga,
            cbxInstitution: this.props.rowData?.institution?.slug,
        })
    }

    handleChange(e) {
        const { name, value, type, checked } = e.target;
        type === "checkbox" ? this.setState({ [name]: checked }) : this.setState({ [name]: value })
        if (name === "cbxState") {
            this.setState({ cbxState: value }, () => {
                this.populateLGAs(this.state.cbxState)
            })
        }
    }

    populateLGAs(stateValue) {
        const option = {
            method: 'GET',
            headers: this.bearerData,
        }
        fetch(ApiPath + `api/misc/fetch-lgas?state=${stateValue}`, option)
            .then(response => (response.json()))
            .then(response => {
                if (response.ok) {
                    this.setState({ LGAValues: response.body.lgas })
                }
            })
            .catch(err => {
                this.props.ShowToast(err.message + " - LGAs...", "exclamation")
            })
    }

    handleSubmit() {
        if (this.state.cbxInstitution === "") {
            this.props.ShowToast("Institution required.", "exclamation")
            return;

        } else if (this.state.txtBranchCode === "") {
            this.props.ShowToast("Branch Code required.", "exclamation");
            return;
        } else if (this.state.txtBranch === "") {
            this.props.ShowToast("Branch Name required.", "exclamation");
            return;
        } else if (this.state.cbxLGA === "") {
            this.props.ShowToast("LGA required.", "exclamation");
            return;

        } else if (this.state.cbxState === "") {
            this.props.ShowToast("State required.", "exclamation");
            return;

        } else if (this.state.txtAddress === "") {
            this.props.ShowToast("Branch Address required.", "exclamation");
            return;

        } else if (this.state.txtPhone === "") {
            this.props.ShowToast("Valid Phone Number required.", "exclamation");
            return;

        } else if (this.state.txtEmail === "" || !ValidateEmail(this.state.txtEmail)) {
            this.props.ShowToast("Valid email address required.", "exclamation");
            return;

        }

        ///Else, proceed...
        this.props.setIsLoaderVisible(true);
        const data = {
            "institution": this.state.cbxInstitution,
            "branchName": this.state.txtBranch,
            "branchCode": this.state.txtBranchCode,
            "state": this.state.cbxState,
            "lga": this.state.cbxLGA,
            "address": this.state.txtAddress,
            "contactEmail": this.state.txtEmail,
            "phoneNo": this.state.txtPhone,
            "slug": this.props.rowData.slug,
        };

        const options = {
            method: 'POST',
            headers: this.bearerData,
            body: JSON.stringify(data),
        }

        fetch(ApiPath + 'api/branch/update-branch', options)
            .then(response => {
                return response.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false)

                if (response && response.ok) {
                    this.props.ShowToast("Branch details updated successfully!", "success")
                    this.props.setIsEditSuccessful(true)
                    this.props.setEditClicked(false)
                } else {
                    this.props.ShowToast("Invalid input variables.", "exclamation")
                }
            })
            .catch(error => {
                this.props.setIsLoaderVisible(false)
                this.props.ShowToast(error.message, "exclamation")
            })

    }

    closeForm() {
        this.props.setEditClicked(false)
    }

    render() {
        const displayClass = (this.props.editClicked) ? "dialog-background edit fade" : "dialog-background edit";
        const isNibssUser = sessionStorage?.getItem("is_nibss_user")?.indexOf("NIBSS") > -1 ? true : false;

        return (
            <div className={displayClass}>
                <div className="dialog-container">
                    <div className="dialog-header">
                        <h2 id="dialog-title" className="edit"><i className="icofont-chart-flow-2"></i> View / Edit Branch</h2>
                    </div>
                    <div className="dialog-body">
                        <div className="form-row">
                            <div className="input-group">
                                <label><i className="icofont-user-alt-3"></i> Account User</label>
                                <input type="text" name="txtAccountUser" className="form-control" value={this.state.txtAccountUser} disabled />
                            </div>
                        </div>
                        <hr className="golden-rule" /><h2 className="row-title">Branch Details</h2>
                        <div className={isNibssUser ? "form-row-2-1 mb-3" : "d-none"}>
                            <div className="input-group col-md">
                                <label><span>*</span>Institution Name</label>
                                <select
                                    name="cbxInstitution"
                                    className="custom-select"
                                    value={this.state.cbxInstitution}
                                    onChange={this.handleChange}
                                    disabled
                                >
                                    <option value=""></option>
                                    {//Return an array of institutions
                                        this.state.institutionsArray.map(item => {
                                            return <option key={item.slug} value={item.slug}>{item.name}</option>
                                        })
                                    }
                                </select>
                                <label className="control-info"><i className="icofont-warning-alt"></i> Select Institution</label>
                            </div>
                        </div>
                        <div className="form-row-2-1">
                            <div className="input-group col-md">
                                <label><span>*</span>Branch Name</label>
                                <input
                                    type="text"
                                    name="txtBranch"
                                    className="form-control"
                                    required
                                    onChange={this.handleChange}
                                    value={this.state.txtBranch} />
                                <label className="control-info"><i className="icofont-warning-alt"></i> Type-in Branch Name</label>
                            </div>
                            <div className="input-group col-md">
                                <label><span>*</span>Branch Code</label>
                                <input
                                    type="text"
                                    name="txtBranchCode"
                                    className="form-control"
                                    required
                                    maxLength="14"
                                    onChange={this.handleChange}
                                    value={this.state.txtBranchCode} />
                                <label className="control-info"><i className="icofont-warning-alt"></i> Enter 10-digit Branch Code</label>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="input-group">
                                <label><span>*</span>State</label>
                                <select
                                    name="cbxState"
                                    className="custom-select"
                                    value={this.state.cbxState}
                                    onChange={this.handleChange}
                                >
                                    <option value=""></option>
                                    {
                                        NigerianStates.map((item, index) => {
                                            return (<option key={index} value={item}>{item}</option>)
                                        })
                                    }
                                </select>
                                <label className="control-info"><i className="icofont-warning-alt"></i> Select Nigerian state from list</label>
                            </div>
                            <div className="input-group">
                                <label><span>*</span>Local Government Area (LGA)</label>
                                <select
                                    className="custom-select form-control"
                                    name="cbxLGA"
                                    onChange={this.handleChange}
                                    value={this.state.cbxLGA}
                                >
                                    <option value=""></option>
                                    {
                                        this.state.LGAValues.map(item => {
                                            return (
                                                <option key={item.id} value={item.lga}>{item.lga}</option>
                                            )
                                        })
                                    }
                                </select>
                                <label className="control-info"><i className="icofont-warning-alt"></i> Select LGA of selected State</label>
                            </div>
                        </div>
                        <div className="form-row mb-3">
                            <div className="input-group">
                                <label><span>*</span>Address</label>
                                <textarea
                                    name="txtAddress"
                                    className="form-control"
                                    rows="5"
                                    onChange={this.handleChange}
                                    value={this.state.txtAddress}>
                                </textarea>
                                <label className="control-info"><i className="icofont-warning-alt"></i> Enter Branch Address</label>
                            </div>
                            <div className="input-group">
                                <label><span>*</span>Contact Email</label>
                                <input
                                    type="email"
                                    name="txtEmail"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.txtEmail}
                                    required />
                                <label className="control-info"><i className="icofont-warning-alt"></i> Branch Email Address</label>
                                <label><span>*</span>Phone</label>
                                <input
                                    type="text"
                                    name="txtPhone"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.txtPhone}
                                    required
                                    maxLength="11" />
                                <label className="control-info"><i className="icofont-warning-alt"></i> Branch contact Phone Number</label>
                            </div>
                        </div>
                    </div>
                    <hr className="mb-2" />
                    <div className="dialog-footer">
                        <button className="btn-green mr-1" id="btnUpdateInstitution" name="btnUpdateInstitution" type="button" onClick={() => this.handleSubmit()} title="Create">
                            <i className="icofont-upload-alt"></i> Update Branch
                        </button>
                        <button type="button" className="btn-grey mr-2" id="btnCancel" onClick={this.closeForm}><i className="icofont-exit" title="Cancel"></i> Cancel</button>
                    </div>
                </div>
            </div>
        )
    }
}
export default EditBranchDialog
