import React from "react"
import ApiPath from "../../admin/assets/App/api-path"
import { SplitDate } from "../../admin/assets/App/split-date"
import { MessageBoxType } from "../../_common/constants"

export const CreateEClipsDialog = props => {

    const updateEClips = () => {
        /**
         * Once all the
         * displayed values
         * are correct
         * this proceeds to
         * update to the
         * database using the
         * linked api /
         * endpoint..
         */

        const startDateTime = props.data?.footageTimeStart.split("T");
        const endDateTime = props.data?.footageTimeEnd.split("T");
        const myInstituionSlug = sessionStorage.getItem("institution_slug");

        const data = {
            category: props.data?.category.toLowerCase(),
            institution: props.data?.institutionArray.find(item => item.name === props.data?.institution)?.slug,
            referenceType: props.data?.transactionRefType,
            reference: props.data?.disputeRef,
            transactionDate: props.data?.transactionDate,
            amount: props.data?.disputeAmount,
            startDate: startDateTime[0],
            startTime: startDateTime[1],
            endDate: endDateTime[0],
            endTime: endDateTime[1],
            additionalInfo: props.data?.additionalInfo,
            receivingCode: props.data?.institutionArray.find(item => item.name === props.data?.institution)?.code,   //For the receiving institution code
            requestCode: props.data?.institutionArray.find(item => item.slug === myInstituionSlug)?.code,       //The requestion institution code

        }

        const options = {
            body: JSON.stringify(data),
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                'Cache-Control': 'public, max-age=0'
            },
        }

        //Try to update...
        props.setIsLoaderVisible(true);

        fetch(ApiPath + 'api/eclip/create-eclip', options)
            ?.then(response => {
                props.setIsLoaderVisible(false)
                return response.json();
            })
            .then(response => {

                if (response) {
                    if (response.ok === true) {
                        props.ShowToast("New Clip request logged successfully!", MessageBoxType.SUCCESS)
                        props.setDisplayDialog(false)
                        props.setClearData(true)

                    } else {
                        props.ShowToast(response.message, MessageBoxType.EXCLAMATION)
                    }

                } else {
                    props.ShowToast("Invalid input variables.", MessageBoxType.EXCLAMATION)
                }
            })
            .catch(error => {
                props.ShowToast(error.message, MessageBoxType.EXCLAMATION)
            })

    }

    const handleClose = () => {
        props.setDisplayDialog(false);
    }

    const dialogClass = (props.displayDialogClicked) ? "dialog-background fade" : "dialog-background"

    return (
        <>
            <div className={dialogClass}>
                <div className="dialog-container" style={{ "background": "#fff" }}>
                    <div className="dialog-header">
                        <h2 className="create"><i className="ion-ios-film-outline"></i> Submit New Clip Request</h2>
                    </div>
                    <div className="dialog-body">
                        <h4 className="mb-2" style={{ fontSize: 13, fontWeight: "normal" }}>Below are your inputs:</h4>
                        <table className="dialog-table"><caption></caption>
                            <thead>
                                <tr>
                                    <th scope="col" className="align-left">Field</th>
                                    <th scope="col" className="align-left">Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Category</td>
                                    <td>{props.data?.category}</td>
                                </tr>
                                <tr>
                                    <td>Institution</td>
                                    <td>{props.data?.institution}</td>
                                </tr>
                                <tr>
                                    <td>Transaction Ref Type</td>
                                    <td>{props.data?.transactionRefType}</td>
                                </tr>
                                <tr>
                                    <td>Dispute Reference</td>
                                    <td>{props.data?.disputeRef}</td>
                                </tr>
                                <tr>
                                    <td>Transaction Date</td>
                                    <td>{props.data?.transactionDate}</td>
                                </tr>
                                <tr>
                                    <td>Dispute Amount</td>
                                    <td>{props.data?.disputeAmount} (NGN)</td>
                                </tr>
                                <tr>
                                    <td>Footage Period</td>
                                    <td>{SplitDate(props.data?.footageTimeStart).dateTime} - {SplitDate(props.data?.footageTimeEnd).dateTime}</td>
                                </tr>
                                <tr>
                                    <td>Additional Request info</td>
                                    <td>{props.data?.additionalInfo}</td>
                                </tr>

                            </tbody>
                        </table>
                        <hr className="golden-rule" />
                    </div>
                    <div className="dialog-footer">
                        <button
                            className="btn-green mr-1"
                            id="btnSubmit"
                            name="btnSubmit"
                            type="button"
                            onClick={updateEClips}
                        ><i className="icofont-upload-alt"></i> Submit</button>
                        <button
                            id="btnCancel"
                            type="button"
                            className="btn-grey mr-2"
                            onClick={handleClose} >
                            <i className="icofont-exit"></i> Cancel
                        </button>
                    </div>
                </div>
            </div>

        </>
    )

}
