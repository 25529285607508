import React from 'react'
import ApiPath from '../../admin/assets/App/api-path'
import { SplitDate } from '../../admin/assets/App/split-date'
import objectToCSV from '../../admin/assets/App/object-to-csv'
import ExportToCSVFile from '../../admin/assets/App/ExportToCSVFile'
import SearchComponent from '../../admin/assets/App/SearchComponent'
import NIBSSUsersSearchList from '../../admin/assets/data/NIBSSUsersSearchList'
import NibssUsersTableRows from './nibss-users-table-rows'
import Pagination from '../../admin/assets/App/Pagination'
import NIBSSUserFormData from './nibss-user-form-data'

class NibssUsersForm extends React.Component {

    constructor() {
        super()
        this.state = {
            apiDataNIBSS: [],
            fetchSuccessful: false,
            createSuccessful: false,

            searchGroupState: false,
            searchField: "",
            searchValue: "",
            rowData: [],
            createClicked: false,
            editClicked: false,

            currentSelectedPage: 1,     //From here is for pagination management
            arrayPages: [],
            lastPage: 1,
            perPage: localStorage.getItem("items_per_page") || 20,
        }

        this.headersData = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
            'Cache-Control': 'public, max-age=0'
        }

    }

    componentDidMount() {
        this.getNIBSSUsersData()
    }

    getNIBSSUsersData = () => {
        this.props.setIsLoaderVisible(true)
        const options = {
            method: 'GET',
            headers: this.headersData,
        }

        fetch(`${ApiPath}api/nibbs-user/fetch-nibbs-users?page=${this.state.currentSelectedPage}` +
            `&limit=${this.state.perPage}&${this.state.searchField}=${this.state.searchValue}`, options)
            ?.then(response => {
                return response.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false)
                if (response && response.ok === true) {
                    this?.setState({
                        apiDataNIBSS: response.body.nibbsUsers.data,
                        metaData: response.body.nibbsUsers.meta,
                        fetchSuccessful: true,
                    }, () => {
                        const arrayPages = [];
                        this.setState({
                            lastPage: this.state.metaData?.last_page,
                            perPage: this.state.metaData?.per_page,
                            currentSelectedPage: this.state.metaData?.current_page,
                        }, () => {
                            //Set this array for the looping
                            for (let i = 1; i <= this.state.lastPage; i++) {
                                arrayPages.push({ "id": i, "name": "Item " + i });
                            }

                            this.setState({
                                arrayPages: arrayPages,
                            })
                        })
                    })
                }
            })
    }

    EditNibssUser = itemID => {
        /**
         * Select a particular row of
         * interest for the purpose of
         * edititng...
         * We have decided to use the
         * slug parameter for the
         * filtering...
         */
        const selArray = this.state.apiDataNIBSS.filter(itemList => itemList.slug === itemID)
        this.setState({
            rowData: selArray[0],
            editClicked: true,
        })
    }

    handleExportUsers = () => {
        //First, regard the filter...
        //And set the items per page to say 1000
        const previousPerPage = this.state.perPage;

        //Reset the perPage property;
        this.setState({
            perPage: 10000,
        }, () => this.getNIBSSUsersData())

        const exportData = [];

        this.state.apiDataNIBSS.forEach(item => {
            exportData.push({
                "email": item.email,
                "firstname": item.firstName,
                "lastname": item.lastName,
                "middlename": "-",
                "institution": "NIBSS",
                "branch": "-",
                "userType": "NIBSS User",
                "userRole": item.userRole.name,
                "createdAt": SplitDate(item.created_at).dateTime,
                "updatedAt": SplitDate(item.updated_at).dateTime,
                "status": item.status,
                "username": item.username,
                "phone_no": "-",
                "lastLoginDate": item.lastLoginDate ? SplitDate(item.lastLoginDate).dateTime : "-"
            })
        })

        const csvData = objectToCSV(exportData)
        ExportToCSVFile(csvData, "users.csv")

        this.props.ShowToast("Users data exported successfully!", "information");

        //Reset to the previous value
        this.setState({ perPage: previousPerPage }, () => this.getNIBSSUsersData())

    }

    filterRecords = () => {
        this.getNIBSSUsersData()
    }

    render() {

        let counter = 0;
        /**
         * This (createSuccessful) ensures that the table
         * refreshes
         * after each time
         * a record
         * is created
         * edited
         * or deleted
         */

        if (this.state.createSuccessful) {
            this.getNIBSSUsersData()

            this.setState({
                createSuccessful: false,
            })
        }

        const contactEmails = [];
        const contactNames = [];

        const status = [{ "item_name": "Active" }, { "item_name": "Inactive" }];

        this.state.apiDataNIBSS.forEach(item => {
            contactEmails.push({ "item_name": item.email })
            contactNames.push({ "item_name": item.firstName })
            contactNames.push({ "item_name": item.lastName })
        })

        return (
            <>
                <div className="title">
                    <h3>NIBSS Users</h3>

                    <SearchComponent
                        setSearchField={val => this.setState({ searchField: val })}
                        setSearchValue={val => this.setState({ searchValue: val })}
                        handleChange={this.handleChange}
                        ReferenceSearchList={NIBSSUsersSearchList}
                        filterRecords={this.filterRecords}
                        ShowToast={this.props.ShowToast}
                        inputList={{ status, contactNames, contactEmails }}
                    />

                    <div className="buttons">
                        <button
                            type="button"
                            className="btn-grey t-tip mr-1"
                            data-title="Create NIBSS User"
                            id="btnCreateNIBSSUser"
                            onClick={() => this.setState({ createClicked: true })}><i className="ion-person-add">
                            </i> Create NIBSS User
                        </button>
                        <button type="button" className="btn-green t-tip" data-title="Export as CSV File" id="btnExport"
                            onClick={this.handleExportUsers}>
                            <i className="icofont-airplane-alt"></i> Export
                        </button>
                    </div>
                </div>

                <div className="table-container">
                    <table className="bordered-table">
                        <caption></caption>
                        <thead>
                            <tr>
                                <th scope="col" className="align-center">#</th>
                                <th scope="col" className="align-left">Email</th>
                                <th scope="col" className="align-left">Firstname</th>
                                <th scope="col" className="align-left">Lastname</th>
                                <th scope="col" className="align-left">User Role</th>
                                <th scope="col" className="align-left">Last Login Date</th>
                                <th scope="col" className="align-center">Status</th>
                                <th scope="col" className="align-center" style={{ width: 150 }}>Action</th>
                            </tr>
                        </thead>
                        <tbody id="tbl_users">
                            {
                                //NIBSS USERS ROWS DiSPLAY
                                this.state.fetchSuccessful && this.state.apiDataNIBSS.map(item => {
                                    counter++
                                    const itemCounter = (this.state.currentSelectedPage - 1) * this.state.perPage + counter
                                    return (
                                        <NibssUsersTableRows
                                            key={item.slug}
                                            apiData={item}
                                            counter={itemCounter}
                                            setIsCreateSuccessful={value => this.setState({ createSuccessful: value })}
                                            EditUser={this.EditNibssUser}
                                            ShowToast={this.props.ShowToast}
                                            setIsLoaderVisible={this.props.setIsLoaderVisible}

                                        />
                                    )
                                })
                            }

                        </tbody>
                    </table>
                </div>
                <Pagination
                    setRefreshForm={value => this.setState({ createSuccessful: value })}
                    setPerPage={value => this.setState({ perPage: value })}
                    setCurrentSelectedPage={value => this.setState({ currentSelectedPage: value })}
                    lastPage={this.state.lastPage}
                    perPage={this.state.perPage}
                    currentSelectedPage={this.state.currentSelectedPage}
                    arrayPages={this.state.arrayPages}
                />

                {
                    this.state.createClicked && <NIBSSUserFormData
                        ShowToast={this.props.ShowToast}
                        setIsLoaderVisible={this.props.setIsLoaderVisible}
                        createClicked={this.state.createClicked}
                        setCreateClicked={() => this.setState({ createClicked: false })}
                        setIsCreateSuccessful={() => this.setState({ createSuccessful: true })}
                        sender={"Create"}
                    />
                }

                {
                    this.state.editClicked && <NIBSSUserFormData
                        ShowToast={this.props.ShowToast}
                        setIsLoaderVisible={this.props.setIsLoaderVisible}
                        setIsCreateSuccessful={() => this.setState({ createSuccessful: true })}
                        rowData={this.state.rowData}
                        createClicked={this.state.editClicked}
                        setCreateClicked={() => this.setState({ editClicked: false })}
                        sender={"Edit"}
                    />
                }

            </>
        )

    }

}

export default NibssUsersForm;
