const cardsValues = [
  {
    id: 1,
    numbers: 34,
    cases: "Total Cases",
    lastUpdatedTime: "3 hours ago",
    icofontClass: "icofont-ui-user-group",
  },
  {
    id: 2,
    numbers: 241,
    cases: "Total AMOs",
    lastUpdatedTime: "3 hours ago",
    icofontClass: "icofont-cloud",
  },
  {
    id: 3,
    numbers: 36,
    cases: "Total AMFs",
    lastUpdatedTime: "7 hours ago",
    icofontClass: "icofont-thunder-light",
  },
  {
    id: 4,
    numbers: 43,
    cases: "Total AMEs",
    lastUpdatedTime: "12 hours ago",
    icofontClass: "icofont-tools",
  },
];

export default cardsValues;
