
const ExportToCSVFile = (data, fileName, fileType = 'text/csv') => {
    /**
     * This function exports a file in the specified format
    /* If the file format is not specified, the default format
    /* of text/csv is applied...
     */
    let returnValue = false;

    const blob = new Blob([data], { type: fileType });
    const url = window.URL.createObjectURL(blob)
    const a = document?.createElement("a")
    a.setAttribute('hidden', '');
    a.setAttribute('href', url)
    a.setAttribute('download', fileName)
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    returnValue = true;

    return (
        returnValue
    )
}

export default ExportToCSVFile
