import NavBar from "../../admin/assets/App/nav-bar"
import TopBar from "../../admin/assets/App/topbar"
import LockedScreen from "../../admin/assets/App/LockedScreen";
import EDMMSMandatesForm from "./edmms-mandates-form";
import Footer from "../../admin/assets/App/footer";
import { useState } from 'react';
import { Helmet } from "react-helmet";

export const EDMMSMandates = (props) => {
    /**
     * THIS IS MORE OR LESS
     * THE HOST OF THE
     * CREATE E-DMMS
     * FORM, CONTAINING
     * THE PAGE'S BASIC STRUCTURE
     * AND COMPONENTS
     */
    const [mainClass, setMainClass] = useState("main");

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>New eDMMS Mandate | NIBSS Document Management Hub Portal</title>
            </Helmet>
            <LockedScreen
                setIsLoaderVisible={props.setIsLoaderVisible}
                ShowToast={props.ShowToast}
            />

            <div className="cont-ainer">
                <NavBar sender="New eDMMS Mandate" />
                <div className={mainClass}>
                    <TopBar
                        setMainClass={value => setMainClass(value)}
                    />
                    <div className="page-title">
                        <h2><i className="icofont-mr icofont-document-folder"></i>New eDMMS Mandate Request</h2>
                    </div>
                    <div className="branch-summaries">
                        <div className="summaries">

                            <EDMMSMandatesForm
                                ShowToast={props.ShowToast}
                                setIsLoaderVisible={props.setIsLoaderVisible}
                            />
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>

        </>
    )
}
