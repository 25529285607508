import React from "react"
import ApiPath from "../../admin/assets/App/api-path";
import ValidateEmail from "../../admin/assets/App/validate-email";

class InstitutionUserFormData extends React.Component {

    constructor() {
        super()
        this.state = {
            txtAccountUser: `${sessionStorage.getItem("session_first_name")} ${sessionStorage.getItem("session_last_name")}`,
            cbxInstitution: "",
            cbxBranch: "",
            cbxUserRole: "",
            txtEmail: "",
            txtLastname: "",
            txtFirstname: "",
            txtMiddlename: "",
            txtPhone: "",
            cbxUserType: "",
            institutionsData: [],
            branchData: [],
            filteredBranchData: [],
            userRoleData: [],
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.closeForm = this.closeForm.bind(this)
        this.headersData = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
            'Cache-Control': 'public, max-age=0'
        }

        this.isNibssUser = sessionStorage.getItem("is_nibss_user")?.indexOf("NIBSS") > -1 ? true : false;

    }

    handleChange = e => {
        const { name, value } = e.target;
        this?.setState({ [name]: value }, () => {
            if (name === "cbxInstitution") {
                //filter the branches to match the selected institution...
                this.filterDataForBranch();
            }
        })
    }

    filterDataForBranch = () => {
        document.querySelector(".branch-spinner")?.classList.remove("d-none");
        this.setState({
            filteredBranchData: this.state.branchData.filter(item => item.institution?.slug === this.state.cbxInstitution)
        })

        //hide again...
        document.querySelector(".branch-spinner")?.classList.add("d-none");
    }

    checkInputs = () => {
        let returnString = true;

        if (this.state.cbxUserType === "") {
            this.props.ShowToast("Select a valid User type before proceeding.", "exclamation")
            returnString = false;
        } else if (this.state.cbxUserRole === "") {
            this.props.ShowToast("User Role Required.", "exclamation")
            returnString = false;
        } else if (this.state.cbxInstitution === "") {
            this.props.ShowToast("Institution required.", "exclamation")
            returnString = false;
        } else if (this.state.txtFirstname === "") {
            this.props.ShowToast("First Name required", "exclamation");
            returnString = false;
        } else if (!ValidateEmail(this.state.txtEmail)) {
            this.props.ShowToast("Valid Email Address required", "exclamation");
            returnString = false;
        } else if (this.state.txtLastname === "") {
            this.props.ShowToast("Last Name required!", "exclamation");
            returnString = false;
        } else if (this.state.txtPhone.length > 0 && this.state.txtPhone.length < 11) {
            this.props.ShowToast("Enter valid phone number", "exclamation");
            returnString = false;
        }

        if (this.state.cbxInstitution !== "" && this.state.cbxUserType !== "admin" && this.state.cbxBranch === "") {
            this.props.ShowToast("Branch required when creating an institution user.", "exclamation");
            returnString = false;
        }

        return returnString;
    }

    handleSubmit = () => {

        //Validate inputs before actual update
        const output = this.checkInputs();

        if (!output) {
            //If the checkInputs function returns false, then return
            return;
        }
        ///Else, proceed...
        this.props.setIsLoaderVisible(true);
        const data = {
            "institution": this.state.cbxInstitution,
            "userType": this.state.cbxUserType,
            "branch": this.state.cbxBranch,
            "userRole": this.state.cbxUserRole,
            "email": this.state.txtEmail,
            "firstName": this.state.txtFirstname,
            "lastName": this.state.txtLastname,
            "middleName": this.state.txtMiddlename,
            "phoneNo": this.state.txtPhone,
            "slug": this.props.rowData?.slug,

        };

        const options = {
            method: 'POST',
            headers: this.headersData,
            body: JSON.stringify(data),
        }

        let endPointValue;
        if (this.state.cbxUserType.toLowerCase() === 'admin') {
            // Institution Admin
            endPointValue = this.props.sender === 'Edit' ? 'api/user/update-institution-admin' : 'api/user/create-institution-admin';
        } else {
            // Institution Users
            endPointValue = this.props.sender === 'Edit' ? 'api/user/update-institution-user' : 'api/user/create-institution-user';
        }

        // // const userApi = (this.state.cbxUserType === "admin") ? "api/user/create-institution-admin" : "api/user/create-institution-user"
        // // const endPointValue = this.props.sender === "Edit" ? 'api/user/update-institution-user' : "api/user/update-institution-admin"

        fetch(ApiPath + endPointValue, options)
            .then(response => {
                return response.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false)
                if (response) {
                    if (response.ok === true) {
                        /**
                         * Successfuly
                         * created a
                         * new record
                         * proceed to
                         * set props and show
                         * toast
                         */
                        this.props.setIsCreateSuccessful()
                        this.props.ShowToast("Institution User updated successfully!", "success")
                        this.props.setCreateClicked()

                    } else {
                        this.props.ShowToast(response.message, "exclamation")
                        return;
                    }
                } else {
                    this.props.ShowToast("Invalid input value(s)", "exclamation")
                }
            })
            .catch(error => {
                this.props.ShowToast(error.message, "exclamation")
                this.props.setIsLoaderVisible(false)
            })

    }

    closeForm() {
        this.props.setCreateClicked();
    }

    componentDidMount() {

        this.getBranches();

        if (!this.isNibssUser) {
            this.setState({
                cbxInstitution: this.props.selectedInstitution,
            }, () => {
                this.getBranches();
            })
        }

        //For every user... under the edit action...
        if (this.props.rowData) {
            //First, get the branch and institutions data...
            //this.getBranches();
            this.setState({ institutionsData: this.props?.institutionsArray }, () => {
                //We can now set the others after these basics...
                this.setState({
                    cbxInstitution: this.props.rowData.institution?.slug
                        || this.props.branchList.find(item => item.slug === this.props.rowData?.branch?.slug)?.institution?.slug,
                    cbxUserRole: this.props.rowData?.userRole?.slug,
                    txtEmail: this.props.rowData?.email,
                    txtLastname: this.props.rowData?.lastName,
                    txtFirstname: this.props.rowData?.firstName,
                    txtMiddlename: this.props.rowData?.middleName,
                    txtPhone: this.props.rowData?.phone_no,
                    cbxUserType: this.props.rowData?.user_type,
                }, () => {

                    //Else, still proceed...
                    this.getBranches();

                    this.filterDataForBranch();
                    this.setState({ cbxBranch: this.props.rowData?.branch?.slug })
                })
            })

        }

    }

    getBranches = () => {
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                'Cache-Control': 'public, max-age=0'
            },
        }

        const endPoint = (this.isNibssUser) ? 'api/branch/fetch-branches' : `api/institution/fetch-institution-branches/${sessionStorage.getItem("institution_slug")}`
        fetch(ApiPath + endPoint, options)
            ?.then(response => {
                return response.json();
            })
            .then(response => {
                if (response && response.ok === true) {
                    this.isNibssUser
                        ? this.setState({
                            branchData: response.body.branches.data,
                            filteredBranchData: response.body.branches.data?.filter(item => item.institution?.slug === this.state.cbxInstitution)
                        })
                        : this.setState({
                            branchData: response.body.data.branches,
                        })
                }
            })
    }

    render() {
        //Institutions...
        const institutions = this.isNibssUser ? this.props.institutionsArray : this.props.institutionsArray?.filter(item =>
            item.slug === sessionStorage.getItem("institution_slug"))

        const InstitutionsArray = institutions?.map(item => {
            return (<option className="bg-white" key={item.slug} value={item.slug}>{item.name}</option>)
        })

        const displayBranch = (this.state.cbxUserType !== "user") ? "input-group d-none" : "input-group"
        const cbxRolesArray = this.props.rolesArray && this.props.rolesArray.map((item, index) => {
            return <option key={index} value={item.slug} className="bg-white">{item.name}</option>
        })

        const selectWithBg = "bg-silver custom-select";
        const selectOnly = "custom-select";
        const selectedBranchArray = this.isNibssUser ? this.state.filteredBranchData : this.state.branchData;

        return (
            <div className={this.props.createClicked ? "dialog-background fade" : "dialog-background"}>
                <div className="dialog-container">
                    <div className="dialog-header">
                        <h2 className={this.props.sender.toLowerCase()}><i className="icofont-users"></i> {this.props.sender} Institution User</h2>
                    </div>
                    <div className="dialog-body">
                        <div className="form-row">
                            <div className="input-group">
                                <label><i className="icofont-user-alt-3"></i> Account User</label>
                                <input type="text" name="txtAccountUser" className="form-control" value={this.state.txtAccountUser} disabled />
                            </div>
                        </div>
                        <hr className="golden-rule" /><h3 className="row-title">User Details</h3>

                        <div className="form-row">
                            <div className="input-group col-md">
                                <label><span>*</span>Institution User Type</label>
                                <select
                                    name="cbxUserType"
                                    className={this.state.cbxUserType === "" ? selectWithBg : selectOnly}
                                    value={this.state.cbxUserType}
                                    onChange={this.handleChange}
                                >
                                    <option value="" className="bg-silver">Select User Type</option>
                                    {(this.isNibssUser) && <option value="admin" className="bg-white">Institution Admin</option>}
                                    <option value="user" className="bg-white">Institution User</option>
                                </select>
                            </div>
                            <div className="input-group">
                                <label><span>*</span>User Role</label>
                                <select
                                    name="cbxUserRole"
                                    value={this.state.cbxUserRole}
                                    onChange={this.handleChange}
                                    className={this.state.cbxUserRole === "" ? selectWithBg : selectOnly}
                                >
                                    <option value="">Select User Role</option>
                                    {cbxRolesArray}
                                </select>
                            </div>
                        </div>
                        <div className="form-row-2-1 mb-2">
                            <div className="input-group">
                                <label><span>*</span>Institution <i className='icofont-spinner-alt-2 branch-spinner d-none' /></label>
                                <select
                                    id="cbxInstitution"
                                    name="cbxInstitution"
                                    className={this.state.cbxInstitution === "" ? selectWithBg : selectOnly}
                                    value={this.state.cbxInstitution}
                                    onChange={this.handleChange}
                                >
                                    <option value="">Select Institution</option>
                                    {//Return an array of institutions
                                        InstitutionsArray
                                    }
                                </select>
                            </div>
                            <div className={displayBranch}>
                                <label>Branch</label>
                                <select
                                    name="cbxBranch"
                                    className="custom-select"
                                    value={this.state.cbxBranch}
                                    onChange={this.handleChange}
                                >
                                    <option value="">Select Branch</option>
                                    {//Return an array of institution Branch, based on the selected institution above...
                                        selectedBranchArray?.map(item => {
                                            return <option key={item.slug} value={item.slug} className="bg-white">{item.branchName}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="input-group col-md">
                                <label><span>*</span>First Name</label>
                                <input type="text" name="txtFirstname" className="form-control" onChange={this.handleChange} value={this.state.txtFirstname} required />
                            </div>
                            <div className="input-group col-md">
                                <label><span>*</span>Last Name</label>
                                <input type="text" name="txtLastname" className="form-control" onChange={this.handleChange} value={this.state.txtLastname} required />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="input-group col-md">
                                <label>Middle Name</label>
                                <input type="text" name="txtMiddlename" className="form-control" onChange={this.handleChange} value={this.state.txtMiddlename} />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="input-group col-md">
                                <label><span>*</span>Email Address</label>
                                <input type="email" name="txtEmail" className="form-control" onChange={this.handleChange} value={this.state.txtEmail} required />
                            </div>
                            <div className="input-group col-md">
                                <label>Phone</label>
                                <input
                                    type="tel"
                                    name="txtPhone"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.txtPhone}
                                    maxLength="11"
                                    minLength="11"
                                />
                            </div>
                        </div>
                        <hr className="mb-2" />
                    </div>
                    <div className="dialog-footer">
                        <button
                            className="btn-green mr-1"
                            id="btnUpdateInstitution"
                            name="btnUpdateInstitution"
                            type="button"
                            onClick={this.handleSubmit}
                            title="Create">
                            <i className="icofont-upload-alt"></i> {this.props.sender === "Create" ? "Create" : "Update"} Institution User
                        </button>
                        <button
                            type="button"
                            className="btn-grey mr-2"
                            id="btnCancel"
                            onClick={this.closeForm}>
                            <i className="icofont-exit" title="Cancel"></i> Cancel
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default InstitutionUserFormData;
