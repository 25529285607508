import React from 'react'
import ApiPath from './../../admin/assets/App/api-path'

class UserRoleTableRow extends React.Component {

    constructor() {
        super()
        this.state = {
            slug: "",
        }

        this.EditUserRole = this.EditUserRole.bind(this)
        this.DeleteUserRole = this.DeleteUserRole.bind(this)
        this.ViewServices = this.ViewServices.bind(this)
    }

    componentDidMount() {
        this.setState({
            slug: this.props.data?.slug,
        })
    }

    ViewServices() {
        this.props?.setDisplayViewDialog(true);
        this.props?.setViewData(this.props.data?.institution_user_role_services)
    }

    EditUserRole(thisData) {
        this.props.EditUserRoles(thisData);
    }

    DeleteUserRole() {
        //Delete


        const dialog = window?.confirm("Are you sure you wish to delete the selected record?")

        if (!dialog) {
            return
        }

        //Else, good to go
        this.props.setIsLoaderVisible(true)

        //Set the data first...
        const data = { "slug": this.state.slug };
        const options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                'Cache-Control': 'public, max-age=0'
            },
        }

        fetch(ApiPath + 'api/institution-user-role/disable-institution-user-role', options)
            ?.then(response => {
                this.props.setIsLoaderVisible(false);
                return response.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false)
                if (response) {
                    if (response.ok) {
                        //Success
                        this.props.setIsLoaderVisible(false)
                        this.props.ShowToast("User Role deleted successfully!", "success")
                        this.props.isDeleteSuccessful(true)

                    } else {
                        this.props.ShowToast(response.message, "exclamation")
                        return;
                    }
                } else {
                    this.props.ShowToast("Invalid input value", "exclamation")
                }
            })
            .catch(error => {
                this.props.setIsLoaderVisible(false)
                this.props.ShowToast(error.message, "exclamation")
            })

    }

    render() {
        return (
            <tr>
                <td className="align-center">{this.props.counter}.</td>
                <td>{this.props.data?.name}</td>
                <td className='align-left'>
                    {this.props.data?.roleId}
                </td>
                <td className="align-center">
                    <span className=" badge-item-success">{this.props.data?.institution_user_role_services?.length}</span>
                </td>
                <td className="align-center">
                    <button type="button" className="btn-grey ml-1" title="Services" onClick={this.ViewServices} id="btnViewServices">
                        <i className="icofont-ui-settings"></i> View
                    </button>
                </td>
                <td className="align-center">{this.props.data?.users_count}</td>

                <td className="align-center">
                    <button type="button" id="EditButton" className="btn-grey mr-1" title="Edit" onClick={() => this.EditUserRole(this.props.data?.slug)}>
                        <i className="icofont-edit"></i> Edit
                    </button>
                    <button type="button" className="btn-danger" title="Delete" onClick={this.DeleteUserRole}>
                        <i className="icofont-ui-delete"></i> Delete
                    </button>
                </td>
            </tr>
        )
    }
}

export default UserRoleTableRow
