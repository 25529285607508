import React from 'react';
import ApiPath from '../../admin/assets/App/api-path';
import DynamicSort from '../../admin/assets/App/DynamicSort';
import { loadEDMMSExportTable } from '../outgoing-e-dmms-mandates/load-edmms-export-table';
import ExportToCSVFile from '../../admin/assets/App/ExportToCSVFile';
import objectToCSV from '../../admin/assets/App/object-to-csv';
import { EDMMSReportsTableRow } from './e-dmms-report-table-row';
import SearchComponent from '../../admin/assets/App/SearchComponent';
import eDMMSSearchList from '../../admin/assets/data/e-DMMSSearchList';
import Pagination from '../../admin/assets/App/Pagination';
import { ViewEDMMSMandateDetails } from '../outgoing-e-dmms-mandates/view-edmms-mandate-details';
import { EDMMSTableColumns } from './e-dmms-table-columns';
import { DateRange } from '../../components/date-range';

export default class EDMMSReportForm extends React.Component {
    constructor() {
        super();
        this.state = {
            actionSuccessful: false,
            apiStaticData: [],
            apiData: [],
            metaData: {},
            viewData: [],
            editData: [],
            exportData: [],
            institutions: [],
            fetchSuccessful: true,

            displayDetails: false,

            searchField: "",
            searchValue1: "",
            searchValue2: "",
            startDate: '',
            endDate: "",

            currentSelectedPage: 1,
            arrayPages: [],
            lastPage: 1,
            perPage: localStorage.getItem("items_per_page") || 20,
            totalItems: 0,
        }

        this.fetchOptions = {
            headers: {
                'Content-Type': 'application/json',
                'Cache-Control': 'public, max-age=0',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
            },
            method: "GET"
        }
    }

    fetchInstituions = () => {
        /**
         * This function fetches the institutions
         * for the search querries...
         */
        fetch(`${ApiPath}api/institution/fetch-institutions`, this.fetchOptions)
            ?.then(response => (response.json()))
            .then(output => {
                if (output) {
                    if (output.ok) {
                        this.setState({
                            institutions: output.body.data.institutions?.sort(DynamicSort("name")),
                        })
                    }
                }
            })
    }

    fetchAllMandatesReports = () => {
        /**
         * This fetches all the
         * mandates created by
         * all the institutions,'
         * filtereable of course
         * based on certain criteria...
         */
        fetch(`${ApiPath}api/edmms-report/fetch-mandate-report?page=1` +
            `&limit=200000`, this.fetchOptions)
            ?.then(output => (output.json()))
            ?.then(reportResponse => {
                if (reportResponse && reportResponse.ok) {
                    this?.setState({
                        apiStaticData: reportResponse.body.data,
                    })
                }
            })

    }

    fetchMandateReports = () => {
        /**
         * Here, this fetches only
         * the report needed per query
         * ..
         */
        this.props.setIsLoaderVisible(true);
        const dateRangeFilter = (this.state.startDate && this.state.endDate) ? `&createdAt=${this.state.startDate}&createdAt=${this.state.endDate}` : '';

        const searchField = this.state.searchField;
        let searchValue = this.state.searchValue1;

        if (searchField === 'institution') {
            searchValue = this.state.institutions.find(item => item.name === this.state.searchValue1).slug;
        }

        fetch(`${ApiPath}api/edmms-report/fetch-mandate-report?page=${this.state.currentSelectedPage}` +
            `&${searchField}=${searchValue}&limit=${this.state.perPage}${dateRangeFilter}`, this.fetchOptions)
            ?.then(response => (response.json()))
            ?.then(reportResponse => {
                this.props.setIsLoaderVisible(false)

                if (reportResponse && reportResponse.ok === true) {
                    this?.setState({
                        metaData: reportResponse.body.meta,
                        apiData: reportResponse.body.data,
                        actionSuccessful: false,
                        fetchSuccessful: true,
                        arrayPages: []
                    })

                    // Set other parameters...
                    const pagesArray = [];
                    const mData = reportResponse.body.meta;
                    const lastPage = mData?.last_page;

                    // Set other params...
                    this.setState({
                        lastPage: mData?.last_page,
                        totalItems: mData?.total,
                        currentSelectedPage: mData?.current_page,
                    })

                    // Then, set the array of pages...
                    // By running a loop..
                    for (let j = 1; j <= lastPage; j++) {
                        pagesArray.push({ "id": j, "name": "Item " + j });
                    }

                    // Set the variable at the end...
                    this.setState({ arrayPages: pagesArray });

                } else {
                    // An issue occured... Show the user...
                    this.props.setIsLoaderVisible(false);
                    this.props.ShowToast(reportResponse?.message, "exclamation");
                }
            })

    }

    exportMandateReports = () => {
        /**
         * Export the eDMMS mandates
         * data based on the
         * selected filter
         * data, as a .CSV file
         */
        this.props.setIsLoaderVisible(true);

        fetch(`${ApiPath}api/edmms-report/fetch-mandate-report?page=1` +
            `&${this.state.searchField}=${this.state.searchValue1}&limit=200000`, this.fetchOptions)
            ?.then(response => (response.json()))
            .then(resp => {
                this.props.setIsLoaderVisible(false)
                if (resp && resp.ok) {
                    //The export proper...
                    const DataToExport = loadEDMMSExportTable(resp.body.data);

                    // Set into csv format, then export
                    const csvData = objectToCSV(DataToExport);
                    ExportToCSVFile(csvData, "edmms-mandates-reports.csv");

                    this.props.ShowToast("Mandates exported successfully! Open downloads folder to preview...", "information");

                } else {
                    this.props.ShowToast(resp.message, "exclamation");
                }

            })
            .catch(err => {
                // Hide the loader and show the issue, if there's one...
                this.props.setIsLoaderVisible(false);
                this.props.ShowToast(err.message, "exclamation");
            })
    }

    componentDidMount() {
        this.fetchAllMandatesReports();
        this.fetchMandateReports();
        this.fetchInstituions();
    }

    filterRecords = () => {
        // fetch records each time search parameters change...
        this.fetchMandateReports();
    }

    render() {

        const tblRows = this.state.fetchSuccessful && this.state.apiData?.map((item, index) => {
            const itemCounter = (this.state.currentSelectedPage - 1) * this.state.perPage + (index + 1);

            return (<EDMMSReportsTableRow
                key={index}
                counter={itemCounter}
                ShowToast={this.props.ShowToast}
                setIsLoaderVisible={this.props.setIsLoaderVisible}
                data={item}
                showDetailsForm={() => this.setState({ displayDetails: true })}
                setActionSuccessful={() => this.setState({ actionSuccessful: true })}
                setViewData={val => this.setState({ viewData: val })}
            />)
        })

        if (this.state.actionSuccessful) {
            // Anytime we complete the action, we
            // do the following...
            this.fetchMandateReports();
            this.setState({ actionSuccessful: false })
        }

        // Search / filter data...
        const status = [
            { "item_name": "Accepted" },
            { "item_name": "Approved" },
            { "item_name": "Payment Pending" },
            { "item_name": "Submitted" },
            { "item_name": "Rejected" }
        ];

        const institutionNames = [];
        const bvnArray = [];
        const chnArray = [];
        const referenceNo = [];
        const refereeAccountNo = [];

        this.state.apiStaticData?.forEach(item => {
            chnArray.push({ "item_name": item.chn });
            referenceNo.push({ "item_name": item.mandateCode });
            bvnArray.push({ "item_name": item.bvn });
            refereeAccountNo.push({ "item_name": item.paymentAccountNo });
        })

        // The institutions array...
        this.state.institutions?.forEach(item => {
            institutionNames.push({ "item_name": item.name })
        })

        return (
            <>
                <div className="title">
                    <DateRange
                        setDateFrom={val => this.setState({ startDate: val })}
                        ShowToast={this.props.ShowToast}
                        setDateTo={val => this.setState({ endDate: val })}
                        callback={this.filterRecords}
                    />
                    <SearchComponent
                        filterRecords={this.filterRecords}
                        setSearchValue={val => this.setState({ searchValue1: val })}
                        setSearchValue2={val => this.setState({ searchValue2: val })}
                        inputList={{ status, institutionNames, referenceNo, bvnArray, chnArray, refereeAccountNo }}
                        ShowToast={this.props.ShowToast}
                        setSearchField={val => this.setState({ searchField: val })}
                        ReferenceSearchList={eDMMSSearchList}
                    />
                    <div className='buttons'>
                        <button
                            className='btn-green t-tip'
                            id='btnExport'
                            type='button'
                            data-title='Export eDMMS records as .csv'
                            onClick={this.exportMandateReports}
                        >
                            <i className='icofont-airplane-alt'></i> Export
                        </button>
                    </div>
                </div>
                <div className='table-container'>
                    <table className='bordered-table'>
                        <caption></caption>
                        <thead>
                            <tr>
                                <EDMMSTableColumns />
                                <th scope="col" className="align-left">ACCOUNT NO</th>
                                <th scope="col" className="align-center">STATUS</th>
                                <th scope="col" className="align-center">ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody>{tblRows}</tbody>
                    </table>
                </div>
                <Pagination
                    setRefreshForm={val => this.setState({ actionSuccessful: val })}
                    currentSelectedPage={this.state.currentSelectedPage}
                    setCurrentSelectedPage={val => this.setState({ currentSelectedPage: val })}
                    perPage={this.state.perPage}
                    setPerPage={val => this.setState({ perPage: val })}
                    lastPage={this.state.lastPage}
                    arrayPages={this.state.arrayPages}
                />
                {this.state.displayDetails && <ViewEDMMSMandateDetails
                    sender="eDMMS Reports"
                    data={this.state.viewData}
                    displayView={this.state.displayDetails}
                    ShowToast={this.props.ShowToast}
                    hideDialog={() => this.setState({ displayDetails: false })}
                    setIsLoaderVisible={this.props.setIsLoaderVisible}
                />}
            </>
        )
    }
}
