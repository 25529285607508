import React from 'react'
import ApiPath from '../../admin/assets/App/api-path';
import GetServices from "../../admin/assets/App/GetServices";
import { Enums } from "../../_common/enums";

class BillingFeesForm extends React.Component {
    constructor() {
        super();
        this.state = {
            txtBillingAmount: "",
            roleServices: [],
            cbxBillingService: "",
            txtViewsFee: "",
            txtOverdueTime: "",

        };

        this.headersData = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("session_token")}`,
            'Cache-Control': 'public, max-age=0'
        }
    }

    componentDidMount() {
        this.fetchService();

        this.setState({
            cbxBillingService: "1",
        }, () => this.getData())
    }

    getData() {

        this?.props.setIsLoaderVisible(true);
        const options = {
            method: "GET",
            headers: this?.headersData,
        };

        //We determine the particular endpoint to use
        let endpoint;

        switch (this.state.cbxBillingService) {
            case "1":
                endpoint = "api/e-reference-fee/get-e-reference-fee";
                break;
            case "2":
                endpoint = "api/trapped-card-fee/get-trapped-card-fee";
                break;
            case "3":
                endpoint = "api/edmms-fee/get-edmms-fee";
                break;
            default:
                endpoint = "api/eclip-fee/get-eclip-fee";
                break;
        }

        fetch(`${ApiPath}${endpoint}`, options)
            ?.then((response) => {
                this?.props.setIsLoaderVisible(false);
                return response.json();
            })
            ?.then((response) => {
                if (response && response.ok) {
                    //Success
                    if (this.state.cbxBillingService === "4") {
                        // Always do this for the eClips part...
                        this.setState({
                            txtBillingAmount: response.body.eclipFee?.eclipRequestFee,
                            txtViewsFee: response.body.eclipFee.eclipViewFee,
                            txtOverdueTime: response.body.eclipFee?.overDueTime
                        })
                    } else {
                        this.setState({
                            txtBillingAmount: response.body.eReferenceFee.value,
                        });
                    }
                } else {
                    this.props.ShowToast(response.message, "exclamation");
                }
            })
            ?.catch((error) => {
                this?.props.setIsLoaderVisible(false);
                this?.props.ShowToast(error.messsage, "exclamation");
            });

    }

    handleClick = () => {

        try {
            if (parseInt(this.state.txtBillingAmount) <= 0 || this.state.txtBillingAmount === "") {
                this.props.ShowToast(
                    "You must enter a numeric value more than zero (0) to proceed",
                    "exclamation"
                );
                document
                    .getElementById("txtBillingAmount")
                    .classList.add("error-border");
                document.getElementById("txtBillingAmount").focus();
                return;
            }

            // Check others...
            // Ensure the request and views fees are included...
            const errorMessage = "Amount must be numeric and cannot be empty!"

            if (this.state.cbxBillingService === "4") {
                if (this.state.txtViewsFee === "") {
                    this.props.ShowToast(errorMessage, "exclamation")
                    document.getElementById("txtViewsFee")?.classList.add(Enums.errorBorder);
                    return;
                }
                if (this.state.txtOverdueTime === "") {
                    this.props.ShowToast(errorMessage, "exclamation")
                    document.getElementById("txtOverdueTime")?.classList.add(Enums.errorBorder);
                    return;
                }
            }


            //Else, good to go
            this.props.setIsLoaderVisible(true);

            const data = this.state.cbxBillingService !== "4"
                ? {
                    value: this.state.txtBillingAmount,
                    service: this.state.cbxBillingService
                }
                : {
                    eclipRequestFee: parseFloat(this.state.txtBillingAmount),
                    eclipViewFee: parseFloat(this.state.txtViewsFee),
                    overDueTimeHour: parseInt(this.state.txtOverdueTime)
                };

            const options = {
                headers: this?.headersData,
                method: "POST",
                body: JSON.stringify(data),
            };

            let endPoint;
            switch (parseInt(this.state.cbxBillingService)) {
                case 1:
                    endPoint = "api/e-reference-fee/create-e-reference-fee";
                    break;
                case 2:
                    endPoint = "api/trapped-card-fee/create-trapped-card-fee";
                    break;
                case 3:
                    endPoint = "api/edmms-fee/create-edmms-fee";
                    break;
                default:
                    endPoint = "api/eclip-fee/create-eclip-fee";
                    break;
            }

            fetch(ApiPath + endPoint, options)
                .then((res) => {
                    this.props.setIsLoaderVisible(false);
                    return res.json();
                })
                .then((response) => {
                    this.props.setIsLoaderVisible(false);
                    if (response && response.ok === true) {
                        //Success
                        this.props.ShowToast(
                            "Billing Fees updated successfully!", "success");
                    } else {
                        this.props.ShowToast(response.message, "exclamation");

                    }

                })
                .catch((error) => {
                    this.props.setIsLoaderVisible(false);
                    this.props.ShowToast(error.message, "exclamation");
                });

        } catch (err) {
            this.props.ShowToast(err.toString(), "exclamation");
            this.props.setIsLoaderVisible(false);

        }
    }

    fetchService = () => {
        const Options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                'Cache-Control': 'public, max-age=0'
            },
        }
        fetch(ApiPath + 'api/institution-type-service/fetch-institution-type-service', Options)
            ?.then(response => {
                return response.json();
            })
            ?.then(res => {
                if (res && res.ok) {
                    this.setState({
                        roleServices: res.body.services,
                    })
                }
            })
    }

    render() {

        const servicesArray = this.state.roleServices?.map((item, index) => {
            return (
                <option
                    key={index + 1}
                    value={index + 1}>
                    {GetServices(item.name)}
                </option>
            )
        })

        return (
            <>
                <div className="title mb-3">
                    <h3>Fees settings</h3>
                </div>
                <div className="form-row-4">
                    <div className="input-group">
                        <label>Select Service</label>
                        <select
                            id="cbxBillingService"
                            name="cbxBillingService"
                            className="form-control"
                            onChange={e => this.setState({ cbxBillingService: e?.target.value }, () => {
                                //Callback function to fetch the required data
                                this.getData();
                            })}
                            value={this.state.cbxBillingService}
                        >
                            <option value=""></option>
                            {servicesArray}
                        </select>
                        <label className="control-info">
                            <i className="icofont-warning-alt"></i> Select service from list
                        </label>
                    </div>
                    <div className="input-group">
                        <label>{this.state.cbxBillingService === "4" ? "Request Fees" : "Enter Fees"}</label>
                        <input
                            type="number"
                            id="txtBillingAmount"
                            name="txtBillingAmount"
                            steps="0.1"
                            className="form-control align-right"
                            onChange={e => this.setState({ txtBillingAmount: e?.target.value })}
                            value={this.state.txtBillingAmount}
                        />
                        <label className={this.state.cbxBillingService !== "4" ? "control-info" : "d-none"}>
                            <i className="icofont-warning-alt"></i>{" Billing Fees Amount"}
                        </label>
                    </div>
                    <div className="input-group">
                        <button
                            type="button"
                            id="btnBillingFees"
                            className="btn-green t-tip special-button"
                            data-title="Update Billing Fees"
                            onClick={this.handleClick}
                        ><i className="icofont-upload-alt"></i> Update
                        </button>
                    </div>
                </div>
                <div className={this.state.cbxBillingService === "4" ? 'form-row-4' : 'd-none'}>
                    <div></div>
                    <div className='input-group'>
                        <label>Views Fees</label>
                        <input
                            type="number"
                            id="txtViewsFee"
                            name="txtViewsFee"
                            steps="0.1"
                            className="form-control align-right"
                            onChange={e => this.setState({ txtViewsFee: e?.target.value })}
                            value={this.state.txtViewsFee}
                        />
                    </div>
                </div>
                <div className={this.state.cbxBillingService === "4" ? 'form-row-4' : 'd-none'}>
                    <div></div>
                    <div className='input-group'>
                        <label>Overdue Time</label>
                        <input
                            type="number"
                            id="txtOverdueTime"
                            name="txtOverdueTime"
                            steps="0.1"
                            className="form-control align-right"
                            onChange={e => this.setState({ txtOverdueTime: e?.target.value })}
                            value={this.state.txtOverdueTime}
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default BillingFeesForm
