import ApiPath from "../../admin/assets/App/api-path";
import { SplitDate } from "../../admin/assets/App/split-date";

const InstitutionFormRows = props => {

    function handleClick() {
        //Send the entire props.item to the edit part
        props.EditInstitution(props.item)
    }

    const ToggleInstitutionStatus = () => {
        /**
         * This toggles the
         * status of the
         * selected institution...
         * If the institution
         * is active, it sets the
         * status to 'inactive'
         * and vice-versa...
         */
        const verify = window.confirm(`Confirm that you wish to ${buttonTitle.toLowerCase()} the institution?`);

        if (verify) {
            //Show Loader
            props.setIsLoaderVisible(true)
            //Go ahead and enable/disable the institution
            //based on the selected output...
            const payLoad = { slug: props.item.slug }
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                    'Cache-Control': 'public, max-age=0'
                },
                body: JSON.stringify(payLoad),
            }

            fetch(ApiPath + 'api/institution/disable-institution', options)
                ?.then(res => {
                    return res.json();
                })
                .then(response => {
                    props.setIsLoaderVisible(false);
                    if (response) {
                        if (response.ok) {
                            //Show the messagebox for success
                            //And also, refresh the from
                            //After the update
                            props.ShowToast(`Item ${buttonTitle.toLowerCase()}d successfully!`, "success");
                            props.actionSuccessful(true);
                        } else {
                            props.ShowToast(response.message, "exclamation");
                        }
                    }
                })
                .catch(error => {
                    props.setIsLoaderVisible(false);
                    props.ShowToast(error.message, "exclamation");
                })

            props.actionSuccessful(true);

        }

    }

    const buttonTitle = props.item.status === "active" ? "Disable" : "Enable";
    const status = props.item.status === "active" ? "icofont-play-alt-1" : "icofont-stop";
    const spanClass = props.item.status === "active" ? "badge-success" : "badge-danger";

    return (
        <tr>
            <td className="align-center">{props.counter}.</td>
            <td>{props.item.code}</td>
            <td>{props.item.name}</td>
            <td>{props.item.institutionType.name}</td>
            <td>{SplitDate(props.item.createdAt).dateTime}</td>
            <td>{props.item.billingType}</td>
            <td><span className={spanClass}><i className={status}></i></span></td>
            <td className="align-center">
                <button
                    id="btnEdit"
                    className="btn-grey mr-1"
                    title="Edit Institution"
                    button="button"
                    onClick={() => handleClick()}>
                    <span className='icofont-edit'></span> Edit
                </button>
                <button
                    id="btnToggle"
                    className="btn-grey"
                    title="Enable/Disable Institution"
                    type="button"
                    onClick={ToggleInstitutionStatus}>
                    {buttonTitle}
                </button>
            </td>
        </tr>
    );
}

export default InstitutionFormRows;
