import { useState } from 'react';
import ApiPath from './../../admin/assets/App/api-path'
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

const LoginValidateBody = ({ ShowToast, setIsLoaderVisible }) => {

    const [validator, setValidator] = useState("")
    const history = useHistory();

    function handleChange(e) {
        setValidator(e.target.value)
    }

    function resendEmailToken() {

        const data = {
            email: sessionStorage.getItem("email"),
            password: sessionStorage.getItem("password")
        };

        setIsLoaderVisible(true); //Show toast

        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Cache-Control': 'public, max-age=0'
            },
            body: JSON.stringify(data),
        };

        const loginPath = "api/auth/login";

        fetch(ApiPath + loginPath, options)
            .then((response) => {
                setIsLoaderVisible(false);
                return response.json();
            })
            .then((response) => {
                setIsLoaderVisible(false);

                if (response && response.ok === true) {
                    ShowToast("Token resent! Check your email.", "information");
                } else {
                    ShowToast(response.message, "exclamation");
                }
            })
            .catch((error) => {
                setIsLoaderVisible(false);
                ShowToast(error.message, "exclamation");
            });
    }

    const getServiceIds = (services) => {
        //From the input services
        //generate an array of numbers for the service ids
        const serviceIds = []
        services.forEach(item => {
            serviceIds.push(item.id)
        })

        return serviceIds;
    }

    const handleClick = () => {
        if (validator === "" || validator.length < 6) {
            ShowToast("Enter 6-digit token sent to your registered email.", "exclamation")
            document?.getElementById("txtValidator")?.focus();
            return;
        }

        setIsLoaderVisible(true)          //Show Loader

        const loggedUser = sessionStorage.getItem("session_valid_user")
        const data = { "email": loggedUser, "token": validator };

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Cache-Control': 'public, max-age=0'
            },
            body: JSON.stringify(data),
        }

        fetch(ApiPath + 'api/auth/verify-login', options)
            .then(response => {
                setIsLoaderVisible(false)
                return response.json();
            })
            .then(response => {
                setIsLoaderVisible(false)

                if (response) {
                    if (response.ok) {
                        const output = response.body;

                        const $timeStamp = new Date().getTime();
                        sessionStorage.setItem("session_token", output.bearerToken?.token);
                        sessionStorage.setItem("session_token_timestamp", $timeStamp);
                        sessionStorage.setItem("session_locked_state", "unlocked");
                        sessionStorage.setItem("session_timeout_period", 1000 * 60 * 10)    //Timeout = 10 mins
                        sessionStorage.setItem("email", "")
                        sessionStorage.setItem("password", "")

                        //Data
                        sessionStorage.setItem("session_first_name", output.firstName);
                        sessionStorage.setItem("session_last_name", output.lastName);
                        sessionStorage.setItem("session_user_type", output.userRole);
                        sessionStorage.setItem("customer_branch", output.branch?.branchName || "-");
                        sessionStorage.setItem("customer_branch_slug", output.branch?.slug || "-");
                        sessionStorage.setItem("is_nibss_user", "-")
                        sessionStorage.setItem("toggle_state", "toggle")

                        //serviceIds
                        sessionStorage.setItem("service_ids", getServiceIds(output.services))

                        //others
                        sessionStorage.setItem("institution", output.branch?.institution?.name || output.institution?.name);
                        sessionStorage.setItem("institution_slug", output.branch?.institution?.slug || output.institution?.slug);

                        history.push("/general/quick-access");

                    } else {
                        ShowToast(response.message, "exclamation")
                        return;
                    }

                } else {
                    ShowToast("Portal connection error. Try again, and if this continues, try logging in again.", "exclamation")

                }
            })
            .catch(error => {
                setIsLoaderVisible(false)
                ShowToast(error, "exclamation")
            })

    }

    const loginEmail = sessionStorage.getItem("session_user");

    return (

        <div className="login-data">
            <h3><i className="icofont-mr icofont-unlock"></i>2FA-Code</h3>
            <div className="login-details">
                <h3 id="loggedInUser">{loginEmail}</h3>
                <h4>Complete your login by entering the 6-digit token sent to your registered email account</h4>
                <div className="controls">
                    <input
                        type="text"
                        autoComplete="off"
                        id="txtValidator"
                        name="validator"
                        onChange={handleChange}
                        value={validator}
                        placeholder="000000"
                        maxLength="6"
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                handleClick();
                            }
                        }}
                    />
                </div>

                <div className="form-inline">
                    <button type="button" id="btnVerify" onClick={handleClick}>Verify</button>
                    <button type="button" id="btnResendToken" onClick={resendEmailToken}>Resend Token</button>
                </div>
                <Link to="/">Login with different credentials</Link>
            </div>
            <div className="cant-access">
                <Link to="mailto:admin@gmail.com">Can't access your account?</Link>
            </div>
        </div>
    )
}
export default LoginValidateBody
