import React from "react";
import { EDMMSCompaniesTableRows } from "./e-dmms-companies-table-rows";
import SearchComponent from '../../admin/assets/App/SearchComponent'
import CompaniesSearchList from "../../admin/assets/data/CompaniesSearchList";
import Pagination from "../../admin/assets/App/Pagination";
import EDMMSCompaniesFormData from "./e-dmms-companies-form-data";
import objectToCSV from "../../admin/assets/App/object-to-csv";
import ExportToCSVFile from "../../admin/assets/App/ExportToCSVFile";
import ApiPath from "../../admin/assets/App/api-path";

export default class EDMMSCompaniesForm extends React.Component {
    constructor() {
        super();

        this.state = {
            createSuccessful: false,

            //Search fields..
            searchField: "",
            searchValue: "",

            //others...
            fetchSuccessful: false,
            actionSuccessful: false,
            apiData: [],
            registrars: [],
            editData: [],
            editClicked: false,
            createClicked: false,
            sortBy: 'createdAt',
            order: 'asc',

            //Pagination state variables...
            currentSelectedPage: 1,
            arrayPages: [],
            lastPage: 1,
            perPage: localStorage.getItem("items_per_page") || 20,
            totalItems: 0,
        }

        this.headersData = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${sessionStorage.getItem("session_token")}`,
            'Cache-Control': 'public, max-age=0'
        }
    }

    fetchRegistrars = () => {
        /**
         * Fetch institutions
         * as registrars...
         */
        fetch(`${ApiPath}api/edmms-registrar/get-edmms-registrars?limit=20000`, { method: "GET", headers: this.headersData })
            ?.then(res => (res.json()))
            ?.then(response => {
                if (response && response.ok) {
                    this.setState({
                        registrars: response.body.registrars?.data,
                    })
                }
            })
    }

    getEDMMSCompanies = () => {
        /** Fetch data of
         * EDMMS comapnies on form load or any time the
         * form is refreshed...
         * implement codes later
         * once endpoints are avaialble
         */

        const fetchOptions = {
            method: "GET",
            headers: this.headersData,
        }


        // show the loader...
        this.props.setIsLoaderVisible(true);

        fetch(`${ApiPath}api/edmms-company/get-edmms-companies?page=${this.state.currentSelectedPage}` +
            `&limit=${this.state.perPage}&${this.state.searchField}=${this.state.searchValue}&sortBy=${this.state.sortBy}` +
                `&order=${this.state.order}`, fetchOptions)
            ?.then(out => (out.json()))
            .then(response => {

                // hide the loader...
                this.props.setIsLoaderVisible(false);

                // Check the response...
                if (response) {
                    if (response.ok) {
                        this.setState({
                            actionSuccessful: false,
                            apiData: response.body.companies?.data,
                            metaData: response.body.companies?.meta,
                            fetchSuccessful: true,
                            arrayPages: []
                        })

                        // Other parameters...
                        const metData = response.body.companies.meta;
                        const pagArray = [];
                        const lastPage = metData.last_page;

                        // array of pages...
                        for (let m = 1; m <= lastPage; m++) {
                            pagArray.push({ "id": m, "name": "Item " + m })
                        }

                        // Other parameters...
                        this.setState({
                            lastPage: metData.last_page,
                            currentSelectedPage: metData?.current_page,
                            arrayPages: pagArray
                        })


                    } else {
                        this.props.ShowToast(response.message, "exclamation");
                        return;
                    }
                } else {
                    this.props.ShowToast(response.message, "exclamation");
                }
            })
            .catch((err) => {
                // Issue occured. Display error here...
                // Hide the loader first...
                this.props.setIsLoaderVisible(false);
                this.props.ShowToast(err.message, "exclamation");
            })
    }

    componentDidMount() {
        this.getEDMMSCompanies();
        this.fetchRegistrars();
    }

    handleChange = e => {
        const { name, value } = e.target;
        this.setState({ [name]: value })
    }

    editRowItem = itemSlug => {
        /**
         * Find the needed row from the
         * fetched api items list,
         *  based on the selected
         * item's slug...
         */
        const selectedItem = this.state.apiData.find(itemList => itemList.slug === itemSlug)

        this.setState({
            editData: selectedItem,
            editClicked: true,
        })

    }

    handleExport = () => {
        //Exports the desired fields to a csv/excel document...
        const previousPerPage = this.state.perPage;

        this.setState({ perPage: 200000 }, () => {

            this.getEDMMSCompanies();

            //Do others in here also...

            const DataToExport = [];
            this.state.apiData.forEach(item => {
                //First, get individual reference details
                const thisObject = {
                    "Company Number": `${item.companyNumber}\t`,
                    "Quoted Company": `${item.companyName}\t`,
                    "RC No": item.rcNo,
                    "Registrar": item.registrar?.name,
                    "Date Added": item.createdAt,
                    "Status": item.status
                }

                DataToExport.push(thisObject)

            })

            const csvData = objectToCSV(DataToExport);
            ExportToCSVFile(csvData, "eDMMS Companies_DMH.csv");

            this.props.setIsLoaderVisible(false);
            this.props.ShowToast("EDMMS Companies exported successfully! Open downloads folder to preview...", "information");

            //Return the number of items per page
            this.setState({ perPage: previousPerPage }, () => this.getEDMMSCompanies())

        })

    }

    filterRecords = () => {
        this.getEDMMSCompanies();
    }

    render() {
        let counter = 0;
        if (this.state.createSuccessful) {
            //Re-obtain the data...
            this.getEDMMSCompanies();

            //Finally, set state this to false...
            this.setState({
                createSuccessful: false
            })
        }

        //The Search List items...
        const status = [{ "item_name": "Active" }, { "item_name": "Inactive" }];

        // The othe fields...
        const companyName = [];
        const companyNumber = [];
        const rcNo = [];

        this.state.apiData.forEach(item => {
            companyName.push({ item_name: item.companyName })
            companyNumber.push({ item_name: item.companyNumber })
            rcNo.push({ item_name: item.rcNo })
        })

        return (
            <>
                <div className="title">
                    <div style={{ display: 'flex'}}>
                        <div className="input-group mr-1">
                            <select
                                className="form-control"
                                id="cbxSortBy"
                                value={this.state.sortBy}
                                onChange={e => this.setState({ sortBy: e.target.value }, () => this.getEDMMSCompanies())}
                                style={{ fontSize: '0.9em', padding: '3px 7px', border: 'none', boxShadow: 'none'}}
                            >
                                <option value='createdAt'>Sort by Date Added</option>
                                <option value='companyName'>Sort by Company Name</option>
                            </select>
                        </div>
                        <div className="input-group">
                            <select className="form-control"
                                id="cbxOrder"
                                value={this.state.order}
                                onChange={e => this.setState({ order: e.target.value }, () => this.getEDMMSCompanies())}
                                style={{ fontSize: '0.9em', padding: '3px 7px', borderRadius: '3px'}}
                            >
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </div>
                    </div>

                    <SearchComponent
                        setSearchValue={val => this.setState({ searchValue: val })}
                        setSearchField={val => this.setState({ searchField: val })}
                        handleChange={this.handleChange}
                        filterRecords={this.filterRecords}
                        ReferenceSearchList={CompaniesSearchList}
                        ShowToast={this.props.ShowToast}
                        inputList={{ status, companyName, companyNumber, rcNo }}
                    />
                    <div className="buttons">
                        <button
                            type="button"
                            className="btn-grey t-tip mr-1"
                            data-title="Add Company"
                            id="btnAddCompany"
                            onClick={() => this.setState({ createClicked: true })}><i className="icofont-plus" /> Add Company
                        </button>
                        <button
                            type="button"
                            className="btn-green t-tip"
                            data-title="Export data as CSV File"
                            onClick={this.handleExport}
                            id="btnExport"
                        >
                            <i className="icofont-airplane-alt" /> Export
                        </button>
                    </div>
                </div>

                {/* Next, table */}
                <div className="table-container">
                    <table className="bordered-table">
                        <caption></caption>
                        <thead>
                            <tr>
                                <th scope="col" className="align-center">#</th>
                                <th scope="col" className="align-left">COMPANY NUMBER</th>
                                <th scope="col" className="align-left">QUOTED COMPANY</th>
                                <th scope="col" className="align-left">RC NO</th>
                                <th scope="col" className="align-left">REGISTRAR</th>
                                <th scope="col" className="align-left">DATE ADDED</th>
                                <th scope="col" className="align-center" style={{ width: 80 }}>STATUS</th>
                                <th scope="col" className="align-center" style={{ width: 150 }}>ACTION</th>
                            </tr>
                        </thead>
                        <tbody id="tbl_companies">
                            {
                                this.state.fetchSuccessful && this.state.apiData.map(companyItem => {
                                    counter++;
                                    const anotherCounter = (this.state.currentSelectedPage - 1) * this.state.perPage + counter

                                    return (
                                        <EDMMSCompaniesTableRows
                                            key={companyItem.slug}
                                            counter={anotherCounter}
                                            apiData={companyItem}
                                            createSuccessful={() => this.setState({ createSuccessful: true })}
                                            editRowItem={this.editRowItem}
                                            ShowToast={this.props.ShowToast}
                                            setIsLoaderVisible={this.props.setIsLoaderVisible}
                                        />
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <Pagination
                    setRefreshForm={value => this.setState({ createSuccessful: value })}
                    perPage={this.state.perPage}
                    setPerPage={value => this.setState({ perPage: value })}
                    setCurrentSelectedPage={value => this.setState({ currentSelectedPage: value })}
                    lastPage={this.state.lastPage}
                    currentSelectedPage={this.state.currentSelectedPage}
                    arrayPages={this.state.arrayPages}
                />

                {
                    this.state.createClicked && <EDMMSCompaniesFormData
                        mode={"Create"}
                        hideDialog={() => this.setState({ createClicked: false })}
                        registrarsList={this.state.registrars}
                        setIsLoaderVisible={this.props.setIsLoaderVisible}
                        ShowToast={this.props.ShowToast}
                        updateIsSuccessful={() => this.setState({ createSuccessful: true })}
                    />
                }
                {
                    this.state.editClicked && <EDMMSCompaniesFormData
                        mode={"Edit"}
                        hideDialog={() => this.setState({ editClicked: false })}
                        registrarsList={this.state.registrars}
                        data={this.state.editData}
                        setIsLoaderVisible={this.props.setIsLoaderVisible}
                        ShowToast={this.props.ShowToast}
                        updateIsSuccessful={() => this.setState({ createSuccessful: true })}
                    />
                }
                <button id="testControl" onChange={this.handleChange} className="d-none"></button>
            </>
        )
    }
}
