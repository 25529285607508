import NavBar from "../../admin/assets/App/nav-bar"
import TopBar from "../../admin/assets/App/topbar"
import CardBox from "./card-box"
import MainSumaries from './main-summaries'
import LockedScreen from "../assets/App/LockedScreen"

import { useState } from 'react'
import { Helmet } from 'react-helmet'

export const Dashboard = (props) => {
    const [mainClass, setMainClass] = useState("main");

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Dashboard | NIBSS Document Management Hub Portal</title>
            </Helmet>
            <LockedScreen
                setIsLoaderVisible={props.setIsLoaderVisible}
                ShowToast={props.ShowToast}
            />
            <div className="cont-ainer">
                <NavBar sender="Dashboard" />
                <div className={mainClass}>
                    <TopBar
                        setMainClass={value => setMainClass(value)}
                    />
                    <CardBox />
                    <MainSumaries
                        ShowToast={props.ShowToast}
                        setIsLoaderVisible={props.setIsLoaderVisible}
                    />
                </div>
            </div>
        </>
    )
}
