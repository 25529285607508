import React, { useState } from 'react'
import ApiPath from '../../admin/assets/App/api-path';
import Badges from '../../admin/assets/App/Badges';
import CapitalizeFirstLetter from '../../admin/assets/App/CapitalizeFirstLetter';
import { SplitDate } from '../../admin/assets/App/split-date';

const IncomingEDMMSMandatesTableRow = props => {

    // Get some initial variables...
    const [options] = useState({
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
            'Cache-Control': 'public, max-age=0'
        },
        method: 'POST',
        body: '',
    })

    const [userType] = useState(sessionStorage.getItem("service_ids")?.split(',').indexOf('10') > -1 ? 'Authorizer' : 'Operator');

    const acceptMandate = () => {
        // runs a function to accept the mandate...
        props.setIsLoaderVisible(true);

        // set the body...
        const body = { slug: props.data?.slug }
        options.body = JSON.stringify(body);

        fetch(ApiPath + "api/edmms-mandate/accept-incoming-mandate", options)
            ?.then(resp => (resp.json()))
            .then(response => {
                // Hide the loader at this point...
                props.setIsLoaderVisible(false);
                if (response && response.ok) {
                    /**
                     * Update was successful, no issues
                     * whatsoever...
                     */
                    props.ShowToast("Mandate accepted!", "success");
                    props.setActionSuccessful();   // This refreshes the form...
                } else {
                    props.ShowToast(response.message, "exclamation");
                }

            })
            .catch(err => {
                props.setIsLoaderVisible(false)
                props.ShowToast(err.message, "exclamation")
            })
    }

    const approveMandate = () => {
        // runs a function to approve the mandate...
        // NB: For authorizers only...
        props.setIsLoaderVisible(true);

        // set the body...
        options.body = JSON.stringify({ slug: props.data?.slug });

        fetch(ApiPath + "api/edmms-mandate/approve-incoming-mandate", options)
            ?.then(response => (response.json()))
            .then(appResp => {
                // Hide the loader at this point...
                props.setIsLoaderVisible(false);
                if (appResp && appResp.ok) {
                    /**
                     * Update was successful, no issues
                     * whatsoever...
                     */
                    props.ShowToast("Mandate approved!", "success");
                    props.setActionSuccessful();   // This refreshes the form...
                } else {
                    props.ShowToast(appResp?.message, "exclamation");
                }

            })
            .catch(err => {
                props.setIsLoaderVisible(false)
                // Show toast after hiding the loader...
                props.ShowToast(err.message, "exclamation")
            })
    }

    // Here, we process the status badges...
    const [statusClass] = useState(Badges(props.data?.status));
    const displayDetailsButton = props.data?.status === 'submitted' || props.data?.status === 'accepted' || props.data?.status === 'approved';

    return (
        <>
            <tr>
                <td className="align-center">{props.counter}</td>
                <td className='align-left'>{props.data?.mandateCode}</td>
                <td className='align-left'>{SplitDate(props.data?.createdAt).dateTime}</td>
                <td className='align-left'>{props.data?.registrar || "******"}</td>
                <td className='align-left'>{props.data?.institution?.name}</td>
                <td className='align-left'>{props.data?.branch?.branchName}</td>
                <td className='align-left'>{props.data?.companyName}</td>
                <td className='align-left'>{props.data?.othernames || "-"}</td>
                <td className='align-left'>{props.data?.bvn || "-"}</td>
                <td className='align-left'>{props.data?.chn || "-"}</td>
                <td className='align-center'><span className={statusClass}>{CapitalizeFirstLetter(props.data?.status)}</span></td>
                <td className='align-center' width={180}>
                    {displayDetailsButton && <i
                        id="btnDetails"
                        className="icofont-ui-note mr-1"
                        style={{ fontSize: 20, color: "grey", cursor: "pointer" }}
                        title="View Details"
                        onClick={() => {
                            // Show the form, after setting the slug
                            // of the record...
                            props.setViewData(props.data);
                            props.showDetailsForm();
                        }}
                    />}
                    <i
                        id="btnAccept"
                        className={(props?.data?.status === "submitted" && userType === "Operator") ? "icofont-checked mr-1" : "d-none"}
                        style={{ fontSize: 22, color: "green", cursor: "pointer" }}
                        title="Accept Mandate"
                        onClick={acceptMandate}
                    />
                    <i
                        id="btnApprove"
                        className={(props?.data?.status === "accepted" && userType === "Authorizer") ? "icofont-tick-boxed mr-1" : "d-none"}
                        style={{ fontSize: 20, fontWeight: 'bold', color: "darkgreen", cursor: "pointer" }}
                        title="Approve Mandate"
                        onClick={approveMandate}
                    />
                    {props.data?.status?.toLowerCase() !== 'declined' && <i
                        id="btnReject"
                        style={{ fontSize: 20, fontWeight: 'bold', color: "maroon", cursor: "pointer" }}
                        className="icofont-not-allowed"
                        title="Reject Mandate"
                        onClick={props.showRejectForm}
                    />}
                </td>
            </tr>
        </>
    )

}

export default IncomingEDMMSMandatesTableRow;
