import React from "react";
import ApiPath from "../assets/App/api-path";
import objectToCSV from "../assets/App/object-to-csv";
import ExportToCSVFile from "../assets/App/ExportToCSVFile";
import { SplitDate } from "../assets/App/split-date";
import Pagination from "../assets/App/Pagination";
import AuditLogsSearchList from "../assets/data/AuditLogsSearchList";
import SearchComponent from "../assets/App/SearchComponent";
import { Enums } from "../../_common/enums";

export class AuditLogsForm extends React.Component {
    constructor() {
        super()
        this.state = {
            apiData: [],
            exportApiData: [],
            metaData: [],
            currentSelectedPage: 1,
            arrayPages: [],
            lastPage: 1,
            perPage: 100,
            refreshForm: false,
            //for search and filter
            searchField: "",
            searchValue: "",
            searchValue2: "",
        }


        this.options = Enums.getOptions;

    }

    componentDidMount() {
        this.getData()
        this.fetchExportData();
    }

    RefreshForm = () => {
        //Reload the entire page
        window.location.reload();
    }

    ExportPage = () => {
        //Move the data to a specified csvFile
        this.props.setIsLoaderVisible(true);

        /**
         *This is fetched each time the page is refreshed
        Ensure that it is also the same
        with the most recent
        search query parameters...
         * */

        this.props.setIsLoaderVisible(true)

        const prevPPage = this.state.perPage;

        this.setState({ perPage: 200000 }, () => {
            const queryString = this.getQueryString();
            fetch(queryString, this.options)
                ?.then(response => {
                    return response.json();
                })
                .then(response => {
                    this.props.setIsLoaderVisible(false)
                    if (response && response.ok) {
                        const apiExportData = response.body.auditLogs.data;

                        if (apiExportData.length === 0) {
                            this.props.ShowToast("No data to export!", 'exclamation');
                            return;
                        }

                        //Do the exporting here...
                        const thisData = apiExportData.map(item => {
                            return ({
                                "time": SplitDate(item.createdAt).dateTime,
                                "date": item.causer,
                                "user": JSON.parse(item.properties).email || "-",
                                "email": JSON.parse(item.properties).action,
                                "description": item.description,
                                "userIP": JSON.parse(item.properties).ip || "-",
                                "macAdress": "-",
                            })
                        })

                        const csvData = objectToCSV(thisData)
                        ExportToCSVFile(csvData, "audit-logs.csv")

                        this.props.ShowToast("Audit logs downloaded successfully!", "information");
                        this.props.setIsLoaderVisible(false);

                        //Return to previuos value...
                        this.setState({
                            perPage: prevPPage,
                        })

                    }
                })
        })


    }

    fetchExportData = () => {

        this.props.setIsLoaderVisible(true)

        //Remember to effect the same
        //search query parameters on
        // this fetch, same as the
        // normal fetch data...
        const prevPPage = this.state.perPage;

        this.setState({ perPage: "200000" }, () => {
            const queryString = this.getQueryString();
            fetch(queryString, this.options)
                ?.then(response => {
                    return response.json();
                })
                .then(response => {
                    this.props.setIsLoaderVisible(false)
                    if (response && response.ok) {
                        this.setState({
                            exportApiData: response.body.auditLogs.data,
                        })
                    }
                })
        })

        this.setState({
            perPage: prevPPage,
        })

    }

    getQueryString = () => {
        //This makes sure
        //that all the different
        //search query parameters
        //are accomodated for...
        let returnValue = "";

        if (this.state.searchField === "createdAt") {
            //Split the date into 2 parts...
            returnValue = `${ApiPath}api/admin/audit-logs?page=${this.state.currentSelectedPage}&limit=${this.state.perPage}` +
                `&createdAt=${this.state.searchValue}&createdAt=${this.state.searchValue2}`

        } else {
            returnValue = `${ApiPath}api/admin/audit-logs?page=${this.state.currentSelectedPage}&limit=${this.state.perPage}` +
                `&${this.state.searchField}=${this.state.searchValue}`
        }

        return returnValue;
    }

    getData() {

        this.props.setIsLoaderVisible(true)

        const queryString = this.getQueryString();

        fetch(queryString, this.options)
            ?.then(response => {
                return response.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false)
                if (response) {
                    if (response.ok === true) {
                        this.setState({
                            apiData: response.body.auditLogs.data,
                            metaData: response.body.auditLogs.meta,
                        }, () => {

                            this.setState({
                                perPage: this.state.metaData?.per_page,
                                lastPage: this.state.metaData?.last_page,
                                currentSelectedPage: this.state.metaData?.current_page,
                            }, () => {
                                //Set this array for the looping
                                const arrPages = [];
                                for (let i = 1; i <= this.state.lastPage; i++) {
                                    arrPages.push({ "id": i, "name": "Item " + i });
                                }
                                this.setState({
                                    arrayPages: arrPages,
                                })
                            })

                        })
                    } else {
                        this.props.ShowToast("Unable to load table. If this persists, please contact your admin.", "exclamation")
                        return;
                    }
                } else {
                    this.props.ShowToast("Invalid login credentials", "exclamation")
                }
            })
            .catch(error => {
                this.props.setIsLoaderVisible(false)
                this.props.ShowToast(error.message, "exclamation")
            })
    }

    filterRecords = () => {
        this.getData();
    }

    render() {

        let counter = 0;
        const TableRows = this.state.apiData && this.state.apiData.map(item => {
            counter++;
            //This next item ensures that a proper counter is established
            const itemCounter = (this.state.currentSelectedPage - 1) * this.state.perPage + counter
            const properties = JSON.parse(item.properties)
            return (
                <tr key={item.id}>
                    <td className="align-center">{itemCounter}.</td>
                    <td>{SplitDate(item.createdAt).dateTime}</td>
                    <td>{item.causer}</td>
                    <td>{properties.email}</td>
                    <td>{properties.action}</td>
                    <td>{item.description}</td>
                    <td>{properties.ip}</td>
                    <td className="align-center">-</td>
                </tr>
            )
        })

        if (this.state.refreshForm) {
            this.filterRecords()
            this?.setState({
                refreshForm: false,
            })
        }

        //Populate arrays for needed items...
        const causer = [];
        const contactEmails = [];
        const ipAddresses = [];
        const logType = [];

        this.state.exportApiData.forEach(item => {
            const propts = JSON.parse(item.properties)

            causer.push({ "item_name": item.causer || "-" })
            contactEmails.push({ "item_name": propts.email || "-" })
            ipAddresses.push({ "item_name": propts.ip || "-" })
            logType.push({ "item_name": item.description || "-" })

        })

        return (
            <div className="branch-summaries">
                <div className="summaries">
                    <div className="title">
                        <h3>Audit Logs</h3>
                        <SearchComponent
                            setSearchField={val => this.setState({ searchField: val })}
                            setSearchValue={val => this.setState({ searchValue: val })}
                            setSearchValue2={val => this.setState({ searchValue2: val })}
                            ReferenceSearchList={AuditLogsSearchList}
                            filterRecords={this.filterRecords}
                            ShowToast={this.props.ShowToast}
                            inputList={{ causer, contactEmails, ipAddresses, logType }}
                            visibleState={this.props.visibleState}
                            domNode={this.props.domNode}

                        />
                        <div className="buttons">
                            <button id="btnRefresh" type="button" className="btn-grey t-tip mr-1" data-title="Refresh" onClick={this.RefreshForm}>
                                <i className="icofont-refresh"></i> Refresh
                            </button>
                            <button id="btnExport" type="button" className="btn-green t-tip mr-1" data-title="Export Audit Logs" onClick={this.ExportPage}>
                                <i className="icofont-airplane-alt"></i> Export
                            </button>
                        </div>
                    </div>
                    <div className="table-container">
                        <table className="bordered-table">
                            <caption></caption>
                            <thead>
                                <tr>
                                    <th scope="col" className="align-center">#</th>
                                    <th scope="col" className="align-left">Time</th>
                                    <th scope="col" className="align-left">User</th>
                                    <th scope="col" className="align-left">Email</th>
                                    <th scope="col" className="align-left">Activity Type</th>
                                    <th scope="col" className="align-left">Details</th>
                                    <th scope="col" className="align-left">User IP Address</th>
                                    <th scope="col" className="align-center">User Mac Address</th>
                                </tr>
                            </thead>
                            <tbody id="tbl_audit_logs">
                                {TableRows}
                            </tbody>
                        </table>
                        <button
                            className="d-none"
                            id="testButton"
                            value="testbuttonValue"
                            onChange={this.filterRecords}
                        ></button>
                    </div>
                    <Pagination
                        perPage={this.state.perPage}
                        lastPage={this.state.lastPage}
                        arrayPages={this.state.arrayPages}
                        setPerPage={value => this.setState({ perPage: value })}
                        setRefreshForm={value => this.setState({ refreshForm: value })}
                        currentSelectedPage={this.state.currentSelectedPage}
                        setCurrentSelectedPage={value => this.setState({ currentSelectedPage: value })}
                    />

                </div>
            </div>
        )
    }
}

/**
     * This create trapped cards form
     * basically
     * allows us to have
     * a quick view of
     * application shortcuts.
     *
     * We need to add the
     * table and the
     * table rows to
     * follow the
     * expected
     * description on
     * the requirements
     * document...
     */
/**
* This create trapped cards form
* basically
* allows us to have
* a quick view of
* application shortcuts.
*
* We need to add the
* table and the
* table rows to
* follow the
* expected
* description on
* the requirements
* document...
*/
/**
* This create trapped cards form
* basically
* allows us to have
* a quick view of
* application shortcuts.
*
* We need to add the
* table and the
* table rows to
* follow the
* expected
* description on
* the requirements
* document...
*/
