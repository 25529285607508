const AuditLogsSearchList = [
    {
        id: 1,
        idVal: "createdAt",        //This is the searchField in the database
        value: "Date Range",
        itemName: "Date Range",
    },
    {
        id: 2,
        idVal: "description",
        value: "Activity",
        itemName: "Activity"
    },
    {
        id: 3,
        idVal: "causer",        //This is the searchField in the database
        value: "User",
        itemName: "User",
    },
    {
        id: 4,
        idVal: "email",        //This is the searchField in the database
        value: "User Email",
        itemName: "User Email",
    },
    {
        id: 5,
        idVal: "ipAddress",        //This is the searchField in the database
        value: "User IP Address",
        itemName: "User IP Address",
    },
]

export default AuditLogsSearchList
