import React from "react";
import { AddStockDialog } from "./add-stock-dialog";
import { StockComponent } from "./stock-component";
import ApiPath from '../../admin/assets/App/api-path'
import DynamicSort from "../../admin/assets/App/DynamicSort";
import FileInput from "../../components/file-input";
import { PersonalInfoPartFormComponent } from "./components/personal-info-part-form-component";

export default class EDMMSMandatesForm extends React.Component {
    constructor() {
        super();
        this.state = {
            stockData: [],
            displayAddStockDialog: false,
            institutionsArray: [],
            branchesArray: [],
            showAccountLoader: false,
            documentName: "",

            //inputs
            txtSurname: "",
            txtOthernames: "",
            txtPhone: "",
            txtEmail: "",
            txtBVN: "",
            txtClearingHouseNumber: "",
            cbxInstitutions: "",
            cbxBranch: "",
            txtAccountName: "",
            txtAccountNumber: "",
            txtTotalFees: 0.0,
            txtVAT: 0.0,
            txtAmountDue: 0.0,
            registrars: [],
            companies: [],
            eDMMSFee: 0.0,
            vatAmount: 0.0,

            //File input
            mandateForm: "",
            mandateExt: "",

        }

        this.headersData = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
            'Cache-Control': 'public, max-age=0'
        }
    }

    componentDidMount() {
        this.getEDMMSCompanies();
        this.getRegistrars();
        this.fetchInstitutions();
        this.getEDDMSServiceFee();
        this.getServiceVAT();
    }

    getEDDMSServiceFee = async () => {
        // Get the service fee...
        fetch(`${ApiPath}api/edmms-fee/get-edmms-fee`, { method: 'GET', headers: this.headersData })
            ?.then(res => (res.json()))
            .then(response => {
                if (response && response.ok) {
                    // Proceed...
                    this.setState({
                        eDMMSFee: response.body.eReferenceFee.value
                    })
                }
            })

    }

    getServiceVAT = () => {
        // Also fetch the VAT...
        fetch(`${ApiPath}api/user/vat/latest-vat`, { method: 'GET', headers: this.headersData })
            ?.then(response => (response.json()))
            .then(res => {
                if (res && res.ok) {
                    // proceed to set value...
                    this.setState({
                        vatAmount: res.body.data.value
                    })
                }
            })
    }

    getRegistrars = () => {
        // Fetch data for the registrars...
        fetch(`${ApiPath}api/edmms-registrar/get-edmms-registrars?limit=200000`, { method: 'GET', headers: this.headersData })
            ?.then(res => (res.json()))
            .then(response => {
                if (response && response.ok) {
                    // Proceed...
                    this.setState({
                        registrars: response.body.registrars?.data
                    })
                }
            })
    }

    getEDMMSCompanies = () => {
        // Fetch data for the companies...
        fetch(`${ApiPath}api/edmms-company/get-edmms-companies?limit=200000`, { method: 'GET', headers: this.headersData })
            ?.then(resonse => (resonse.json()))
            .then(response => {
                if (response && response.ok) {
                    // Proceed...
                    this.setState({
                        companies: response.body.companies?.data
                    })
                }
            })
    }

    fetchInstitutions = () => {
        const options = {
            method: 'GET',
            headers: this.headersData,
        }

        fetch(ApiPath + 'api/institution/fetch-institutions-for-service?service=3', options)
            ?.then(response => {
                return response.json();
            })
            .then(response => {
                if (response && response.ok) {
                    this.setState({
                        institutionsArray: response.body.data.sort(DynamicSort("name")),
                    })
                }
            })
    }

    fetchBranches = () => {
        //Fetch the branches data successfully
        //And sets to the branches state variable...
        const options = {
            method: 'GET',
            headers: this.headersData,
        }

        const institutionSlug = this.state.institutionsArray?.find(inst => inst.code === this.state.cbxInstitutions)?.slug;
        fetch(`${ApiPath}api/institution/fetch-institution-branches/${institutionSlug}`, options)
            ?.then(ret => {
                return ret.json();
            })
            .then(res => {
                if (res && res.ok) {
                    this.setState({
                        branchesArray: res.body.data.branches,
                    })
                }
            })
    }

    fetchAccountDetails = () => {
        /**
         * As the name implies
         * get the account details
         * based on the supplied data...
         */

        // validate institution is selected...
        if (!this.state.cbxInstitutions) {
            this.props.ShowToast('You have not selected any institution yet.', 'exclamation');
            return;
        }

        // validate that the account number (correct one) was selected...
        if (!this.state.txtAccountNumber || this.state.txtAccountNumber.length < 10) {
            this.props.ShowToast('You must enter a valid 10-digit NUBAN account number proceed.')
        }

        const body = {
            accountNo: this.state.txtAccountNumber,
            institutionCode: this.state.cbxInstitutions
        }

        //The options, after the validation...
        const options = {
            method: "POST",
            headers: this.headersData,
            body: JSON.stringify(body)
        }

        const endpoint = `${ApiPath}api/misc/get-account-name`;

        //The actual fetch...
        this.setState({ showAccountLoader: true })
        fetch(endpoint, options)
            ?.then(response => {
                this.setState({ showAccountLoader: false })
                return response.json()
            })
            .then(response => {
                if (response) {
                    this.setState({
                        txtAccountName: `${response.body.accountName}`
                    })
                }
            })
            .catch(error => {
                this.setState({ showAccountLoader: false })
                this.props.ShowToast(error.message, "exclamation");
            })

    }

    handleChange = e => {
        const { id, value } = e.target;
        this.setState({
            [id]: value,
        }, () => {
            if (id === "cbxInstitutions") {
                //Immediately change the branches list...
                this.fetchBranches();
            } else if (id === "txtAccountNumber") {
                //Check if the length is up to 10 digits
                if (this.state.txtAccountNumber.length === 10) {
                    //Check the ICAD service...
                    this.fetchAccountDetails();
                }
            }
        })
    }

    createMandate = () => {
        //Create the mandate after validating the required fields...
        if (this.state.stockData.length === 0) {
            this.props.ShowToast("You must add at least 1 stock holding information.", "exclamation");
            return;
        }

        if (this.state.txtSurname === "") {
            this.props.ShowToast("Surname required.", "exclamation");
            return;
        }
        if (this.state.txtPhone === "" || this.state.txtPhone.length < 11) {
            this.props.ShowToast("11-Digit phone number required!", "exclamation");
            return;
        }
        if (this.state.txtBVN.length < 11) {
            this.props.ShowToast("11-Digit BVN is required!", "exclamation");
            return;
        }
        if (this.state.cbxInstitutions === "") {
            this.props.ShowToast("Payment Bank required!", "exclamation");
            return;
        }

        if (this.state.cbxBranch === "") {
            this.props.ShowToast("Payment branch required!", "exclamation");
            return;
        }

        if (this.state.txtAccountName === "") {
            // Account name required!
            this.props.ShowToast("Account name required!", "exclamation");
            return;
        }

        if (this.state.mandateForm === "") {
            this.props.ShowToast("Upload a copy of the mandate form to proceed.", "exclamation");
            return;
        }

        //After successful validation, proceed to update
        //1. set the information to the specified field by the endpoint.
        //2. set the parameters for the update...
        //3. Submit and await response...
        const body = {
            receivingBranch: this.state.cbxBranch,
            companyName: this.state.txtSurname,
            othernames: this.state.txtOthernames,
            phoneNo: this.state.txtPhone,
            bvn: this.state.txtBVN,
            paymentInstitution: this.state.institutionsArray.find(elt => elt.code === this.state.cbxInstitutions)?.slug,
            branchName: this.state.branchesArray?.find(element => element.slug === this.state.cbxBranch)?.branchName,
            paymentAccountNo: this.state.txtAccountNumber,
            paymentAccountName: this.state.txtAccountName,
            mandateForm: this.state.mandateForm,
            stockHoldings: [...this.state.stockData]
        }

        const postOptions = {
            method: 'POST',
            headers: this.headersData,
            body: JSON.stringify(body)
        }

        // Proceed...
        this.props.setIsLoaderVisible(true);

        fetch(`${ApiPath}api/edmms-mandate/log-edmms-mandate`, postOptions)
            ?.then(resp => (resp.json()))
            .then(resp => {
                this.props.setIsLoaderVisible(false);
                if (resp && resp.ok) {
                    // Updated successfully.
                    this.props.ShowToast("Mandate logged successfully!", "success");

                } else {
                    this.props.ShowToast(resp.message, "exclamation");
                }
            })
            .catch(error => {
                this.props.setIsLoaderVisible(false);
                this.props.ShowToast(error.message, "exclamation");
            })
    }

    removeItem = itemIndex => {
        //Remove the item from the list...
        const data = this.state.stockData;
        data.splice(itemIndex, 1);

        //Change the state object to the new data...
        this.setState({ stockData: data })
    }

    render() {

        const stockInformation = this.state.stockData?.map(item => (
            <StockComponent item={item} key={item?.shareHolderAccountNo} removeItem={this.removeItem} companies={this.state.companies} registrars={this.state.registrars} />
        ))

        const institutions = this.state.institutionsArray?.map(item => (<option value={item.code} key={item.slug}>{item.name}</option>))
        const branches = this.state.branchesArray?.map(item => (<option value={item.slug} key={item.slug}>{item.branchName}</option>))

        const totalFees = this.state.registrars.length * parseFloat(this.state.eDMMSFee);
        const vat = this.state.vatAmount * totalFees;
        const amountDue = totalFees + vat;

        return (
            <>
                <legend className="legend mt-2">
                    <caption>Stock Holding Information</caption>
                    {this.state.stockData.length > 0
                        ? stockInformation
                        :
                        <div className="no-stock-image">
                            <h3>No Stock Data!</h3>
                            <i className="icofont-not-allowed" />
                            <img src="/assets/img/svg/no-data.svg" alt="" />
                        </div>
                    }
                    <div className="form-row-5">
                        <div></div><div></div>
                        <div className="input-group">
                            <button
                                className="btn-green"
                                id="btnAddStock"
                                onClick={() => this.setState({ displayAddStockDialog: true })}
                            >
                                <i className="icofont-plus" /> Add Stock
                            </button>
                        </div>
                        <div></div><div></div>
                    </div>
                </legend>
                {/* Personal Information */}
                <PersonalInfoPartFormComponent
                    txtSurname={this.state.txtSurname}
                    txtOthernames={this.state.txtOthernames}
                    txtPhone={this.state.txtPhone}
                    txtEmail={this.state.txtEmail}
                    txtBVN={this.state.txtBVN}
                    txtClearingHouseNumber={this.state.txtClearingHouseNumber}
                    handleChange={this.handleChange}
                />

                {/* Mandate payment information */}
                <legend className="legend">
                    <caption>e-Dividend Mandate Payment Information</caption>
                    <div className="form-row-3 mb-2">
                        <div className="input-group">
                            <label><span>*</span>Payment Bank</label>
                            <select
                                className="form-control"
                                id="cbxInstitutions"
                                value={this.state.cbxInstitutions}
                                onChange={this.handleChange}
                            >
                                <option value="">Select Institution</option>
                                {institutions}
                            </select>
                        </div>
                        <div className="input-group">
                            <label><span>*</span>Branch</label>
                            <select
                                className="form-control"
                                id="cbxBranch"
                                value={this.state.cbxBranch}
                                onChange={this.handleChange}
                            >
                                <option value="">Select Branch</option>
                                {branches}
                            </select>
                        </div>
                    </div>
                    <div className="form-row-3 mb-2">
                        <div className="form-row-3-1">
                            <div className="input-group">
                                <label><span>*</span>Account Number</label>
                                <input
                                    type="text"
                                    maxLength={10}
                                    className="form-control"
                                    id="txtAccountNumber"
                                    value={this.state.txtAccountNumber}
                                    onChange={this.handleChange}
                                    placeholder="XXXXXXXXXX"
                                />
                                {this.state.showAccountLoader && <i class='bx bx-loader bx-spin' style={{ color: 'var(--main-green)', position: 'absolute', right: '10px', top: '30px', fontSize: '1.5em' }}></i>}
                            </div>
                        </div>
                        <div className="input-group">
                            <label><span>*</span>Account Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="txtAccountName"
                                value={this.state.txtAccountName}
                                onChange={this.handleChange}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <div className="form-row-2-1 mb-2">
                        <div className="input-group">
                            <label><span>*</span>Upload Mandate Form</label>
                            <FileInput
                                setFileOutput={value => this.setState({ mandateForm: value })}
                                setFirstPart={value => this.setState({ mandateExt: value })}
                                showToast={this.props.ShowToast}
                                acceptFileTypes={".pdf, .jpg, .jpeg, .png"}
                                maxSize={2000000}
                                setDocumentName={value => this.setState({ documentName: value })}
                            />
                            <div className="control-info"><i className="icofont-warning-alt"></i> Maximum file size: 2MB</div>
                        </div>
                    </div>
                    <div className="form-row-3 mb-2">
                        <div className="input-group">
                            <label>Total Fees</label>
                            <input
                                className="form-control"
                                id="txtTotalFees"
                                value={totalFees}
                                onChange={this.handleChange}
                                type="number"
                                disabled
                            />
                        </div>
                        <div className="input-group">
                            <label>VAT</label>
                            <input
                                className="form-control"
                                id="txtVAT"
                                value={vat}
                                onChange={this.handleChange}
                                type="number"
                                disabled
                            />
                        </div>
                        <div className="input-group">
                            <label>Amount Due</label>
                            <input
                                className="form-control"
                                id="txtAmountDue"
                                value={amountDue}
                                onChange={this.handleChange}
                                type="number"
                                disabled
                            />
                        </div>
                    </div>
                </legend>
                <div className="form-row-3">
                    <div></div>
                    <div className="input-group">
                        <button
                            className="btn-green" id="btnCreateMandate" onClick={this.createMandate}><i className="icofont-upload-alt" /> Create Mandate Request</button>
                    </div>
                </div>

                {this.state.displayAddStockDialog && <AddStockDialog
                    hideDialog={() => this.setState({ displayAddStockDialog: false })}
                    stockData={this.state.stockData}
                    setStockData={value => this.setState({ stockData: value })}
                    ShowToast={this.props.ShowToast}
                    companies={this.state.companies}
                    registrars={this.state.registrars}
                />}
            </>
        )
    }
}
