import React from 'react'

export const EDMMSTableColumns = () => {
    return (
        <>
            <th scope="col" className="align-center">#</th>
            <th scope="col" className="align-left">MANDATE CODE</th>
            <th scope="col" className="align-left">CREATED AT</th>
            <th scope="col" className="align-left">REGISTRAR</th>
            <th scope="col" className="align-left">INSTITUTION</th>
            <th scope="col" className="align-left">BRANCH</th>
            <th scope="col" className="align-left">SURNAME</th>
            <th scope="col" className="align-left">OTHERNAMES</th>
            <th scope="col" className="align-left">BVN</th>
            <th scope="col" className="align-left">CHN</th>
        </>
    )
}
