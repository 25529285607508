import QuickAccessItem from "./quick-access-item";
import QuickAccessList from "../assets/data/quick-access-list";

const isNIBSSUser = (sessionStorage.getItem("is_nibss_user")?.indexOf("NIBSS") > -1) ? true : false;

const QuickAccessForm = () => {

    /**
     * This quick access form
     * basically
     * allows us to have
     * a quick view of
     * application shortcuts.
     */

    let counter = 0;

    return (
        <div className="access-container">
            <div className="access-header">
                <h2>Document Management Hub Portal</h2>
                <h4>Welcome Page</h4>
            </div>
            <div className="access-grid d-none">
                {
                    QuickAccessList.map(item => {
                        counter++;
                        let showQAItem = isNIBSSUser ? true : item.forInstitutionUser;

                        //Secoond clause
                        if (sessionStorage.getItem("session_user_type") !== "Admin" && item.adminOnly === true) {
                            showQAItem = false;
                        }
                        return (
                            showQAItem && <QuickAccessItem
                                key={item.id}
                                id={counter}
                                imgURL={item.imgURL}
                                title={item.title}
                                description={item.description}
                                pageURL={item.pageURL}
                            />
                        )
                    })
                }

            </div>
            <div className="access-body">
                <img src="/assets/img/svg/quick-access.svg" alt="" />
                <h3>Select action from navigation menu</h3>
            </div>

        </div>
    );
}

export default QuickAccessForm;
