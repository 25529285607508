import React from "react";
import ApiPath from "../../admin/assets/App/api-path";
import { SplitDate } from "../../admin/assets/App/split-date";
import objectToCSV from "../../admin/assets/App/object-to-csv";
import ExportToCSVFile from "../../admin/assets/App/ExportToCSVFile";
import IncomingCardsTableRows from "./incoming-cards-table-rows";
import SearchComponent from "../../admin/assets/App/SearchComponent";
import TrappedCardsSearchList from "../../admin/assets/data/TrappedCardsSearchList";
import Pagination from "../../admin/assets/App/Pagination";
import ApproveRejectCardsDialog from "./approve-reject-cards-dialog";
import CapitalizeFirstLetter from "../../admin/assets/App/CapitalizeFirstLetter";
import DynamicSort from "../../admin/assets/App/DynamicSort";
import InstitutionsDialog from "../../components/InstitutionsDialog";
import MaskCardNumber from "../../admin/assets/App/MaskCardNumber";
import { DateRange } from "../../components/date-range";

/**
* This incoming cards form
* basically
* allows us to have
* a quick view of
* application shortcuts.
*
* We need to add the
* table and the
* table rows to
* follow the
* expected
* description on
* the requirements
* document...
*/

export default class IncomingCardsForm extends React.Component {
    constructor() {
        super()
        this.state = {
            actionSuccessful: false,
            apiData: [],
            metaData: {},
            viewData: [],
            editData: [],
            exportData: [],
            branches: [],
            branchNames: [],
            institutionsArray: [],
            fetchSuccessful: true, //Change later...

            //Search parameters
            searchField: "",
            searchValue: "",
            startDate: "",
            endDate: "", displayApproveDialog: false,

            //Pagination values
            currentSelectedPage: 1,
            arrayPages: [],
            lastPage: 1,
            perPage: localStorage.getItem("items_per_page") || 20,
            totalItems: 0,

            //The institution dialog
            showInstitutionDialog: false,
            selectedAcquiringInstitution: sessionStorage.getItem("institution_slug")
        }

        this.headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
            'Cache-Control': 'public, max-age=0'
        }
    }

    fetchInstitutions = () => {
        const options = {
            method: 'GET',
            headers: this.headers,
        }

        fetch(ApiPath + 'api/institution/fetch-institutions', options)
            ?.then(response => {
                return response.json();
            })
            .then(response => {
                if (response.ok) {
                    this.setState({
                        institutionsArray: response.body.data.institutions.sort(DynamicSort("name")),
                    })
                }
            })
    }

    getBranches = () => {
        const options = {
            method: 'GET',
            headers: this.headers,
        }

        fetch(`${ApiPath}api/institution/fetch-institution-branches/${this.state.selectedAcquiringInstitution}`, options)
            ?.then(response => {
                return response.json();
            })
            .then(response => {
                if (response && response.ok) {
                    this.setState({
                        branches: response.body.data.branches,
                        branchNames: response.body.data.branches?.map(item => ({ item_name: item.branchName }))

                    })
                }
            })

    }

    refreshBranches = () => {
        this.getBranches();
    }

    getQueryString = () => {

        let queryString = "";
        let branch = false;

        if (this.state.searchField === "issuingInstitution") {
            //Set the slug as the required searchField...
            const institutionSlug = this.state.institutionsArray.find(item => item.name === this.state.searchValue)?.slug;

            queryString = `${ApiPath}api/trapped-card/fetch-incoming-trapped-cards?page=${this.state.currentSelectedPage}` +
                `&${this.state.searchField}=${institutionSlug}&limit=${this.state.perPage}`;

        } else {
            if (this.state.searchField === 'branch' && this.state.searchValue === '') {
                branch = true;
            }
            //Else proceed...
            const searchValue = this.state.branches.find(item => item.branchName === this.state.searchValue)?.slug;
            queryString = `${ApiPath}api/trapped-card/fetch-incoming-trapped-cards?page=${this.state.currentSelectedPage}` +
                `&${this.state.searchField}=${searchValue}&limit=${this.state.perPage}`;

        }

        queryString += (this.state.startDate && this.state.endDate) ? `&createdAt=${this.state.startDate}&createdAt=${this.state.endDate}` : '';

        return { queryString, branch };

    }

    getIncomingCardsData = () => {

        /**
         * Set the endpoints to match the cards later...
         */
        const options = {
            method: 'GET',
            headers: this.headers,
        }

        const { queryString, branch } = this.getQueryString();

        if (branch) {
            return;
        }

        this.props.setIsLoaderVisible(true)

        fetch(queryString, options)
            ?.then(response => {
                return response.json();
            })
            .then(res => {
                this.props.setIsLoaderVisible(false)
                if (res && res.ok === true) {
                    this?.setState({
                        apiData: res.body.trappedCards.data,
                        metaData: res.body.trappedCards.meta,
                        actionSuccessful: false,
                        fetchSuccessful: true,
                        arrayPages: [],
                    }, () => {
                        const fetchedPagesArray = [];
                        this.setState({
                            lastPage: this.state.metaData?.last_page,
                            currentSelectedPage: this.state.metaData?.current_page,
                            totalItems: this.state.metaData?.total,
                        }, () => {
                            //Set this array for the looping
                            for (let i = 1; i <= this.state.lastPage; i++) {
                                fetchedPagesArray.push({ "id": i, "name": "Item " + i });
                            }

                            this.setState({ arrayPages: fetchedPagesArray })
                        })
                    })
                } else {
                    this.props.setIsLoaderVisible(false);
                    this.props.ShowToast(res?.message, "exclamation");
                }
            })
    }

    componentDidMount() {
        //Fetch all the data for the rows as the form loads...
        this.getIncomingCardsData();
        this.getBranches();
        this.fetchInstitutions();

    }

    filterRecords = () => {
        //First, get the insitution...
        this.getIncomingCardsData();
    }

    RefreshForm = () => {
        window.location.reload();
    }

    ExportData = () => {
        /**
         * Exports the data in pre-defined format...
         */
        const prevPerPage = this.state.perPage;
        this.setState({ perPage: 200000 }, () => {
            this.getIncomingCardsData()

            const DataToExport = [];
            this.state.apiData.forEach(item => {
                //First, get individual reference details

                var thisObject = {
                    "Log Issue ID": `${item.trappedCardNo}\t`,
                    "Card Number": `${MaskCardNumber(item.cardNumber)}\t`,
                    "Name on Card": item.nameOnCard,
                    "Acquiring Institution": item.acquiringInstitution?.name,
                    "Issuing Institution": item.issuingInstitution?.name,
                    "Branch": item.branch || "-",
                    "Card Scheme": CapitalizeFirstLetter(item.cardScheme),
                    "Initiate Date": SplitDate(item.createdAt).dateTime,
                    "Treated By": item.trappedCardLog?.accpetedByUser?.email || item.trappedCardLog?.rejectedByUser?.email || "-",
                    "Status": item.status,
                }

                DataToExport.push(thisObject)

            })

            const csvData = objectToCSV(DataToExport);
            ExportToCSVFile(csvData, "Incoming-trapped-cards.csv");

            this.props.ShowToast("Incoming Trapped Cards exported successfully! Open downloads folder to preview...", "information");

            //Return the number of items per page
            this.setState({ perPage: prevPerPage }, () => this.getIncomingCardsData())

        })


    }

    render() {

        let counter = 0;
        const TableRows = this.state.fetchSuccessful && this.state.apiData.map(item => {
            counter++;
            const itemCounter = (this.state.currentSelectedPage - 1) * this.state.perPage + counter

            return (<IncomingCardsTableRows
                key={counter}
                counter={itemCounter}
                setViewData={value => this.setState({ viewData: value })}
                setIsLoaderVisible={this.props.setIsLoaderVisible}
                setActionSuccessful={value => this.setState({ actionSuccessful: value })}
                ShowToast={this.props.ShowToast}
                item={item}
                setEditData={value => this.setState({ editData: value })}
                setDisplayApproveDialog={value => this.setState({ displayApproveDialog: value })}

            />)
        })

        if (this.state.actionSuccessful) {
            this.getIncomingCardsData()
            this.setState({
                actionSuccessful: false,
            })
        }

        //Create search lists..
        // const branchNames = this.state.branches.map(item => ({ item_name: item.branchName }))

        const branchNames = this.state.branchNames;

        const status = [{ item_name: "Pending" }, { item_name: "Accepted" }, { item_name: "Rejected" }]

        const institutionNames = this.state.institutionsArray.map(item => ({ item_name: item.name }))

        const selectedInstitutionName = this.state.institutionsArray.find(item => item.slug === this.state.selectedAcquiringInstitution)?.name;

        return (
            <>
                <div className="title">
                    <DateRange
                        callback={this.filterRecords}
                        ShowToast={this.props.ShowToast}
                        setDateFrom={val => this.setState({ startDate: val })}
                        setDateTo={val => this.setState({ endDate: val })}
                    />
                    <div className="center-component">
                        {this.state.searchField === "branch" && <button
                            style={{ borderRadius: '5px', paddingTop: '7px', paddingBottom: '7px' }}
                            className="btn-grey t-tip"
                            onClick={() => this.setState({ showInstitutionDialog: true })}
                            data-title={`Selected acquiring instituion: ${selectedInstitutionName}`}
                            id="btn">{selectedInstitutionName} <i className="icofont-rounded-down" style={{ fontWeight: 'bold' }} />
                        </button>}
                        <SearchComponent
                            setSearchField={val => this.setState({ searchField: val })}
                            setSearchValue={val => this.setState({ searchValue: val })}
                            setSearchValue2={val => this.setState({ searchValue2: val })}
                            ReferenceSearchList={TrappedCardsSearchList}
                            filterRecords={this.filterRecords}
                            inputList={{ status, institutionNames, branchNames }}
                            ShowToast={this.props.ShowToast}
                        />
                    </div>
                    <div className="buttons">
                        <button
                            type="button"
                            className="btn-grey t-tip mr-1"
                            id="btnRefresh"
                            data-title="Refresh"
                            onClick={this.RefreshForm}>
                            <i className="icofont-refresh"></i> Refresh
                        </button>
                        <button
                            type="button"
                            className="btn-green t-tip"
                            id="btnExport"
                            data-title="Export records to MsExcel file"
                            onClick={this.ExportData}>
                            <i className="icofont-airplane-alt"></i> Export
                        </button>
                    </div>
                </div>
                <div className="table-container">
                    <table className="bordered-table">
                        <caption></caption>
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col" className="align-left">TRAPPED CARD NO</th>
                                <th scope="col" className="align-left">CARD NUMBER</th>
                                <th scope="col" className="align-left">NAME ON CARD</th>
                                <th scope="col" className="align-left">ISSUING BANK</th>
                                <th scope="col" className="align-left">ACQUIRING BANK</th>
                                <th scope="col" className="align-center">BRANCH</th>
                                <th scope="col" className="align-center">CARD SCHEME</th>
                                <th scope="col" className="align-center">INITIATE DATE</th>
                                <th scope="col" className="align-center">TREATED BY</th>
                                <th scope="col" className="align-center" width="120">STATUS</th>
                                <th scope="col" className="align-center" width="120">ACTION</th>
                            </tr>
                        </thead>
                        <tbody id="Incoming_cards_table">
                            {TableRows}
                        </tbody>
                    </table>
                </div>
                <Pagination
                    perPage={this.state.perPage}
                    setCurrentSelectedPage={value => this.setState({ currentSelectedPage: value })}
                    setPerPage={value => this.setState({ perPage: value })}
                    currentSelectedPage={this.state.currentSelectedPage}
                    setRefreshForm={value => this.setState({ actionSuccessful: value })}
                    lastPage={this.state.lastPage}
                    arrayPages={this.state.arrayPages}
                />

                {
                    this.state.displayApproveDialog && <ApproveRejectCardsDialog
                        data={this.state.editData}
                        displayApproveDialog={this.state.displayApproveDialog}
                        setApproveSuccessful={value => this.setState({ actionSuccessful: value })}
                        setDisplayApproveDialog={value => this.setState({ displayApproveDialog: value })}
                        setIsLoaderVisible={this.props.setIsLoaderVisible}
                        ShowToast={this.props.ShowToast}

                    />
                }

                {
                    this.state.showInstitutionDialog && <InstitutionsDialog
                        hideInstitutionsDialog={() => this.setState({ showInstitutionDialog: false })}
                        institutions={this.state.institutionsArray}
                        showDialog={this.state.showInstitutionDialog}
                        setInstitution={value => this.setState({ selectedAcquiringInstitution: value }, () => {
                            this.refreshBranches();
                        })}
                        selectedInstitution={this.state.selectedAcquiringInstitution}
                    />
                }

            </>

        );
    }

}

/**
     * This create trapped cards form
     * basically
     * allows us to have
     * a quick view of
     * application shortcuts.
     *
     * We need to add the
     * table and the
     * table rows to
     * follow the
     * expected
     * description on
     * the requirements
     * document...
     */
/**
* This create trapped cards form
* basically
* allows us to have
* a quick view of
* application shortcuts.
*
* We need to add the
* table and the
* table rows to
* follow the
* expected
* description on
* the requirements
* document...
*/
/**
* This create trapped cards form
* basically
* allows us to have
* a quick view of
* application shortcuts.
*
* We need to add the
* table and the
* table rows to
* follow the
* expected
* description on
* the requirements
* document...
*/
