const SearchList = (props) => {
    return (
        <label className="search-list"> {props.itemName}
            <input
                type="radio"
                id={props.id}
                name="search"
                onKeyDown={e => {
                    if (e.key === "Enter") {
                        props.filterRecords();
                    }
                }}
                onClick={props.ChangeSearchCriteria}
                value={props.value}
                onChange={props.ChangeSearchCriteria}
                checked={props.searchField === props.id}

            />
            <span></span>
        </label>
    );
}

export default SearchList;
