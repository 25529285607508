import NavBar from "../../admin/assets/App/nav-bar"
import TopBar from "../../admin/assets/App/topbar"
import LockedScreen from "../../admin/assets/App/LockedScreen";
import Footer from "../../admin/assets/App/footer";

import { Helmet } from "react-helmet";
import { useState } from 'react'
import { UserRolesForm } from "./user-roles-form";

export const UserRoles = (props) => {
    const [mainClass, setMainClass] = useState("main");

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Configuration - User Roles | NIBSS Document Management Hub Portal</title>
            </Helmet>
            <LockedScreen
                setIsLoaderVisible={props.setIsLoaderVisible}
                ShowToast={props.ShowToast}
            />
            <div className="cont-ainer">
                <NavBar sender="User Roles" />
                <div className={mainClass}>
                    <TopBar
                        setMainClass={value => setMainClass(value)}
                    />
                    <div className="page-title">
                        <h2><i className="icofont-mr icofont-ui-settings"></i>Configuration - Institution User Roles</h2>
                    </div>
                    <div className="branch-summaries">
                        <div className="summaries">
                            <UserRolesForm
                                setIsLoaderVisible={props.setIsLoaderVisible}
                                ShowToast={props.ShowToast}
                            />
                        </div>
                    </div>
                    <Footer />
                </div>

            </div>

        </>
    )
}
