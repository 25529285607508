import NavBar from "../../admin/assets/App/nav-bar";
import TopBar from "../../admin/assets/App/topbar"
import LockedScreen from "../../admin/assets/App/LockedScreen";
import Footer from "../../admin/assets/App/footer";
import { Helmet } from "react-helmet";
import React, { useState } from 'react'
import PublicHolidaysForm from "./public-holidays-form"

export const PublicHolidays = (props) => {
    const [mainClass, setMainClass] = useState("main");
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Configuration - Public Holidays | NIBSS Document Management Hub Portal</title>
            </Helmet>
            <LockedScreen
                setIsLoaderVisible={props.setIsLoaderVisible}
                ShowToast={props.ShowToast}
            />
            <div className="cont-ainer">
                <NavBar sender="Public Holidays" />
                <div className={mainClass}>
                    <TopBar
                        setMainClass={value => setMainClass(value)}
                    />
                    <div className="page-title">
                        <h2><i className="icofont-mr icofont-ui-settings"></i>Configuration - Public Holidays</h2>
                    </div>
                    <div className="branch-summaries">
                        <div className="summaries">
                            <div className="title">
                                <h3>Public Holidays</h3>
                            </div>
                            <PublicHolidaysForm
                                setIsLoaderVisible={props.setIsLoaderVisible}
                                ShowToast={props.ShowToast}
                            />
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}
