import NavBar from "../../admin/assets/App/nav-bar"
import TopBar from "../../admin/assets/App/topbar"
import LockedScreen from "../../admin/assets/App/LockedScreen";
import Footer from "../../admin/assets/App/footer";
import React, { useState } from 'react'
import { Helmet } from "react-helmet";
import ConfigureCardBinForm from "./configure-card-bin-form";

export const ConfigureCardBin = (props) => {
  const [mainClass, setMainClass] = useState("main");
  /**
      * This is the configure card bin
      * basically
      * allows us to have
      * access to the
      * configure card bin
      * interface
      */

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Configuration - Configure Card Bin | NIBSS Document Management Hub Portal
        </title>
      </Helmet>

      <LockedScreen
        setIsLoaderVisible={props.setIsLoaderVisible}
        ShowToast={props.ShowToast}
      />
      <div className="cont-ainer">
        <NavBar sender="Configure Card Bin" />
        <div className={mainClass}>
          <TopBar
            setMainClass={value => setMainClass(value)}
          />
          <div className="page-title">
            <h2>
              <i className="icofont-mr icofont-ui-settings"></i>Configuration
              - Trapped Cards Bin
            </h2>
          </div>
          <div className="branch-summaries">
            <div className="summaries">
              <ConfigureCardBinForm
                setIsLoaderVisible={props.setIsLoaderVisible}
                ShowToast={props.ShowToast}
              />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
