import React, { useState } from 'react'
import Badges from '../../admin/assets/App/Badges'
import { SplitDate } from '../../admin/assets/App/split-date'
import CapitalizeFirstLetter from '../../admin/assets/App/CapitalizeFirstLetter'

export const EDMMSReportsTableRow = props => {
    const [statusClass] = useState(Badges(props.data?.status));

    return (
        <tr>
            <td>{props.counter}</td>
            <td>{props.data?.mandateCode}</td>
            <td>{SplitDate(props.data?.createdAt).dateTime}</td>
            <td>{"******"}</td>
            <td>{props.data?.institution?.name}</td>
            <td>{props.data?.branch?.branchName}</td>
            <td>{props.data?.companyName}</td>
            <td>{props.data?.othernames || "-"}</td>
            <td>{props.data?.bvn || "-"}</td>
            <td>{props.data?.chn || "-"}</td>
            <td>{props.data?.paymentAccountNo}</td>
            <td className='align-center'>
                <span className={statusClass}>{CapitalizeFirstLetter(props.data?.status)}</span>
            </td>
            <td className='align-center'>
                <i
                    id="btnDetails"
                    className="icofont-ui-note mr-1"
                    style={{ fontSize: 20, color: "grey", cursor: "pointer" }}
                    title="View Details"
                    onClick={() => {
                        // Show the form, after setting the slug
                        // of the record...
                        props.setViewData(props.data);
                        props.showDetailsForm();
                    }}
                />
            </td>
        </tr>
    )
}
