import React from "react";
import UserRoleCheckGroup from "./user-role-checkgroup";
const EditUserRolesDialog = (props) => {

    return (
        <UserRoleCheckGroup
            ShowToast={props.ShowToast}
            setIsLoaderVisible={props.setIsLoaderVisible}
            data={props.rowData}
            sender={"Edit"}
            displayClicked={props.editClicked}
            setDisplayClicked={props.setEditClicked}
            setActionSuccessful={props.setIsEditSuccessful}

        />
    )

}

export default EditUserRolesDialog
