const TrappedCardsSearchList = [

     /**
     * This trapped cards search list
     * basically
     * allows us to have
     * a quick view of
     * application shortcuts.
     *
     * We need to add the
     * table and the
     * table rows to
     * follow the
     * expected
     * description on
     * the requirements
     * document...
     */

     /**
     * This trapped cards search list
     * basically
     * allows us to have
     * a quick view of
     * application shortcuts.
     *
     * We need to add the
     * table and the
     * table rows to
     * follow the
     * expected
     * description on
     * the requirements
     * document...
     */
    {
        id: 1,
        idVal: "status",        //This is the searchField in the database
        value: "Status",
        itemName: "Status",
    },
    {
        id: 2,
        idVal: "branch",
        value: "Branch",
        itemName: "Branch",
    },
    {
        id: 3,
        idVal: "issuingInstitution",
        value: "Issuer",
        itemName: "Issuer",
    },
    {
        id: 4,
        idVal: "createdAt",
        value: "Date Range",
        itemName: "Date Range",
    },
]

export default TrappedCardsSearchList
