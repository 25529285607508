const navLinksData = [
    {
        id: 0,
        location: "/general/quick-access",
        iconclass: "icofont-compass",
        linkName: "Welcome Page",
        link_id: "link_dashboard",
        forAll: true,
        hideSpeciallyForInstitutionUser: false,
        // Here, we need to add the specific modules they have access to,
        //based on user roles... Work with Kola on this...
        roleNumbers: "1-2-3-4" //For the services (eRef, TrappedCards, eClips and eDMMs respectively)
    },
    {
        id: 1,
        location: "/admin/dashboard",
        iconclass: "icofont-dashboard",
        linkName: "Dashboard",
        link_id: "link_dashboard",
        forAll: false,
        hideSpeciallyForInstitutionUser: true,
    },
    {
        id: 2,
        location: "/admin/audit-logs",
        iconclass: "icofont-data",
        linkName: "Audit Logs",
        lineExists: true,
        link_id: "link_audit_logs",
        forAll: false,
        hideSpeciallyForInstitutionUser: true,
    },
    {
        id: 3,
        location: "/institutions/institutions",
        iconclass: "icofont-institution",
        linkName: "Institution Management",
        lineExists: true,
        link_id: "link_institutions",
        forAll: false,
        hideSpeciallyForInstitutionUser: true,

    },
    {
        id: 4,
        location: "/branch-management/branch-management",
        iconclass: "icofont-chart-flow-2",
        linkName: "Branch Management",
        link_id: "link_branch_management",
        forAll: true,
        hideSpeciallyForInstitutionUser: true,

    },
    {
        id: 53,
        location: "/user-management/nibss-users",
        iconclass: "ion-person-stalker",
        linkName: "NIBSS Users",
        lineExists: false,
        link_id: "link_nibss_user_management",
        forAll: false,
        hideSpeciallyForInstitutionUser: true,

    },
    {
        id: 54,
        location: "/user-management/institution-users",
        iconclass: "icofont-users",
        linkName: "Institution Users",
        lineExists: true,
        link_id: "link_institution_user_management",
        forAll: true,
        hideSpeciallyForInstitutionUser: true,

    },
    {
        id: 62,
        location: "/config/admin-group-emails",
        iconclass: "icofont-email",
        linkName: "Admin Group Emails",
        link_id: "link_admin_emails",
        forAll: false,
        hideSpeciallyForInstitutionUser: true,

    },
    {
        id: 6,
        location: "/user-management/bulk-user-actions",
        iconclass: "icofont-addons",
        linkName: "Bulk Actions",
        link_id: "bulk_actions",
        forAll: true,
        hideSpeciallyForInstitutionUser: true,

    },
    {
        id: 7,
        location: "/config/institution-types",
        iconclass: "icofont-building-alt",
        linkName: "Institution Types",
        link_id: "link_institution_types",
        forAll: false,
        hideSpeciallyForInstitutionUser: true,
    },
    {
        id: 8,
        location: "/config/user-roles",
        iconclass: "icofont-user-alt-5",
        linkName: "User Roles",
        link_id: "link_user_roles",
        forAll: false,
        hideSpeciallyForInstitutionUser: true,
    },
    {
        id: 9,
        location: "/config/public-holidays",
        iconclass: "icofont-ui-calendar",
        linkName: "Public Holidays",
        link_id: "link_public_holidays",
        forAll: false,
        hideSpeciallyForInstitutionUser: true,
    },
    {
        id: 10,
        location: "/config/configure-vat",
        iconclass: "bx bx-money",
        linkName: "Configure VAT",
        link_id: "link_configure_vat",
        forAll: false,
        hideSpeciallyForInstitutionUser: true,
    },
    {
        id: 50,
        location: "/configuration/e-reference-billing-fee-settings",
        iconclass: "bx bx-exclude",
        linkName: "Configure Fees",
        link_id: "link_billing_fees",
        forAll: false,
        hideSpeciallyForInstitutionUser: true,
    },
    {
        id: 51,
        location: "/configuration/trapped-cards-bin",
        iconclass: "icofont-credit-card",
        linkName: "Configure Card Bin",
        link_id: "link_configure_bin",
        forAll: false,
        hideSpeciallyForInstitutionUser: true,
    },
    // // {
    // //     id: 52,
    // //     location: "/configuration/e-clips-charges-settings",
    // //     iconclass: "ion-clock",
    // //     linkName: "e-Clips Charges Settings",
    // //     link_id: "link_e_clips_overdue_time",
    // //     forAll: false,
    // //     hideSpeciallyForInstitutionUser: true,
    // // },
    {
        id: 11,
        location: "/config/change-password",
        iconclass: "bx bx-user-circle",
        linkName: "Change Password",
        lineExists: true,
        link_id: "link_change_password",
        forAll: true,
        hideSpeciallyForInstitutionUser: false,
    },
    {
        id: 12,
        location: "/e-reference/create-reference",
        iconclass: "icofont-letter",
        linkName: "Create e-Reference",
        link_id: "link_create_e_reference",
        lineExists: false,
        forAll: true,
        enabledServiceId: [1],
        hideSpeciallyForInstitutionUser: false,
    },
    {
        id: 13,
        location: "/e-reference/outgoing-references",
        iconclass: "ion-log-out",
        linkName: "Outgoing e-References",
        link_id: "link_outgoing_references",
        lineExists: false,
        forAll: false,
        enabledServiceId: [1, 2],
        hideSpeciallyForInstitutionUser: true
    },
    {
        id: 14,
        location: "/e-reference/incoming-references",
        iconclass: "ion-log-in",
        linkName: "Incoming e-References",
        link_id: "link_incoming_references",
        forAll: true,
        enabledServiceId: [1, 2],
        hideSpeciallyForInstitutionUser: false
    },
    {
        id: 15,
        location: "/e-reference/reference-reports",
        iconclass: "bx bxs-book-content",
        linkName: "e-Reference Reports",
        link_id: "link_reference_reports",
        lineExists: false,
        forAll: true,
        hideSpeciallyForInstitutionUser: true,
    },
    {
        id: '15b',
        location: "/trapped-cards/trapped-cards-reports",
        iconclass: "icofont-card",
        link_id: "link_trapped_cards_reports",
        lineExists: false,
        linkName: "Trapped Cards Reports",
        forAll: true,
        hideSpeciallyForInstitutionUser: true,
    },
    {
        id: '15c',
        linkName: "e-Clips Reports",
        link_id: "link_e_clips_reports",
        location: "/e-clips/e-clips-reports",
        iconclass: "ion-closed-captioning",
        lineExists: false,
        forAll: true,
        hideSpeciallyForInstitutionUser: true,
    },
    {
        id: '15d',
        link_id: "link_e_dmms_reports",
        location: "/e-dmms/edmms-reports",
        iconclass: "ion-ios-briefcase",
        linkName: "e-DMMS Reports",
        lineExists: false,
        forAll: true,
        hideSpeciallyForInstitutionUser: true,
    },
    {
        id: 16,
        location: "/e-reference/billing-and-reports",
        iconclass: "icofont-chart-bar-graph",
        linkName: "Billing & Reports",
        link_id: "link_billing_and_reports",
        lineExists: false,
        forAll: false,
        hideSpeciallyForInstitutionUser: true,
    },
    {
        id: 17,
        location: "/e-reference/billing-and-reports-scheduled-billings",
        iconclass: "bx bx-timer",
        linkName: "Scheduled Billing",
        link_id: "link_billing_and_reports",
        lineExists: true,
        forAll: false,
        hideSpeciallyForInstitutionUser: true,
    },
    {
        id: 18,
        location: "/trapped-cards/log-trapped-card",
        iconclass: "icofont-penalty-card",
        linkName: "Log Trapped Card",
        link_id: "link_create_trapped_card",
        lineExists: false,
        forAll: true,
        enabledServiceId: [3, 5],
        hideSpeciallyForInstitutionUser: false,
    },
    {
        id: 19,
        location: "/trapped-cards/outgoing-trapped-cards",
        iconclass: "bx bx-credit-card",
        linkName: "Outgoing Trapped Cards",
        link_id: "link_outgoing_trapped_cards",
        lineExists: false,
        forAll: true,
        enabledServiceId: [3, 5],
        hideSpeciallyForInstitutionUser: false,
    },
    {
        id: 20,
        location: "/trapped-cards/incoming-trapped-cards",
        iconclass: "icofont-folder-open",
        linkName: "Incoming Trapped Cards",
        link_id: "link_incoming_trapped_cards",
        lineExists: true,
        forAll: true,
        enabledServiceId: [4, 5],
        hideSpeciallyForInstitutionUser: false,
    },
    {
        id: 21,
        location: "/e-clips/new-clip-request",
        iconclass: "ion-ios-film-outline",
        linkName: "New Clip Request",
        link_id: "link_new_clip_request",
        lineExists: false,
        forAll: true,
        enabledServiceId: [6, 8],
        hideSpeciallyForInstitutionUser: false,
    },
    {
        id: 22,
        location: "/e-clips/outgoing-e-clips",
        iconclass: "ion-ios-videocam",
        linkName: "Outgoing e-Clips",
        link_id: "link_outgoing_e-clips",
        lineExists: false,
        forAll: true,
        enabledServiceId: [6, 8],
        hideSpeciallyForInstitutionUser: false,
    },
    {
        id: 23,
        location: "/e-clips/incoming-e-clips",
        iconclass: "ion-ios-videocam-outline",
        linkName: "Incoming e-Clips",
        link_id: "link_incoming_e-clips",
        lineExists: true,
        forAll: true,
        enabledServiceId: [7, 8],
        hideSpeciallyForInstitutionUser: false,
    },
    {
        id: 241,
        location: "/e-dmms/e-dmms-companies",
        iconclass: "icofont-id-card",
        linkName: "eDMMS Companies",
        link_id: "link_edmms_companies",
        lineExists: false,
        forAll: false,
        hideSpeciallyForInstitutionUser: true,
    },
    {
        id: 24,
        location: "/e-dmms/create-edmms-mandate-form",
        iconclass: "icofont-document-folder",
        linkName: "New eDMMS Mandate",
        link_id: "link_create_edmms_mandate",
        lineExists: false,
        forAll: true,
        enabledServiceId: [9],
        hideSpeciallyForInstitutionUser: false,
    },
    {
        id: 25,
        location: "/e-dmms/outgoing-edmms-mandates",
        iconclass: "icofont-square-right",
        linkName: "Outgoing e-DMMS Mandates",
        link_id: "link_outgoing_edmms_mandate",
        lineExists: false,
        forAll: true,
        enabledServiceId: [9, 10],
        hideSpeciallyForInstitutionUser: false,
    },
    {
        id: 26,
        location: "/e-dmms/incoming-edmms-mandates",
        iconclass: "icofont-square-left",
        linkName: "Incoming e-DMMS Mandates",
        link_id: "link_incoming_edmms_mandate",
        lineExists: false,
        forAll: true,
        enabledServiceId: [9, 10],
        hideSpeciallyForInstitutionUser: false,
    },
]

export default navLinksData
