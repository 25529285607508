import ApiPath from "../../admin/assets/App/api-path"
import objectToCSV from "../../admin/assets/App/object-to-csv"
import ExportToCSVFile from "../../admin/assets/App/ExportToCSVFile"
import BranchManagementTableRows from "./branch-management-table-rows"
import CreateBranchDialog from "./create-branch-dialog"
import EditBranchDialog from "./edit-branch-dialog"
import React from "react";
import { Enums } from "../../_common/enums"
import Pagination from "../../admin/assets/App/Pagination"
import BranchSearchList from "../../admin/assets/data/BranchSearchList"
import SearchComponent from "../../admin/assets/App/SearchComponent"
import { SplitDate } from "../../admin/assets/App/split-date";
import DynamicSort from "../../admin/assets/App/DynamicSort"

class BranchManagementForm extends React.Component {

    constructor() {
        super()
        this.state = {
            apiData: [],
            metaData: [],
            fetchSuccessful: false,
            createSuccessful: false,
            refreshForm: false,
            searchField: "",
            searchValue: "",
            rowData: [],
            editClicked: false,
            institutions: [],
            currentSelectedPage: 1,
            arrayPages: [],
            lastPage: 1,
            perPage: localStorage.getItem("items_per_page") || 20,
        }

        this.CreateBranch = this.CreateBranch.bind(this)
        this.handleItemsCount = this.handleItemsCount.bind(this)

    }

    handleItemsCount = e => {
        this.setState({ perPage: e?.target.value }, () => {
            localStorage.setItem("items_per_page", this.state.perPage)
            this.getBranches()
        })
    }

    EditBranch = itemID => {
        /**
         * Select a particular row of
         * interest for the purpose of
         * edititng...
         * We have decided to use the
         * slug parameter for the
         * filtering...
         */
        const selArray = this.state.apiData.filter(itemList => itemList.slug === itemID);

        this.setState({
            rowData: selArray[0],
            editClicked: true,
        })
    }

    getResponseData = inputData => {
        return (sessionStorage.getItem("is_nibss_user").indexOf("NIBSS") === -1
            ? inputData.filter(item => item.institution?.name === sessionStorage.getItem("institution"))
            : inputData)

    }

    getBranches = () => {
        this.props.setIsLoaderVisible(true)
        const options = {
            method: 'GET',
            headers: Enums.headersData(sessionStorage.getItem("session_token")),
        }

        const searchValue = (this.state.searchField === "institution" && this.state.searchValue !== "")
            ? this.state.institutions.find(item => item.name === this.state.searchValue)?.slug
            : this.state.searchValue;

        const endPoint = `${ApiPath}api/branch/fetch-branches?page=${this.state.currentSelectedPage}` +
            `&limit=${this.state.perPage}&${this.state.searchField}=${searchValue}`

        fetch(endPoint, options)
            ?.then(response => {
                return response.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false)
                if (response) {
                    if (response.ok === true) {
                        //Get the actual fetch...
                        this.setState({
                            apiData: this.getResponseData(response.body.branches.data),
                            metaData: response.body.branches.meta,
                            fetchSuccessful: true,
                            arrayPages: [],

                        }, () => {
                            const arrayPages = [];
                            this.setState({
                                lastPage: this.state.metaData?.last_page,
                                perPage: this.state.metaData?.per_page,
                                currentSelectedPage: this.state.metaData?.current_page,
                            }, () => {
                                //Set this array for the looping
                                for (let i = 1; i <= this.state.lastPage; i++) {
                                    arrayPages.push({ "id": i, "name": "Item " + i, "other": "description" });
                                }
                                this.setState({
                                    arrayPages: arrayPages,
                                })
                            })
                        })

                    } else {
                        this.props.ShowToast(response.message, "exclamation")
                        return;
                    }

                }
            })
            .catch(error => {
                this.props.setIsLoaderVisible(false);
                this.props.ShowToast(error.message, "exclamation");
            })

    }

    handleSelectedPage = pageNo => {
        this.setState({ currentSelectedPage: pageNo }, () => this.getBranches())
    }

    CreateBranch() {
        document?.querySelector(".create-branch")?.classList.add("fade");
    }

    handleExportBranches = () => {

        //First, reset the items per page...
        try {

            this.props.setIsLoaderVisible(true);

            const formerPerPage = this.state.perPage;
            this.setState({
                perPage: 2000,

            }, () => {
                this.getBranches()

                const dataToExport = this.state.apiData.map(item => {
                    return (
                        {
                            "institution": item.institution?.name,
                            "branchName": item.branchName,
                            "branchCode": item.branchCode,
                            "state": item.state,
                            "lga": item.lga,
                            "address": item.address,
                            "contactEmail": item.contactEmail,
                            "phoneNo": item.phoneNo,
                            "usersCount": item.meta?.usersCount,
                            "status": item.status,
                            "createdAt": SplitDate(item.createdAt).dateTime,
                            "updatedAt": SplitDate(item.updatedAt).dateTime,
                        }
                    )
                })

                const csvData = objectToCSV(dataToExport)
                ExportToCSVFile(csvData, "branch-data.csv")

                this.props.setIsLoaderVisible(false);

                //Return to previous state...
                this.setState({ perPage: formerPerPage }, () => this.getBranches())
                this.props.ShowToast("Data downloaded successfully!", "information");

            })


        } catch (error) {
            this.props.setIsLoaderVisible(false);
            this.props.ShowToast(error, "exclamation");
        }

    }

    downLoadSchema() {
        const data = {
            "institution": "",
            "branchName": "",
            "branchCode": "",
            "state": "",
            "lga": "",
            "address": "",
            "contactEmail": "",
            "phoneNo": "",
            "usersCount": "",
            "status": "",
            "createdAt": "",
            "updatedAt": "",
        };

        const csvRows = []
        const headers = Object.keys(data)

        csvRows.push(headers.join(","));

        ExportToCSVFile(csvRows, "branch-data.csv")
    }

    getInstitutions() {
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                'Cache-Control': 'public, max-age=0'
            },
        }
        fetch(ApiPath + 'api/institution/fetch-institutions?limit=10000', options)
            ?.then(response => {
                return response.json();
            })
            .then(response => {
                if (response.ok) {
                    this.setState({ institutions: response.body.data.institutions.sort(DynamicSort("name")) }, () => {
                        if (sessionStorage.getItem("is_nibss_user")?.indexOf("NIBSS") === -1) {
                            //Not a NIBSS User...
                            this.setState({
                                institutions: [{ "slug": sessionStorage.getItem("institution_slug"), "name": sessionStorage.getItem("institution") }],
                            })
                        }
                    });
                }
            })
            .catch(error => {
                this.props.setIsLoaderVisible(false);
                this.props.ShowToast(error.message, "exclamation");
            })
    }

    componentDidMount() {
        this.getInstitutions()
        this.getBranches()

    }

    filterRecords = () => {
        this.getBranches();
    }

    render() {
        /**
         * This ensures that the table
         * refreshes
         * after each time
         * a record
         * is created
         * edited
         * or deleted
         */
        if (this.state.createSuccessful) {
            this.getBranches();
            this.setState({
                createSuccessful: false,
            })
        }

        if (this.state.refreshForm) {
            this.getBranches();
            this.setState({
                refreshForm: false,
            })
        }

        let counter = 0;
        const TableRows = this.state.fetchSuccessful && this.state.apiData.map(item => {
            counter++
            //This next item ensures that a proper counter is established
            const itemCounter = (this.state.currentSelectedPage - 1) * this.state.perPage + counter

            return (
                <BranchManagementTableRows
                    key={item.slug}
                    apiData={item}
                    counter={itemCounter}
                    EditBranch={this.EditBranch}
                    ShowToast={this.props.ShowToast}
                    setEditData={value => this.setState({ rowData: value })}
                    setEditClicked={value => this.setState({ editClicked: value })}
                    setUpdateSuccessful={update => {
                        if (update) {
                            this.getBranches()
                        }
                    }}
                    setIsLoaderVisible={this.props.setIsLoaderVisible}

                />
            )
        })

        //Arrays for searching...
        const branchNames = [];
        const LGAs = [];
        const statesArray = [];
        const branchCodes = [];
        const contactEmails = [];
        const status = [{ "item_name": "Active" }, { "item_name": "Inactive" }];

        const institutionNames = this.state.institutions.map(item => {
            return ({ "item_name": item.name })
        })

        this.state.apiData.forEach(item => {
            branchNames.push({ "item_name": item.branchName })
            LGAs.push({ "item_name": item.lga })
            statesArray.push({ "item_name": item.state })
            branchCodes.push({ "item_name": item.branchCode })
            contactEmails.push({ "item_name": item.contactEmail })
        })

        return (
            <>
                <div className="title">
                    <h3>Branches</h3>
                    <SearchComponent
                        setSearchField={val => this.setState({ searchField: val })}
                        setSearchValue={val => this.setState({ searchValue: val })}
                        ReferenceSearchList={BranchSearchList}
                        filterRecords={this.filterRecords}
                        ShowToast={this.props.ShowToast}
                        inputList={{ branchNames, LGAs, statesArray, institutionNames, branchCodes, status, contactEmails }}

                    />

                    <div className="buttons">
                        <button type="button" className="btn-grey t-tip mr-1" data-title="Create new branch"
                            onClick={this.CreateBranch}>
                            <i className="icofont-plus"></i> Create Branch
                        </button>
                        <button id="btnExport" type="button" className="btn-green t-tip mr-1" data-title="Export to MsExcel file"
                            onClick={this.handleExportBranches}>
                            <i className="icofont-airplane-alt"></i> Export
                        </button>
                        <button type="button" className="btn-grey t-tip mr-1 d-none" data-title="Filter records">
                            <i className="icofont-filter"></i>
                        </button>
                        <button type="button" className="btn-grey t-tip mr-1"
                            data-title="Download schema for Bulk Create Branches" onClick={() => this.downLoadSchema()}>
                            <i className="icofont-download"></i>
                        </button>
                    </div>
                </div>

                <div className="table-container">
                    <table className="bordered-table">
                        <caption></caption>
                        <thead>
                            <tr>
                                <th scope="col" className="align-center">#</th>
                                <th scope="col" className="align-left">Institution</th>
                                <th scope="col" className="align-left">Branch Name</th>
                                <th scope="col" className="align-left">Branch Code</th>
                                <th scope="col" className="align-left">State</th>
                                <th scope="col" className="align-left">Local Government</th>
                                <th scope="col" className="align-center">Number of Users</th>
                                <th scope="col" className="align-center d-none" width="100">Status</th>
                                <th scope="col" className="align-center">Action</th>
                            </tr>
                        </thead>
                        <tbody id="tbl_branches">
                            {TableRows}
                        </tbody>
                    </table>
                </div>

                <Pagination
                    setPerPage={value => this.setState({ perPage: value })}
                    setRefreshForm={value => this.setState({ refreshForm: value })}
                    perPage={this.state.perPage}
                    lastPage={this.state.lastPage}
                    arrayPages={this.state.arrayPages}
                    currentSelectedPage={this.state.currentSelectedPage}
                    setCurrentSelectedPage={value => this.setState({ currentSelectedPage: value })}
                />

                <CreateBranchDialog
                    ShowToast={this.props.ShowToast}
                    setIsLoaderVisible={this.props.setIsLoaderVisible}
                    institutionsArray={this.state.institutions}
                    setUpdateSuccessful={result => {
                        if (result) {
                            this.getBranches()
                        }
                    }}
                />

                {this.state.editClicked && <EditBranchDialog
                    setIsLoaderVisible={this.props.setIsLoaderVisible}
                    ShowToast={this.props.ShowToast}
                    institutionsArray={this.state.institutions}
                    setIsEditSuccessful={editState => this.setState({ createSuccessful: editState })}
                    rowData={this.state.rowData}
                    editClicked={this.state.editClicked}
                    setEditClicked={value => this.setState({ editClicked: value })}
                />}

            </>
        )
    }
}

export default BranchManagementForm;
