import React from 'react'
import CreateReferenceDialog from './create-reference-dialog'
import ApiPath, { IcadApiKey } from '../../../admin/assets/App/api-path'
import DynamicSort from '../../../admin/assets/App/DynamicSort'
import { Enums } from "../../../_common/enums";
import FileInput from '../../../components/file-input'

export default class CreateReferenceForm extends React.Component {
    constructor() {
        super()
        this.state = {
            displayDialogClicked: false,
            data: [],
            txtAccountNumber: "",
            cbxAccountType: "",
            txtAccountName: "",
            cbxCustomerBranch: sessionStorage.getItem("customer_branch_slug") || "",
            cbxRefereeInstitution: "",
            cbxRefereeInstitutionName: "",
            cbxRefereeInstitutionSlug: "",
            cbxRefereeBranchName: "Central",
            cbxRefereeBranchSlug: "",
            txtRefereeAccountNumber: "",
            txtRefereeAccountName: "",
            txtRefereeAccountType: "",
            documentName: "",
            referenceObject: "",
            firstPart: "",
            mandateExtension: "",
            fetchingAccountDetails: false,
            institutionsArray: [],
            branchesArray: [],
            clearData: false,
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleKeyUp = this.handleKeyUp.bind(this)

        this.applicationJson = 'application/json';
        this.headers = {
            'Content-Type': this.applicationJson,
            'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
            'Cache-Control': 'public, max-age=0'
        }
    }

    clearAllData() {
        //This more like re-initializes the form,
        //Making it ready for new
        //Inputs after each update..
        this.setState({
            displayDialogClicked: false,
            data: [],
            txtAccountNumber: "",
            cbxAccountType: "",
            txtAccountName: "",
            cbxCustomerBranch: "",
            cbxRefereeInstitution: "",
            cbxRefereeInstitutionName: "",
            cbxRefereeInstitutionSlug: "",
            cbxRefereeBranchName: "Central",
            cbxRefereeBranchSlug: "Central",
            txtRefereeAccountNumber: "",
            txtRefereeAccountName: "",
            txtRefereeAccountType: "",
            documentName: "",
            fetchingAccountDetails: false,
            mandateExtension: "",
            institutionsArray: [],
            branchesArray: [],
            clearData: false,
        })
    }

    fetchInstitutions = () => {
        const options = {
            method: 'GET',
            headers: this.headers,
        }

        fetch(ApiPath + 'api/institution/fetch-institutions-for-service?service=1', options)
            ?.then(response => {
                return response.json();
            })
            .then(response => {
                if (response && response.ok) {
                    this.setState({
                        institutionsArray: response.body.data?.sort(DynamicSort("name")),
                    })
                }
            })
    }

    fetchBranches = () => {
        //Fetch the branches data successfully
        //And sets to the branches state variable...
        const options = {
            method: 'GET',
            headers: this.headers
        }

        fetch(`${ApiPath}api/institution/fetch-institution-branches/${this.state.cbxRefereeInstitution}`, options)
            ?.then(ret => {
                return ret.json();
            })
            .then(res => {
                if (res && res.ok) {
                    this.setState({
                        branchesArray: res.body.data.branches,
                    })
                }
            })
    }

    componentDidMount() {
        this.fetchInstitutions();
        this.fetchBranches();
    }

    fetchAccountDetails = () => {
        /**
         * As the name implies
         * get the account details
         * based on the supplied data...
         */
        if (this.state.txtRefereeAccountNumber === "" || this.state.txtRefereeAccountNumber.length < 10) {
            this.props.ShowToast("Enter a valid 10-digit referee's account number before proceeding", "exclamation");
            return;
        } else if (this.state.cbxRefereeInstitutionName === "") {
            this.props.ShowToast("Select a valid referee institution to proceed.", "exclamation");
            return;
        }

        const accountNumber = this.state.txtRefereeAccountNumber;
        const bankCode = this.state.institutionsArray.find(item => item.name === this.state.cbxRefereeInstitutionName)?.code;

        const body = {
            accountNo: accountNumber,
            institutionCode: bankCode
        }

        //The options, after the validation is complete..
        const options = {
            method: "POST",
            headers: {
                'Content-Type': this.applicationJson,
                'Accept': this.applicationJson,
                'apiKey': IcadApiKey,
                'Cache-Control': 'public, max-age=0'
            },
            body: JSON.stringify(body)
        }

        //The actual fetch...
        /**
         * Fetching the account details here
         * includes setting the proposed variables, including
         * REACT_APP_ICAD_API_PATH and
         * REACT_APP_API_KEY
         * With the above set, together with the
         * REACT_APP_API_PATH in the .env file,
         * we're basically good to go!
         * Kindly take note (@DevOps)
         */
        const ICADEndpoint = `${ApiPath}api/misc/get-account-name`;

        this.setState({ fetchingAccountDetails: true });
        fetch(`${ICADEndpoint}`, options)
            ?.then(response => {
                this.setState({ fetchingAccountDetails: false })
                return response.json()
            })
            .then(response => {
                if (response) {
                    this.setState({
                        txtRefereeAccountName: `${response.body.accountName}`
                    })
                } else {
                    this.props.ShowToast('Error fetching account details. Please try again after some time.')
                }
            })
            .catch(error => {
                // This is about the only
                // Place and manner error can be captured in this
                // sub-routine... (And the response above on line 175).
                // For further error messages, we need to at least,
                // get a sample response of the endpoint...
                this.setState({ fetchingAccountDetails: false })
                this.props.ShowToast(error.message, "exclamation");
            })

    }

    getInstitutionName = slugValue => {
        // filter the record to
        // provide the necessary
        const retValue = this.state.institutionsArray.find(inst => inst.slug === slugValue);
        return retValue?.name;
    }

    getBranchName = slugValue => {
        // filter the record to
        // provide the necessary
        const retValue = this.state.branchesArray.find(item => item.slug === slugValue);
        return retValue?.branchName || "Central";
    }

    handleChange = e => {
        /**
         * set the state
         * of each parameter
         * according to the
         * value
         * on input.change
         * run...
         */
        if (this.state.displayDialogClicked) {
            return
        }

        this.setState({ [e?.target.name]: e?.target.value }, () => {
            if (e?.target.id === "cbxRefereeInstitution") {
                //Filter the branches in the list to only represent the selected institution...
                this.fetchBranches();
                this.setState({
                    cbxRefereeInstitutionName: this.getInstitutionName(this.state.cbxRefereeInstitution),
                })
            }
            if (e?.target.id === "cbxRefereeBranchSlug") {
                this.setState({
                    cbxRefereeBranchName: this.getBranchName(this.state.cbxRefereeBranchSlug),
                })
            }
        });
    }

    handleBlur(e) {
        /**
         * As the name imples
         * each time
         * the control loses
         * focus, we check
         * to see if the
         * expected conditions
         * are met
         * if they are met, they
         */
        e?.target?.classList.remove(Enums.errorBorder)
    }

    handleKeyUp(e) {
        /**
        * As the name implies
        * each time
        * the use types a
        * letter,
        * it is capitalized
        * and rendered in the
        * screen.
        */
        this.setState({
            txtAccountName: e?.target.value.toUpperCase()
        })
    }

    handleDisplayDialog = () => {
        try {
            const container = document.querySelector(".cont-ainer");
            const inputs = container.querySelectorAll(".form-control");
            let issuesCount = 0;

            inputs.forEach(element => {
                if (element.value === "" && (element.id !== "cbxCustomerBranch" || element.id !== "cbxRefereeBranchSlug")) {
                    //Exlcude the customer branch from the list...
                    element.classList.add(Enums.errorBorder);
                    issuesCount++;
                }
            })

            if (this.state.referenceObject === "") {
                this.props.ShowToast("You need to attach a document to proceed.", "exclamation")
                return;
            }

            if (issuesCount > 0) {
                this.props.ShowToast("Complete the missing fields as displayed", "exclamation");
                return
            }

            //Also validate some specific inputs
            if (isNaN(this.state.txtAccountNumber) || this.state.txtAccountNumber.length < 10) {
                this.props.ShowToast("Customer Account Number must be numeric digits (10-digits)!", "exclamation");
                return;
            }
            //Referee Account Number
            if (isNaN(this.state.txtRefereeAccountNumber) || this.state.txtRefereeAccountNumber.length < 10) {
                this.props.ShowToast("Referee Account Number must be numeric digits (10-digits)!", "exclamation");
                return;
            }

            this.setState({

                displayDialogClicked: true,
                data: {
                    "txtAccountNumber": this.state.txtAccountNumber,
                    "cbxAccountType": this.state.cbxAccountType,
                    "txtAccountName": this.state.txtAccountName,
                    "cbxCustomerBranch": this.state.cbxCustomerBranch,
                    "cbxRefereeInstitution": this.state.cbxRefereeInstitution,
                    "cbxRefereeInstitutionName": this.state.cbxRefereeInstitutionName,
                    "cbxRefereeBranchName": this.state.cbxRefereeBranchName,
                    "cbxRefereeBranchSlug": this.state.cbxRefereeBranchSlug,
                    "txtRefereeAccountNumber": this.state.txtRefereeAccountNumber,
                    "txtRefereeAccountName": this.state.txtRefereeAccountName,
                    "txtRefereeAccountType": this.state.txtRefereeAccountType,
                    "referenceObject": this.state.referenceObject,
                    "documentName": this.state.documentName,
                    "channel": "Other",
                    "customerMandate": "Reference Form",
                    "mandateExtension": this.state.mandateExtension,
                }
            })

        } catch (error) {
            this.props.ShowToast(error, "exclamation");
        }
    }

    render() {
        if (this.state.clearData) {
            this.clearAllData()
        }
        const spinnerClass = (this.state.fetchingAccountDetails) ? "bx bx-loader" : "bx bx-loader d-none"
        const SetInstitutions = this.state.institutionsArray && this.state.institutionsArray.map(item => {
            return (
                <option key={item.slug} value={item.slug}>{item.name}</option>
            )
        })
        const SetBranches = this.state.branchesArray && this.state.branchesArray.map(item => {
            return (
                <option key={item.slug} value={item.slug}>{item.branchName}</option>
            )
        })

        return (<>
            <div className="form-row-4 mb-2">
                <div className="input-group">
                    <label><span>*</span>Customer Account Number</label>
                    <input
                        type="text"
                        id="txtAccountNumber" name="txtAccountNumber"
                        value={this.state.txtAccountNumber}
                        className="form-control"
                        autoComplete="off"
                        maxLength="10"
                        onChange={this.handleChange}
                        placeholder="XXXXXXXXXX"
                        onBlur={this.handleBlur}
                        required />
                    <label className="control-info"><i className="icofont-warning-alt"></i> Enter 10-digits NUBAN Number</label>
                </div>
                <div className="input-group">
                    <label><span>*</span>Customer Account Type</label>
                    <select
                        className="form-control"
                        id="cbxAccountType" name="cbxAccountType"
                        value={this.state.cbxAccountType}
                        onBlur={this.handleBlur}
                        onChange={this.handleChange}>
                        <option value=""></option>
                        <option value="Individual">Individual</option>
                        <option value="Corporate">Corporate</option>
                    </select>
                    <label className="control-info"><i className="icofont-warning-alt"></i> Select Type of Account</label>
                </div>
                <div className="input-group">
                    <label><span>*</span>Account Name</label>
                    <input
                        type="text"
                        id="txtAccountName" name="txtAccountName"
                        value={this.state.txtAccountName}
                        className="form-control"
                        onChange={this.handleChange}
                        onKeyUp={this.handleKeyUp}
                        autoComplete="off"
                        onBlur={this.handleBlur} />
                    <label className="control-info"><i className="icofont-warning-alt"></i> Name on account</label>
                </div>
                <div className={sessionStorage.getItem("session_user_type")?.toLowerCase() !== "admin" ? "d-none" : "input-group"}>
                    <label><span>*</span>Customer Branch</label>
                    <select
                        className="form-contrl"
                        id="cbxCustomerBranch" name="cbxCustomerBranch"
                        value={this.state.cbxCustomerBranch}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}>
                        <option value=""></option>
                        {SetBranches}
                    </select>
                    <label className="control-info"><i className="icofont-warning-alt"></i> Customer's Branch</label>
                </div>
            </div>
            <div className="form-row-2-1-1 mb-2">
                <div className="input-group">
                    <label><span>*</span>Referee's Institution</label>
                    <select
                        className="form-control" id="cbxRefereeInstitution"
                        name="cbxRefereeInstitution" value={this.state.cbxRefereeInstitution}
                        onChange={this.handleChange} onBlur={this.handleBlur}>
                        <option value=""></option>
                        {SetInstitutions}
                    </select>
                    <label className="control-info"><i className="icofont-warning-alt"></i> Select Institution</label>
                </div>
                <div className="input-group">
                    <label>Referee's Branch</label>
                    <select
                        className="form-control" id="cbxRefereeBranchSlug"
                        name="cbxRefereeBranchSlug" value={this.state.cbxRefereeBranchSlug}
                        onChange={this.handleChange} onBlur={this.handleBlur}>
                        <option value="Central">Central</option>
                        {SetBranches}
                    </select>
                    <label className="control-info"><i className="icofont-warning-alt"></i> Branch of Referee (optional)</label>
                </div>
            </div>
            <div className="form-row-4 mb-2">
                <div className="input-group">
                    <label><span>*</span>Referee's Account Number</label>
                    <input
                        type="text" id="txtRefereeAccountNumber"
                        name="txtRefereeAccountNumber" value={this.state.txtRefereeAccountNumber}
                        onChange={this.handleChange} className="form-control"
                        maxLength="10" placeholder="XXXXXXXXXX"
                        onBlur={this.handleBlur} required />
                    <label className="control-info"><i className="icofont-warning-alt"></i> Enter 10-digits NUBAN Number</label>
                </div>
                <div className="input-group">
                    <button
                        className="btn-green mt-2"
                        title="Get Account Details"
                        id="btnGetRefAccountDetails"
                        onClick={this.fetchAccountDetails}>
                        <i className="icofont-info-circle"></i> Get Account Name
                    </button>
                    <button
                        className="d-none"
                        id="testButton"
                        onClick={() => {
                            this.fetchInstitutions()
                            this.fetchAccountDetails()
                        }}
                        onChange={e => {
                            this.handleChange(e)
                            this.handleBlur(e)
                            this.handleKeyUp(e)
                        }}
                    ></button>
                </div>
                <div className="Input-group"><i className={spinnerClass} id="spinner"></i></div>
            </div>
            <div className="form-row-1-1-2 mb-2">
                <div className="input-group">
                    <label><span>*</span>Referee's Account Name</label>
                    <input
                        type="text"
                        id="txtRefereeAccountName" name="txtRefereeAccountName"
                        value={this.state.txtRefereeAccountName}
                        onChange={this.handleChange}
                        className="form-control"
                        onBlur={this.handleBlur}
                        autoComplete="off"
                        onKeyUp={e => this.setState({ txtRefereeAccountName: e.target.value.toUpperCase() })}
                        required />
                    <label className="control-info"><i className="icofont-warning-alt"></i> Name on Referee's Account</label>
                </div>
                <div className="input-group">
                    <label><span>*</span>Referee Account Type</label>
                    <select
                        className="form-control"
                        id="txtRefereeAccountType" name="txtRefereeAccountType"
                        value={this.state.txtRefereeAccountType} onBlur={this.handleBlur}
                        onChange={this.handleChange}>
                        <option value=""></option>
                        <option value="Individual">Individual</option>
                        <option value="Corporate">Corporate</option>
                    </select>
                    <label className="control-info"><i className="icofont-warning-alt"></i> Select Type of Account</label>
                </div>
                <div className="input-group">
                    <label><span>*</span>Attach Referee Form</label>
                    <FileInput
                        setFileOutput={value => this.setState({ referenceObject: value })}
                        setFirstPart={value => this.setState({ firstPart: value }, () => this.setState({
                            mandateExtension: this.state.firstPart.indexOf("pdf") > -1 ? "pdf" : "image"
                        }))}
                        setDocumentName={value => this.setState({ documentName: value })}
                        showToast={this.props.ShowToast}
                        acceptFileTypes={".pdf, .jpg, .jpeg, .png"}
                        maxSize={500153}
                    />
                    <label className="control-info"><i className="icofont-warning-alt"></i> Choose pdf or image file (.pdf | .jpg | .jpeg | .png) not more than 500kb</label>
                </div>
            </div>
            <div className="form-row-2 mb-2">

            </div>
            <hr className="mb-3" />
            <div className="form-row-3 mb-2">
                <div className="input-group"></div>
                <div className="input-group">
                    <button
                        className="btn-green t-tip"
                        id="btnCreateReference"
                        data-title="Click to update the reference details"
                        onClick={this.handleDisplayDialog}>
                        <i className="icofont-plus"></i> Create Reference
                    </button>
                </div>
            </div>
            {this.state.displayDialogClicked &&
                <CreateReferenceDialog
                    data={this.state.data}
                    ShowToast={this.props.ShowToast}
                    setIsLoaderVisible={this.props.setIsLoaderVisible}
                    displayDialogClicked={this.state.displayDialogClicked}
                    setDisplayDialog={value => this.setState({ displayDialogClicked: value })}
                    setClearData={value => this.setState({ clearData: value })}

                />}
        </>)
    }
}

