import React from 'react'
import Badges from '../../admin/assets/App/Badges'
import CapitalizeFirstLetter from '../../admin/assets/App/CapitalizeFirstLetter'
import { SplitDate } from '../../admin/assets/App/split-date'
import { Enums } from '../../_common/enums'
import ApiPath from '../../admin/assets/App/api-path'
import { MessageBoxType } from '../../_common/constants'

export default class ViewEClipsDetailsDialog extends React.Component {

    constructor() {
        super()
        this.state = {
            imageSource: "",
            detailsData: [],
            referenceComments: [],
            referenceCreator: [],
            referenceLog: [],
            uploadedFile: {},
            eclipDetailsData: [],
            eclipAttachments: [],
            myInstitutionSlug: sessionStorage.getItem('institution_slug')
        }

        this.token = sessionStorage.getItem('session_token');

    }

    downloadAttachment = () => {
        /**
         * The subroutine for
         * handling the download
         * of any attachment to
         * the page...
         *
         * Continue this when
         * the endpoints are ready...
         */
        //Enable later...
        // const checkURL = (this.props.mandateExtension !== "pdf")
        //     ? `data:image/${this.props.mandateExtension};base64,${this.state.uploadedFile}`
        //     : `data:application/pdf;base64,${this.state.uploadedFile}`;


        // // const downloadLink = document.createElement("a")
        // // const fileName = `download.${this.props.mandateExtension}`

        // // downloadLink.href = checkURL
        // // downloadLink.download = fileName

        // //Finally, download by simulating
        // //the click event...
        // downloadLink.click()


    }

    getEClipDetails = () => {
        /**
         * Fetch the deatils of the
         * e-Clip in question
         * to preview other things...
         */

        const slug = this.props.data?.slug;
        const options = {
            method: 'GET',
            headers: Enums.headersData(this.token),
        }

        fetch(`${ApiPath}api/eclip/eclip-details/${slug}`, options)
            ?.then(response => {
                return response.json();
            })
            .then(response => {
                if (response) {
                    if (response.ok) {
                        this.setState({
                            eclipDetailsData: response.body.data.eClipDetail,
                            eclipAttachments: response.body.data.attachments,
                        })
                    } else {
                        this.props.ShowToast(response.message, MessageBoxType.EXCLAMATION)
                    }
                }
            })
    }

    componentDidMount() {
        this.getEClipDetails();
    }

    handleClose = () => {
        this.props.hideDisplayView();
    }

    render() {
        const dialogClass = (this.props.displayView) ? "modal fade show" : "modal fade"
        const publicURLLink = '/e-clips/0/public?xyz=' + this.props.data?.publicUrl;

        return (
            <>
                <div className={dialogClass}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h2 className="create" style={{ fontSize: 14 }}><i className="icofont-list"></i> e-Clips Details</h2>
                            </div>
                            <div className="modal-body">
                                <table className="dialog-table mb-2"><caption></caption>
                                    <thead>
                                        <tr>
                                            <th scope="col" className="align-left">Fields</th>
                                            <th scope="col" className="align-left">Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Request ID</td>
                                            <td>{this.props.data?.requestId}</td>
                                        </tr>
                                        <tr>
                                            <td>Type</td>
                                            <td>{this.props.data?.referenceType}</td>
                                        </tr>
                                        <tr>
                                            <td>Requesting Institution</td>
                                            <td>{this.props.data?.issuingInstitution?.name}</td>
                                        </tr>
                                        <tr>
                                            <td>Acquiring Institution</td>
                                            <td>{this.props.data?.acquiringInstitution?.name}</td>
                                        </tr>
                                        <tr>
                                            <td>Created by</td>
                                            <td>{this.props.data?.createdByUser?.email}</td>
                                        </tr>
                                        <tr>
                                            <td>Created (Date & Time)</td>
                                            <td>{SplitDate(this.props.data?.createdAt).dateTime}</td>
                                        </tr>
                                        <tr>
                                            <td>Dispute Reference</td>
                                            <td>{this.props.data?.reference}</td>
                                        </tr>
                                        <tr>
                                            <td>Last Modified</td>
                                            <td>{SplitDate(this.props.data?.updatedAt).dateTime}</td>
                                        </tr>
                                        <tr>
                                            <td>Overdue?</td>
                                            <td>{CapitalizeFirstLetter(this.props.data?.isOverDue)}</td>
                                        </tr>
                                        <tr>
                                            <td>Status</td>
                                            <td>
                                                <span style={{ width: 180 }} className={Badges(this.props.data?.status)}>
                                                    {CapitalizeFirstLetter(this.props.data?.status)}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Attachments</td>
                                            <td>
                                                <button
                                                    type="button"
                                                    id="btnPreview"
                                                    // onClick={previewAttachment}
                                                    className="btn-grey mr-1">
                                                    <i className="bx bx-slideshow"></i> Preview
                                                </button>
                                                <button
                                                    type="button"
                                                    id="btnAttachment"
                                                    onClick={this.downloadAttachment}
                                                    className="btn-grey">
                                                    <i className="icofont-download"></i> Download
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Item History</td>
                                            <td>{this.props.data?.eclipLog?.acceptedByUser?.email}</td>
                                        </tr>
                                        <tr>
                                            <td>Public URL</td>
                                            <td>{this.state.myInstitutionSlug === this.props.data?.acquiringInstitution?.slug ? <a
                                                href={publicURLLink}
                                                target="_blank"
                                                rel='noreferrer'
                                                style={{ color: 'steelblue' }}
                                            >{this.props.data?.publicUrl}</a> : '-'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>No. of Views using public URL</td>
                                            <td>{this.props.data?.views}</td>    {/* Actual Number of views later */}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="modal-footer">
                                <hr />
                                <button
                                    type="button"
                                    className="btn-grey mr-2"
                                    onClick={this.handleClose}
                                    id="btnCancel">
                                    <i className="icofont-exit"></i> Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="mb-3" />

            </>
        )
    }
}
