import React from "react";
import EditScheduledBillingDialog from "./edit-scheduled-billing-dialog";
import GetServices from "../../../admin/assets/App/GetServices"
import { SplitDate } from "../../../admin/assets/App/split-date"
import DayOfWeek from "../../../admin/assets/App/DayOfWeek";
import ApiPath from "../../../admin/assets/App/api-path";
import CapitalizeFirstLetter from "../../../admin/assets/App/CapitalizeFirstLetter";

export default class ScheduledBillingTableRow extends React.Component {
    constructor() {
        super()
        this.state = {
            displayEditDialog: false,
        }
    }

    EditItem = () => {
        //Opens the edit dialog...
        this.setState({
            displayEditDialog: true,
        })
    }

    DeleteItem = () => {
        //Always display the confirm to verify deletion
        const confirmDelete = window.confirm("Are you sure you wish to delete the selected scheduled billing record?");

        if (confirmDelete) {
            //Go ahead and delete....
            const deleteSlug = { slug: this.props.data.slug }

            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                    'Cache-Control': 'public, max-age=0'
                },
                body: JSON.stringify(deleteSlug),
            }

            fetch(`${ApiPath}api/scheduled-billing/remove-scheduled-billing`, options)    //Change the endpoint when ready...
                ?.then(response => {
                    this.props.setIsLoaderVisible(false)
                    return response.json();
                })
                .then(response => {
                    this.props.setIsLoaderVisible(false)

                    if (response) {
                        if (response.ok === true) {
                            this.props.ShowToast("Scheduled Billing Record deleted successfully!", "success")
                            this.props.setActionSuccessful(true)
                        } else {
                            this.props.ShowToast(response.message, "exclamation")
                        }
                    }
                })
                .catch(error => {
                    this.props.ShowToast(error.message, "exclamation")
                })

        }
    }

    CheckEmails = () => {

        let emails = this.props.data?.emailAddresses || "{emails: [\"salami@gmail.com\", \"mekus@hotmail.com\"]}";     //Just for unit testing...
        if (emails?.indexOf(":") > -1) {
            //Some unnecessary details are there... trim...
            //Due to Kola's unexpected data return...
            //WE can delete this later, after his fix...
            const subArray = emails.split(":");
            const final = subArray[1].split("}");
            emails = final[0];
        }

        return JSON.parse(emails).join(", ");

    }

    ShowEmails = () => {
        //Displays a dialog showing emails
        //List...
        this.props.ShowToast(this.CheckEmails(), "information")
    }

    render() {

        const updater = (this.props.data.updatedByUser) ? this.props.data.updatedByUser.email : "-";

        return (
            <>
                <tr>
                    <td className="align-center">{this.props.counter}.</td>
                    <td>{GetServices(this.props.data?.billingService?.name)}</td>
                    <td>{CapitalizeFirstLetter(this.props.data.billingType)}</td>
                    <td>{this.props.data.runDate ? DayOfWeek(this.props.data.runDate) : "-"}</td>
                    <td>{SplitDate(this.props.data.createdAt).date}</td>
                    <td>{SplitDate(this.props.data.updatedAt).date}</td>
                    <td>{`${this.props.data.createdByUser?.firstName} ${this.props.data.createdByUser?.lastName}`}</td>
                    <td>{updater}</td>
                    <td>
                        <button
                            className="btn-grey"
                            onClick={this.ShowEmails}
                        ><i className="icofont-email"></i> Emails</button>
                    </td>
                    <td>
                        <button
                            id="btnEdit"
                            className="btn-grey mr-1"
                            onClick={this.EditItem}
                        >
                            <i className="icofont-edit-alt"></i> Edit
                        </button>
                        <button
                            id="btnDelete"
                            className="btn-danger"
                            onClick={this.DeleteItem}
                        >
                            <i className="icofont-ui-delete"></i> Delete
                        </button>
                    </td>
                </tr>

                {this.state.displayEditDialog && <EditScheduledBillingDialog
                    data={this.props.data}
                    billingServices={this.props.billingServices}
                    setDisplayEditDialog={value => this.setState({ displayEditDialog: value })}
                    displayEditDialog={this.state.displayEditDialog}
                    ShowToast={this.props.ShowToast}
                    setIsLoaderVisible={this.props.setIsLoaderVisible}
                    setEditSuccessful={this.props.setActionSuccessful}

                />}

            </>
        )
    }
}
