import { Audits } from './audits'
import Summaries from './summaries'

const MainSummaries = (props) => {
    return (
        <div className="main-summaries">
            <Summaries
                ShowToast={props.ShowToast}
                setIsLoaderVisible={props.setIsLoaderVisible}
            />
             <Audits />
        </div>
    )
}

export default MainSummaries
