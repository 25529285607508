import React from "react"
import CreateBillingsDialog from "./create-billings-dialog";
import ApiPath from "../../../admin/assets/App/api-path";
import Pagination from "../../../admin/assets/App/Pagination";
import BillingsFormTableRow from "./billings-form-table-row";
import BillingsSearchList from "../../../admin/assets/data/BillingsSearchList";
import SearchComponent from "../../../admin/assets/App/SearchComponent";
import ProductPicker from "../../../admin/assets/App/ProductPicker";


export default class BillingsForm extends React.Component {

    constructor() {
        super()
        this.state = {
            //main items...
            actionSuccessful: false,
            apiData: [],
            metaData: [],
            viewData: [],
            editData: [],
            displayDatePicker: false,
            displayProductPicker: false,
            cbxDateFrom: "",
            cbxProduct: "",

            //Search items
            searchField: "Select Item from List",
            searchValue: "",

            roleServices: [],
            fetchSuccessful: false,
            displayCreateDialog: false,
            displayEdit: false,

            //Pagination items
            currentSelectedPage: 1,
            perPage: localStorage.getItem("items_per_page") || 20,
            arrayPages: [],
            lastPage: 1,
            totalItems: 0,
        }
    }

    componentDidMount() {
        this.fetchBillingsData();
        this.fetchService()
    }

    fetchBillingsData = () => {
        /**
         * Get the data to be displayed on the form
         */
        this.props.setIsLoaderVisible(true);

        const Options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                'Cache-Control': 'public, max-age=0'
            },
        }

        const searchValue = (this.state.searchField === "endDate")
            ? this.state.searchValue
            : this.state.roleServices.find(item => this.state.searchValue === item.name)?.slug || "-";

        if (this.state.searchField === "service" && this.state.searchValue === "") {
            this.setState({
                searchField: "SomeOtherValue",
                searchValue: "",
            })
        }

        fetch(`${ApiPath}api/billing/get-billings?page=${this.state.currentSelectedPage}` +
            `&limit=${this.state.perPage}&${this.state.searchField}=${searchValue}`, Options)
            ?.then(response => {
                this.props.setIsLoaderVisible(false)
                return response.json();
            })
            ?.then(response => {
                this.props.setIsLoaderVisible(false);

                if (response && response.ok) {
                    this.setState({
                        apiData: response.body.billings.data,
                        metaData: response.body.billings.meta,
                        fetchSuccessful: true,
                        arrayPages: [],
                    }, () => {
                        const pagesArray = []
                        this.setState({
                            currentSelectedPage: this.state.metaData?.current_page,
                            lastPage: this.state.metaData?.last_page,
                            totalItems: this.state.metaData?.total,
                        }, () => {
                            //Set this array for the looping
                            for (let i = 1; i <= this.state.lastPage; i++) {
                                pagesArray.push({ "id": i, "name": "Item " + i });
                            }

                            this.setState({ arrayPages: pagesArray })
                        })
                    })
                } else {
                    this.props.ShowToast(response?.message, "exclamation");
                }
            })
            .catch(error => {
                this.props.setIsLoaderVisible(false);
                this.props.ShowToast(error.message, "exclamation");
            })
    }

    fetchService = () => {
        const Options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                'Cache-Control': 'public, max-age=0'
            },
        }
        fetch(ApiPath + 'api/institution-type-service/fetch-institution-type-service', Options)
            ?.then(response => {
                return response.json();
            })
            ?.then(response => {
                if (response && response.ok) {
                    this.setState({
                        roleServices: response.body.services,
                    })
                }
            })
    }

    RefreshForm = () => {
        window.location.reload();
    }

    filterRecords = () => {
        this.fetchBillingsData();
    }

    render() {

        let counter = 0;
        const TableRows = this.state.fetchSuccessful && this.state.apiData.map(item => {
            counter++;
            const itemCounter = (this.state.currentSelectedPage - 1) * this.state.perPage + counter

            return (<BillingsFormTableRow
                key={itemCounter}
                roleServices={this.state.roleServices}
                data={item}
                counter={itemCounter}
                ShowToast={this.props.ShowToast}
                setIsLoaderVisible={this.props.setIsLoaderVisible}
                setActionSuccessful={value => this.setState({ actionSuccessful: value })}
            />)
        })

        if (this.state.actionSuccessful) {
            this.RefreshForm();
            this.setState({
                actionSuccessful: false,
            })
        }

        //Get the services..
        const services = this.state.roleServices.map(item => {
            return (
                { "item_name": item.name }
            )
        })

        return (
            <>
                <div className="title">
                    <h3>Billings Summary</h3>
                    <SearchComponent
                        setSearchField={val => this.setState({ searchField: val })}
                        setSearchValue={val => this.setState({ searchValue: val })}
                        handleChange={this.handleChange}
                        ReferenceSearchList={BillingsSearchList}
                        filterRecords={this.filterRecords}
                        ShowToast={this.props.ShowToast}
                        inputList={{ services }}

                    />

                    <div className="buttons">
                        <button
                            id="btnCreate"
                            type="button"
                            className="btn-grey t-tip mr-1"
                            data-title="Generate Billing"
                            onClick={() => this.setState({ displayCreateDialog: true })}>
                            <i className="bx bxs-receipt"></i> Generate Billing...
                        </button>
                        <button
                            id="btnRefresh"
                            type="button"
                            className="btn-green t-tip mr-1"
                            data-title="Refresh"
                            onClick={this.RefreshForm}>
                            <i className="icofont-refresh"></i> Refresh
                        </button>
                    </div>
                </div>
                <div className="table-container">
                    <table className="bordered-table">
                        <caption></caption>
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col" className="align-left">CREATION DATE</th>
                                <th scope="col" className="align-left">END DATE</th>
                                <th scope="col" className="align-center">DAYS</th>
                                <th scope="col" className="align-left">SERVICE</th>
                                <th scope="col" className="align-center">VOLUME</th>
                                <th scope="col" className="align-center">DOWNLOADS</th>
                                <th scope="col" className="align-center">VIEW MORE</th>
                                <th scope="col" className="align-center">SEND TO EMAIL</th>
                                <th scope="col" className="align-center">ACTION</th>
                            </tr>
                        </thead>
                        <tbody id="tbl_billings">
                            {TableRows}
                        </tbody>
                    </table>
                </div>
                <Pagination
                    currentSelectedPage={this.state.currentSelectedPage}
                    perPage={this.state.perPage}
                    setPerPage={value => this.setState({ perPage: value })}
                    setCurrentSelectedPage={value => this.setState({ currentSelectedPage: value })}
                    setRefreshForm={value => this.setState({ actionSuccessful: value })}
                    lastPage={this.state.lastPage}
                    arrayPages={this.state.arrayPages}
                />

                {this.state.displayCreateDialog && <CreateBillingsDialog
                    displayCreateDialog={this.state.displayCreateDialog}
                    setDisplayCreateDialog={value => this.setState({ displayCreateDialog: value })}
                    ShowToast={this.props.ShowToast}
                    setIsLoaderVisible={this.props.setIsLoaderVisible}
                    setCreateSuccessful={val => this.setState({ actionSuccessful: val })}
                    roleServices={this.state.roleServices}

                />}

                {
                    this.state.displayProductPicker && <ProductPicker
                        displayProductPicker={this.state.displayProductPicker}
                        setDisplayProductPicker={value => this.setState({ displayProductPicker: value })}
                        handleChange={this.handleChange}
                        setOutput={val => this.setState({ searchValue: val })}
                        cbxProduct={this.state.cbxProduct}

                    />
                }



            </>
        )
    }

}

