import toTwoDigits from './hooks/toTwoDigits';
import checkIfWeekend from './hooks/checkIfWeekend';
import { CalendarHeader } from './calendar-header';
import { Day } from "./day"
import { NewEventModal } from './new-event-modal';
import { DeleteEventModal } from './delete-event-modal';
import { Enums } from '../../_common/enums';
import ApiPath from '../../admin/assets/App/api-path';
import React from 'react';

class PublicHolidaysForm extends React.Component {
  constructor() {
    super()
    this.state = {
      nav: 0,
      clicked: null,
      events: [],
      dateDisplay: '',
      days: [],
      refreshData: false,
    }

    this.RefreshForm = this.RefreshForm.bind(this)

  }

  componentDidMount() {
    this.LoadHolidays();
    this.createDates();
  }

  RefreshForm() {
    this.LoadHolidays();
  }

  LoadHolidays = () => {
    const options = {
      method: 'GET',
      headers: Enums.headersData(sessionStorage.getItem("session_token"))
    }

    fetch(`${ApiPath}api/holiday/fetch-holiday`, options)
      ?.then(response => {
        return response.json();
      })
      .then(response => {
        if (response && response.ok) {
          //Returns a results array...
          this.setState({
            events: response.body.holidays,
          }, () => {
            localStorage.setItem("events", JSON.stringify(response.body.holidays))
            this.createDates()
            this.setClickedNull();
          })

        }
      })
  }

  eventForDate = date => this.state.events.find(e => e.holiday_date === date);

  incrementDate = () => {
    this.setState(prevState => {
      return { nav: prevState.nav + 1, refreshData: true }
    }, () => this.createDates())
  }

  decrementDate = () => {
    this.setState(prevState => {
      return { nav: prevState.nav - 1, refreshData: true }
    }, () => this.createDates())
  }

  handleClick = val => {
    if (val?.value !== 'padding') {
      this.setState({
        clicked: val?.date
      })
    }
  }

  setClickedNull = () => {
    this.setState({ clicked: null })
  }

  DeleteItem = (dateValue) => {
    /**
     * Delete/disable
     * the item
     * from the database
     * from the list
     * of public holidays
     */

    const slugValue = this?.eventForDate(dateValue)?.slug;

    this.props.setIsLoaderVisible(true)
    const data = { "slug": slugValue }
    const options = {
      method: 'POST',
      headers: Enums.headersData(sessionStorage.getItem("session_token")),
      body: JSON.stringify(data),
    }

    fetch(ApiPath + 'api/holiday/disable-holiday', options)
      ?.then(response => {
        this.props.setIsLoaderVisible(false);
        return response.json();
      })
      .then(response => {
        this.props.setIsLoaderVisible(false)
        if (response) {
          if (response.ok === true) {
            this.props.ShowToast("Holiday Deleted!", "success")
            this.RefreshForm();
          } else {
            this.props.ShowToast(response.message, "exclamation")
            return;
          }

        } else {
          this.props.ShowToast("Unable to delete. Try again.", "exclamation")
        }
      })
      .catch(err => {
        this.props.setIsLoaderVisible(false)
        this.props.ShowToast(err.message, "exclamation")
      })

  }

  createDates = () => {

    const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dt = new Date();

    if (this.state.nav !== 0) {
      dt.setMonth(new Date().getMonth() + this.state.nav);
    }

    const day = dt.getDate();
    const month = dt.getMonth();
    const year = dt.getFullYear();

    const firstDayOfMonth = new Date(year, month, 1);
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const dateString = firstDayOfMonth.toLocaleDateString('en-us', {
      weekday: 'long',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });

    this.setState({
      dateDisplay: `${dt.toLocaleDateString('en-us', { month: 'long' })} ${year}`
    })

    const paddingDays = weekdays.indexOf(dateString.split(', ')[0]);
    const daysArr = [];

    for (let i = 1; i <= paddingDays + daysInMonth; i++) {
      const dy = toTwoDigits(i - paddingDays);
      const mnt = toTwoDigits(month + 1);

      const dayString = `${year}-${mnt}-${dy}`;
      const dow = new Date(dayString).getDay();

      if (i > paddingDays) {
        daysArr.push({
          value: checkIfWeekend(dow) ? 'padding' : i - paddingDays,
          event: this.eventForDate(dayString),
          isCurrentDay: i - paddingDays === day && this.state.nav === 0,
          date: dayString,
          weekend: checkIfWeekend(dow) ? 'weekend' : '',
        });
      } else {
        daysArr.push({
          value: 'padding',
          event: null,
          isCurrentDay: false,
          date: '',
          weekend: '',
        });
      }
    }

    this.setState({ days: daysArr })

  }

  render() {

    if (this.state.refreshData) {
      this.LoadHolidays();
      this.createDates();

      this.setState({
        refreshData: false,
      })
    }

    return (
      <>
        <div id="container">
          <CalendarHeader
            dateDisplay={this.state.dateDisplay}
            onNext={this.incrementDate}
            onBack={this.decrementDate}
          />
          <div id="weekdays" title="weekdays">
            <div title="Sunday">Sunday</div>
            <div title="Monday">Monday</div>
            <div title="Tuesday">Tuesday</div>
            <div title="Wednesday">Wednesday</div>
            <div title="Thursday">Thursday</div>
            <div title="Friday">Friday</div>
            <div title="Saturday">Saturday</div>
          </div>
          <button id="testButton" className="d-none"
            onClick={() => {
              this.incrementDate()
              this.decrementDate()
              this.handleClick()
              this.setClickedNull()
              this.LoadHolidays()
              this.createDates()
              this.DeleteItem("someStringSlug")
            }}></button>

          <div id="calendar" title="Calendar">
            {this.state.days && this.state.days.map((d, index) => (
              <Day
                key={index}
                day={d}
                onClick={() => this.handleClick(d)}
              />
            ))}
          </div>
        </div>

        {
          this.state.clicked && !this.eventForDate(this.state.clicked) &&
          <NewEventModal
            onClose={() => this.RefreshForm()}
            thisDate={this.state.clicked}
            onSave={() => this.RefreshForm()}
            ShowToast={this.props.ShowToast}
            setIsLoaderVisible={this.props.setIsLoaderVisible}
          />
        }

        {
          this.state.clicked && this.eventForDate(this.state.clicked) &&
          <DeleteEventModal
            event={this.eventForDate(this.state.clicked)}
            onClose={() => this.RefreshForm()}
            onDelete={() => {
              this.DeleteItem(this.state.clicked)
            }}
            ShowToast={this.props.ShowToast}
            setIsLoaderVisible={this.props.setIsLoaderVisible}
          />
        }
      </>
    );
  }

}

export default PublicHolidaysForm
