import React, { Component } from 'react'
import ApiPath from '../../admin/assets/App/api-path';
import Pagination from '../../admin/assets/App/Pagination';
import SearchComponent from '../../admin/assets/App/SearchComponent';
import { loadEDMMSExportTable } from './load-edmms-export-table';
import { OutgoingEDMMSMandateTableRow } from './outgoing-edmms-mandate-table-row';
import RejectOutgoingEDMMSMandate from './reject-outgoing-edmms-mandate';
import { ViewEDMMSMandateDetails } from './view-edmms-mandate-details';
import objectToCSV from '../../admin/assets/App/object-to-csv';
import ExportToCSVFile from '../../admin/assets/App/ExportToCSVFile';
import eDMMSSearchList from '../../admin/assets/data/e-DMMSSearchList';
import DynamicSort from '../../admin/assets/App/DynamicSort';
import { DateRange } from '../../components/date-range';

export default class OutgoingEDMMSMandateForm extends Component {

    constructor() {
        super();
        this.state = {
            actionSuccessful: false,
            apiData: [],
            apiStaticData: [],
            metaData: {},
            viewData: [],
            editData: [],
            exportData: [],
            institutions: [],
            fetchSuccessful: true,

            displayReject: false,
            displayDetails: false,

            searchField: "",
            searchValue1: "",
            searchValue2: "",
            startDate: "",
            endDate: "",

            currentSelectedPage: 1,
            arrayPages: [],
            lastPage: 1,
            perPage: localStorage.getItem("items_per_page") || 20,
            totalItems: 0,
        }

        this.options = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                'Cache-Control': 'public, max-age=0'
            },
            method: 'GET',
        };
    }

    fetchInstitutions = () => {
        /**
         * This function fetches the institutions
         * for the search querries...
         */
        fetch(`${ApiPath}api/institution/fetch-institutions`, this.options)
            ?.then(result => (result.json()))
            .then(result => {
                if (result && result.ok) {
                    this.setState({
                        institutions: result.body.data.institutions?.sort(DynamicSort("name")),
                    })
                }

            })
    }

    fetchAllMandates = () => {
        /**
         * This one fetches all the
         * mandates and makes them
         * available for the
         * search box...
         */
        fetch(`${ApiPath}api/edmms-mandate/fetch-outgoing-mandates?page=1` +
            `&limit=200000`, this.options)
            ?.then(output => (output.json()))
            ?.then(resp => {
                if (resp && resp.ok) {
                    this?.setState({
                        apiStaticData: resp.body.data,
                    })
                }
            })
    }

    fetchOutgoingEDMMSMandates = () => {
        /**
         * This fetches
         * the outgoing eDMMS mandates
         * created by the institution
         * and branch, immediately the
         * form loads...
         */

        this.props.setIsLoaderVisible(true);
        let searchField = "", searchValue = "";

        const searchPeriod = (this.state.startDate && this.state.endDate)
            ? `&createdAt=${this.state.startDate}&createdAt=${this.state.endDate}`
            : '';

        if (this.state.searchField === 'receivingInstitution') {
            searchField = 'receivingInstitution';
            searchValue = this.state.institutions.find(item => item.name === this.state.searchValue1).slug;
        } else {
            searchField = this.state.searchField;
            searchValue = `${this.state.searchValue1}`;
        }

        fetch(`${ApiPath}api/edmms-mandate/fetch-outgoing-mandates?page=${this.state.currentSelectedPage}` +
            `&${searchField}=${searchValue}&limit=${this.state.perPage}${searchPeriod}`, this.options)
            ?.then(res => (res.json()))
            ?.then(response => {

                this.props.setIsLoaderVisible(false);

                if (response && response.ok) {
                    this?.setState({
                        metaData: response.body.meta,
                        apiData: response.body.data,
                        fetchSuccessful: true,
                        actionSuccessful: false,
                        arrayPages: []
                    })

                    // Set other parameters...
                    const pagesArray = [];
                    const mData = response.body.meta;
                    const lastPage = mData?.last_page;

                    // Set other params...
                    this.setState({
                        totalItems: mData?.total,
                        lastPage: mData?.last_page,
                        currentSelectedPage: mData?.current_page,
                    })

                    // Then, set the array of pages...
                    for (let i = 1; i <= lastPage; i++) {
                        pagesArray.push({ "id": i, "name": "Item " + i });
                    }

                    // Set the variable at the end...
                    this.setState({ arrayPages: pagesArray })
                } else {
                    this.props.setIsLoaderVisible(false);
                    this.props.ShowToast(response?.message, "exclamation");
                }
            })

    }

    exportData = () => {
        /**
         * We export the eDMMS mandate data,
         * according to the provided
         * specifications
         */
        const thisPerPage = 2000000;

        this.props.setIsLoaderVisible(true)

        fetch(`${ApiPath}api/edmms-mandate/fetch-outgoing-mandates?page=1` +
            `&${this.state.searchField}=${this.state.searchValue1}&limit=${thisPerPage}`, this.options)
            ?.then(response => (response.json()))
            .then(response => {
                this.props.setIsLoaderVisible(false)
                if (response && response.ok) {
                    const apiOutput = response.body.data

                    //The export proper...
                    const DataToExport = loadEDMMSExportTable(apiOutput);

                    // Set into csv format, then export
                    const csvData = objectToCSV(DataToExport);
                    ExportToCSVFile(csvData, "outgoing-mandates.csv");

                    this.props.ShowToast("Mandates exported successfully! Open downloads folder to preview...", "information");

                } else {
                    this.props.ShowToast(response.message, "exclamation");
                }

            })
            .catch(err => {
                // Hide the loader and show the issue, if there's one...
                this.props.setIsLoaderVisible(false);
                this.props.ShowToast(err.message, "exclamation");
            })
    }

    filterRecords = () => {
        // Just applying the changes as we filter...
        // Particularly for the date range refreshing...
        this.fetchOutgoingEDMMSMandates();
    }

    refreshForm = () => {
        window.location.reload();
    }

    componentDidMount() {
        this.fetchOutgoingEDMMSMandates();
        this.fetchAllMandates();
        this.fetchInstitutions();
    }

    render() {

        const tblRows = this.state.fetchSuccessful && this.state.apiData?.map((item, index) => {
            const itemCounter = (this.state.currentSelectedPage - 1) * this.state.perPage + (index + 1);

            return (<OutgoingEDMMSMandateTableRow
                key={index + '_B'}
                counter={itemCounter}
                ShowToast={this.props.ShowToast}
                setIsLoaderVisible={this.props.setIsLoaderVisible}
                data={item}
                showRejectForm={() => this.setState({ displayReject: true, viewData: item })}
                showDetailsForm={() => this.setState({ displayDetails: true })}
                setActionSuccessful={() => this.setState({ actionSuccessful: true })}
                setViewData={val => this.setState({ viewData: val })}
            />)
        })

        if (this.state.actionSuccessful) {
            // Anytime we complete the action, we
            // do the following...
            this.fetchOutgoingEDMMSMandates();
            this.setState({ actionSuccessful: false })
        }

        // Search / filter data...
        const status = [{ "item_name": "Submitted" }, { "item_name": "Approved" }, { "item_name": "Accepted" }, { "item_name": "Rejected" }];
        const institutionNames = [];
        const referenceNo = [];
        const bvnArray = [];
        const chnArray = [];
        const refereeAccountNo = [];

        this.state.apiStaticData?.forEach(item => {
            chnArray.push({ "item_name": item.chn });
            referenceNo.push({ "item_name": item.mandateCode });
            bvnArray.push({ "item_name": item.bvn });
            refereeAccountNo.push({ "item_name": item.paymentAccountNo });
        })

        this.state.institutions?.forEach(element => {
            institutionNames.push({ "item_name": element.name });
        })

        const replacementEDMMSSearchList = eDMMSSearchList.filter(item => item.id !== 2);

        return (
            <>
                <div className='title'>
                    <DateRange
                        setDateFrom={val => this.setState({ startDate: val })}
                        ShowToast={this.props.ShowToast}
                        setDateTo={val => this.setState({ endDate: val })}
                        callback={this.filterRecords}

                    />
                    <SearchComponent
                        inputList={{ status, institutionNames, referenceNo, bvnArray, chnArray, refereeAccountNo }}
                        ShowToast={this.props.ShowToast}
                        filterRecords={this.filterRecords}
                        ReferenceSearchList={[...replacementEDMMSSearchList, {
                            id: 2,
                            idVal: "receivingInstitution",
                            value: "Fin. Institution",
                            itemName: "Financial Institution"
                        }]}
                        setSearchField={val => this.setState({ searchField: val })}
                        setSearchValue={val => this.setState({ searchValue1: val })}
                        setSearchValue2={val => this.setState({ searchValue2: val })}

                    />
                    <div className='buttons'>
                        <button
                            id='btnExport'
                            data-title='Export eDMMS records as .csv'
                            className='btn-green t-tip'
                            type='button'
                            onClick={this.exportData}
                        ><i className='icofont-airplane-alt'></i> Export
                        </button>
                    </div>
                </div>
                <div className='table-container'>
                    <table className='bordered-table'>
                        <caption></caption>
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col" className="align-left">MANDATE CODE</th>
                                <th scope="col" className="align-left">CREATED AT</th>
                                <th scope="col" className="align-left">REGISTRAR</th>
                                <th scope="col" className="align-left">INSTITUTION</th>
                                <th scope="col" className="align-left">BRANCH</th>
                                <th scope="col" className="align-left">COMPANY NAME / FULLNAME</th>
                                <th scope="col" className="align-center">STATUS</th>
                                <th scope="col" className="align-center">ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody>{tblRows}</tbody>
                    </table>
                </div>
                <Pagination
                    setRefreshForm={val => this.setState({ actionSuccessful: val })}
                    currentSelectedPage={this.state.currentSelectedPage}
                    setCurrentSelectedPage={val => this.setState({ currentSelectedPage: val })}
                    perPage={this.state.perPage}
                    setPerPage={val => this.setState({ perPage: val })}
                    lastPage={this.state.lastPage}
                    arrayPages={this.state.arrayPages}
                />
                {this.state.displayDetails && <ViewEDMMSMandateDetails
                    hideDialog={() => this.setState({ displayDetails: false })}
                    data={this.state.viewData}
                    sender="Outgoing eDMMS Mandates"
                    displayView={this.state.displayDetails}
                    ShowToast={this.props.ShowToast}
                    setIsLoaderVisible={this.props.setIsLoaderVisible}
                />}
                <RejectOutgoingEDMMSMandate
                    displayReject={this.state.displayReject}
                    closeForm={() => this.setState({ displayReject: false })}
                    ShowToast={this.props.ShowToast}
                    setIsLoaderVisible={this.props.setIsLoaderVisible}
                    data={this.state.viewData}
                    setActionSuccessful={() => this.setState({ actionSuccessful: true })}
                />

            </>
        )
    }
}
