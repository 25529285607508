export const StockComponent = props => {

    /**
     * The stock component for
     * the EDMMS module
     * Use the registrars and companies items to filter
     * and find the required item for the proper
     * names display..
     */

    const { item, index, removeItem, companies, registrars } = props;

    return (
        <div className="stock-group" key={index}>
            <h3><i className="icofont-institution" /> {companies?.find(element => element.slug === item.company)?.companyName}</h3>
            <div className="reg-data">
                <div>
                    <h4>Registrar: {registrars?.find(element => element.slug === item.registrar)?.name}</h4>
                    <h4>Share-Holder Account Number: {item.shareHolderAccountNo}</h4>
                </div>
                <button
                    className="btn-grey" onClick={() => removeItem(index)}>
                    <i className="icofont-remove" /><i className="icofont-ui-delete" /> Remove Stock
                </button>
            </div>
        </div>
    )
}
