import { useState, useEffect } from 'react';

export const useFetch = url => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState('');

    const options = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
            'Content-Type': 'application/json',
            'Cache-Control': 'public, max-age=0'
        }
    }

    const getData = async () => {
        setIsLoading(true);

        const makeReq = await fetch(url, options);
        const response = await makeReq.json();

        setIsLoading(false);

        if (response && response.ok) {
            setData(response.body.result)
        } else {
            // Error occured...
            setError(response.message)
        }
    }

    useEffect(() => {
        getData();

        // eslint-disable-next-line
    }, []);

    return { isLoading, data, error };
}
