import NavHeader from './nav-header'
import NavLinks from './nav-links'
import navLinksData from '../data/nav-links-data'
import { Link } from 'react-router-dom'
import ApiPath from './api-path'
import React, { useState, useEffect } from 'react'

/**
 This Nav bar
 handles the
 display of
 the navbar
 and ensures the
 consistency
 of navigation
 and visits of
 respective
 links and pages.
  */

const NavBar = ({ sender }) => {

    const [userHasERefServicePermission, setUserHasERefServicePermission] = useState(false);
    const [userHasTrappedCardsServicePermission, setUserHasTrappedCardsServicePermission] = useState(false);
    const [userHasEClipsServicePermission, setUserHasEClipsServicePermission] = useState(false);
    const [userHasEDMMSServicePermission, setUserHasEDMMSServicePermission] = useState(false);

    useEffect(() => {
        setInitialPermissions();
        // eslint-disable-next-line
    }, []);

    const setInitialPermissions = async () => {
        setUserHasERefServicePermission(await checkIfUserHasPermissionForService(1));
        setUserHasTrappedCardsServicePermission(await checkIfUserHasPermissionForService(2));
        setUserHasEClipsServicePermission(await checkIfUserHasPermissionForService(3));
        setUserHasEDMMSServicePermission(await checkIfUserHasPermissionForService(4));
    }

    function LogOut(e) {

        e.preventDefault();
        const options = {
            method: "POST",
            headers: {
                "Content-Type": 'application/json',
                "Authorization": `Bearer ${sessionStorage.getItem("session_token")}`,
                'Cache-Control': 'public, max-age=0'
            }
        };

        fetch(ApiPath + "api/auth/logout", options)
            ?.then(response => (response.json()))
            .then(responseValue => {
                if (responseValue.ok) {

                    //Clean out the things needed...
                    const isNibssUser = sessionStorage.getItem("is_nibss_user").indexOf("NIBSS") > -1 ? true : false;

                    sessionStorage.setItem("session_token_timestamp", null);
                    sessionStorage.setItem("session_valid_user", null);
                    sessionStorage.setItem("session_token", null);
                    sessionStorage.setItem("session_locked_state", null);
                    sessionStorage.setItem("session_first_name", null);
                    sessionStorage.setItem("session_last_name", null);
                    sessionStorage.setItem("session_user_type", null);
                    sessionStorage.setItem("service_ids", null);
                    sessionStorage.setItem("institution", null);
                    sessionStorage.setItem("is_nibss_user", "-");
                    sessionStorage.setItem("customer_branch", null);
                    sessionStorage.setItem("customer_branch_slug", null);
                    sessionStorage.setItem("institution_slug", null);

                    //Sign out
                    window.location.href = isNibssUser ? "/nibss-user-login" : "/"

                }
            })

    }

    const checkServiceIds = (item) => {

        //This is an institution user. Show/Hide what they can see...
        const servicesArray = sessionStorage.getItem("service_ids")?.split(",") || ["1", "3", "6"];
        const intServicesArray = servicesArray.map(elt => (parseInt(elt.trim())))

        let serviceIdFound = false;
        item.enabledServiceId.forEach(element => {

            if (intServicesArray.indexOf(element) > -1) {
                //Only set this to true if any of the search
                //service array ids elements is found in the enabledServiceId array...
                serviceIdFound = true;
            }

        })

        //Assign the value to the returnValue variable...
        return serviceIdFound;

    }

    const checkIdRangesAsUser = inputID => {
        let output = true;

        if (((inputID > 11 && inputID < 15)
            || (inputID > 17 && inputID < 21)
            || (inputID > 20 && inputID < 27))      // eClips and eDMMS
            && sessionStorage.getItem("is_nibss_user")?.indexOf("NIBSS") > -1) {
            //This makes sure that the
            // e-reference part does not display to the nibss users
            output = false
        }

        return output;
    }

    const checkIfUserHasPermissionForService = async serviceId => {
        /**
         *Call the API, based on the service ID and check if the institution has permission...
         */
        try {
            const fetchData = await fetch(`${ApiPath}api/institution/fetch-institutions-for-service?service=${serviceId}`);
            const response = await fetchData.json();

            if (response.ok) {
                // Check if the institution is in the list...
                const fData = response.body?.data?.filter(elt => elt.slug === sessionStorage.getItem('institution_slug'));
                return (fData.length > 0) ? true : false;
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    }

    const checkUserTypes = async function (itemID) {
        let snl = true;

        // Hide for non-eReference users...
        if (sessionStorage.getItem("session_user_type") !== 'Admin') {
            if (!userHasERefServicePermission) {
                // User does not have eReference permission... Thus, hide all eRef services...
                // if (itemID === 12 || itemID === 13 || itemID === 14 || itemID === 15) {
                if (itemID > 11 && itemID < 16) {
                    snl = false;
                }
            }

            // Hide for non-Trapped Cards users...
            if (!userHasTrappedCardsServicePermission) {
                // User does not have Trapped Cards permission... Thus, hide all trapped cards services...
                //        if ((itemID > 17 && itemID < 21) && itemID === '15b') {
                snl = false;
                //        }
            }

            // Hide for non-eClips users...
            if (!userHasEClipsServicePermission) {
                // User does not have eClips permission... Thus, hide all eClips services...
                // if (itemID === 22 || itemID === 22 || itemID === 23 || itemID === '15c') {
                //        if ((itemID > 21 && itemID < 24) && itemID === '15c') {
                snl = false;
                //       }
            }

            // Lastly, hide for non-eDMMS users...
            if (!userHasEDMMSServicePermission) {
                // User does not have eDMMS permission... Thus, hide all eDMMS services...
                // if (itemID === 241 || itemID === 24 || itemID === 25 || itemID === 26 || itemID === '15d') {
                //if ((itemID > 23 && itemID < 27) && itemID === 241 && itemID === '15d') {
                snl = false;
                //}
            }
        }


        return snl

    }

    const NavLinkValues = navLinksData.map(item => {
        const linkActive = (sender === item.linkName) ? "nav-active" : ''

        //eslint-disable-next-line
        const navLinkShow = (sessionStorage.getItem("is_nibss_user")?.indexOf("NIBSS") > -1) ? true : item.forAll

        let showNavLink = checkIdRangesAsUser(item.id) && navLinkShow;

        //Another clause...
        if (sessionStorage.getItem("session_user_type") !== "Admin"
            && (sessionStorage.getItem("is_nibss_user")?.indexOf("NIBSS") === -1)
            && item.hideSpeciallyForInstitutionUser) {
            showNavLink = false;
        }

        //Final clause, check for service id matches...
        if (item.enabledServiceId && sessionStorage.getItem("is_nibss_user") !== "NIBSS") {
            showNavLink = checkServiceIds(item);
        }

        if (sessionStorage.getItem("session_user_type") === "Admin" && (
            item.id === 12 || item.id === 13 || item.id === 14)) {
            showNavLink = false;
        }

        // Correct this later... by assigning to the showNavLink property...
        checkUserTypes(item.id)

        return (
            showNavLink && <NavLinks
                key={item.id}
                navActive={linkActive}
                iconClass={item.iconclass}
                linkName={item.linkName}
                drawLine={item.lineExists}
                to={item.location}
                linkID={item.link_id}
            />
        )
    })


    return (
        <div className="cont-ainer">
            <div className="navigation">
                <ul>
                    <NavHeader />
                    {NavLinkValues}

                    <li>
                        <Link to='/' onClick={LogOut}>
                            <i className="ion-android-exit"></i>
                            <span className="title">Sign Out</span>
                        </Link>
                    </li>
                </ul>
                <button className="d-none" id="testButton" onClick={e => LogOut(e)}></button>
            </div>
        </div>
    )
}

export default NavBar
