const BranchSearchList = [
    {
        id: 1,
        idVal: "institution",
        value: "Institution",
        itemName: "Institution"
    },
    {
        id: 2,
        idVal: "state",
        value: "State",
        itemName: "State",
    },
    {
        id: 3,
        idVal: "lga",
        value: "Local Govt Area",
        itemName: "LGA"
    },
    {
        id: 4,
        idVal: "status",
        value: "Status",
        itemName: "Status",
    },
    {
        id: 5,
        idVal: "branchName",
        value: "Branch Name",
        itemName: "Branch Name",
    },
    {
        id: 6,
        idVal: "branchCode",
        value: "Branch Code",
        itemName: "Branch Code",
    },
    {
        id: 7,
        idVal: "contactEmail",
        value: "Contact Email",
        itemName: "Contact Email",
    },
]

export default BranchSearchList
