import CapitalizeFirstLetter from "../../admin/assets/App/CapitalizeFirstLetter";
import ApiPath from "../../admin/assets/App/api-path";

/**
* This incoming cards form
* basically
* allows us to have
* a quick view of
* application shortcuts.
*
* We need to add the
* table and the
* table rows to
* follow the
* expected
* description on
* the requirements
* document...
*/
/**
* This incoming cards form
* basically
* allows us to have
* a quick view of
* application shortcuts.
*
* We need to add the
* table and the
* table rows to
* follow the
* expected
* description on
* the requirements
* document...
*/

const ApproveRejectCardsDialog = (props) => {

    /**
    * This incoming cards form
    * basically
    * allows us to have
    * a quick view of
    * application shortcuts.
    *
    * We need to add the
    * table and the
    * table rows to
    * follow the
    * expected
    * description on
    * the requirements
    * document...
    */
    /**
    * This incoming cards form
    * basically
    * allows us to have
    * a quick view of
    * application shortcuts.
    *
    * We need to add the
    * table and the
    * table rows to
    * follow the
    * expected
    * description on
    * the requirements
    * document...
    */

    //Mostly will look like the approve/reject module of e-Reference...
    const closeForm = () => {
        props.setDisplayApproveDialog(false)
    }

    const approveRejectCard = (e) => {
        const api = "api/trapped-card/treat-trapped-card";
        let statement;

        if (e.target.id === "btnRejectReference") {
            statement = "rejected";
        } else {
            statement = "accepted";
        }

        const data = {
            slug: props.data.slug,
            action: statement,
        }

        const options = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                'Cache-Control': 'public, max-age=0'
            },
            body: JSON.stringify(data),
        }

        props.setIsLoaderVisible(true);

        fetch(ApiPath + api, options)
            ?.then(response => {
                props.setIsLoaderVisible(false)
                return response.json();
            })
            .then(response => {
                props.setIsLoaderVisible(false)

                if (response) {
                    if (response.ok === true) {
                        props.ShowToast(`Card ${statement} successfully!`, "success")
                        props.setDisplayApproveDialog(false)
                        props.setApproveSuccessful(true);

                    } else {
                        props.ShowToast(response.message, "exclamation")
                    }
                } else {
                    props.ShowToast("Invalid input variables.", "exclamation")
                }
            })
            .catch(error => {
                props.ShowToast(error.message, "exclamation")
            })
    }

    const cardImageURL = () => {
        let returnValue;
        switch (props.data.cardScheme?.toLowerCase()) {
            case "mastercard":
                returnValue = "/assets/img/jpg/m-card.jpg"
                break;
            case "visa":
                returnValue = "/assets/img/jpg/v-card.jpg";
                break;
            case "verve":
                returnValue = "/assets/img/jpg/ve-card.jpg";
                break;
            case "afrigo":
                returnValue = "/assets/img/jpg/afri-go-card.jpg";
                break;
            default:
                returnValue = "";
                break;
        }

        return returnValue;
    }


    const dialogclass = props.displayApproveDialog ? "modal fade show" : "modal fade";
    return (
        <div className={dialogclass}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="create"><i className="icofont-injection-syringe"></i> Treat Card</h2>
                    </div>
                    <div className="modal-body">
                        <h4 className="mb-2" style={{ fontSize: 13, fontWeight: "normal" }}>Below are your inputs:</h4>
                        <table className="dialog-table"><caption></caption>
                            <thead>
                                <tr>
                                    <th scope="col" className="align-left">Field</th>
                                    <th scope="col" className="align-left">Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Trapped Card Request Number</td>
                                    <td>{props.data?.trappedCardNo}</td>
                                </tr>
                                <tr>
                                    <td>Card Number</td>
                                    <td>{props.data?.cardNumber}</td>
                                </tr>
                                <tr>
                                    <td>Card Scheme</td>
                                    <td style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        {CapitalizeFirstLetter(props.data?.cardScheme)}
                                        <img src={cardImageURL()} width={50} alt="" />
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                        <hr className="golden-rule" />
                    </div>
                    <div className="modal-footer">
                        <button
                            id="btnApproveReference"
                            className="btn-green mr-1"
                            onClick={approveRejectCard}>
                            <i className="icofont-check-circled"></i> Accept
                        </button>
                        <button
                            id="btnRejectReference"
                            className="btn-danger mr-1"
                            onClick={approveRejectCard}>
                            <i className="icofont-close-circled"></i> Reject
                        </button>
                        <button
                            id="btnClose"
                            className="btn-grey"
                            onClick={closeForm}>
                            <i className="icofont-exit"></i> Cancel
                        </button>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default ApproveRejectCardsDialog;
/**
     * This create trapped cards form
     * basically
     * allows us to have
     * a quick view of
     * application shortcuts.
     *
     * We need to add the
     * table and the
     * table rows to
     * follow the
     * expected
     * description on
     * the requirements
     * document...
     */
     /**
     * This create trapped cards form
     * basically
     * allows us to have
     * a quick view of
     * application shortcuts.
     *
     * We need to add the
     * table and the
     * table rows to
     * follow the
     * expected
     * description on
     * the requirements
     * document...
     */
     /**
     * This create trapped cards form
     * basically
     * allows us to have
     * a quick view of
     * application shortcuts.
     *
     * We need to add the
     * table and the
     * table rows to
     * follow the
     * expected
     * description on
     * the requirements
     * document...
     */
