
const DynamicSort = (property, sortType = "asc") => {
    const sortOrder = sortType === "asc" ? 1 : -1;

    return function (a, b) {
        const secVal = (a[property] > b[property]) ? 1 : 0;
        const result = (a[property] < b[property])
            ? -1
            : secVal;

        return result * sortOrder;
    }
}

export default DynamicSort;
