const MaskCardNumber = (cardNumber, chosenChar = "*") => {
    //The card number is 16 digits
    //And masked such that
    //1.    Only the first 6 and last 4 digits are visible
    //2.    other digits are replaced with stars or asterixes
    //  (depending on the chosenChar above, default is asterisk)
    const first6 = cardNumber?.substring(0, 6);
    const last4 = cardNumber?.substring(12);
    const charArray = [];
    for (let i = 0; i < 6; i++) {
        charArray.push(chosenChar);
    }

    return first6 + charArray.join("") + last4;

}

export default MaskCardNumber;

/**
     * This create trapped cards form
     * basically
     * allows us to have
     * a quick view of
     * application shortcuts.
     *
     * We need to add the
     * table and the
     * table rows to
     * follow the
     * expected
     * description on
     * the requirements
     * document...
     */
     /**
     * This create trapped cards form
     * basically
     * allows us to have
     * a quick view of
     * application shortcuts.
     *
     * We need to add the
     * table and the
     * table rows to
     * follow the
     * expected
     * description on
     * the requirements
     * document...
     */
     /**
     * This create trapped cards form
     * basically
     * allows us to have
     * a quick view of
     * application shortcuts.
     *
     * We need to add the
     * table and the
     * table rows to
     * follow the
     * expected
     * description on
     * the requirements
     * document...
     */
