const ERefRoleGroup = (props) => {

    const setOperatorChanges = e => {
        props.setERef(e.target.id);
        props.setERefCode(1);
    }

    const setAuthorizerChanges = e => {
        props.setERef(e.target.id);
        props.setERefCode(2);
    }

    const clearValues = () => {
        props.setERef('');
        props.setERefCode('');
    }

    const authorizer = "Authorizer";
    const operator = "Operator";

    return (
        <div className="role-group">
            <h3 className="role-group-title">e-Reference</h3>
            <div className="role-group-inputs">
                <label>
                    <input
                        type="radio"
                        id={ operator }
                        name="e_Reference"
                        checked={props.e_Reference === operator}
                        onChange={setOperatorChanges}
                    /> { operator }
                </label>
                <label>
                    <input
                        type="radio"
                        id={ authorizer }
                        name="e_Reference"
                        checked={props.e_Reference === authorizer}
                        onChange={setAuthorizerChanges}
                    /> { authorizer }
                </label>
                <button
                    className="clear-radios btn-grey"
                    id="btnClearERef"
                    onClick={clearValues}>
                    <i className="icofont-mop"></i> Clear
                </button>
            </div>
        </div>
    );
}

export default ERefRoleGroup;
