import { useEffect, useState } from "react";
import ApiPath from "../../admin/assets/App/api-path";
import { MessageBoxType } from "../../_common/constants";

const GeneratePublicURL = (props) => {

    const [txtPublicURL, setTxtPublicURL] = useState("");
    const [resetURL, setResetURL] = useState(false);

    const headersData = {
        'Content-Type': 'application/json',
        'Cache-Control': 'public, max-age=0',
        'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`
    }

    useEffect(() => {
        /**
         * I'm trying using a functional component
         * here and then disabling the es-lint
         * on the last line.
         * It works...
         */
        getPublicURL()
        // eslint-disable-next-line
    }, [])

    const displayClass = props.displayForm ? "dialog-background fade" : "dialog-background";

    const closeForm = () => {
        props.setDisplayPublicURL(false);

        //Ensure that the page is only refreshed
        //Only when the public URL is reset
        if (resetURL) {
            props.setActionSuccessful();
        }
    }

    const copyURL = async () => {
        //Set the text in the textarea to
        //the clipboard
        props.ShowToast("Public URL Copied!", "information")
        await navigator.clipboard.writeText(txtPublicURL);
    }

    const getPublicURL = () => {
        /**
         * Get the public URL on form load...
         */
        setTxtPublicURL(props.item?.publicUrl);

    }

    const generateNewPublicURL = () => {
        //Generate a new
        //Public URL as the
        //reset button is clicked...
        const data = { slug: props.itemSlug }
        const options = {
            method: "POST",
            headers: headersData,
            body: JSON.stringify(data),
        }

        props.setIsLoaderVisible(true);

        fetch(`${ApiPath}api/eclip/create-public-url`, options)
            ?.then(response => {
                props.setIsLoaderVisible(false)
                return (response.json())
            })
            .then(response => {
                if (response) {
                    if (response.ok) {
                        //Go ahead and set the values for the textarea...
                        setResetURL(true);
                        props.ShowToast("Public URL generated successfully!", MessageBoxType.SUCCESS)
                        setTxtPublicURL(response.body.data.publicUrl);

                    } else {
                        props.ShowToast(response.message, MessageBoxType.EXCLAMATION)
                    }
                }

            })
            .catch(error => {
                props.setIsLoaderVisible(false)
                props.ShowToast(error.message, MessageBoxType.EXCLAMATION)
            })


    }

    const disablePublicURL = () => {
        //Disable any
        //Public URL that
        //has been previously generated...
        //Remember, the item slug is 'props.itemSlug'...
        const disableSlug = { slug: props.itemSlug }
        const options = {
            method: "POST",
            headers: headersData,
            body: JSON.stringify(disableSlug),
        }

        props.setIsLoaderVisible(true);

        fetch(`${ApiPath}api/eclip/delete-public-url`, options)
            ?.then(response => {
                props.setIsLoaderVisible(false)
                return (response.json())
            })
            .then(response => {
                props.setIsLoaderVisible(false)
                if (response) {
                    if (response.ok) {
                        //Go ahead and set the values for the textarea...
                        setResetURL(true);
                        props.ShowToast("Public URL disabled successfully!", MessageBoxType.SUCCESS)

                    } else {
                        props.ShowToast(response.message, MessageBoxType.EXCLAMATION)
                    }
                }
            })
            .catch(error => {
                props.setIsLoaderVisible(false)
                props.ShowToast(error.message, MessageBoxType.EXCLAMATION)
            })

    }

    return (
        <div className={displayClass}>
            <div className="dialog-container">
                <div className="dialog-header">
                    <h2 className="nully"><i className="ion-settings"></i> Generate/Reset Public URL</h2>
                </div>
                <div className="dialog-body">
                    <div className="form-row-3x">
                        <div className="input-group-inline">
                            <input
                                className="form-control"
                                id="txtPublicURL"
                                name="txtPublicURL"
                                value={txtPublicURL}
                                onChange={e => setTxtPublicURL(e?.target.value)}
                            />
                            <button
                                className="btn-grey mr-1"
                                id="btnCopy"
                                onClick={copyURL}
                                title="Copy"

                            ><i className="icofont-copy-invert"></i></button>
                        </div>
                    </div>
                </div>
                <div className="dialog-footer">

                    <button
                        className="btn-info mr-1"
                        id="btnReset"
                        onClick={generateNewPublicURL}
                    >
                        <i className="ion-ios-refresh" /> Generate / Reset</button>
                    <button
                        className="btn-grey mr-1"
                        id="btnDisable"
                        onClick={disablePublicURL}
                    >
                        <i className="ion-eye-disabled" /> Disable</button>
                    <button
                        className="btn-grey"
                        id="btnCancel"
                        onClick={closeForm}
                    >
                        <i className="ion-android-exit"></i> Close</button>
                    <button className="d-none" id="testButton" onClick={getPublicURL}></button>
                </div>
            </div>
        </div>
    );
}

export default GeneratePublicURL;
