import NavBar from "../../admin/assets/App/nav-bar"
import TopBar from "../../admin/assets/App/topbar"
import LockedScreen from "../../admin/assets/App/LockedScreen";
import { useState } from 'react';
import { Helmet } from "react-helmet";
import Footer from "../../admin/assets/App/footer";
import EDMMSReportForm from "./e-dmms-report-form";

export const EDMMSReports = props => {

    const { ShowToast, setIsLoaderVisible } = props;

    /**
     * This is the outgoing references
     * container
     * for the main module....
     */
    const [mainClass, setMainClass] = useState("main");

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>e-DMMS Reports | NIBSS Document Management Hub Portal</title>
            </Helmet>
            <LockedScreen
                setIsLoaderVisible={setIsLoaderVisible}
                ShowToast={ShowToast}
            />

            <div className="cont-ainer">
                <NavBar sender="e-DMMS Reports" />
                <div className={mainClass}>
                    <TopBar
                        setMainClass={value => setMainClass(value)}
                    />
                    <div className="page-title">
                        <h2><i className="icofont-mr ion-ios-briefcase"></i>e-DMMS Reports</h2>
                    </div>
                    <div className="branch-summaries">
                        <div className="summaries">
                            <EDMMSReportForm
                                ShowToast={ShowToast}
                                setIsLoaderVisible={setIsLoaderVisible}
                            />
                        </div>
                    </div>
                    <Footer />
                </div>

            </div>

        </>
    )
}
