import LoginHeader from "./login-header"
import LoginFormData from "./login-form-data"
import { Helmet } from "react-helmet";

export const Login = (props) => {
    const userType = false;
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Login | NIBSS Document Management Hub Portal</title>
            </Helmet>
            <div className="login-container">
                <h1>Document Hub Portal</h1>
                <div className="login">
                    <LoginHeader mainImageSrc="assets/img/svg/secure_login.svg" />
                    <LoginFormData
                        isNibssUser={userType}
                        ShowToast={props.ShowToast}
                        setIsLoaderVisible={props.setIsLoaderVisible}
                    />
                </div>
            </div>
        </>
    )
}

