import React from "react"
import OutgoingCardsTableRows from "./outgoing-cards-table-rows";
import Pagination from "../../admin/assets/App/Pagination";
import SearchComponent from "../../admin/assets/App/SearchComponent";
import ApiPath from "../../admin/assets/App/api-path";
import TrappedCardsSearchList from "../../admin/assets/data/TrappedCardsSearchList"
import objectToCSV from "../../admin/assets/App/object-to-csv";
import ExportToCSVFile from "../../admin/assets/App/ExportToCSVFile";
import { SplitDate } from "../../admin/assets/App/split-date";
import CapitalizeFirstLetter from "../../admin/assets/App/CapitalizeFirstLetter";
import DynamicSort from "../../admin/assets/App/DynamicSort";
import MaskCardNumber from "../../admin/assets/App/MaskCardNumber";
import { DateRange } from "../../components/date-range";

export default class OutgoingCardsForm extends React.Component {

    /**
    * This outgoing cards form
    * basically
    * allows us to have
    * a quick view of
    * application shortcuts.
    *
    * We need to add the
    * table and the
    * table rows to
    * follow the
    * expected
    * description on
    * the requirements
    * document...
    */

    /**
    * This outgoing cards form
    * basically
    * allows us to have
    * a quick view of
    * application shortcuts.
    *
    * We need to add the
    * table and the
    * table rows to
    * follow the
    * expected
    * description on
    * the requirements
    * document...
    */

    constructor() {
        super()
        this.state = {
            actionSuccessful: false,
            apiData: [],
            metaData: {},
            institutionsArray: [],
            branches: [],
            fetchSuccessful: false,

            //Search parameters
            searchField: "",
            searchValue: "",
            startDate: "",
            endDate: "",

            //Pagination values
            currentSelectedPage: 1,
            arrayPages: [],
            lastPage: 1,
            perPage: localStorage.getItem("items_per_page") || 20,
            totalItems: 0,
        }

        this.headersData = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
            'Cache-Control': 'public, max-age=0'
        }
    }

    fetchInstitutions = () => {
        const options = {
            headers: this.headersData,
            method: 'GET',
        }

        fetch(ApiPath + 'api/institution/fetch-institutions', options)
            ?.then(response => {
                return response.json();
            })
            .then(response => {
                if (response && response.ok) {
                    this.setState({
                        institutionsArray: response.body.data.institutions.sort(DynamicSort("name")),
                    })
                }
            })
    }

    getBranches() {
        const options = {
            method: 'GET',
            headers: this.headersData,
        }

        fetch(`${ApiPath}api/institution/fetch-institution-branches/${sessionStorage.getItem("institution_slug")}`, options)
            ?.then(response => {
                return response.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false)
                if (response && response.ok) {
                    this.setState({
                        branches: response.body.data.branches,
                    })
                }
            })

    }

    getOutgoingCardsData = () => {

        /**
         * Set the endpoints to match the cards later...
         */
        const options = {
            method: 'GET',
            headers: this.headersData,
        }

        let searchValue = this.state.searchValue;

        if (this.state.searchField === "issuingInstitution") {
            searchValue = this.state.institutionsArray.find(item => item.name === this.state.searchValue)?.slug;

        } else if (this.state.searchField === "branch") {
            if (this.state.searchValue === '') {
                return
            }
            searchValue = this.state.branches.find(item => item.branchName === this.state.searchValue)?.slug;
        }

        const searchPeriod = (this.state.startDate && this.state.endDate) ? `&createdAt=${this.state.startDate}&createdAt=${this.state.endDate}` : '';

        this.props.setIsLoaderVisible(true)

        fetch(`${ApiPath}api/trapped-card/fetch-outgoing-trapped-cards?page=${this.state.currentSelectedPage}` +
            `&${this.state.searchField}=${searchValue}&limit=${this.state.perPage}${searchPeriod}`, options)
            ?.then(response => {
                return response.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false)
                if (response && response.ok === true) {
                    this?.setState({
                        apiData: response.body.trappedCards.data,
                        metaData: response.body.trappedCards.meta,
                        fetchSuccessful: true,
                        actionSuccessful: false,
                        arrayPages: [],
                    }, () => {
                        const pagesArray = [];
                        this.setState({
                            lastPage: this.state.metaData?.last_page,
                            currentSelectedPage: this.state.metaData?.current_page,
                            totalItems: this.state.metaData?.total,
                        }, () => {
                            //Set this array for the looping
                            for (let i = 1; i <= this.state.lastPage; i++) {
                                pagesArray.push({ "id": i, "name": "Item " + i });
                            }

                            this.setState({ arrayPages: pagesArray })
                        })
                    })
                } else {
                    this.props.setIsLoaderVisible(false);
                    this.props.ShowToast(response?.message, "exclamation");
                }
            })
    }

    componentDidMount() {
        //Fetch all the data for the rows as the form loads...
        this.getOutgoingCardsData();
        this.getBranches();
        this.fetchInstitutions();

    }

    filterRecords = () => {
        this.getOutgoingCardsData();
    }

    RefreshForm = () => {
        window.location.reload();
    }

    ExportData = () => {
        /**
         * Exports the data in pre-defined format...
         */
        const prevPerPage = this.state.perPage;

        this.setState({ perPage: 200000 }, () => {

            this.getOutgoingCardsData();

            //Do others in here also...

            const DataToExport = [];
            this.state.apiData.forEach(item => {
                //First, get individual reference details
                var thisObject = {
                    "Log Issue ID": `${item.trappedCardNo}\t`,
                    "Card Number": `${MaskCardNumber(item.cardNumber)}\t`,
                    "Name on Card": item.nameOnCard,
                    "Issuing Institution": item.issuingInstitution?.name,
                    "Acquiring Institution": item.acquiringInstitution?.name,
                    "Initiate Date": SplitDate(item.createdAt).dateTime,
                    "Branch": item.branch || "-",
                    "Card Scheme": CapitalizeFirstLetter(item.cardScheme),
                    "Treated By": item.trappedCardLog?.accpetedByUser?.email || item.trappedCardLog?.rejectedByUser?.email || "-",
                    "Status": item.status,
                }

                DataToExport.push(thisObject)

            })

            const csvData = objectToCSV(DataToExport);
            ExportToCSVFile(csvData, "outgoing-trapped-cards.csv");

            this.props.setIsLoaderVisible(false);
            this.props.ShowToast("Outgoing Trapped Cards exported successfully! Open downloads folder to preview...", "information");

            //Return the number of items per page
            this.setState({ perPage: prevPerPage }, () => this.getOutgoingCardsData())

        })


    }

    render() {

        let counter = 0;
        const TableRows = this.state.fetchSuccessful && this.state.apiData.map(item => {
            counter++;
            const itemCounter = (this.state.currentSelectedPage - 1) * this.state.perPage + counter

            return (<OutgoingCardsTableRows
                key={counter}
                counter={itemCounter}
                ShowToast={this.props.ShowToast}
                item={item}
                setIsLoaderVisible={this.props.setIsLoaderVisible}
                setActionSuccessful={value => this.setState({ actionSuccessful: value })}

            />)
        })

        if (this.state.actionSuccessful) {
            this.getOutgoingCardsData()
            this.setState({
                actionSuccessful: false,
            })
        }

        //Create search lists..
        const status = [{ item_name: "Pending" }, { item_name: "Accepted" }, { item_name: "Rejected" }]
        const branchNames = this.state.branches.map(item => {
            return (
                { item_name: item.branchName }
            )
        })

        const institutionNames = this.state.institutionsArray.map(item => {
            return ({ item_name: item.name })
        })

        return (
            <>
                <div className="title">
                    <DateRange
                        setDateFrom={val => this.setState({ startDate: val })}
                        setDateTo={val => this.setState({ endDate: val })}
                        callback={this.filterRecords}
                        ShowToast={this.props.ShowToast}
                    />
                    <SearchComponent
                        setSearchField={val => this.setState({ searchField: val })}
                        setSearchValue={val => this.setState({ searchValue: val })}
                        ReferenceSearchList={TrappedCardsSearchList.filter(item => item.idVal !== "createdAt")} //Remove the createdAt / Date Range from this list...
                        filterRecords={this.filterRecords}
                        inputList={{ status, institutionNames, branchNames }}
                        ShowToast={this.props.ShowToast}
                    />
                    <div className="buttons">
                        <button
                            id="btnRefresh"
                            type="button"
                            className="btn-grey t-tip mr-1"
                            data-title="Refresh"
                            onClick={this.RefreshForm}>
                            <i className="icofont-refresh"></i> Refresh
                        </button>
                        <button
                            id="btnExport"
                            type="button"
                            className="btn-green t-tip"
                            data-title="Export records to MsExcel file"
                            onClick={this.ExportData}>
                            <i className="icofont-airplane-alt"></i> Export
                        </button>
                    </div>
                </div>
                <div className="table-container">
                    <table className="bordered-table">
                        <caption></caption>
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col" className="align-left">LOG ISSUE ID</th>
                                <th scope="col" className="align-left">CARD NUMBER</th>
                                <th scope="col" className="align-left">NAME ON CARD</th>
                                <th scope="col" className="align-left">ISSUING BANK</th>
                                <th scope="col" className="align-left">ACQUIRING BANK</th>
                                <th scope="col" className="align-center">BRANCH</th>
                                <th scope="col" className="align-center">CARD SCHEME</th>
                                <th scope="col" className="align-center">DATE INITIATED</th>
                                <th scope="col" className="align-center">TREATED BY</th>
                                <th scope="col" className="align-center" width="120">STATUS</th>
                            </tr>
                        </thead>
                        <tbody id="outgoing_cards_table">
                            {TableRows}
                        </tbody>
                    </table>
                </div>
                <Pagination
                    setRefreshForm={value => this.setState({ actionSuccessful: value })}
                    setCurrentSelectedPage={value => this.setState({ currentSelectedPage: value })}
                    setPerPage={value => this.setState({ perPage: value })}
                    perPage={this.state.perPage}
                    currentSelectedPage={this.state.currentSelectedPage}
                    lastPage={this.state.lastPage}
                    arrayPages={this.state.arrayPages}
                />

            </>
        )
    }
}

/**
     * This create trapped cards form
     * basically
     * allows us to have
     * a quick view of
     * application shortcuts.
     *
     * We need to add the
     * table and the
     * table rows to
     * follow the
     * expected
     * description on
     * the requirements
     * document...
     */
/**
* This create trapped cards form
* basically
* allows us to have
* a quick view of
* application shortcuts.
*
* We need to add the
* table and the
* table rows to
* follow the
* expected
* description on
* the requirements
* document...
*/
/**
* This create trapped cards form
* basically
* allows us to have
* a quick view of
* application shortcuts.
*
* We need to add the
* table and the
* table rows to
* follow the
* expected
* description on
* the requirements
* document...
*/
