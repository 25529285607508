import React from 'react';
import DateDiff from '../admin/assets/App/DateDiff';
import { dateToWAFormat } from '../admin/assets/App/split-date';
import '../admin/assets/css/date-range.css'

export class DateRange extends React.Component {
    /**
     * This is a custom
     * date-range component
     * finalized to do the
     * work right...
     */

    constructor() {
        super();
        this.state = {
            dateFrom: '',
            dateTo: '',
            isVisible: false,
            maxDays: 30
        }
    }

    componentDidMount() {
        if (this.props.maxDays) {
            this.setState({
                maxDays: this.props.maxDays,
            })
        }
    }

    clearFields = () => {
        // as the name implies...
        this.setState({
            dateFrom: '',
            dateTo: ''
        })
    }

    setDateValues = () => {
        // Set the outside date values...
        if (!this.state.dateFrom || !this.state.dateTo) {
            this.props.ShowToast('You must select a from and to date to proceed', 'exclamation');
            return;
        }

        // Also check for difference (max of 30 days)...
        if (DateDiff(this.state.dateFrom, this.state.dateTo).days > this.state.maxDays) {
            this.props.ShowToast(`You cannot select a date range of more than ${this.state.maxDays} days.`, "exclamation");
            return;
        }

        // Else, proceed...
        this.props.setDateFrom(this.state.dateFrom);
        this.props.setDateTo(this.state.dateTo);

        // Close the form, then callback...
        this.setState({
            isVisible: false,
        })

    }

    render() {
        return (
            <div className='date-range-container' title='Filter by Date Range'>
                <div className='range-select'>
                    <div className='range-input'>
                        {(this.state.dateFrom && this.state.dateTo) ? <span className='date-items'>
                            <i>{dateToWAFormat(this.state.dateFrom)}</i>TO<i>{dateToWAFormat(this.state.dateTo)}</i>
                        </span> : <span style={{ color: 'var(--faded-text)' }}>Choose Date Range</span>}
                        <div className='date-buttons'>
                            <button className='success' onClick={() => this.setState({ isVisible: !this.state.isVisible })} title="Set Filter">
                                <i className='icofont-filter' />
                            </button>
                            {(this.state.dateFrom || this.state.dateTo) && <button className='danger' onClick={this.clearFields} title="Clear" >
                                <i className='bx bx-block' />
                            </button>}
                            {(this.state.dateFrom || this.state.dateTo) && <button className='info' onClick={() => this.props.callback()} title="Apply Filter" >
                                <i className='icofont-search' />
                            </button>}
                        </div>
                    </div>
                </div>
                <div className={this.state.isVisible ? 'dialog-background fade' : 'dialog-background'}>
                    <div className='lock-container'>
                        <div className='dialog-header'>
                            <h3 className="nully"><i className="icofont-ui-calendar"></i> Select Date Range</h3>
                        </div>
                        <div className='dialog-body'>
                            <p style={{ marginTop: "-20px" }}>Choose a date range from the inputs:</p>
                            <div className="form-row mb-1">
                                <div className="input-group">
                                    <label>From</label>
                                    <input
                                        type="date"
                                        id="dateFrom"
                                        className="form-control"
                                        value={this.state.dateFrom}
                                        onChange={e => this.setState({ dateFrom: e.target.value })} />
                                </div>
                                <div className="input-group">
                                    <label>To</label>
                                    <input
                                        type="date"
                                        id="dateTo"
                                        className="form-control"
                                        value={this.state.dateTo}
                                        onChange={e => this.setState({ dateTo: e.target.value })} />
                                </div>
                            </div>
                        </div>
                        <div className='dialog-footer'>
                            <button
                                id="btnSelect"
                                name="btnSelect"
                                onClick={this.setDateValues}
                                className="btn-green mr-1">
                                <i className="icofont-tick-mark"></i> Select
                            </button>
                            <button
                                onClick={() => this.setState({ isVisible: false })}
                                id="btnCancel"
                                className="btn-grey">
                                <i className="icofont-close"></i> Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
