
const LoginHeader = (props) => {

    const otherImage = props.otherImage ? props.otherImage : "/assets/img/png/nibss-logo.png"

    return (
        <div className="header">
            <img src={props.mainImageSrc} alt=""></img>
            <div className="header-details">
                <img src={otherImage} alt=""></img>
                <p>
                    <span>Document Hub</span> Portal
                </p>
            </div>
        </div>
    )
}

export default LoginHeader
