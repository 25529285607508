import NavBar from "../../admin/assets/App/nav-bar"
import TopBar from "../../admin/assets/App/topbar"
import LockedScreen from "../../admin/assets/App/LockedScreen";
import CreateTrappedCardsForm from "./create-trapped-cards-form";
import Footer from "../../admin/assets/App/footer";
import { useState } from 'react';
import { Helmet } from "react-helmet";

export const CreateTrappedCards = (props) => {
    /**
     * THIS IS MORE OR LESS
     * THE HOST OF THE
     * CREATE TRAPPED CARDS
     * FORM, CONTAINING
     * THE PAGE'S BASIC STRUCTURE
     * AND COMPONENTS
     */
    const [mainClass, setMainClass] = useState("main");

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Traped Cards/Log Trapped Card | NIBSS Document Management Hub Portal</title>
            </Helmet>
            <LockedScreen
                setIsLoaderVisible={props.setIsLoaderVisible}
                ShowToast={props.ShowToast}
            />

            <div className="cont-ainer">
                <NavBar sender="Log Trapped Card" />
                <div className={mainClass}>
                    <TopBar
                        setMainClass={value => setMainClass(value)}
                    />
                    <div className="page-title">
                        <h2><i className="icofont-mr icofont-penalty-card"></i>Log Trapped Card</h2>
                    </div>
                    <div className="branch-summaries">
                        <div className="summaries">
                            <div className="title">
                                <h3>Log Trapped Card Issue</h3>
                            </div>
                            <CreateTrappedCardsForm
                                ShowToast={props.ShowToast}
                                setIsLoaderVisible={props.setIsLoaderVisible}
                            />
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>

        </>
    )
}
/**
     * This create trapped cards form
     * basically
     * allows us to have
     * a quick view of
     * application shortcuts.
     *
     * We need to add the
     * table and the
     * table rows to
     * follow the
     * expected
     * description on
     * the requirements
     * document...
     */
     /**
     * This create trapped cards form
     * basically
     * allows us to have
     * a quick view of
     * application shortcuts.
     *
     * We need to add the
     * table and the
     * table rows to
     * follow the
     * expected
     * description on
     * the requirements
     * document...
     */
     /**
     * This create trapped cards form
     * basically
     * allows us to have
     * a quick view of
     * application shortcuts.
     *
     * We need to add the
     * table and the
     * table rows to
     * follow the
     * expected
     * description on
     * the requirements
     * document...
     */
