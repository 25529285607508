import React from "react";
import BillingsFormData from "./billings-form-data";

export default class CreateBillingsDialog extends React.Component {
    constructor() {
        super();
        this.state = {
            cbxService: "",
            cbxDateFrom: "",
            cbxDateTo: "",
            chkSendEmail: false,
            txtSpecialEmail: "",
        }
    }

    handleChange = e => {
        const { name, value, type, checked } = e?.target;
        type === "checkbox" ? this.setState({
            [name]: checked,
        }) : this.setState({
            [name]: value,
        })
    }

    closeForm = () => {
        this.props.setDisplayCreateDialog(false)
    }

    render() {

        const dialogClass = this.props.displayCreateDialog ? "dialog-background fade" : "dialog-background";

        return (
            <div className={dialogClass}>
                <BillingsFormData
                    ShowToast={this.props.ShowToast}
                    setIsLoaderVisible={this.props.setIsLoaderVisible}
                    roleServices={this.props.roleServices}
                    cbxService={this.state.cbxService}
                    cbxDateFrom={this.state.cbxDateFrom}
                    cbxDateTo={this.state.cbxDateTo}
                    handleChange={this.handleChange}
                    chkSendEmail={this.state.chkSendEmail}
                    setActionSuccessful={this.props.setCreateSuccessful}
                    setDisplayCreateDialog={this.props.setDisplayCreateDialog}
                    closeForm={this.closeForm}
                    nameTag={"Create"}
                />
                {/* For testing purposes... */}
                <button
                    className="d-none"
                    id="testButton"
                    onClick={this.handleSubmit}
                    onChange={this.handleChange}
                ></button>
                <input className="d-none" type="checkbox" id="chkTest" onChange={this.handleChange} />
            </div>
        )
    }
}
