import { SplitDate, ShortDateToCoded } from "../../../admin/assets/App/split-date";
import GetServices from "../../../admin/assets/App/GetServices"

const BillingDetailsDialog = (props) => {

    const closeForm = () => {
        props.setDisplayBillingDetailsDialog(false)
    }

    const dialogClass = (props.displayBillingDetailsDialog) ? "modal fade show" : "modal fade";

    //Change later to external file...
    const docTitle = `${SplitDate(props.data.createdAt).codedDate} ${GetServices(props.data.service?.name)}
    ${ShortDateToCoded(props.data.startDate)}-${ShortDateToCoded(props.data.endDate)}`;

    return (
        <>
            <div className={dialogClass}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="nully"><i className="icofont-listine-dots"></i> Billing Details</h2>
                        </div>
                        <div className="modal-body">
                            <div className="dialog-table-header">
                                <i className="icofont-ui-file" />
                                <span>{docTitle}</span>
                            </div>
                            <table className="dialog-table mb-2">
                                <caption></caption>
                                <thead>
                                    <tr>
                                        <th scope="col" className="align-left ml-2">Field</th>
                                        <th scope="col" className="align-center">Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Email of User</td>
                                        <td className="align-center">{props.data.createdByUser?.email}</td>
                                    </tr>
                                    <tr>
                                        <td>Total Volume of Billing File</td>
                                        <td className="align-center">{props.aggregateData.billingFileVolume}</td>
                                    </tr>
                                    <tr>
                                        <td>Total Value of Billing File</td>
                                        <td className="align-center">{props.aggregateData.billingFileAmount}</td>
                                    </tr>
                                    <tr>
                                        <td>Total Volume of Smart Det</td>
                                        <td className="align-center">{props.aggregateData.smartDetVolume}</td>
                                    </tr>
                                    <tr>
                                        <td>Total Value of Smart Det</td>
                                        <td className="align-center">{props.aggregateData.smartDetAmount}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <hr className="golden-rule" />
                        </div>
                        <div className="modal-footer">
                            <hr />
                            <button
                                type="button"
                                className="btn-grey mr-2"
                                onClick={closeForm}
                                id="btnCancel">
                                <i className="icofont-exit"></i> Close
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default BillingDetailsDialog
