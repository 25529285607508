export const BulkDialog = (props) => {

    //Destructure the props for shorter, simpler access...
    const { hideBulkDialog, showBulkDialog, data } = props

    const closeBulkDialog = () => {
        hideBulkDialog()
    }

    const dialogClass = showBulkDialog ? "dialog-background fade" : "dialog-background";

    return (
        <div className={dialogClass}>
        <div className="lock-container special">
                <div className="dialog-header">
                    <h3 className="nully"><i className="icofont-addons"></i> Bulk Update Status</h3>
                </div>
                <div className="dialog-body">
                    <div className="form-row-3-1 mb-1" style={{ marginTop: -10, marginBottom: 10 }}>
                        <table className="list-table">
                            <caption></caption>
                            <tbody>
                                <tr>
                                    <td><i className="icofont-check-circled success"></i> SUCCESS</td>
                                    <td className="align-center">{ data?.success }</td>
                                </tr>
                                <tr>
                                    <td><i className="icofont-close-circled danger"></i> FAILED</td>
                                    <td className="align-center">{ data?.fail }</td>
                                </tr>
                                <tr>
                                    <td><i className="icofont-plus-circle secondary"></i> TOTAL</td>
                                    <td className="align-center">{ data?.total }</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
                <div className="dialog-footer">
                    <button
                        onClick={closeBulkDialog}
                        id="btnCancel"
                        className="btn-grey"
                        style={{ marginTop: "-20"}}>
                        <i className="ion-android-exit"></i> Close
                    </button>
                </div>
            </div>
        </div>
    );
}
