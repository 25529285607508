import NavBar from "../../admin/assets/App/nav-bar"
import TopBar from "../../admin/assets/App/topbar"
import LockedScreen from "../assets/App/LockedScreen"

import { useState } from 'react'
import { Helmet } from 'react-helmet'
import QuickAccessForm from "./quick-access-form"

export const QuickAccess = (props) => {
    const [mainClass, setMainClass] = useState("main");

     /**
     * This quick access form
     * basically
     * allows us to have
     * a quick view of
     * application shortcuts.
     */

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Quick Access | NIBSS Document Management Hub Portal</title>
            </Helmet>
            <LockedScreen
                setIsLoaderVisible={props.setIsLoaderVisible}
                ShowToast={props.ShowToast}
            />
            <div className="cont-ainer">
                <NavBar sender="Welcome Page" />
                <div className={mainClass}>
                    <TopBar
                        setMainClass={value => setMainClass(value)}
                    />
                    <QuickAccessForm />

                </div>
            </div>
        </>
    )
}
