import NavBar from "../../../admin/assets/App/nav-bar"
import TopBar from "../../../admin/assets/App/topbar"
import LockedScreen from "../../../admin/assets/App/LockedScreen";
import CreateReferenceForm from "./create-reference-form";
import Footer from "../../../admin/assets/App/footer";
import { useState } from 'react';
import { Helmet } from "react-helmet";

export const CreateReference = (props) => {
    /**
     * This is the branch management container
     * for the main module....
     */
    const [mainClass, setMainClass] = useState("main");
    const hasBranch = (sessionStorage.getItem("session_user_type") === "Admin") ? "" : ` - ( ${sessionStorage.getItem('customer_branch')} Branch )`


    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>E-Reference/Create Reference | NIBSS Document Management Hub Portal</title>
            </Helmet>
            <LockedScreen
                setIsLoaderVisible={props.setIsLoaderVisible}
                ShowToast={props.ShowToast}
            />

            <div className="cont-ainer">
                <NavBar sender="Create e-Reference" />
                <div className={mainClass}>
                    <TopBar
                        setMainClass={value => setMainClass(value)}
                    />
                    <div className="page-title">
                        <h2><i className="icofont-mr icofont-letter"></i>Create E-Reference{hasBranch}</h2>
                    </div>
                    <div className="branch-summaries">
                        <div className="summaries">
                            <div className="title">
                                <h3>Create Reference</h3>
                            </div>
                            <CreateReferenceForm
                                ShowToast={props.ShowToast}
                                setIsLoaderVisible={props.setIsLoaderVisible}
                            />
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>

        </>
    )
}
