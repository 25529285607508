const TrappedCardsReportSearchList = [
    {
        id: 2,
        value: "Status",
        idVal: "status",        //This is the searchField in the database
        itemName: "Status",
    },
    {
        id: 3,
        value: "Issuing Bank",
        idVal: "issuingInstitution",
        itemName: "Issuing Bank",
    },
    {
        id: 4,
        value: "Acquiring Bank",
        idVal: "acquiringInstitution",
        itemName: "Acquiring Bank",
    },
    {
        id: 5,
        value: "Trapped Card No",
        idVal: "trappedCardNo",
        itemName: "Trapped Card No",
    },
    {
        id: 6,
        value: "Card Number",
        idVal: "cardNumber",
        itemName: "Card Number",
    },
]

export default TrappedCardsReportSearchList
