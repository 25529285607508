const QuickAccessList = [

     /**
     * This quick access list
     * basically
     * allows us to have
     * a quick view of
     * application shortcuts.
     *
     * We need to add the
     * table and the
     * table rows to
     * follow the
     * expected
     * description on
     * the requirements
     * document...
     */

     /**
     * This quick access list
     * basically
     * allows us to have
     * a quick view of
     * application shortcuts.
     *
     * We need to add the
     * table and the
     * table rows to
     * follow the
     * expected
     * description on
     * the requirements
     * document...
     */
    {
        id: 1,
        imgURL: "/assets/img/svg/dashboard.svg",
        title: "Admin Dashboard",
        description: "Statistics / Settings",
        pageURL: "/admin/dashboard",
        forInstitutionUser: false,
        adminOnly: true,
    },
    {
        id: 2,
        imgURL: "/assets/img/svg/audit_logs.svg",
        title: "Audit Logs",
        description: "Accesses / Usage",
        pageURL: "/admin/audit-logs",
        forInstitutionUser: false,
        adminOnly: true,
    },
    {
        id: 3,
        imgURL: "/assets/img/svg/institutions.svg",
        title: "Inst. Management",
        description: "Create / Manage Institutions",
        pageURL: "/institutions/institutions",
        forInstitutionUser: false,
        adminOnly: true,
    },
    {
        id: 4,
        imgURL: "/assets/img/svg/branch.svg",
        title: "Branch Management",
        description: "Create / Manage Branches",
        pageURL: "/branch-management/branch-management",
        forInstitutionUser: true,
        adminOnly: true,
    },
    {
        id: 5,
        imgURL: "/assets/img/svg/system-users.svg",
        title: "User Management",
        description: "Create / Manage Users",
        pageURL: "/users/users-management",
        forInstitutionUser: false,
        adminOnly: true,

    },
    {
        id: 6,
        imgURL: "/assets/img/svg/settings.svg",
        title: "Configuration",
        description: "Application Configuration",
        pageURL: "/config/admin-group-emails",
        forInstitutionUser: false,
        adminOnly: true,

    },
    {
        id: 7,
        imgURL: "/assets/img/svg/reference.svg",
        title: "e-Reference",
        description: "e-Reference Module",
        pageURL: "/e-reference/create-reference",
        forInstitutionUser: true,
        adminOnly: false,

    },
    {
        id: 8,
        imgURL: "/assets/img/svg/basicCreditCard.svg",
        title: "Trapped Cards",
        description: "Trapped Cards Module",
        pageURL: "/trapped-cards/log-trapped-card",
        forInstitutionUser: true,
        adminOnly: false,

    },

]

export default QuickAccessList
