import { SplitDate } from "../../admin/assets/App/split-date";

export const loadEDMMSExportTable = itemsToLoad => {
    /**
     * Iterate through the items
     * in the list and create an export object...
     * this is what will be eventually
     * returned by the function...
     *
     *
     */
    const exportObject = [];

    itemsToLoad.forEach(item => {
        const output = {
            "Mandate Code": item.mandateCode,
            "Created At": SplitDate(item.createdAt).dateTime,
            "Registrar": item.registrar || "-",
            "Institution": item.institution.name,
            "Branch": item.branch?.branchName,
            "Surname": item.companyName,
            "Othernames": item.othernames || "-",
            "BVN": item.bvn || "-",
            "CHN": item.chn || "-",
            "Shareholder A/C Number": item.paymentAccountNo
        }

        exportObject.push(output)
    })

    return exportObject;
}
