import React from "react";
import { Enums } from "../../_common/enums";
import ApiPath from "../../admin/assets/App/api-path";
import Pagination from "../../admin/assets/App/Pagination";
import SearchComponent from "../../admin/assets/App/SearchComponent";
import { EClipsSearchList } from "../../admin/assets/data/EClipsSearchList";
import { IncomingEClipsTableRows } from "./incoming-e-clips-table-rows";
import DynamicSort from "../../admin/assets/App/DynamicSort";
import GeneratePublicURL from "../outgoing-e-clips/generate-public-url";
import { MessageBoxType } from "../../_common/constants"
import ViewEClipsDetailsDialog from "../outgoing-e-clips/view-e-clips-details-dialog"
import { TreatEClipsDialog } from "./treat-e-clips-dialog";
import { SplitDate } from "../../admin/assets/App/split-date";
import CapitalizeFirstLetter from "../../admin/assets/App/CapitalizeFirstLetter";
import objectToCSV from "../../admin/assets/App/object-to-csv";
import ExportToCSVFile from "../../admin/assets/App/ExportToCSVFile";
import { DateRange } from "../../components/date-range";

export class IncomingEClipsForm extends React.Component {
    constructor() {
        super();
        this.state = {
            actionSuccessful: false,
            apiData: [],
            metaData: {},
            institutionsArray: [],
            branches: [],
            fetchSuccessful: false,
            rowData: [],
            displayTreatDialog: false,
            displayPublicURLDialog: false,
            viewDetailsDialog: false,

            //Search parameters
            searchValue: "",
            searchField: "",
            startDate: "",
            endDate: "",

            //Pagination values
            lastPage: 1,
            arrayPages: [],
            currentSelectedPage: 1,
            perPage: localStorage.getItem("items_per_page") || 20,
            totalItems: 0,
        }

        this.token = sessionStorage.getItem("session_token");
    }

    fetchInstitutions = () => {
        const options = {
            method: 'GET',
            headers: Enums.headersData(this.token),
        }

        fetch(ApiPath + 'api/institution/fetch-institutions', options)
            ?.then(res => {
                return res.json();
            })
            .then(response => {
                if (response) {
                    if (response.ok) {
                        this.setState({
                            institutionsArray: response.body.data.institutions.sort(DynamicSort("name")),
                        })
                    } else {
                        this.props.ShowToast(response.message, MessageBoxType.EXCLAMATION)
                    }
                }
            })
    }

    fetchIncomingEClips = () => {
        this.props.setIsLoaderVisible(true);

        let searchValue = this.state.searchValue;

        if (this.state.searchField === "branch") {
            searchValue = this.state.branches.find(item => item.branchName === this.state.searchValue)?.slug;
        } else if (this.state.searchField === "acquiringInstitution") {
            searchValue = this.state.institutionsArray.find(item => item.name === this.state.searchValue)?.slug;
        }

        const dateRangeFilter = (this.state.startDate !== '') ? `&startDate=${this.state.startDate}&endDate=${this.state.endDate}` : '';

        const options = {
            method: 'GET',
            headers: Enums.headersData(this.token),
        }

        fetch(`${ApiPath}api/eclip/incoming-eclip?page=${this.state.currentSelectedPage}` +
            `&${this.state.searchField}=${searchValue}&limit=${this.state.perPage}${dateRangeFilter}`, options)
            ?.then(response => {
                return response.json();
            })
            .then(res => {
                this.props.setIsLoaderVisible(false)
                if (res && res.ok === true) {
                    this?.setState({
                        actionSuccessful: false,
                        fetchSuccessful: true,
                        metaData: res.body.data?.meta,
                        apiData: res.body.data?.data,
                        arrayPages: [],
                    }, () => {
                        const preArray = [];
                        this.setState({
                            currentSelectedPage: this.state.metaData?.current_page,
                            lastPage: this.state.metaData?.last_page,
                            totalItems: this.state.metaData?.total,
                        }, () => {
                            //Set this array for the looping
                            for (let i = 1; i <= this.state.lastPage; i++) {
                                preArray.push({ "id": i, "name": "Item " + i });
                            }

                            this.setState({ arrayPages: preArray })
                        })
                    })

                } else {
                    this.props.ShowToast(res?.message, "exclamation");
                    this.props.setIsLoaderVisible(false);

                }
            })
    }

    ExportData = () => {
        /**
         * Export the output in
         * CSV format...
         *
         * Exports the data in pre-defined format...
         */
        const previousPerPage = this.state.perPage;
        this.setState({ perPage: 200000 }, () => this.fetchIncomingEClips())

        const dataToExport = [];
        this.state.apiData.forEach((item, index) => {
            //First, get individual reference details

            let thisObject = {
                "S/N": index + 1,
                "Request ID": item.requestId,
                "Transaction Ref. Type": item.referenceType,
                "Acquiring Institution": item.acquiringInstitution?.name,
                "Reference": item.reference,
                "Created": SplitDate(item.createdAt).dateTime || "-",
                "Updated At": SplitDate(item.updatedAt).dateTime,
                "Is Overdue?": CapitalizeFirstLetter(item.isOverDue),
                "Status": CapitalizeFirstLetter(item.status),
            }

            dataToExport.push(thisObject)

        })

        const csvData = objectToCSV(dataToExport);
        ExportToCSVFile(csvData, "e-Clips.csv");

        this.props.ShowToast("Incoming e-Clips exported successfully! Open downloads folder to preview...", "information");

        //Return the number of items per page
        this.setState({ perPage: previousPerPage })
        this.fetchIncomingEClips();

    }

    componentDidMount() {
        this.fetchIncomingEClips();
        this.fetchInstitutions()
    }

    filterRecords = () => {
        this.fetchIncomingEClips();
    }

    RefreshForm = () => {
        window.location.reload();
    }

    render() {

        let counter = 0;
        const tblRows = this.state.fetchSuccessful && this.state.apiData?.map(item => {
            counter++;
            const itemCounter = (this.state.currentSelectedPage - 1) * this.state.perPage + counter;

            return (<IncomingEClipsTableRows
                counter={itemCounter}
                item={item}
                ShowToast={this.props.ShowToast}
                setIsLoaderVisible={this.props.setIsLoaderVisible}
                key={counter}
                showTreatDialog={() => this.setState({ displayTreatDialog: true })}
                setActionSuccessful={value => this.setState({ actionSuccessful: value })}
                setRowData={value => this.setState({ rowData: value })}
                setDisplayPublicURL={val => this.setState({ displayPublicURLDialog: val })}
                showViewMoreDetails={() => this.setState({ viewDetailsDialog: true })}

            />)
        })

        if (this.state.actionSuccessful) {
            this.fetchIncomingEClips()
            this.setState({
                actionSuccessful: false,
            })
        }

        //Craete search list items here later on...
        const status = [{ item_name: "Pending" }, { item_name: "Accepted" }, { item_name: "Rejected" }]
        const overdueStatus = [{ item_name: "Yes" }, { item_name: "No" }]
        const referenceType = [{ item_name: "arbiter-logcode" }, { item_name: "switch-serial" }, { item_name: "other" }]
        const disputeRef = this.state.apiData.map(item => {
            return ({ item_name: item.reference })
        })

        const institutionNames = this.state.institutionsArray.map(item => {
            return ({ item_name: item.name })
        })

        const requestId = this.state.apiData.map(item => {
            return ({ item_name: item.requestId })
        })

        return (
            <>
                <div className="title">
                    <DateRange
                        ShowToast={this.props.ShowToast}
                        setDateTo={value => this.setState({ endDate: value })}
                        callback={this.filterRecords}
                        setDateFrom={value => this.setState({ startDate: value })}
                        maxDays={60}
                    />
                    <SearchComponent
                        ReferenceSearchList={EClipsSearchList} //Remove the createdAt / Date Range from this list...
                        setSearchField={val => this.setState({ searchField: val })}
                        setSearchValue={val => this.setState({ searchValue: val })}
                        filterRecords={this.filterRecords}
                        inputList={{ status, institutionNames, referenceType, overdueStatus, requestId, disputeRef }}
                        ShowToast={this.props.ShowToast}
                        daysLimit={60}
                    />
                    <div className="buttons">
                        <button
                            id="btnRefresh"
                            data-title="Refresh"
                            className="btn-grey t-tip mr-1"
                            type="button"
                            onClick={this.RefreshForm}>
                            <i className="icofont-refresh"></i> Refresh
                        </button>
                        <button
                            type="button"
                            data-title="Export records as CSV file"
                            id="btnExport"
                            className="btn-green t-tip"
                            onClick={this.ExportData}>
                            <i className="icofont-airplane-alt"></i> Export
                        </button>
                    </div>
                </div>
                <div className="table-container alt">
                    <table className="bordered-table">
                        <caption></caption>
                        <thead className='t-head'>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col" className="align-left">REQUEST ID</th>
                                <th scope="col" className="align-left">TYPE</th>
                                <th scope="col" className="align-left">REQUESTING INSTITUTION</th>
                                <th scope="col" className="align-left">CREATED</th>
                                <th scope="col" className="align-left">DISPUTE REF</th>
                                <th scope="col" className="align-center">LAST MODIFIED</th>
                                <th scope="col" className="align-center">OVERDUE</th>
                                <th scope="col" className="align-center" width="120">STATUS</th>
                                <th scope="col" className="align-center">ACTION</th>
                            </tr>
                        </thead>
                        <tbody id="incoming_e_clips_table">
                            {tblRows}
                        </tbody>
                    </table>
                </div>
                <Pagination
                    setPerPage={value => this.setState({ perPage: value })}
                    lastPage={this.state.lastPage}
                    setCurrentSelectedPage={value => this.setState({ currentSelectedPage: value })}
                    currentSelectedPage={this.state.currentSelectedPage}
                    perPage={this.state.perPage}
                    setRefreshForm={value => this.setState({ actionSuccessful: value })}
                    arrayPages={this.state.arrayPages}
                />

                {
                    this.state.displayPublicURLDialog && <GeneratePublicURL
                        itemSlug={this.state.rowData.slug}
                        item={this.state.rowData}
                        ShowToast={this.props.ShowToast}
                        setIsLoaderVisible={this.props.setIsLoaderVisible}
                        displayForm={this.state.displayPublicURLDialog}
                        setDisplayPublicURL={value => this.setState({ displayPublicURLDialog: value })}
                        setActionSuccessful={() => this.setState({ actionSuccessful: true })}

                    />
                }

                {
                    this.state.viewDetailsDialog && <ViewEClipsDetailsDialog
                        hideDisplayView={() => this.setState({ viewDetailsDialog: false })}
                        displayView={this.state.viewDetailsDialog}
                        data={this.state.rowData}
                    />
                }

                {
                    this.state.displayTreatDialog && <TreatEClipsDialog
                        displayView={this.state.displayTreatDialog}
                        data={this.state.rowData}
                        hideDisplayView={() => this.setState({ displayTreatDialog: false })}
                        setActionSuccessful={() => this.setState({ actionSuccessful: true })}
                        ShowToast={this.props.ShowToast}
                        setIsLoaderVisible={this.props.setIsLoaderVisible}

                    />
                }

            </>
        )
    }
}
