import LoginHeader from "./login-header"
import LoginValidateBody from './login-validate-body'

export const LoginValidate = (props) => {

    return (
        <>
            <div className="login-container">
                <h1>Document Hub Portal</h1>
                <div className="login">
                    <LoginHeader mainImageSrc="assets/img/svg/two_factor_auth.svg" />
                    <LoginValidateBody
                        ShowToast={props.ShowToast}
                        setIsLoaderVisible={props.setIsLoaderVisible}
                    />
                </div>
            </div>
        </>
    )
}
