import React from "react"
import ImageDialog from "../create-reference/image-dialog"
import Badges from "../../../admin/assets/App/Badges"
import CapitalizeFirstLetter from "../../../admin/assets/App/CapitalizeFirstLetter"
import { SplitDate } from "../../../admin/assets/App/split-date"
import ApiPath from "../../../admin/assets/App/api-path"

class ReferenceReportsTableRow extends React.Component {
    /**
     * The recent updates here
     * were made to ensure
     * that the reports comments
     * are displayed with
     * the appropriate
     * endpoint(s)...
     */
    constructor() {
        super()
        this.state = {
            itemDetails: [],
            displayImage: false,
            uploadedFile: {},
            mandateExtension: "",
            mandatePath: "",
            apiRefPath: sessionStorage.getItem("is_nibss_user") === "NIBSS"
                ? "api/e-reference/nibss-user-view-e-reference-detail/"
                : "api/e-reference/view-e-reference-detail/"
        }
    }

    componentDidMount() {
        //set the mandate Extension state variable...
        this.setState({
            mandateExtension: this.props.item?.mandatePath?.split(".")[1],
        })

    }

    getReferenceComments = () => {
        // Fetch the comments
        // of the said reference,
        // and install in the comments place...
        const optns = {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                'Content-Type': 'application/json',
                'Cache-Control': 'public, max-age=0'
            },
            method: 'GET'
        }

        //Trigger the API fetch for the required data...
        fetch(`${ApiPath}api/e-reference/get-comments-for-nibss-user/${this.props.item.slug}`, optns)
            ?.then(answer => (answer.json()))
            ?.then(answer => {
                if (answer && answer.ok) {
                    this.props.setCommentsData(answer.body.comments?.data)
                }
            })

    }

    PreviewAttachment = () => {

        //Fetch the comments for the dialog table...
        this.getReferenceComments()

        //The details part...
        this.fetchDetails();

    }

    fetchDetails = () => {
        const slug = this.props.item.slug;
        const options = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                'Content-Type': 'application/json',
                'Cache-Control': 'public, max-age=0'
            }
        }

        this.props.setIsLoaderVisible(true);
        fetch(`${ApiPath}${this.state.apiRefPath}${slug}`, options)
            ?.then(res => (res.json()))
            .then(response => {
                this.props.setIsLoaderVisible(false);

                if (response && response.ok) {
                    this.props.setViewData(response.body.data);
                    this.props.setDisplayView(true)

                    // // // Set the data to the mandatePath object
                    // // this.setState({
                    // //     uploadedFile: response.body.data?.mandatePath,
                    // //     displayImage: true
                    // // })

                    this.props.setViewData(response.body.data);
                    this.props.setDisplayView(true);

                } else {
                    this.props.ShowToast(response.message, "exclamation");
                }

            }).catch(error => {
                this.props.setIsLoaderVisible(false);
                this.props.ShowToast(error.message, "exclamation");
            })

    }

    render() {
        const statusClass = Badges(this.props.item?.actionStatus);
        const actionStatus = CapitalizeFirstLetter(this.props.item?.actionStatus);
        const isOverDue = CapitalizeFirstLetter(this.props.item.isOverDue);
        const acceptRejectUser = this.props.item?.referenceLog?.acceptedByUser
            ? this.props.item?.referenceLog?.acceptedByUser?.email
            : this.props.item?.referenceLog?.rejectedByUser?.email;

        // Date and time have been corrected and updated
        // in the columns to show date and time, instead of just
        // displaying them as context/hover titles...

        return (
            <>
                <tr>
                    <td className="align-center">{this.props.counter}.</td>
                    <td title={this.props.item?.referenceNo}>{this.props.item?.referenceNo?.substring(0, 10)}...</td>
                    <td><span className={statusClass}>{actionStatus}</span></td>
                    <td>{this.props.item?.customerBranch?.institution?.name || sessionStorage.getItem("institution")}</td>
                    <td>{this.props.item?.customerBranch?.branchName}</td>
                    <td>{this.props.item?.receiverInstitution?.name}</td>
                    <td>{this.props.item?.receiverBranch?.branchName}</td>
                    <td>{this.props.item?.refereeAccountName}</td>
                    <td>{this.props.item?.refereeAccountNumber}</td>
                    <td title={SplitDate(this.props.item?.createdAt).dateTime}>{SplitDate(this.props.item?.createdAt).dateTime}</td>
                    <td title={SplitDate(this.props.item?.createdAt).dateTime}>
                        {SplitDate(this.props.item?.createdAt).dateTime}
                    </td>
                    <td>{this.props.item?.createdByUser?.email}</td>
                    <td>{this.props.item?.referenceLog?.submittedByUser?.email}</td>
                    <td>{acceptRejectUser}</td>
                    <td>{this.props.items?.referenceLog?.approvedByUser?.email}</td>
                    <td className="align-center">{isOverDue}</td>
                    <td>{SplitDate(this.props.item?.dueDate).dateTime}</td>
                    <td className="align-center" width="80">
                        <button
                            id="btnPreview"
                            className="btn-grey"
                            title="Preview Attachment"
                            onClick={this.PreviewAttachment}><i className="icofont-picture"></i> Preview
                        </button>
                    </td>

                </tr>
                {
                    this.state.displayImage && this.state.uploadedFile && this.state.mandateExtension !== "" &&
                    <ImageDialog
                        isURL={false}
                        src={this.state.uploadedFile}
                        mandateExtension={this.state.mandateExtension}
                        setImageDisplay={value => this.setState({ displayImage: value })}
                    />
                }

            </>
        );
    }
}

export default ReferenceReportsTableRow
