export const EClipsSearchList = [
    {
        id: 1,
        idVal: "status",        //This is the searchField in the database
        value: "Status",
        itemName: "Status",
    },
    {
        id: 2,
        idVal: "acquiringInstitution",
        value: "Req. Institution",
        itemName: "Requesting Institution",
    },
    {
        id: 3,
        idVal: "referenceType",
        value: "Request Type",
        itemName: "Request Type",
    },
    {
        id: 4,
        idVal: "isOverdue",
        value: "Is Overdue",
        itemName: "Is Overdue",
    },
    {
        id: 5,
        idVal: "requestId",
        value: "Request ID",
        itemName: "Request ID",
    },
    {
        id: 6,
        idVal: "reference",
        value: "Dispute Ref.",
        itemName: "Dispute Ref.",
    },

]
