const GetServices = (serviceName) => {

    let service;

    switch (serviceName) {
        case "E-reference":
            service = "e-Reference";
            break;
        case "Trapped-cards":
            service = "Trapped Cards";
            break;
        case "EDMMS":
            service = "e-DMMS";
            break;
        default:
            service = "e-Clips";
            break;
    }
    return (service);

}

export default GetServices
