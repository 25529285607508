import React from 'react'
import { SplitDate } from '../../../admin/assets/App/split-date'

export const CommentsDialog = ({ data, displayVisible, hideDialog }) => {

    return (
        <div className={(displayVisible) ? "modal fade show" : "modal fade"}>
            <div className='modal-content' style={{ maxWidth: '750px' }}>
                <div className='modal-header'>
                    <h2 className='nully' style={{ fontSize: 14 }}>Comments Details</h2>
                </div>
                <div className='modal-body'>
                    <table className='bordered-table mb-2'>
                        <caption></caption>
                        <thead>
                            <tr>
                                <th scope='col' className='align-left'>S/N</th>
                                <th scope='col' className='align-left'>Comments</th>
                                <th scope='col' className='align-left'>Created By</th>
                                <th scope='col' className='align-left'>Created At</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}.</td>
                                            <td>{item.body}</td>
                                            <td>{item.createdByUser?.email}</td>
                                            <td>{SplitDate(item.createdAt).dateTime}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className='modal-footer'>
                    <hr />
                    <button
                        className='btn-grey mr-2'
                        onClick={hideDialog}
                        id='btnCloseCommentsDialog'
                    >
                        <i className='icofont-exit' /> Close
                    </button>
                </div>
            </div>
        </div>
    )
}
