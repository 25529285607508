import NavBar from "../../../admin/assets/App/nav-bar"
import TopBar from "../../../admin/assets/App/topbar"
import LockedScreen from "../../../admin/assets/App/LockedScreen";
import ReferenceReportsForm from "./reference-reports-form";

import { useState } from 'react';
import { Helmet } from "react-helmet";
import Footer from "../../../admin/assets/App/footer";

export const ReferenceReports = (props) => {

    /**
     * This is the reference reports
     * container
     * for the main module....
     */

    const [mainClass, setMainClass] = useState("main");

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>E-Reference/Reference Reports | NIBSS Document Management Hub Portal</title>
            </Helmet>
            <LockedScreen
                setIsLoaderVisible={props.setIsLoaderVisible}
                ShowToast={props.ShowToast}
            />
            <div className="cont-ainer">
                <NavBar sender="e-Reference Reports" />
                <div className={mainClass}>
                    <TopBar
                        setMainClass={value => setMainClass(value)}
                    />
                    <div className="page-title">
                        <h2><i className="bx bxs-book-content"></i>Reference Reports</h2>
                    </div>
                    <div className="branch-summaries">
                        <div className="summaries">
                            <ReferenceReportsForm
                                ShowToast={props.ShowToast}
                                setIsLoaderVisible={props.setIsLoaderVisible}
                            />
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>

        </>
    )
}
