import LoginHeader from "./login-header"
import LoginFormData from "./login-form-data"

export const NibssUserLogin = (props) => {
    const userType = true;

    return (
        <>
            <div className="login-container">
                <h1>Document Hub Portal</h1>
                <div className="login">
                    <LoginHeader mainImageSrc="assets/img/svg/secure_login.svg" />
                    <LoginFormData
                        isNibssUser={userType}
                        ShowToast={props.ShowToast}
                        setIsLoaderVisible={props.setIsLoaderVisible}
                    />
                </div>
            </div>
        </>
    )
}
