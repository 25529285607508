const ReferenceReportsSummary = (props) => {

    return (
        <div className="overDue Summary">
            {/* Overdue items summary for Reference Reports */}
            <table className="outgoing-ref-table">
                <caption>References Summary (Current Page)</caption>
                <tbody>
                    <tr>
                        <td colSpan="2" className="rowBold">Total number of records</td>
                        <td>{props.totalItems}</td>
                    </tr>
                    {/* <!--Incoming Items--> */}
                    <tr>
                        <td rowSpan="3" className="rowBold">Incoming Items</td>
                        <td className="rowBold">Overdue</td>
                        <td>{props.summary?.incomingOverReferenceCount}</td>
                    </tr>
                    <tr>
                        <td className="rowBold">Pending</td>
                        <td>{props.summary?.incomingPendingReferenceCount}</td>
                    </tr>
                    <tr>
                        <td className="rowBold">Completed</td>
                        <td>{props.summary?.incomingCompletedReferenceCount}</td>
                    </tr>
                    {/* Outgoing */}
                    <tr>
                        <td rowSpan="3" className="rowBold">Outgoing Items</td>
                        <td className="rowBold">Overdue</td>
                        <td>{props.summary?.outGoingOverReferenceCount}</td>
                    </tr>
                    <tr>
                        <td className="rowBold">Pending</td>
                        <td>{props.summary?.outgoingPendingReferenceCount}</td>
                    </tr>
                    {/* Completed */}
                    <tr>
                        <td className="rowBold">Approved</td>
                        <td>{props.summary?.outgoingApproveReferenceCount}</td>
                    </tr>
                </tbody>

            </table>

        </div>
    )
}

export default ReferenceReportsSummary
