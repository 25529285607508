import React from "react";
import SearchList from "./SearchList";
import DynamicSort from "./DynamicSort"
import DatePicker from "./DatePicker";
import DateRangePicker from "./DateRangePicker";

/**
 * This component is the general
 * search component displayed on
 * the top of
 * every table,
 * used for searching and filtering
 * data based on input specifications...
 * It is an original component developed by
 * BigBenny, for Spexes Consulting LTD
 */

class SearchComponent extends React.Component {

    constructor() {
        super()
        this.state = {
            outputList: [],
            listVisible: false,
            searchTitle: "<Select>",
            searchField: "<Select>",
            searchValue: "",
            searchGroupState: false,
            displayDatePicker: false,
            displayDateRangePicker: false,
            maxDays: 30,
        }
    }

    componentDidMount() {
        //Destructure the input object...
        this.checkSelectedFieldAndLoadList();
    }

    checkSelectedFieldAndLoadList = () => {

        let selectedOutput = [];

        switch (this.state.searchField) {
            case "isOverdue":
                selectedOutput = this.props.inputList?.overdueStatus;
                break;
            case "customerAccountName":
                selectedOutput = this.props.inputList?.customerAccountName;
                break;
            case "customerAccountNo":
                selectedOutput = this.props.inputList?.customerAccountNo;
                break;
            case "bin":
                selectedOutput = this.props.inputList?.cardBin;
                break;
            case "institution": case "refereeInstitution": case "customerInstitution":
            case "issuingInstitution": case "acquiringInstitution": case "receivingInstitution":
                selectedOutput = this.props.inputList?.institutionNames;
                break;
            case "refereeAccountName":
                selectedOutput = this.props.inputList?.refereeAccountName;
                break;
            case "refereeAccountNo": case "paymentAccountNo":
                selectedOutput = this.props.inputList?.refereeAccountNo;
                break;
            case "referenceType":
                selectedOutput = this.props.inputList?.referenceType;
                break;
            case "branchName": case "branch":
                selectedOutput = this.props.inputList?.branchNames;
                break;
            case "lga":
                selectedOutput = this.props.inputList?.LGAs;
                break;
            case "state":
                selectedOutput = this.props.inputList?.statesArray;
                break;
            case "status":
                selectedOutput = this.props.inputList?.status;
                break;
            case "actionStatus":
                selectedOutput = this.props.inputList?.actionStatus;
                break;
            case "isOverDue":
                selectedOutput = this.props.inputList?.isOverDue;
                break;
            case "contactEmail": case "email":
                selectedOutput = this.props.inputList?.contactEmails;
                break;
            case "firstName": case "lastName":
                selectedOutput = this.props.inputList?.contactNames;
                break;
            case "phoneNo":
                selectedOutput = this.props.inputList?.phoneNos;
                break;
            case "referenceCode": case "mandateCode":
                selectedOutput = this.props.inputList?.referenceNo;
                break;
            case "reference":
                selectedOutput = this.props.inputList?.disputeRef;
                break;
            case "service":
                selectedOutput = this.props.inputList?.services;
                break;
            case "trappedCardNo":
                selectedOutput = this.props.inputList?.trappedCardNo;
                break;
            case "cardNumber":
                selectedOutput = this.props.inputList?.cardNumber;
                break;
            case "description":
                selectedOutput = this.props.inputList?.logType;
                break;
            case "ipAddress":
                selectedOutput = this.props.inputList?.ipAddresses;
                break;
            case "causer":
                selectedOutput = this.props.inputList?.causer;
                break;
            case "companyName":
                selectedOutput = this.props.inputList?.companyname;
                break;
            case "companyNumber":
                selectedOutput = this.props.inputList?.companyNumber;
                break;
            case "rcNo":
                selectedOutput = this.props.inputList?.rcNo;
                break;
            case "bvn":
                selectedOutput = this.props.inputList?.bvnArray;
                break;
            case "chn":
                selectedOutput = this.props.inputList?.chnArray;
                break;
            default:
                selectedOutput = [];
                break;
        }

        // If default is selected, then check if te searchfield matches this...
        // (Logic to reduce non-empty switch cases above to 30)
        if (selectedOutput?.length === 0) {
            if (this.state.searchField === "requestId") {
                selectedOutput = this.props.inputList?.requestId;
            }
        }

        this.setState({
            outputList: selectedOutput?.sort(DynamicSort("item_name")),
        })

    }

    handleChange = e => {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
            //searchGroupState: false,
        }, () => {
            if (this.state.outputList !== []) {
                this.setState({
                    searchGroupState: false
                })
            }
            this.checkListVisibility()
            this.props.setSearchValue(value);
        });
    }

    checkListVisibility = () => {
        if (this.state.searchValue.length > 0) {
            this.ShowList()
        } else {
            this.HideList();
        }
    }

    ShowList = () => {
        this.setState({ listVisible: true })
    }

    HideList = () => {
        this.setState({ listVisible: false })
    }

    selectItem = value => {
        this.setState({
            searchValue: value,
        }, () => {
            this.HideList()
            this.props.setSearchValue(value);
            document.getElementById("txtSearch")?.focus();
        })
    }

    toggleSearchGroup = () => {
        this.setState({
            searchGroupState: !this.state.searchGroupState,
        })
    }

    ChangeSearchCriteria = e => {
        this.setState({
            searchField: e?.target?.id,
            searchTitle: e?.target?.value,
        }, () => {
            this.toggleSearchGroup()
            this.checkSelectedFieldAndLoadList();
            this.SearchByDatePicker();
            //Set the parent state variables
            this.props.setSearchField(e?.target?.id)

            //Also determine the spacing of the margin-left of the input...
            // // const buttonWidth = document.getElementById("btnSearchBy").innerHTML.length * 5;
            // // document.getElementById("txtSearch").style.paddingLeft = `calc(${buttonWidth} + 60px)!important`;

        })
    }

    SearchByDatePicker = () => {
        if (this.state.searchField === "endDate" || this.state.searchField === "transactionDate") {
            this.setState({
                displayDatePicker: true,
            })

        } else if (this.state.searchField === "createdAt") {
            this.setState({ displayDateRangePicker: true })
        }
    }

    filterRecords = () => {
        //This works as the search button is clicked (or enter key is pressed)
        //Ensure a proper search title has been selected
        if (this.state.searchTitle === "<Select>") {
            this.props.ShowToast("Select a valid search title from the list.", "exclamation");
            this.setState({
                searchGroupState: true,
            })
            return;
        }

        const txtSearch = document.getElementById("txtSearch");

        this.setState({
            searchValue: txtSearch?.value,
        }, () => {
            //Create variations for the case of date range...
            if (this.state.searchField === "createdAt" && txtSearch.value.indexOf("to") > -1) {
                //This is for a date-range situation
                //Thus split into the desired parts
                const splitDate = txtSearch?.value.split("to");

                //Set the split values...
                this.props.setSearchValue(splitDate[0].trim())
                this.props.setSearchValue2(splitDate[1].trim())

            } else {
                //Just set only the regular value...
                this.props.setSearchValue(txtSearch?.value);

            }

            this.props.filterRecords();
            this.HideList();
        })

    }

    render() {

        const filteredList = this.state.outputList?.filter(item => item.item_name.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) > -1);
        const sortedList = filteredList?.map((item, index) => {
            //Later, highlight/bolden the match...
            return (
                <div
                    key={index}
                    className="list-item"
                    onClick={() => this.selectItem(item.item_name)}
                >
                    {item.item_name}
                </div>
            )

        })

        const searchGroupClass = this.state.searchGroupState ? "search-group active" : "search-group";
        const searchUserPlaceholder = "Filter by " + this.state.searchTitle;
        const chevronClass = this.state.searchGroupState ? "ion-chevron-up" : "ion-chevron-down"

        return (
            <div className="search">
                <div className={this.state.listVisible ? "filter-list" : "filter-list d-none"}>
                    {sortedList}
                </div>
                <input
                    type="text"
                    value={this.state.searchValue}
                    id="txtSearch"
                    name="searchValue"
                    onKeyDown={e => {
                        if (e.key === "Enter") {
                            this.filterRecords();
                        } else if (e.key === "Escape") {
                            //Hide all dialogs...
                            this.HideList();
                            this.setState({ searchGroupState: false })
                        }
                    }}
                    onChange={this.handleChange}
                    className="filter-search"
                    autoComplete="off"
                    placeholder={searchUserPlaceholder}
                />
                <button
                    id="btnSearchBy"
                    onClick={this.toggleSearchGroup}>
                    <span id="searchButtonText">
                        <b>{this.state.searchTitle}</b>
                    </span> <i className={chevronClass}> </i>
                    <span id="spanToast" className={searchGroupClass} style={{ width: 270 }}>
                        {
                            this.props.ReferenceSearchList.map(item => {
                                return (
                                    <SearchList
                                        key={item.id}
                                        id={item.idVal}
                                        value={item.value}
                                        filterRecords={this.filterRecords}
                                        ChangeSearchCriteria={this.ChangeSearchCriteria}
                                        searchField={this.state.searchField}
                                        itemName={item.itemName}
                                    />
                                )
                            })
                        }
                    </span>
                </button>
                <button
                    id="btnSearchUser"
                    className="t-tip"
                    onClick={this.filterRecords}
                    data-title="Filter records based on input">
                    <i className="icofont-search"></i>
                </button>
                {
                    this.state.displayDatePicker && <DatePicker
                        displayDatePicker={this.state.displayDatePicker}
                        setDisplayDatePicker={value => this.setState({ displayDatePicker: value })}
                        handleChange={this.handleChange}
                        setOutput={val => this.setState({ searchValue: val })}
                    />
                }
                {
                    this.state.displayDateRangePicker && <DateRangePicker
                        displayDateRangePicker={this.state.displayDateRangePicker}
                        setDisplayDateRangePicker={value => this.setState({ displayDateRangePicker: value })}
                        setOutput={val => this.setState({ searchValue: val })}
                        ShowToast={this.props.ShowToast}
                        maxDays={this.props.daysLimit}
                    />
                }
            </div>

        );
    }

}

export default SearchComponent

/**
     * This create trapped cards form
     * basically
     * allows us to have
     * a quick view of
     * application shortcuts.
     *
     * We need to add the
     * table and the
     * table rows to
     * follow the
     * expected
     * description on
     * the requirements
     * document...
     */
/**
* This create trapped cards form
* basically
* allows us to have
* a quick view of
* application shortcuts.
*
* We need to add the
* table and the
* table rows to
* follow the
* expected
* description on
* the requirements
* document...
*/
/**
* This create trapped cards form
* basically
* allows us to have
* a quick view of
* application shortcuts.
*
* We need to add the
* table and the
* table rows to
* follow the
* expected
* description on
* the requirements
* document...
*/
