import React from "react";
import ApiPath from "../../../admin/assets/App/api-path";

class CancelReferenceDialog extends React.Component {

    constructor() {
        super()
        this.state = {
            txtComments: "",
        }

        this.headersData = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
            'Cache-Control': 'public, max-age=0'
        }
    }

    submitComments = () => {
        //Proceed to save the comments using the endpoint...

        if (this.state.txtComments.length === 0) {
            this.props.ShowToast("Enter the reason for cancellation in the comments box.", "exclamation");
            return;
        }

        //Once this has been sorted, proceed to submit comments...
        this.props.setIsLoaderVisible(true);

        const data = { "slug": this.props.data?.slug, "comment": this.state.txtComments }
        const options = {
            headers: this.headersData,
            method: 'POST',
            body: JSON.stringify(data),
        }

        fetch(ApiPath + "api/e-reference/cancel-outgoing-e-reference", options)
            ?.then(res => {
                return res.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false);
                if (response) {
                    if (response.ok) {
                        //Cancelled successfully
                        this.props.ShowToast("Outgoing Reference Cancelled!", "success");
                        this.props.setDisplayCancelDialog(false);
                        this.props.setActionSuccessful(true);

                    } else {
                        this.props.ShowToast(response.message, "exclamation");
                    }
                } else {
                    this.props.ShowToast("Invalid input value(s).", "exclamation");
                }
            })
            .catch(error => {
                this.props.setIsLoaderVisible(false);
                this.props.ShowToast(error.message, "exclamation");

            })

    }

    closeForm = () => {
        this.props.setDisplayCancelDialog(false)
    }

    render() {

        const dialogClass = this.props.displayCancelDialog ? "modal fade show" : "modal fade";

        return (
            <div className={dialogClass}>
                <div className="lock-container">
                    <div className="dialog-header">
                        <h2 className="nully"><i className="bx bx-recycle"></i> Cancel Outgoing Reference</h2>
                    </div>
                    <div className="dialog-body">
                        <p style={{ color: "ButtonText" }}>
                            Enter reason for cancellation:
                        </p>
                        <div className="form-row-3-1">
                            <div className="input-group">
                                <textarea
                                    id="txtComments"
                                    name="txtComments"
                                    className="form-control"
                                    rows="2"
                                    value={this.state.txtComments}
                                    onChange={e => this.setState({ txtComments: e.target?.value })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="dialog-footer">
                        <button
                            id="btnSubmit"
                            className="btn-green mr-1"
                            onClick={this.submitComments}>
                            <i className="icofont-check"></i> Proceed
                        </button>
                        <button
                            id="btnClose"
                            className="btn-grey"
                            onClick={this.closeForm}>
                            <i className="icofont-exit"></i> Cancel
                        </button>

                    </div>
                </div>

            </div>
        );
    }

}

export default CancelReferenceDialog;
