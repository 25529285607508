import React from "react";
import ScheduledBillingFormData from "./scheduled-billing-form-data";

export default class EditScheduledBillingDialog extends React.Component {

    closeForm = () => {
        this.props.setDisplayEditDialog(false)
    }

    render() {

        const dialogClass = this.props.displayEditDialog ? "dialog-background fade" : "dialog-background";

        return (
            <div className={dialogClass}>
                <ScheduledBillingFormData
                    ShowToast={this.props.ShowToast}
                    setIsLoaderVisible={this.props.setIsLoaderVisible}
                    billingServices={this.props.billingServices}
                    closeForm={this.closeForm}
                    nameTag={"Edit"}
                    setDisplayEditDialog={this.props.setDisplayEditDialog}
                    setActionSuccessful={this.props.setEditSuccessful}
                    data={this.props.data}
                />

                {/* For testing alone... Doesn't display... */}
                <input
                    type="checkbox"
                    className="d-none"
                    id="chkTest"
                    onChange={this.handleChange}
                />
                <input
                    type="text"
                    className="d-none"
                    id="txtTest"
                    name="cbxBillingType"
                    onChange={this.handleChange}
                />
            </div>
        )
    }
}
