import React from 'react';
import ApiPath from '../assets/App/api-path'
import DynamicSort from '../assets/App/DynamicSort'

class Summaries extends React.Component {

    constructor() {
        super()
        this.state = {
            institutions: [],
            sortedInstitutions: [],
            users: [],
            branchList: [],
        }

        this.options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                'Cache-Control': 'public, max-age=0'
            },
        }

    }

    componentDidMount() {
        this.fetchInstitutions();
        this.getBranches();

    }

    getBranches = () => {
        this.props.setIsLoaderVisible(true)

        const queryString = `${ApiPath}api/branch/fetch-branches?limit=10000}`;

        fetch(queryString, this.options)
            ?.then(response => {
                return response.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false)
                if (response && response.ok === true) {
                    this.setState({
                        branchList: response.body?.branches?.data,
                    })
                }
            })
    }

    fetchUsers = institution => {
        let users = [];
        fetch(`${ApiPath}api/user/fetch-institution-users?limit=200000?institution=${institution}`, this.options)
            .then(res => (res.json()))
            .then(response => {
                if (response && response.ok) {
                  users = response.body.data.users
                }
            })

        return users;
    }

    fetchInstitutions = () => {

        this.props.setIsLoaderVisible(true);

        fetch(ApiPath + 'api/institution/fetch-institutions?limit=10000', this.options)
            ?.then(response => {
                this.props.setIsLoaderVisible(false)
                return response.json();
            })
            .then(response => {
                if (response.ok) {
                    this.setState({ institutions: response.body.data.institutions?.sort(DynamicSort("name")) });
                }
            })
            .catch(error => {
                this.props.setIsLoaderVisible(false);
                this.props.ShowToast(error.message, "exclamation");
            })
    }

    getBranchCount = (institutionSlug) => {
        //Get the institutions count based on the supplied slug...
        let counter = 0;

        this.state.branchList.forEach((element) => {
            if (element.institution.slug === institutionSlug) {
                counter++
            }
        })

        return counter;
    }


    render() {
        const tableBody = this.state.institutions.map((item, index) => {
            const instName = item.name;

            //Check to ensure limit()
            const usersCount = this.state.users.length;

            return (
                <tr key={index + 1}>
                    <td className="align-left">{instName}</td>
                    <td className="align-center">{usersCount}</td>
                    <td className="align-center">{this.getBranchCount(item.slug)}</td>
                </tr>
            )
        })

        return (
            <div className="summaries">
                <div className="title">
                    <h3>Summaries</h3>
                    <button id="btnRefreshTable" type="button" onClick={this.props.refreshSummaries}><i
                        className="icofont-refresh"></i> Refresh</button>
                </div>
                <div className="table-container">
                    <table><caption></caption>
                        <thead>
                            <tr>
                                <th scope="col" className="align-left">Institutions</th>
                                <th scope="col" className="align-center">Users</th>
                                <th scope="col" className="align-center">Branches</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableBody}

                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default Summaries;
