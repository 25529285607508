const ValidateEmail = (email) => {
    let returnValue = false;

    const re = /\S+@\S+\.\S+/
    returnValue = re.test(email);

    return returnValue;
}

export default ValidateEmail;
