import React from 'react'
import InstitutionTypesTableRow from './institution-types-table-row'
import EditInstitutionTypesDialog from './edit-institution-types-dialog'
import CreateInstitutionTypesDialog from './create-institution-types-dialog'
import ApiPath from '../../admin/assets/App/api-path'
import objectToCSV from '../../admin/assets/App/object-to-csv'
import ExportToCSVFile from '../../admin/assets/App/ExportToCSVFile'


export class InstitutionTypesForm extends React.Component {

    constructor() {
        super()
        this.state = {
            apiData: [],
            fetchSuccessful: false,
            createSuccessful: false,
            rowData: [],
            editClicked: false,
        }
    }

    componentDidMount() {
        this.getInstitutionTypes()
    }

    ExportPage = () => {
        //Move the data to a specified csvFile
        const csvData = objectToCSV(this.state.apiData)
        ExportToCSVFile(csvData, "institution-types.csv")
    }

    getInstitutionTypes() {
        this.props.setIsLoaderVisible(true)
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                'Cache-Control': 'public, max-age=0'
            }
        }

        fetch(ApiPath + 'api/institution-type/fetch-institution-type', options)
            ?.then(response => {
                this.props.setIsLoaderVisible(false)
                return response.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false)

                if (response) {
                    if (response.ok === true) {
                        this.setState({
                            apiData: response.body.institutionTypes,
                            fetchSuccessful: true,
                        })

                    } else {
                        this.props.ShowToast("Unable to load table. Please check your network and if this continues, contact your admin.", "exclamation")
                        return;
                    }
                } else {
                    this.props.ShowToast("Invalid login credentials", "exclamation")
                }
            })
            .catch(error => {
                this.props.setIsLoaderVisible(false)
                this.props.ShowToast(error.message, "exclamation")
            })

    }

    EditInstitutionType = itemID => {
        /**
        * Select a particular row of
        * interest for the purpose of
        * edititng...
        * We have decided to use the
        * slug parameter for the
        * filtering...
        */
        const selArray = this.state.apiData.filter(itemList => itemList.slug === itemID);

        this.setState({
            rowData: selArray[0],
            editClicked: true,
        })

    }

    RefreshForm = () => {
        window.location.reload();
    }

    render() {

        if (this.state.createSuccessful) {
            this.setState({
                createSuccessful: false,
            }, () => this.getInstitutionTypes())

        }

        //Load the table rows each time the table refreshes
        let counter = 0;
        const TableRows = this.state.fetchSuccessful && this.state.apiData.map(item => {
            counter++;
            return (<InstitutionTypesTableRow
                key={counter}
                item={item}
                counter={counter}
                ShowToast={this.props.ShowToast}
                setIsLoaderVisible={this.props.setIsLoaderVisible}
                EditInstitutionType={this.EditInstitutionType}
                isDeleteSuccessful={value => this.setState({ createSuccessful: value })}

            />)
        })

        return (
            <>
                <div className="title">
                    <h3>Institution Types</h3>
                    <div className="buttons">
                        <button id="btnRefresh" type="button" className="btn-grey t-tip mr-1" data-title="Refresh" onClick={this.RefreshForm}>
                            <i className="icofont-refresh"></i> Refresh
                        </button>
                        <button type="button" className="btn-grey t-tip mr-1" data-title="Create institution type"
                            id="btnCreateInstitution" onClick={() => document?.querySelector(".create-form")?.classList.add("fade")}><i className="icofont-plus">
                            </i> Create Institution Type
                        </button>
                        <button type="button" className="btn-green t-tip" data-title="Export to MsExcel file" id="btnExport">
                            <i className="icofont-airplane-alt"></i> Export
                        </button>
                    </div>
                </div>
                <div className="table-container">
                    <table className="bordered-table">
                        <caption></caption>
                        <thead>
                            <tr>
                                <th scope="col" className="align-center">#</th>
                                <th scope="col" className="align-left">Institution Type</th>
                                <th scope="col" className="align-center">Institutions Assigned</th>
                                <th scope="col" className="align-center">Services Assigned</th>
                                <th scope="col" className="align-left">Creation Date</th>
                                <th scope="col" className="align-left">Last Modified Date</th>
                                <th scope="col" className="align-center">Action</th>
                            </tr>
                        </thead>
                        <tbody id="tbl_institution_types">
                            {TableRows}
                        </tbody>
                    </table>
                </div>

                <CreateInstitutionTypesDialog
                    ShowToast={this.props.ShowToast}
                    setIsLoaderVisible={this.props.setIsLoaderVisible}
                    setIsCreateSuccessful={value => this.setState({ createSuccessful: value })}

                />

                {this.state.editClicked && <EditInstitutionTypesDialog
                    ShowToast={this.props.ShowToast}
                    setIsLoaderVisible={this.props.setIsLoaderVisible}
                    setIsEditSuccessful={editState => this.setState({ createSuccessful: editState })}
                    rowData={this.state.rowData}
                    editClicked={this.state.editClicked}
                    setEditClicked={value => this.setState({ editClicked: value })}
                />}
            </>
        )
    }
}
export default InstitutionTypesForm;
