import React from 'react'
import ApiPath from '../../admin/assets/App/api-path'

class RecoverPasswordBody extends React.Component {
  constructor() {
    super()
    this.state = {
      txtPassword: "",
      txtVerifyPassword: "",
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    //We expect a
    //new endpoint to
    //capture the changes and
    //ensure token validation
    //and expiry...
    this.verifyForgotPasswordOnLoad();
  }

  verifyForgotPasswordOnLoad = () => {
    const locString = window.location.href.split("=");
    const token = locString[1];

    const options = {
      body: JSON.stringify({ token: token }),
      method: "POST",
      headers: {
        'Content-Type': "application/json",
        'Cache-Control': 'public, max-age=0'
      }
    }

    fetch(ApiPath + "api/auth/verify-forgot-password", options)
      ?.then(response => {
        return response.json()
      })
      .then(response => {
        if (response && response.ok) {
          //No issue, proceed to update...
        }
      })
      .catch(error => {
        this.props.ShowToast(error.message, "exclamation");
      })

  }

  handleSubmit() {
    try {
      if (this.state.txtPassword.length < 4) {
        this.props.ShowToast("Enter a password of at least 4 characters before updating.", "exclamation")
        return;
      }
      if (this.state.txtPassword !== this.state.txtVerifyPassword) {
        this.props.ShowToast("Your passwords must match!", "exclamation")
        return;
      }

      this.props.setIsLoaderVisible(true)

      const locString = window.location.href.split("=");

      //Else proceed..
      const data = {
        password: this.state.txtPassword,
        token: locString[1],
      };
      const options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      fetch(ApiPath + "api/auth/forgot-password", options)
        .then((response) => {
          this.props.setIsLoaderVisible(false);
          return response.json();
        })
        .then((response) => {
          this.props.setIsLoaderVisible(false);
          if (response) {
            if (response.ok === true) {
              this.props.setIsLoaderVisible(false);
              this.props.ShowToast(
                "You have successfully updated your password. Proceed to the Login page to Login with your updated credentials."
                , "success");
              setTimeout(() => {
                window.location.href = "/";
              }, 2500);
            } else {
              this.props.setIsLoaderVisible(false);
              this.props.ShowToast(response.body.error, "exclamation");
            }
          } else {
            this.props.ShowToast(
              "Invalid login credentials",
              "exclamation"
            );
          }
        })
        .catch((error) => {
          this.props.ShowToast(error.message, "exclamation");
        });


    } catch (error) {
      this.props.setIsLoaderVisible(false)
      this.props.ShowToast(error, "exclamation")
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  render() {

    return (
      <div className="login-data">
        <h3><i className="icofont-mr icofont-key"></i>Recover Password</h3>
        <div className="login-details">
          <h4>Enter your new password</h4>
          <div className="controls">
            <span className="bx bx-key"></span>
            <input
              type="password"
              id="txtPassword"
              name="txtPassword"
              onChange={this.handleChange}
              value={this.state.txtPassword}
              placeholder="Enter New Password" />
          </div>
          <div className="controls">
            <span className="bx bx-key"></span>
            <input
              type="password"
              id="txtVerifyPassword"
              name="txtVerifyPassword"
              onChange={this.handleChange}
              value={this.state.txtVerifyPassword}
              placeholder="Verify New Password" />
          </div>

          <button type="button" id="btnVerify" style={{ width: 200 }} onClick={this.handleSubmit}>Update Password</button>

        </div>
        <div className="cant-access">
          <a href="mailto:admin@gmail.com">Can't access your account?</a>
        </div>

      </div>
    )
  }
}

export default RecoverPasswordBody
