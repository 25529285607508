const SplitDate = (longDate) => {
    const dateParts = longDate ? longDate?.toString().split("T") : ["-", "-"];
    const dateSplit = dateParts[0].split("-");

    const waDate = longDate
        ? `${dateSplit[2]}/${dateSplit[1]}/${dateSplit[0]}`
        : "-";
    const shortCodedDate = (longDate)
        ? `${dateSplit[2]}${dateSplit[1]}${dateSplit[0].substring(2)}`
        : "-";

    return ({
        "date": waDate,
        "time": waDate !== "-" ? waDate.substring(0, 8) : "",
        "dateTime": longDate ? `${waDate} ${dateParts[1].substring(0, 8)}` : waDate,
        "codedDate": shortCodedDate,
    });
}

const ShortDateToCoded = (shortDate) => {
    /**
     * Converts the date
     * to a 6-digit
     * short format
     */
    const splitDate = shortDate ? shortDate.split("-") : ["-", "-"]
    return (
        splitDate[2] + splitDate[1] + splitDate[0].substring(2)
    )
}

const dateToWAFormat = dateValue => {
    const splitDate = dateValue ? dateValue.split("-") : ["-", "-"];
    return `${splitDate[2]}/${splitDate[1]}/${splitDate[0].substring(0, 4)}`
}

export { SplitDate, ShortDateToCoded, dateToWAFormat };
