import React, { Component } from 'react'
import ApiPath from '../../admin/assets/App/api-path';
import Badges from '../../admin/assets/App/Badges';
import CapitalizeFirstLetter from '../../admin/assets/App/CapitalizeFirstLetter';
import { SplitDate } from '../../admin/assets/App/split-date';

export class OutgoingEDMMSMandateTableRow extends Component {
    constructor() {
        super();
        this.state = {

        }

        this.options = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                'Cache-Control': 'public, max-age=0'
            },
            method: 'POST',
            body: '',
        }
    }

    acceptMandate = () => {
        // runs a function to accept the mandate...
        this.props.setIsLoaderVisible(true);

        // set the body...
        const body = { slug: this.props.data?.slug }
        this.options.body = JSON.stringify(body);

        fetch(ApiPath + "api/e-dmms/accept-edmms-mandate", this.options)
            .then(resp => (resp.json()))
            .then(response => {
                // Hide the loader at this point...
                this.props.setIsLoaderVisible(false);
                if (response && response.ok) {
                    /**
                     * Update was successful, no issues
                     * whatsoever...
                     */
                    this.props.ShowToast("Mandate accepted!", "success");
                    this.props.setActionSuccessful();   // This refreshes the form...
                } else {
                    this.props.ShowToast(response.message, "exclamation");
                }

            })
            .catch(err => {
                this.props.setIsLoaderVisible(false);
                this.props.ShowToast(err.message, "exclamation");
            })
    }

    approveMandate = () => {
        // runs a function to approve the mandate...
        this.props.setIsLoaderVisible(true);

        // set the body...
        this.options.body = JSON.stringify({ slug: this.props.data?.slug });

        fetch(ApiPath + "api/edmms-mandate/approve-outgoing-mandate", this.options)
            .then(output => (output.json()))
            .then(approveResponse => {
                // Hide the loader at this point...
                this.props.setIsLoaderVisible(false);
                if (approveResponse && approveResponse.ok) {
                    /**
                     * Update was successful, no issues
                     * whatsoever...
                     */
                    this.props.ShowToast("Mandate approved!", "success");
                    this.props.setActionSuccessful();   // This refreshes the form...
                } else {
                    this.props.ShowToast(approveResponse.message, "exclamation");
                }

            })
            .catch(err => {
                this.props.setIsLoaderVisible(false);

                // Show toast after hiding the loader...
                this.props.ShowToast(err.message, "exclamation");
            })
    }

    render() {
        // Here, we process the status badges...
        const statusClass = Badges(this.props.data?.status);
        const companyName = this.props.data.othernames === "" ? this.props.data?.companyName : this.props.data?.companyName + " " + this.props.data?.othernames

        return (
            <>
                <tr>
                    <td className="align-center">{this.props.counter}</td>
                    <td className='align-left'>{this.props.data?.mandateCode}</td>
                    <td className='align-left'>{SplitDate(this.props.data?.createdAt).dateTime}</td>
                    <td className='align-left'>{"******"}</td>
                    <td className='align-left'>{this.props.data?.institution?.name}</td>
                    <td className='align-left'>{this.props.data?.branch?.branchName}</td>
                    <td className='align-left'>{companyName}</td>
                    <td className='align-center'><span className={statusClass}>{CapitalizeFirstLetter(this.props.data?.status)}</span></td>
                    <td className='align-center' width={180}>
                        <i
                            id="btnDetails"
                            className="icofont-ui-note mr-1"
                            style={{ fontSize: 20, color: "grey", cursor: "pointer" }}
                            title="View Details"
                            onClick={() => {
                                // Show the form, after setting the slug
                                // of the record...
                                this.props.setViewData(this.props.data);
                                this.props.showDetailsForm();
                            }}
                        />
                        <i
                            id="btnAccept"
                            className="icofont-checked mr-1 d-none"
                            style={{ fontSize: 22, color: "green", cursor: "pointer" }}
                            title="Accept Mandate"
                            onClick={this.acceptMandate}
                        />
                        <i
                            id="btnApprove"
                            className={this.props.data.status !== 'pending' ? 'd-none' : "icofont-tick-boxed mr-1"}
                            style={{ fontSize: 20, fontWeight: 'bold', color: "darkgreen", cursor: "pointer" }}
                            title="Approve Mandate"
                            onClick={this.approveMandate}
                        />
                        <i
                            id="btnReject"
                            className={this.props.data.status !== 'pending' ? 'd-none' : "icofont-not-allowed"}
                            style={{ fontSize: 20, fontWeight: 'bold', color: "maroon", cursor: "pointer" }}
                            title="Reject Mandate"
                            onClick={this.props.showRejectForm}
                        />
                    </td>
                </tr>
            </>
        )
    }
}
