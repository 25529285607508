import React from "react"
import ApiPath from "../../../admin/assets/App/api-path"

export default class ReassignIncomingReference extends React.Component {

    constructor() {
        super()
        this.state = {
            cbxBranchSlug: "",
            branchesArray: [],
        }
    }

    componentDidMount() {
        this.fetchBranches();

        if (this.props.data) {
            this.setState({
                cbxBranchSlug: this.props.data?.receiverBranch?.slug,
            })
        }
    }

    fetchBranches() {
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                'Cache-Control': 'public, max-age=0'
            },
        }

        //Ensure to fetch only branches related to this particular institution...
        fetch(`${ApiPath}api/institution/fetch-institution-branches/${this.props.institution}`, options)
            ?.then(response => {
                return response.json();
            })
            .then(response => {
                if (response && response.ok) {
                    this.setState({
                        branchesArray: response.body.data.branches,
                    })
                }
            })
    }

    handleChange = e => {
        const { name, value } = e.target;
        this.setState({ [name]: value })
    }

    closeForm = () => {
        this.props.setDisplayReassignDialog(false)
    }

    ReassignIncomingReference = () => {

        this.props.setIsLoaderVisible(true);

        const data = {
            "slug": this.props.data?.slug,
            "newlyAssignedBranch": this.state.cbxBranchSlug,
        }
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                'Cache-Control': 'public, max-age=0'
            },
            body: JSON.stringify(data),
        }

        const endpoint = sessionStorage.getItem("customer_branch_slug") === this.state.cbxBranchSlug
            ? "api/e-reference/assign-e-reference-to-self"    // Assign to self...
            : "api/e-reference/assign-e-reference-to-branch";

        fetch(ApiPath + endpoint, options)
            ?.then(response => {
                return response.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false)
                if (response) {
                    if (response.ok === true) {
                        //Success
                        this.props.ShowToast(`Reference Re-assigned successfully!`, "success")
                        this.props.setReassignSuccessful(true)
                        this.props.setDisplayReassignDialog(false)

                    } else {
                        this.props.ShowToast(response.message, "exclamation")
                        return;
                    }
                } else {
                    this.props.ShowToast("Invalid input(s)", "exclamation")
                }
            })
            .catch(error => {
                this.props.setIsLoaderVisible(false)
                this.props.ShowToast(error.message, "exclamation")
            })

    }

    render() {
        const SetBranches = this.state.branchesArray.map(item => {
            return (
                <option
                    key={item.slug}
                    value={item.slug}
                >{item.branchName}
                </option>
            )
        })

        const dialogClass = this.props.displayReassignDialog ? "modal fade show" : "modal fade";

        return (
            <div className={dialogClass}>
                <div className="lock-container">
                    <div className="dialog-header">
                        <h2 className="create"><i className="bx bx-transfer-alt"></i> Reassign Incoming Reference</h2>
                    </div>
                    <div className="dialog-body">
                        <p style={{ color: "ButtonText" }}>
                            Select the Branch of Choice:
                        </p>
                        <div className="form-row-3-1">
                            <div className="input-group">
                                <select
                                    className="custom-select"
                                    id="cbxBranchSlug"
                                    name="cbxBranchSlug"
                                    value={this.state.cbxBranchSlug}
                                    onChange={this.handleChange}
                                >
                                    <option value=""></option>
                                    {SetBranches}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="dialog-footer mt-2">
                        <button
                            id="btnRedirect"
                            className="btn-green mr-1"
                            onClick={this.ReassignIncomingReference}>
                            <i className="bx bx-transfer-alt"></i> Reassign
                        </button>
                        <button
                            id="btnClose"
                            className="btn-grey"
                            onClick={this.closeForm}>
                            <i className="icofont-exit"></i> Cancel
                        </button>

                    </div>
                </div>


            </div>
        );
    }

}
