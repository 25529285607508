import React from 'react'
import ApiPath from '../../admin/assets/App/api-path'
import { Enums } from "../../_common/enums";
import Pagination from '../../admin/assets/App/Pagination';
import ConfigureBinTableRows from './configure-bin-table-rows';
import CreateCardBinDialog from './create-card-bin-dialog';
import SearchComponent from '../../admin/assets/App/SearchComponent';
import CardBinSearchList from "../../admin/assets/data/CardBinSearchList";
import DynamicSort from '../../admin/assets/App/DynamicSort';

class ConfigureCardBinForm extends React.Component {
     /**
     * This configure card bin form
     * basically
     * allows us to have
     * a quick view of
     * application shortcuts.
     *
     * We need to add the
     * table and the
     * table rows to
     * follow the
     * expected
     * description on
     * the requirements
     * document...
     */

    constructor() {
        super();
        this.state = {
            txtCardBin: "",
            actionSuccessful: false,
            apiData: [],
            metaData: [],
            viewData: [],
            editData: [],
            inputList: [],
            fetchSuccessful: false,
            institutionsData: [],
            //Pagination items
            currentSelectedPage: 1,
            perPage: localStorage.getItem("items_per_page") || 20,
            arrayPages: [],
            lastPage: 1,
            totalItems: 0,
            //Display logics
            displayCreateDialog: false,
            displayEditDialog: false,

            //filter & search
            searchField: "cardBin",
            searchValue: "",
        };

        this.headersData = {
            "Content-Type": Enums.applicationJson,
            "Authorization": `Bearer ${sessionStorage.getItem("session_token")}`,
            'Cache-Control': 'public, max-age=0'
        }
    }

    getInstitutions = () => {
        const options = {
            method: 'GET',
            headers: this.headersData,
        }
        fetch(ApiPath + 'api/institution/fetch-institutions?limit=10000', options)
            ?.then(response => {
                return response.json();
            })
            .then(response => {
                if (response.ok) {
                    this.setState({
                        institutionsData: response.body.data.institutions.sort(DynamicSort("name")),
                    })
                }
            })

    }

    componentDidMount() {
        this.getBinData();
        this.getInstitutions();
    }

    RefreshForm = () => {
        window.location.reload();
    }

    getBinData() {

        this?.props.setIsLoaderVisible(true);
        const options = {
            method: "GET",
            headers: this?.headersData,
        };

        let searchValue = this.state.searchValue;
        const searchField = this.state.searchField;

        if (this.state.searchField === "institution") {
            searchValue = this.state.institutionsData.find(item => item.name === this.state.searchValue)?.slug;
        }

        //Change API and returned values later as the endpoints are ready...
        fetch(`${ApiPath}api/bin/get-bins?page=${this.state.currentSelectedPage}` +
            `&${searchField}=${searchValue}&limit=${this.state.perPage}`, options)
            ?.then((response) => {
                this?.props.setIsLoaderVisible(false);
                return response.json();
            })
            ?.then((response) => {
                this?.props.setIsLoaderVisible(false);
                if (response) {
                    if (response.ok === true) {
                        //Success
                        this.setState({
                            apiData: response.body.data.bins,
                            metaData: response.body.data.meta,
                            fetchSuccessful: true,
                            actionSuccessful: false,
                            arrayPages: [],
                        }, () => {
                            const pagesArray = [];
                            this.setState({
                                lastPage: this.state.metaData?.last_page,
                                currentSelectedPage: this.state.metaData?.current_page,
                                totalItems: this.state.metaData?.total_item,
                            }, () => {
                                //Set this array for the looping
                                for (let i = 1; i <= this.state.lastPage; i++) {
                                    pagesArray.push({ "id": i, "name": "Item " + i });
                                }

                                this.setState({ arrayPages: pagesArray })
                            })
                        });

                    } else {
                        this.props.ShowToast(response.message, "exclamation");
                    }
                }
            })
            ?.catch((error) => {
                this?.props.setIsLoaderVisible(false);
                this?.props.ShowToast(error.messsage, "exclamation");
            });

    }

    filterRecords = () => {
        this.getBinData();
    }

    render() {

        if (this.state.actionSuccessful) {
            this.getBinData();
            this.setState({
                actionSuccessful: false,
            })
        }

        const CardBinRows = this.state.fetchSuccessful && this.state.apiData.map((item, index) => {
            return (
                <ConfigureBinTableRows
                    key={item.slug}
                    data={item}
                    counter={index + 1}
                    ShowToast={this.props.ShowToast}
                    setIsLoaderVisible={this.props.setIsLoaderVisible}
                    setActionSuccessful={value => this.setState({ actionSuccessful: value })}
                />
            )
        })

        const institutionNames = this.state.institutionsData.map(item => {
            return ({ "item_name": item.name })
        })

        const cardBin = this.state.apiData.map(item => {
            return ({ "item_name": item.bin })
        })

        return (
            <>
                <div className="title">
                    <h3>Cards Bins</h3>
                    <SearchComponent
                        setSearchField={val => this.setState({ searchField: val })}
                        setSearchValue={val => this.setState({ searchValue: val })}
                        ReferenceSearchList={CardBinSearchList}
                        filterRecords={this.filterRecords}
                        inputList={{ institutionNames, cardBin }}
                        ShowToast={this.props.ShowToast}
                    />

                    <div className="buttons">
                        <button
                            id="btnCreate"
                            type="button"
                            className="btn-grey t-tip mr-1"
                            data-title="Create Card Bin for Institution"
                            onClick={() => this.setState({ displayCreateDialog: true })}>
                            <i className="icofont-card"></i> Create Card Bin
                        </button>
                        <button
                            id="btnRefresh"
                            type="button"
                            className="btn-green t-tip mr-1"
                            data-title="Refresh"
                            onClick={this.RefreshForm}>
                            <i className="icofont-refresh"></i> Refresh
                        </button>
                    </div>
                </div>
                <div className="table-container">
                    <table className="bordered-table">
                        <caption></caption>
                        <thead>
                            <tr>
                                <th scope="col" className="align-center" width="60">#</th>
                                <th scope="col" className="align-left">BIN</th>
                                <th scope="col" className="align-left">INSTITUTION</th>
                                <th scope="col" className="align-center">ACTION</th>
                            </tr>
                        </thead>
                        <tbody id="tbl_billings">
                            {CardBinRows}
                        </tbody>
                    </table>
                </div>
                <Pagination
                    setCurrentSelectedPage={value => this.setState({ currentSelectedPage: value })}
                    lastPage={this.state.lastPage}
                    arrayPages={this.state.arrayPages}
                    setRefreshForm={value => this.setState({ actionSuccessful: value })}
                    perPage={this.state.perPage}
                    setPerPage={value => this.setState({ perPage: value })}
                    currentSelectedPage={this.state.currentSelectedPage}
                />

                {
                    this.state.displayCreateDialog && <CreateCardBinDialog
                        setDisplayCreateDialog={value => this.setState({ displayCreateDialog: value })}
                        displayCreateDialog={this.state.displayCreateDialog}
                        ShowToast={this.props.ShowToast}
                        setIsLoaderVisible={this.props.setIsLoaderVisible}
                        setCreateSuccessful={val => this.setState({ actionSuccessful: val })}
                        institutionsData={this.state.institutionsData}

                    />
                }
            </>
        );
    }
}

export default ConfigureCardBinForm
