import React from 'react';
import './public-url.css';

import CapitalizeFirstLetter from '../../admin/assets/App/CapitalizeFirstLetter';
import Badges from '../../admin/assets/App/Badges';
import { SplitDate, dateToWAFormat } from '../../admin/assets/App/split-date';

class PublicURLPage extends React.Component {

    constructor() {
        super();
        this.state = {
            data: {},
            error: ''
        }
    }

    fetchData = async () => {
        // check if the public URL was set...
        const mainURL = new URL(window.location.href);
        const url = new URLSearchParams(mainURL.search);

        const publicURL = url.get('xyz');

        const getData = await fetch(process.env.REACT_APP_PUBLIC_URL + '?publicUrl=' + publicURL);
        const response = await getData.json();

        if (response && response.ok) {
            // Save...
            this.setState({
                data: response.body.data
            });
        } else {
            this.setState({ error: response.message })
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    downloadAttachment = () => {
        // Download attachment, if available...

    }

    previewAttachment = () => {
        // Download attachment, if available...

    }


    render() {

        if (this.state.error) {
            return <div>{this.state.error}</div>
        }

        return (
            <div className='url-container' >
                <div className='body-content'>
                    <div className='table-container' style={{ maxHeight: '100%' }}>
                        <table className='dialog-table'>
                            <caption></caption>
                            <thead>
                                <tr>
                                    <th scope="col" className="align-left">Field</th>
                                    <th scope="col" className="align-left">Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Request ID</td>
                                    <td>{this.state.data?.requestId}</td>
                                </tr>
                                <tr>
                                    <td>Category</td>
                                    <td>{this.state.data?.category}</td>
                                </tr>
                                <tr>
                                    <td>Reference Type</td>
                                    <td>{this.state.data?.referenceType}</td>
                                </tr>
                                <tr>
                                    <td>Reference</td>
                                    <td>{this.state.data?.reference}</td>
                                </tr>
                                <tr>
                                    <td>Start Date</td>
                                    <td>{dateToWAFormat(this.state.data?.startDate)} {this.state.data?.startTime}</td>
                                </tr>
                                <tr>
                                    <td>End Date</td>
                                    <td>{dateToWAFormat(this.state.data?.endDate)} {this.state.data?.endTime}</td>
                                </tr>
                                <tr>
                                    <td>Additional Info</td>
                                    <td>{this.state.data?.additionalInfo}</td>
                                </tr>
                                <tr>
                                    <td>Public URL</td>
                                    <td><a href={window.location.href}>{window.location.href}</a></td>
                                </tr>
                                <tr>
                                    <td>Views</td>
                                    <td>{this.state.data?.views}</td>
                                </tr>
                                <tr>
                                    <td>Is Overdue?</td>
                                    <td>{CapitalizeFirstLetter(this.state.data?.isOverDue)}</td>
                                </tr>
                                <tr>
                                    <td>Overdue Date/Time</td>
                                    <td>{SplitDate(this.state.data?.meta?.overDueTime).dateTime}</td>
                                </tr>
                                <tr>
                                    <td>Status</td>
                                    <td>
                                        <span style={{ width: 180 }} className={Badges(this.state.data?.status)}>
                                            {CapitalizeFirstLetter(this.state.data?.status)}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Date Logged</td>
                                    <td>{SplitDate(this.state.data?.createdAt).dateTime}</td>
                                </tr>
                                <tr>
                                    <td>Last Updated</td>
                                    <td>{SplitDate(this.state.data?.updatedAt).dateTime}</td>
                                </tr>
                                <tr>
                                    <td>Attachments</td>
                                    <td>
                                        <button
                                            type="button"
                                            id="btnPreview"
                                            onClick={this.previewAttachment}
                                            className="btn-grey mr-1">
                                            <i className="bx bx-slideshow"></i> Preview
                                        </button>
                                        <button
                                            type="button"
                                            id="btnAttachment"
                                            onClick={this.downloadAttachment}
                                            className="btn-grey">
                                            <i className="icofont-download"></i> Download
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <hr className="golden-rule" />
                    </div>
                </div>
            </div>
        )
    }
}

export { PublicURLPage };
