import React from 'react';
import DropDownInput from '../../admin/assets/App/DropDownInput';
import ApiPath from '../../admin/assets/App/api-path';
import DynamicSort from '../../admin/assets/App/DynamicSort';
import { CreateEClipsDialog } from './create-e-clips-dialog';
import { Enums } from '../../_common/enums';
import { MessageBoxType as MsgBoxType } from "../../_common/constants"

export default class CreateEClipsForm extends React.Component {
    constructor() {
        super()
        this.state = {
            cbxCategory: "",
            txtInstitution: "",
            cbxTransactionRefType: "",
            txtTransactionDate: "",
            txtDisputeAmount: "",
            txtDisputeRef: "",
            txtFootageTimeStart: "",
            txtFootageTimeEnd: "",
            txtAdditionalInfo: "",
            txtRequestCode: "",
            txtReceivingCode: "",
            data: {},

            //Dependent RefType values...
            disputeRefMaxLength: 15,

            institutions: [],
            displayDialogClicked: false,
            clearData: false,

        }

        this.headersData = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
            'Cache-Control': 'public, max-age=0'
        }
    }

    handleChange = e => {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
        }, () => {
            if (name === "cbxTransactionRefType") {
                //Set the values for the refType
                if (this.state.cbxTransactionRefType === "switch-serial") {
                    this.setState({
                        disputeRefMaxLength: 15,
                    })
                } else {
                    this.setState({
                        disputeRefMaxLength: 30,
                    })
                }
            }

            //Always remove the error-border from the sender
            document.getElementById([name])?.classList.remove(Enums.errorBorder);
        })
    }

    componentDidMount() {
        this.fetchInstitutions();
    }

    fetchInstitutions = () => {
        const options = {
            method: 'GET',
            headers: this.headersData,
        }

        fetch(ApiPath + 'api/institution/fetch-institutions-for-service?service=3', options)
            ?.then(res => {
                return res.json();
            })
            .then(response => {
                if (response.ok) {
                    this.setState({
                        institutions: response.body.data.sort(DynamicSort("name")),
                    })
                }
            })
    }

    checkTransactionRefType = () => {
        let returnValue = true;

        if (this.state.cbxTransactionRefType !== "") {
            if (this.state.cbxTransactionRefType === "switch-serial") {

                if (this.state.txtDisputeRef === ""
                    || isNaN(this.state.txtDisputeRef)
                    || this.state.txtDisputeRef.length < 9) {
                    this.props.ShowToast("Numeric digits required of between 9 and 15 digits for the Dispute Reference Field!", MsgBoxType.EXCLAMATION);
                    document.getElementById("txtDisputeRef")?.classList.add(Enums.errorBorder)
                    returnValue = false;
                }

            } else {
                //Every other non-empty value, do the following...
                if (this.state.txtDisputeRef === "" || this.state.txtDisputeRef.length < 10) {
                    this.props.ShowToast("Alpha-numeric characters of length between 10 and 30 required for the Dispute Reference Field!", MsgBoxType.EXCLAMATION);
                    document.getElementById("txtDisputeRef")?.classList.add(Enums.errorBorder)
                    returnValue = false;
                }
            }
        }

        return returnValue;

    }

    handleDisplayDialog = () => {

        if (this.state.cbxCategory === "") {
            this.props.ShowToast("Category required!", MsgBoxType.EXCLAMATION);
            document.getElementById("cbxCategory")?.classList.add(Enums.errorBorder)
            return;
        }

        //Match the institution to the list, if possible...
        const matchedInstitution = this.state.institutions.find(item => item.name === this.state.txtInstitution);

        if (this.state.txtInstitution === "" || matchedInstitution === "") {
            this.props.ShowToast("Institution required! You must select an institution while typing", MsgBoxType.EXCLAMATION);
            document.getElementById("txtInstitution")?.classList.add(Enums.errorBorder)
            return;
        }

        if (this.state.cbxTransactionRefType === "") {
            this.props.ShowToast("Transaction Ref Type required!", MsgBoxType.EXCLAMATION)
            document.getElementById("cbxTransactionRefType")?.classList.add(Enums.errorBorder)
            return;
        }

        if (!this.checkTransactionRefType) {
            return;
        }

        if (this.state.txtTransactionDate === "") {
            this.props.ShowToast("Transaction Date required!", MsgBoxType.EXCLAMATION);
            document.getElementById("txtTransactionDate")?.classList.add(Enums.errorBorder)
            return;
        }

        /**
         * If every condition has been met,
         * then proceed to add
         * the data to the
         * object for update in the
         * next page...
         */

        this.setState({
            displayDialogClicked: true,
            data: {
                category: this.state.cbxCategory,
                institution: this.state.txtInstitution,
                transactionRefType: this.state.cbxTransactionRefType,
                disputeRef: this.state.txtDisputeRef,
                transactionDate: this.state.txtTransactionDate,
                disputeAmount: this.state.txtDisputeAmount,
                footageTimeStart: this.state.txtFootageTimeStart,
                footageTimeEnd: this.state.txtFootageTimeEnd,
                additionalInfo: this.state.txtAdditionalInfo,
                institutionArray: this.state.institutions,
                requestCode: this.state.txtRequestCode,
                receivingCode: this.state.txtReceivingCode,
            }
        })

    }

    clearAllData = () => {
        this.setState({
            cbxCategory: "",
            txtInstitution: "",
            cbxTransactionRefType: "",
            txtTransactionDate: "",
            txtDisputeAmount: "",
            txtDisputeRef: "",
            txtFootageTimeStart: "",
            txtFootageTimeEnd: "",
            txtAdditionalInfo: "",
            data: {},
            disputeRefMaxLength: 15,
            institutions: [],
            displayDialogClicked: false,
            clearData: false,
        })
    }

    render() {
        if (this.state.clearData) {
            this.clearAllData()
        }

        const institutionsList = [];
        this.state.institutions.forEach(item => {
            institutionsList.push(item.name);
        })

        return (
            <>
                <div className="title">
                    <h3>Create New Clip Request</h3>
                </div>
                <div className="form-row-1-2-1 mb-2">
                    <div className="input-group">
                        <label><span>*</span>Category</label>
                        <select
                            id="cbxCategory"
                            name="cbxCategory"
                            value={this.state.cbxCategory}
                            className="custom-control"
                            onChange={this.handleChange}
                        >
                            <option value=""></option>
                            <option value="Dispute">Dispute</option>
                            <option value="Fraud">Fraud</option>
                            <option value="Other">Other</option>
                        </select>
                        <label className="control-info"><i className="icofont-warning-alt"></i> Select option from list</label>
                    </div>
                    <div className="input-group">
                        <label><span>*</span>Institution</label>
                        <DropDownInput
                            name="txtInstitution"
                            value={this.state.txtInstitution}
                            setValue={value => this.setState({ txtInstitution: value })}
                            handleChange={this.handleChange}
                            dropDownList={institutionsList}
                        />
                    </div>
                </div>
                <div className="dialog-body">
                    <hr className="golden-rule" /><h3 className="row-title">Dispute Information</h3>
                </div>
                <div className="form-row-1-2-1 mb-2">
                    <div className="input-group">
                        <label><span>*</span>Transaction Ref Type</label>
                        <select
                            id="cbxTransactionRefType"
                            name="cbxTransactionRefType"
                            value={this.state.cbxTransactionRefType}
                            className="custom-control"
                            onChange={this.handleChange}
                        >
                            <option value=""></option>
                            <option value="switch-serial">Switch Serial</option>
                            <option value="arbiter-logcode">Arbiter Log Code</option>
                            <option value="other">Other</option>
                        </select>
                    </div>
                    <div className="input-group">
                        <label><span>*</span>Dispute Reference</label>
                        <input
                            className="form-control"
                            type="text"
                            id="txtDisputeRef"
                            name="txtDisputeRef"
                            value={this.state.txtDisputeRef}
                            onChange={this.handleChange}
                            maxLength={this.state.disputeRefMaxLength}

                        />
                    </div>
                </div>
                <div className="form-row-4 mb-2">
                    <div className="input-group">
                        <label><span>*</span>Transaction Date</label>
                        <input
                            className="form-control"
                            type="date"
                            id="txtTransactionDate"
                            name="txtTransactionDate"
                            value={this.state.txtTransactionDate}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="input-group">
                        <label>Dispute Amount</label>
                        <input
                            className="form-control"
                            type="text"
                            id="txtDisputeAmount"
                            name="txtDisputeAmount"
                            value={this.state.txtDisputeAmount}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="input-group">
                        <label>Footage Time (Start)</label>
                        <input
                            className="form-control"
                            type="datetime-local"
                            id="txtFootageTimeStart"
                            name="txtFootageTimeStart"
                            value={this.state.txtFootageTimeStart}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="input-group">
                        <label>Footage Time (End)</label>
                        <input
                            className="form-control"
                            type="datetime-local"
                            id="txtFootageTimeEnd"
                            name="txtFootageTimeEnd"
                            value={this.state.txtFootageTimeEnd}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
                <div className="form-row-2-1-1 mb-2">

                    <div className="input-group">
                        <label>Additional Request Info</label>
                        <textarea
                            className="form-control"
                            rows="5"
                            id="txtAdditionalInfo"
                            name="txtAdditionalInfo"
                            value={this.state.txtAdditionalInfo}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
                <hr className="mb-3" />
                <div className="form-row-3 mb-2">
                    <div className="input-group"></div>
                    <div className="input-group">
                        <button
                            className="btn-green t-tip"
                            id="btnCreateReference"
                            data-title="Click to update the reference details"
                            onClick={this.handleDisplayDialog}>
                            <i className="icofont-plus"></i> Create e-Clip Request
                        </button>
                    </div>
                </div>
                {this.state.displayDialogClicked &&
                    <CreateEClipsDialog
                        data={this.state.data}
                        ShowToast={this.props.ShowToast}
                        setIsLoaderVisible={this.props.setIsLoaderVisible}
                        displayDialogClicked={this.state.displayDialogClicked}
                        setDisplayDialog={value => this.setState({ displayDialogClicked: value })}
                        setClearData={value => this.setState({ clearData: value })}

                    />}
            </>

        )
    }
}
