import LoginHeader from "./login-header"
import ForgotPasswordBody from "./forgot-password-body";

export const ForgotPassword = (props) => {
    const userType = false;

    return (
        <>
            <div className="login-container">
                <h1>Document Hub Portal</h1>
                <div className="login">
                    <LoginHeader mainImageSrc="assets/img/svg/two_factor_auth.svg" />
                    <ForgotPasswordBody
                        isNibssUser={userType}
                        ShowToast={props.ShowToast}
                        setIsLoaderVisible={props.setIsLoaderVisible}
                    />
                </div>
            </div>
        </>
    )
}
