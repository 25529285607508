import React from "react"
import CreateTrappedCardsDialog from "./create-trapped-cards-dialog"
import ApiPath from "../../admin/assets/App/api-path"
import DynamicSort from "../../admin/assets/App/DynamicSort"
import { MessageBoxType as MsgBoxType } from "../../_common/constants";

export default class CreateTrappedCardsForm extends React.Component {

    /**
     * THE CREATE TRAPPED CARDS FORM
     * IS THE MAIN FORM THAT
     * DISPLAYS TO ACCEPT INPUTS
     * THAT ARE EVENTUALLY
     * UPDATED ONCE THE
     * SAID INPUTS HAVE BEEN
     * VALIDATED...
     */

    constructor() {
        super()
        this.state = {
            displayDialogClicked: false,
            data: [],
            txtNameOnCard: "",
            txtCardNumber: "",
            cbxCardScheme: "",
            cbxIssuingBank: "",
            cardImageURL: "",
            cardBinData: [],
            fetchingAccountDetails: false,
            institutionsArray: [],
            clearData: false,

        }

        this.headersData = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
            'Cache-Control': 'public, max-age=0'
        }

    }

    componentDidMount() {
        this.fetchInstitutions();
        this.getCardBinData();
    }

    clearAllData = () => {
        //Reset all the data and prepare for new input...
        this.setState({
            txtNameOnCard: "",
            txtCardNumber: "",
            cbxCardScheme: "",
            cbxIssuingBank: "",
            clearData: false,
        })
    }

    getCardBinData = () => {

        this?.props.setIsLoaderVisible(true);
        const options = {
            method: "GET",
            headers: this?.headersData,
        };

        //Change API and returned values later as the endpoints are ready...
        fetch(`${ApiPath}api/institution/get-bins?limit=10000`, options)
            ?.then((response) => {
                this?.props.setIsLoaderVisible(false);
                return response.json();
            })
            ?.then((response) => {
                this?.props.setIsLoaderVisible(false);
                if (response) {
                    if (response.ok === true) {
                        //Success
                        this.setState({
                            cardBinData: response.body.data.bins,
                        });

                    } else {
                        this.props.ShowToast(response.message, "exclamation");
                    }
                }
            })
            ?.catch((error) => {
                this?.props.setIsLoaderVisible(false);
                this?.props.ShowToast(error.messsage, "exclamation");
            });

    }

    fetchInstitutions = () => {
        const options = {
            method: 'GET',
            headers: this.headersData,
        }

        fetch(ApiPath + 'api/institution/fetch-institutions-for-service?service=2', options)
            ?.then(response => {
                return response.json();
            })
            .then(response => {
                if (response.ok) {
                    this.setState({
                        institutionsArray: response.body.data.sort(DynamicSort("name")),
                    })
                }
            })
    }

    handleChange = e => {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
        }, () => {
            //Check for other details based on the
            //Card number input...
            this.checkCardScheme();
            this.getCardInsitutionFromBin();

            if (value === this.state.cbxCardScheme) {
                switch (this.state.cbxCardScheme) {
                    case "Mastercard":
                        this.setState({ cardImageURL: "/assets/img/jpg/m-card.jpg" })
                        break;
                    case "Visa":
                        this.setState({ cardImageURL: "/assets/img/jpg/v-card.jpg" })
                        break;
                    case "Verve":
                        this.setState({ cardImageURL: "/assets/img/jpg/ve-card.jpg" })
                        break;
                    case "AfriGo":
                        this.setState({ cardImageURL: "/assets/img/jpg/afri-go-card.jpeg" })
                        break;
                    default:
                        this.setState({ cardImageURL: "" })
                        break;
                }
            }

        })
    }

    checkCardScheme = () => {
        /**
         * This subroutine
         * makes sure
         * that the proper
         * card scheme
         * is selected
         * each time a
         * car number is
         * typed... And the proper
         * card image is also
         * shown...
         */
        if (this.state.txtCardNumber.substring(0, 1) === "4") {
            //It's a visa card
            this.setState({
                cbxCardScheme: "Visa",
                cardImageURL: "/assets/img/jpg/v-card.jpg"
            })

        } else if ((parseInt(this.state.txtCardNumber) >= 2221 && parseInt(this.state.txtCardNumber) <= 2720)
            || (parseInt(this.state.txtCardNumber) >= 51 && parseInt(this.state.txtCardNumber) <= 55)) {
            this.setState({
                cbxCardScheme: "Mastercard",
                cardImageURL: "/assets/img/jpg/m-card.jpg"
            })

        } else if ((this.state.txtCardNumber.substring(0, 1) === "6")
            || (parseInt(this.state.txtCardNumber) >= 506099 && parseInt(this.state.txtCardNumber) <= 506198)
            || (parseInt(this.state.txtCardNumber) >= 650002 && parseInt(this.state.txtCardNumber) <= 650027)) {
            this.setState({
                cbxCardScheme: "Verve",
                cardImageURL: "/assets/img/jpg/ve-card.jpg"
            })
        } else if ((parseInt(this.state.txtCardNumber) >= 56445600)) {
            this.setState({
                cbxCardScheme: "AfriGo",
                cardImageURL: "/assets/img/jpg/afri-go-card.jpg"
            })
        } else if (this.state.txtCardNumber === "") {
            this.setState({
                cbxCardScheme: "",
                cardImageURL: ""
            })
        }
    }

    getCardInsitutionFromBin = () => {
        /**
         *
         * Filter the
         * card-bin data
         * to match the input
         * of the cardNumber
         * to auto-select
         * an institution
         * from the list...
         *
         */
        if (this.state.txtCardNumber.length >= 6 && this.state.txtCardNumber.length <= 8) {
            const output = this.state.cardBinData.find(item => item.bin === this.state.txtCardNumber)?.institution?.slug;
            this.setState({
                cbxIssuingBank: output,
            })
        }

    }

    handleDisplayDialog = () => {

        const errorBorder = "error-border";

        //validate each control
        if (this.state.txtCardNumber === "" || this.state.txtCardNumber.trim().length < 16) {
            this.props.ShowToast("Incorrect Card Number!", "exclamation");
            document.getElementById("txtCardNumber")?.classList.add(errorBorder);
            document.getElementById("txtCardNumber")?.focus();
            return;

        } else if (this.state.cbxIssuingBank === "") {
            this.props.ShowToast("No Institution selected. Select Acquiring Bank from list.", "exclamation");
            document.getElementById("cbxIssuingBank")?.classList.add(errorBorder);
            document.getElementById("cbxIssuingBank")?.focus();
            return;
        } else if (this.state.cbxCardScheme === "") {
            this.props.ShowToast("A valid Card Scheme must be selected from the list", MsgBoxType.EXCLAMATION)
            document.getElementById("cbxCardScheme")?.classList.add(errorBorder);
            document.getElementById("cbxCardScheme")?.focus();
        }

        document.querySelectorAll(".form-control").forEach(item => {
            item.classList.remove(errorBorder);
        })

        //Else, proceed to display dialog...
        this.setState({
            displayDialogClicked: true,
            data: {
                txtNameOnCard: this.state.txtNameOnCard,
                txtCardNumber: this.state.txtCardNumber,
                cbxCardScheme: this.state.cbxCardScheme,
                cardImageURL: this.state.cardImageURL,
                cbxIssuingBank: this.state.cbxIssuingBank,
                institutionsList: this.state.institutionsArray,
            }
        })

    }

    render() {

        if (this.state.clearData) {
            this.clearAllData()
        }

        const SetInstitutions = this.state.institutionsArray && this.state.institutionsArray.map(item => {
            return (
                <option key={item.slug} value={item.slug}>{item.name}</option>
            )
        })

        return (<>
            <div className="form-row-2-1-1 mb-2">
                <div className="input-group">
                    <label>Name on Card</label>
                    <input
                        type="text"
                        id="txtNameOnCard"
                        name="txtNameOnCard"
                        autoComplete="off"
                        value={this.state.txtNameOnCard}
                        className="form-control"
                        onChange={this.handleChange}
                    />
                    <label className="control-info"><i className="icofont-warning-alt"></i> Customer's Name as it appears on card</label>
                </div>
            </div>
            <div className="form-row-2-1-1 mb-2">
                <div className="input-group">
                    <label><span>*</span>Card Number</label>
                    <input
                        type="tel"
                        id="txtCardNumber"
                        name="txtCardNumber"
                        value={this.state.txtCardNumber}
                        className="form-control"
                        maxLength="19"
                        inputMode="numeric"
                        onChange={e => {
                            const inputMask = /^\d{0,20}$/g;
                            if (inputMask.test(e.target.value)) {
                                this.setState({ txtCardNumber: e.target.value }, () => {
                                    this.checkCardScheme();
                                    this.getCardInsitutionFromBin();

                                    console.log('Card number is', this.state.txtCardNumber)
                                })
                            }


                        }}
                    />
                    <label className="control-info"><i className="icofont-warning-alt"></i> Card Number</label>
                </div>
                <div className="input-group">
                    <label>Card Scheme</label>
                    <select
                        className="form-control"
                        id="cbxCardScheme"
                        name="cbxCardScheme"
                        value={this.state.cbxCardScheme}
                        onChange={this.handleChange}
                    >
                        <option value=""></option>
                        <option value="Mastercard">Mastercard</option>
                        <option value="Visa">Visa</option>
                        <option value="Verve">Verve</option>
                        <option value="AfriGo">AfriGo</option>
                    </select>
                    <label className="control-info"><i className="icofont-warning-alt"></i> (AfriGo, Mastercard, Visa or Verve)</label>
                </div>
                <div className="input-group">
                    <img src={this.state.cardImageURL} alt="" width={75} style={{ marginTop: '10px' }} />
                </div>
            </div>

            <div className="form-row-2-1-1 mb-2">
                <div className="input-group">
                    <label><span>*</span>Issuing Bank</label>
                    <select
                        className="form-control"
                        id="cbxIssuingBank"
                        name="cbxIssuingBank"
                        value={this.state.cbxIssuingBank}
                        onChange={this.handleChange}>
                        <option value=""></option>
                        {SetInstitutions}
                    </select>
                    <label className="control-info"><i className="icofont-warning-alt"></i> Select Institution from list</label>
                </div>
            </div>

            <hr className="mb-3" />
            <div className="form-row-3 mb-2">
                <div className="input-group"></div>
                <div className="input-group">
                    <button
                        className="btn-green t-tip"
                        id="btnCreateReference"
                        data-title="Click to update the reference details"
                        onClick={this.handleDisplayDialog}>
                        <i className="icofont-plus"></i> Log Card
                    </button>
                </div>
            </div>
            {this.state.displayDialogClicked &&
                <CreateTrappedCardsDialog
                    data={this.state.data}
                    ShowToast={this.props.ShowToast}
                    setIsLoaderVisible={this.props.setIsLoaderVisible}
                    displayDialogClicked={this.state.displayDialogClicked}
                    setDisplayDialog={value => this.setState({ displayDialogClicked: value })}
                    setClearData={value => this.setState({ clearData: value })}

                />}

        </>)
    }
}
/**
     * This create trapped cards form
     * basically
     * allows us to have
     * a quick view of
     * application shortcuts.
     *
     * We need to add the
     * table and the
     * table rows to
     * follow the
     * expected
     * description on
     * the requirements
     * document...
     */
/**
* This create trapped cards form
* basically
* allows us to have
* a quick view of
* application shortcuts.
*
* We need to add the
* table and the
* table rows to
* follow the
* expected
* description on
* the requirements
* document...
*/
/**
* This create trapped cards form
* basically
* allows us to have
* a quick view of
* application shortcuts.
*
* We need to add the
* table and the
* table rows to
* follow the
* expected
* description on
* the requirements
* document...
*/
