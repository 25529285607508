import React from "react";
import ApiPath from "../../admin/assets/App/api-path";
import EDMMSRoleGroup from "./components/edmms-role-group";
import ERefRoleGroup from "./components/e-ref-role-group";
import EClipsRoleGroup from "./components/e-clips-role-group";
import TrappedCardsRoleGroup from "./components/trapped-cards-role-group";

export default class UserRoleCheckGroup extends React.Component {

    constructor() {
        super()
        this.state = {
            //General
            txtRoleID: "",
            txtRoleName: "",
            txtDescription: "",

            //E-Reference
            e_Reference: "",
            eRefCode: 0,

            //Trapped Cards
            trappedCards: "",
            trappedCardsCode: 0,

            //e-Clips
            eClips: "",
            eClipsCode: 0,

            //e-DMMS
            eDMMS: "",
            eDMMSCode: 0,

        }

        this.headersData = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
            'Cache-Control': 'public, max-age=0'
        }

        //Defining string constants
        this.operator = "Operator";
        this.authorizer = "Authorizer";
        this.logger = "Logger";
        this.receiver = "Receiver";
        this.loggerReceiver = "LoggerReceiver";
        this.eDMMSOperator = "eDMMSOperator";
        this.eDMMSApprover = "Approver";

    }

    componentDidMount() {
        this.getRoleServices();
        if (this.props.data) {
            this.setState({
                txtRoleID: this.props.data.roleId,
                txtRoleName: this.props.data.name,
                txtDescription: this.props.data.description,
            })

            //Also, loop through to see what items are selected
            this.props.data.institution_user_role_services?.forEach(item => {
                switch (item.id) {
                    case 1:
                        this.setState({
                            e_Reference: this.operator,
                            eRefCode: 1
                        })
                        break;
                    case 2:
                        this.setState({
                            e_Reference: this.authorizer,
                            eRefCode: 2
                        });
                        break;
                    case 3:
                        this.setState({
                            trappedCards: this.logger,
                            trappedCardsCode: 3
                        })
                        break;
                    case 4:
                        this.setState({
                            trappedCards: this.receiver,
                            trappedCardsCode: 4,
                        })
                        break;
                    case 5:
                        this.setState({
                            trappedCards: this.loggerReceiver,
                            trappedCardsCode: 5,
                        })
                        break;
                    case 6:
                        this.setState({
                            eClips: "eClipsLogger",
                            eClipsCode: 6,
                        })
                        break;
                    case 7:
                        this.setState({
                            eClips: "eClipsReceiver",
                            eClipsCode: 7,
                        })
                        break;
                    case 8:
                        this.setState({
                            eClips: "eClipsLoggerReceiver",
                            eClipsCode: 8,
                        })
                        break;
                    case 9:
                        this.setState({
                            eDMMS: "eDMMSOperator",
                            eDMMSCode: 9,
                        })
                        break;
                    case 10:
                        this.setState({
                            eDMMS: "eDMMSApprover",
                            eDMMSCode: 10,
                        })
                        break;
                    default:
                        break;
                }
            })
        }
    }

    getRoleServices = () => {
        const options = {
            method: 'GET',
            headers: this.headersData
        }
        fetch(ApiPath + 'api/institution-type-service/fetch-institution-type-service', options)
            ?.then(response => {
                return response.json();
            })
            ?.then(response => {
                if (response && response.ok === true) {
                    this.setState({
                        roleServices: response.body.services,
                    })
                }
            })
            .catch(() => {
                this.props.ShowToast("Failed to load Role Services. Please refresh form.", "exclamation");
            })
    }

    handleChange = e => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    closeForm = () => {
        this.props?.setDisplayClicked(false);
    }

    UpdateUserRole = () => {
        const servicesArray = [];

        if (this.state.txtRoleName === "") {
            this.props.ShowToast("Enter a valid Role Name before proceeding.", "exclamation")
            return;
        }
        if (this.state.txtRoleID === "") {
            this.props.ShowToast("Enter a valid Role ID before proceeding.", "exclamation")
            return;
        }
        if (this.state.txtDescription === "") {
            this.props.ShowToast("Enter the Role Description.", "exclamation")
            return;
        }

        /**
         * The services array will contain
         * the various services...
         */
        if (this.state.e_Reference) {
            servicesArray.push({
                id: 1,
                serviceRoles: [this.state.eRefCode],
            })
        }

        if (this.state.trappedCards) {
            servicesArray.push({
                id: 2,
                serviceRoles: [this.state.trappedCardsCode],
            })
        }

        if (this.state.eClips) {
            servicesArray.push({
                id: 3,
                serviceRoles: [this.state.eClipsCode],
            })
        }

        if (this.state.eDMMS) {
            servicesArray.push({
                id: 4,
                serviceRoles: [this.state.eDMMSCode],
            })
        }

        //For the updates...
        const data = {
            "slug": this.props.data?.slug,
            "name": this.state.txtRoleName,
            "roleId": this.state.txtRoleID,
            "description": this.state.txtDescription,
            "services": servicesArray,
        }

        const options = {
            method: 'POST',
            headers: this.headersData,
            body: JSON.stringify(data),
        }

        const api = this.props.sender === "Create"
            ? "api/institution-user-role/create-institution-user-role"
            : "api/institution-user-role/update-institution-user-role";

        this.props.setIsLoaderVisible(true)
        fetch(ApiPath + api, options)
            .then(response => { return response.json() })
            .then(response => {
                this.props.setIsLoaderVisible(false)
                if (response.ok) {
                    this.props.ShowToast("User role updated successfully", "success")
                    this.props.setActionSuccessful(true)
                    this.closeForm()
                } else {
                    this.props.ShowToast(response.message, "exclamation")
                }
            })
            .catch(error => {
                this.props.setIsLoaderVisible(false)
                this.props.ShowToast(error.message, "exclamation")
            })
    }

    render() {
        const displayClass = this.props.displayClicked ? "dialog-background fade" : "dialog-background";

        return (
            <div className={displayClass}>
                <div className="dialog-container">
                    <div className="dialog-header">
                        <h2 className={this.props.sender.toLowerCase()}><i className="icofont-user-alt-5"></i> {this.props.sender} User Role</h2>
                    </div>
                    <div className="dialog-body mb-2">
                        <div className="form-row-1-2">
                            <div className="input-group">
                                <label><span>*</span>Role ID</label>
                                <input
                                    type="text"
                                    id="txtRoleID"
                                    name="txtRoleID"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.txtRoleID} />
                            </div>
                            <div className="input-group">
                                <label><span>*</span>Role Name</label>
                                <input
                                    type="text"
                                    id="txtRoleName"
                                    name="txtRoleName"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.txtRoleName} />
                            </div>
                        </div>
                        <div className="form-row-3x mb-2">
                            <div className="input-group">
                                <label><span>*</span>Description</label>
                                <textarea
                                    name="txtDescription"
                                    id="txtDescription"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.txtDescription}
                                    maxLength="499"
                                    rows="4"
                                >
                                </textarea>
                            </div>
                        </div>
                        <hr className="golden-rule" /><h3 className="row-title">Services & Role Services</h3>
                        <ERefRoleGroup
                            e_Reference={this.state.e_Reference}
                            setERef={value => this.setState({ e_Reference: value })}
                            setERefCode={value => this.setState({ eRefCode: value })}
                        />
                        <TrappedCardsRoleGroup
                            trappedCards={this.state.trappedCards}
                            setTrappedCards={value => this.setState({ trappedCards: value })}
                            setTrappedCardsCode={value => this.setState({ trappedCardsCode: value })}
                        />
                        <EClipsRoleGroup
                            eClips={this.state.eClips}
                            setEClips={value => this.setState({ eClips: value })}
                            setEClipsCode={value => this.setState({ eClipsCode: value })}
                        />
                        <EDMMSRoleGroup
                            eDMMS={this.state.eDMMS}
                            setEDMMS={value => this.setState({ eDMMS: value })}
                            setEDMMSCode={value => this.setState({ eDMMSCode: value })}
                        />

                    </div>
                    <div className="dialog-footer">
                        <button type="button" id="btnCreate"
                            className="btn-green mr-1" name="btnCreate"
                            onClick={this.UpdateUserRole}><i className="icofont-upload-alt"></i> {this.props.sender === "Edit" ? "Update" : this.props.sender} User Role</button>
                        <button type="button" className="btn-grey mr-1" id="btnCancel"
                            onClick={this.closeForm}><i className="icofont-exit"></i> Close</button>
                        <button id="testButton" className="d-none"
                            onClick={() => {
                                this.componentDidMount();
                            }} onChange={this.handleChange}>
                        </button>
                    </div>
                </div>
            </div >
        )
    }

}
