const EClipsRoleGroup = (props) => {

    const setLoggerChanges = e => {
        props.setEClips(e.target.id);
        props.setEClipsCode(6);
    }

    const setReceiverChanges = e => {
        props.setEClips(e.target.id);
        props.setEClipsCode(7);
    }

    const setLoggerReceiverChanges = e => {
        props.setEClips(e.target.id);
        props.setEClipsCode(8);
    }

    const clearValues = () => {
        props.setEClips('');
        props.setEClipsCode('');
    }

    const logger = "Logger";
    const receiver = "Receiver";

    return (
        <div className="role-group">
            <h3 className="role-group-title">e-Clips</h3>
            <div className="role-group-inputs">
                <label>
                    <input
                        type="radio"
                        id="eClipsLogger"
                        name="eClips"
                        checked={props.eClips === "eClipsLogger"}
                        onChange={setLoggerChanges}
                    /> {logger}
                </label>
                <label>
                    <input
                        type="radio"
                        id="eClipsReceiver"
                        name="eClips"
                        checked={props.eClips === "eClipsReceiver"}
                        onChange={setReceiverChanges}
                    /> {receiver}
                </label>
                <label>
                    <input
                        type="radio"
                        id="eClipsLoggerReceiver"
                        name="eClips"
                        checked={props.eClips === "eClipsLoggerReceiver"}
                        onChange={setLoggerReceiverChanges}
                    /> {logger} / {receiver}
                </label>
                <button
                    className="clear-radios btn-grey"
                    id="btnClearEClips"
                    onClick={clearValues}>
                    <i className="icofont-mop"></i> Clear
                </button>
            </div>
        </div>
    );
}

export default EClipsRoleGroup;
