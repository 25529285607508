import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Login } from "./Login/App/Login";
import { NibssUserLogin } from "./Login/App/nibss-user-login"
import { LoginValidate } from "./Login/App/login-validate";
import { ForgotPassword } from "./Login/App/forgot-password";
import { RecoverPassword } from "./Login/App/recover-password";
import { AuditLogs } from "./admin/App/audit-logs";
import { ChangePassword } from "./users/change-password/change-password";
import { Dashboard } from "./admin/App/dashboard";
import { Institutions } from "./institutions/App/institutions";
import { AdminEmails } from "./config/admin-group-emails/admin-group-emails";
import { InstitutionTypes } from "./config/institution-types/institution-types";
import { PublicHolidays } from "./config/public-holidays/public-holidays";
import { UserRoles } from "./config/user-roles/user-roles";
import { ConfigureVAT } from "./config/configure-vat/configure-vat";
import { BranchManagement } from "./branch-management/App/branch-management";
import { CreateReference } from "./e-reference/App/create-reference/create-reference";
import { OutgoingReference } from "./e-reference/App/outgoing-references/outgoing-references";
import { ReferenceReports } from "./e-reference/App/reference-reports/reference-reports";
import { Billings } from "./e-reference/App/billings/billings";
import { IncomingReferences } from "./e-reference/App/incoming-references/incoming-references";
import { ScheduledBilling } from "./e-reference/App/scheduled-billing/scheduled-billing";
import { BillingFees } from "./config/billing-fees/billing-fees";
import { ConfigureCardBin } from "./config/configure-card-bin/configure-card-bin";
import { CreateTrappedCards } from "./trapped-cards/create-trapped-cards/create-trapped-cards";
import { OutgoingCards } from "./trapped-cards/outgoing-cards/outgoing-cards";
import { IncomingCards } from "./trapped-cards/incoming-cards/incoming-cards";
import { QuickAccess } from "./admin/App/quick-access";
import { TrappedCardsReports } from "./trapped-cards/trapped-cards-reports/trapped-cards-reports";
import { BulkActionUsers } from "./bulk-actions/bulk-actions-users";
import ErrorPage from "./ErrorPage";
import { InstituionUsers } from "./users/institution-users/insitution-users";
import { NibssUsers } from "./users/nibss-users/nibss-users";
import { CreateEClips } from "./e-clips/create-e-clips/create-e-clips";
import { OverdueTime } from "./config/configure-e-clips-overdue-time/overdue-time";
import { OutgoingEClips } from "./e-clips/outgoing-e-clips/outgoing-e-clips";
import { IncomingEClips } from "./e-clips/incoming-e-clips/incoming-e-clips";
import { EClipsReports } from "./e-clips/e-clips-reports/e-clips-reports";
import { EDMMSMandates } from "./e-dmms/create-mandates-log/edmms-mandates";
import { EDMMSCompanies } from "./e-dmms/e-dmms-companies/e-dmms-companies";
import { OutgoingEDMMSMandate } from "./e-dmms/outgoing-e-dmms-mandates/outgoing-edmms-mandate";
import { IncomingEDMMSMandate } from "./e-dmms/incoming-e-dmms-mandates/incoming-edmms-mandates";
import { EDMMSReports } from "./e-dmms/e-dmms-report/e-dmms-report";
import { PublicURLPage } from "./e-clips/public/public-url-page";

const SubApp = (props) => {
    return (
        <Router>
            <Switch>
                <Route path="/" exact>
                    <Login ShowToast={props.ShowToast} setIsLoaderVisible={props.setIsLoaderVisible} />
                </Route>
                <Route path="/nibss-user-login" exact>
                    <NibssUserLogin ShowToast={props.ShowToast} setIsLoaderVisible={props.setIsLoaderVisible} />
                </Route>
                <Route path="/auth/forgot-password" exact>
                    <RecoverPassword ShowToast={props.ShowToast} setIsLoaderVisible={props.setIsLoaderVisible} />
                </Route>
                <Route path="/forgot-password" exact>
                    <ForgotPassword ShowToast={props.ShowToast} setIsLoaderVisible={props.setIsLoaderVisible} />
                </Route>
                <Route path="/login-validate" exact>
                    <LoginValidate ShowToast={props.ShowToast} setIsLoaderVisible={props.setIsLoaderVisible} />
                </Route>
                <Route path="/general/quick-access" exact>
                    {(sessionStorage.getItem("session_valid_user") !== 'null')
                        ? <QuickAccess ShowToast={props.ShowToast} setIsLoaderVisible={props.setIsLoaderVisible} />
                        : <ErrorPage />}
                </Route>
                <Route path="/admin/dashboard" exact>
                    {(sessionStorage.getItem("is_nibss_user") !== "-")
                        ? <Dashboard ShowToast={props.ShowToast} setIsLoaderVisible={props.setIsLoaderVisible} />
                        : <ErrorPage />}
                </Route>
                <Route path="/admin/audit-logs" exact>
                    {sessionStorage.getItem("session_valid_user")?.toString() !== 'null'
                        ? <AuditLogs ShowToast={props.ShowToast} setIsLoaderVisible={props.setIsLoaderVisible} />
                        : <ErrorPage />}
                </Route>
                <Route path="/config/change-password" exact>
                    {sessionStorage.getItem("session_valid_user")?.toString() !== 'null'
                        ? <ChangePassword ShowToast={props.ShowToast} setIsLoaderVisible={props.setIsLoaderVisible} />
                        : <ErrorPage />}
                </Route>
                <Route path="/institutions/institutions" exact>
                    {sessionStorage.getItem("session_valid_user")?.toString() !== 'null'
                        ? <Institutions ShowToast={props.ShowToast} setIsLoaderVisible={props.setIsLoaderVisible} />
                        : <ErrorPage />}
                </Route>
                <Route path="/config/admin-group-emails" exact>
                    {sessionStorage.getItem("is_nibss_user") !== "-"
                        ? <AdminEmails ShowToast={props.ShowToast} setIsLoaderVisible={props.setIsLoaderVisible} />
                        : <ErrorPage />}
                </Route>
                <Route path="/config/institution-types" exact>
                    {sessionStorage.getItem("is_nibss_user") !== "-"
                        ? <InstitutionTypes ShowToast={props.ShowToast} setIsLoaderVisible={props.setIsLoaderVisible} />
                        : <ErrorPage />}
                </Route>
                <Route path="/config/user-roles" exact>
                    {sessionStorage.getItem("is_nibss_user") !== "-"
                        ? <UserRoles ShowToast={props.ShowToast} setIsLoaderVisible={props.setIsLoaderVisible} />
                        : <ErrorPage />}
                </Route>
                <Route path="/config/public-holidays" exact>
                    {sessionStorage.getItem("is_nibss_user") !== "-"
                        ? <PublicHolidays ShowToast={props.ShowToast} setIsLoaderVisible={props.setIsLoaderVisible} />
                        : <ErrorPage />}
                </Route>
                <Route path="/config/configure-VAT" exact>
                    {(sessionStorage.getItem("is_nibss_user")?.toString() !== "-")
                        ? <ConfigureVAT ShowToast={props.ShowToast} setIsLoaderVisible={props.setIsLoaderVisible} />
                        : <ErrorPage />}
                </Route>
                <Route path="/user-management/nibss-users" exact>
                    {sessionStorage.getItem("session_valid_user")?.toString() !== "null"
                        ? <NibssUsers ShowToast={props.ShowToast} setIsLoaderVisible={props.setIsLoaderVisible} />
                        : <ErrorPage />}
                </Route>
                <Route path="/user-management/institution-users" exact>
                    {sessionStorage.getItem("session_valid_user")?.toString() !== "null"
                        ? <InstituionUsers ShowToast={props.ShowToast} setIsLoaderVisible={props.setIsLoaderVisible} />
                        : <ErrorPage />}
                </Route>
                <Route path="/branch-management/branch-management" exact>
                    {sessionStorage.getItem("session_valid_user") !== "null"
                        ? <BranchManagement ShowToast={props.ShowToast} setIsLoaderVisible={props.setIsLoaderVisible} />
                        : <ErrorPage />}
                </Route>
                <Route path="/e-reference/create-reference" exact>
                    <CreateReference ShowToast={props.ShowToast} setIsLoaderVisible={props.setIsLoaderVisible} />
                </Route>
                <Route path="/e-reference/outgoing-references" exact>
                    <OutgoingReference ShowToast={props.ShowToast} setIsLoaderVisible={props.setIsLoaderVisible} />
                </Route>
                <Route path="/e-reference/incoming-references" exact>
                    <IncomingReferences ShowToast={props.ShowToast} setIsLoaderVisible={props.setIsLoaderVisible} />
                </Route>
                <Route path="/e-reference/reference-reports" exact>
                    <ReferenceReports ShowToast={props.ShowToast} setIsLoaderVisible={props.setIsLoaderVisible} />
                </Route>
                <Route path="/trapped-cards/trapped-cards-reports" exact>
                    <TrappedCardsReports ShowToast={props.ShowToast} setIsLoaderVisible={props.setIsLoaderVisible} />
                </Route>
                <Route path="/e-reference/billing-and-reports" exact>
                    <Billings ShowToast={props.ShowToast} setIsLoaderVisible={props.setIsLoaderVisible} />
                </Route>
                <Route path="/e-reference/billing-and-reports-scheduled-billings" exact>
                    <ScheduledBilling ShowToast={props.ShowToast} setIsLoaderVisible={props.setIsLoaderVisible} />
                </Route>
                <Route path="/configuration/e-reference-billing-fee-settings" exact>
                    <BillingFees ShowToast={props.ShowToast} setIsLoaderVisible={props.setIsLoaderVisible} />
                </Route>
                <Route path="/configuration/trapped-cards-bin" exact>
                    <ConfigureCardBin ShowToast={props.ShowToast} setIsLoaderVisible={props.setIsLoaderVisible} />
                </Route>
                <Route path="/trapped-cards/log-trapped-card" exact>
                    <CreateTrappedCards ShowToast={props.ShowToast} setIsLoaderVisible={props.setIsLoaderVisible} />
                </Route>
                <Route path="/trapped-cards/outgoing-trapped-cards" exact>
                    <OutgoingCards ShowToast={props.ShowToast} setIsLoaderVisible={props.setIsLoaderVisible} />
                </Route>
                <Route path="/trapped-cards/incoming-trapped-cards" exact>
                    <IncomingCards ShowToast={props.ShowToast} setIsLoaderVisible={props.setIsLoaderVisible} />
                </Route>
                <Route path="/user-management/bulk-user-actions" exact>
                    <BulkActionUsers ShowToast={props.ShowToast} setIsLoaderVisible={props.setIsLoaderVisible} />
                </Route>
                <Route path="/e-clips/new-clip-request" exact>
                    <CreateEClips ShowToast={props.ShowToast} setIsLoaderVisible={props.setIsLoaderVisible} />
                </Route>
                <Route path="/configuration/e-clips-charges-settings" exact>
                    <OverdueTime ShowToast={props.ShowToast} setIsLoaderVisible={props.setIsLoaderVisible} />
                </Route>
                <Route path="/e-clips/outgoing-e-clips" exact>
                    <OutgoingEClips ShowToast={props.ShowToast} setIsLoaderVisible={props.setIsLoaderVisible} />
                </Route>
                <Route path="/e-clips/incoming-e-clips" exact>
                    <IncomingEClips ShowToast={props.ShowToast} setIsLoaderVisible={props.setIsLoaderVisible} />
                </Route>
                <Route path="/e-clips/e-clips-reports" exact>
                    <EClipsReports ShowToast={props.ShowToast} setIsLoaderVisible={props.setIsLoaderVisible} />
                </Route>
                <Route path="/e-dmms/create-edmms-mandate-form" exact>
                    <EDMMSMandates ShowToast={props.ShowToast} setIsLoaderVisible={props.setIsLoaderVisible} />
                </Route>
                <Route path="/e-dmms/e-dmms-companies" exact>
                    <EDMMSCompanies ShowToast={props.ShowToast} setIsLoaderVisible={props.setIsLoaderVisible} />
                </Route>
                <Route path="/e-dmms/outgoing-edmms-mandates" exact>
                    <OutgoingEDMMSMandate ShowToast={props.ShowToast} setIsLoaderVisible={props.setIsLoaderVisible} />
                </Route>
                <Route path="/e-dmms/incoming-edmms-mandates" exact>
                    <IncomingEDMMSMandate ShowToast={props.ShowToast} setIsLoaderVisible={props.setIsLoaderVisible} />
                </Route>
                <Route path="/e-dmms/edmms-reports" exact>
                    <EDMMSReports ShowToast={props.ShowToast} setIsLoaderVisible={props.setIsLoaderVisible} />
                </Route>
                <Route path="/e-clips/0/public">
                    <PublicURLPage />
                </Route>

                {/*404 Page - When all else fails*/}
                <Route path="*"><ErrorPage /></Route>

            </Switch>
        </Router >
    );
}

export default SubApp;
