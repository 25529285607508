import NavBar from "../../../admin/assets/App/nav-bar"
import TopBar from "../../../admin/assets/App/topbar"
import LockedScreen from "../../../admin/assets/App/LockedScreen";
import ScheduledBillingForm from "./scheduled-billing-form";

import { useState } from 'react';
import { Helmet } from "react-helmet";
import Footer from "../../../admin/assets/App/footer";

export const ScheduledBilling = (props) => {

    /**
     * This is the scheduled billings
     * container
     * for the main module....
     */
    const [mainClass, setMainClass] = useState("main");

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Scheduled Billing | NIBSS Document Management Hub Portal</title>
            </Helmet>
            <LockedScreen
                setIsLoaderVisible={props.setIsLoaderVisible}
                ShowToast={props.ShowToast}
            />
            <div className="cont-ainer">
                <NavBar sender="Scheduled Billing" />
                <div className={mainClass}>
                    <TopBar
                        setMainClass={value => setMainClass(value)}
                    />
                    <div className="page-title">
                        <h2><i className="bx bx-timer"></i>Scheduled Billing</h2>
                    </div>
                    <div className="branch-summaries">
                        <div className="summaries">
                            <ScheduledBillingForm
                                ShowToast={props.ShowToast}
                                setIsLoaderVisible={props.setIsLoaderVisible}
                            />
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>

        </>
    )
}
