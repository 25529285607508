
export const CalendarHeader = (props) => {
    return(
      <div id="header">
        <div id="monthDisplay">{props.dateDisplay}</div>
        <div>
          <button onClick={props.onBack} id="backButton" className="btn-green mr-1"><i className="bx bx-chevron-left"></i> Back</button>
          <button onClick={props.onNext} id="nextButton" className="btn-green">Next <i className="bx bx-chevron-right"></i></button>
        </div>
      </div>
    );
  };
