import { useState } from 'react';
import ApiPath from '../../admin/assets/App/api-path';
import { Enums } from '../../_common/enums';

export const DeleteEventModal = (props) => {

  const [txtEditHoliday, setTxtEditHoliday] = useState(props.event?.title)

  const updateHoliday = () => {
    /**
    Function to update
    Holiday in each
    case... */

    const slugValue = props.event?.slug;

    props.setIsLoaderVisible(true)
    const data = {
      "slug": slugValue,
      "title": txtEditHoliday,
      "holidayDate": props.event?.holiday_date,
    }

    const options = {
      method: 'POST',
      headers: Enums.headersData(sessionStorage.getItem("session_token")),
      body: JSON.stringify(data),
    }

    fetch(ApiPath + 'api/holiday/update-holiday', options)
      .then(response => {
        props.setIsLoaderVisible(false);
        return response.json();
      })
      .then(response => {
        props.setIsLoaderVisible(false)
        if (response) {
          if (response.ok === true) {
            props.ShowToast("Holiday Updated succesfully!", "success")
            props.onClose()
          } else {
            props.ShowToast(response.message, "exclamation")
            return;
          }

        } else {
          props.ShowToast("Unable to update. Try again.", "exclamation")
        }
      })
      .catch(err => {
        props.setIsLoaderVisible(false)
        props.ShowToast(err.message, "exclamation")
      })
  }

  return (
    <div id="modalBackDrop">
      <div id="deleteEventModal" className="lock-container">
        <div className="dialog-header">
          <h3 className="edit"><i className="icofont-ui-calendar"></i> Update / Delete Holiday - ({props.event?.holiday_date})</h3>
        </div>
        <div className="dialog-body">
          <p>
            Edit / Delete the following event...
          </p>
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              id="txtEditHoliday"
              name="txtEditHoliday"
              value={txtEditHoliday}
              onChange={e => setTxtEditHoliday(e.target.value)}
            />
          </div>
        </div>

        <div className="dialog-footer mt-2">
          <button
            className="btn-green mr-1"
            onClick={updateHoliday}
            id="updateButton">
            <i className="icofont-upload-alt"></i>  Update
          </button>
          <button
            className="btn-danger mr-1"
            onClick={props.onDelete}
            id="deleteButton">
            <i className="icofont-delete-alt"></i>  Delete
          </button>
          <button
            className="btn-grey"
            onClick={props.onClose}
            id="closeButton">
            <i className="icofont-exit"></i> Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

