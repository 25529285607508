import NavBar from "../../admin/assets/App/nav-bar"
import TopBar from "../../admin/assets/App/topbar"
import LockedScreen from "../../admin/assets/App/LockedScreen";
import IncomingCardsForm from "./incoming-cards-form";
import Footer from "../../admin/assets/App/footer";
import { useState } from 'react';
import { Helmet } from "react-helmet";

export const IncomingCards = props => {
    const [mainClass, setMainClass] = useState("main");

     /**
     * This incoming cards form
     * basically
     * allows us to have
     * a quick view of
     * application shortcuts.
     *
     * We need to add the
     * table and the
     * table rows to
     * follow the
     * expected
     * description on
     * the requirements
     * document...
     */

     /**
     * This incoming cards form
     * basically
     * allows us to have
     * a quick view of
     * application shortcuts.
     *
     * We need to add the
     * table and the
     * table rows to
     * follow the
     * expected
     * description on
     * the requirements
     * document...
     */

     /**
     * This incoming cards form
     * basically
     * allows us to have
     * a quick view of
     * application shortcuts.
     *
     * We need to add the
     * table and the
     * table rows to
     * follow the
     * expected
     * description on
     * the requirements
     * document...
     */

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Traped Cards/Incoming Trapped Cards | NIBSS Document Management Hub Portal</title>
            </Helmet>
            <LockedScreen
                setIsLoaderVisible={props.setIsLoaderVisible}
                ShowToast={props.ShowToast}
            />

            <div className="cont-ainer">
                <NavBar sender="Incoming Trapped Cards" />
                <div className={mainClass}>
                    <TopBar
                        setMainClass={value => setMainClass(value)}
                    />
                    <div className="page-title">
                        <h2><i className="icofont-mr icofont-folder-open"></i>Incoming Trapped Cards</h2>
                    </div>
                    <div className="branch-summaries">
                        <div className="summaries">
                            <IncomingCardsForm
                                ShowToast={props.ShowToast}
                                setIsLoaderVisible={props.setIsLoaderVisible}
                            />
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>

        </>
    )
}
