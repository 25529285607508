const CompaniesSearchList = [
    {
        id: 1,
        idVal: "companyNumber",        //This is the searchField in the database
        value: "Company No",
        itemName: "Company Number",
    },
    {
        id: 2,
        idVal: "companyName",
        value: "Company",
        itemName: "Company Name",
    },
    {
        id: 3,
        idVal: "rcNo",
        value: "RC Number",
        itemName: "RC Number",
    },
    {
        id: 4,
        idVal: "registrar",
        value: "Registrar",
        itemName: "Registrar",
    },
    {
        id: 5,
        idVal: "status",
        value: "Status",
        itemName: "Status",
    },

]

export default CompaniesSearchList
