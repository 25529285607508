import React from "react";
import BillingsFormData from "./billings-form-data";

export default class EditBillingsDialog extends React.Component {
    constructor() {
        super();
        this.state = {
            cbxService: "",
            cbxDateFrom: "",
            cbxDateTo: "",
            chkSendEmail: false,
            txtSpecialEmail: "",
        }
    }

    componentDidMount() {
        if (this.props.data) {
            //Then proceed to set state...
            this.setState({
                cbxService: this.props.data?.service?.slug,
                cbxDateFrom: this.props.data?.startDate,
                cbxDateTo: this.props.data?.endDate,
                chkSendEmail: this.props.data?.isMailable === "yes" ? true : false,
            })
        }
    }

    handleChange = e => {
        const { name, value, type, checked } = e.target;
        type === "checkbox" ? this.setState({
            [name]: checked,
        }) : this.setState({
            [name]: value,
        })
    }

    closeForm = () => {
        this.props.setDisplayEditDialog(false)
    }

    render() {

        const dialogClass = this.props.displayEditDialog ? "dialog-background fade" : "dialog-background";

        return (
            <div className={dialogClass}>
                <BillingsFormData
                    data={this.props.data}
                    ShowToast={this.props.ShowToast}
                    setIsLoaderVisible={this.props.setIsLoaderVisible}
                    roleServices={this.props.roleServices}
                    cbxService={this.state.cbxService}
                    cbxDateFrom={this.state.cbxDateFrom}
                    cbxDateTo={this.state.cbxDateTo}
                    handleChange={this.handleChange}
                    chkSendEmail={this.state.chkSendEmail}
                    closeForm={this.closeForm}
                    setDisplayEditDialog={this.props.setDisplayEditDialog}
                    setActionSuccessful={this.props.setEditSuccessful}
                    nameTag={"Edit"}
                />
                {/* For testing purposes... Doesnt actually display anything..*/}
                <button
                    className="d-none"
                    id="testButton"
                    onClick={this.handleSubmit}
                    onChange={this.handleChange}
                ></button>
                <input className="d-none" type="checkbox" id="chkTest" onChange={this.handleChange} />

            </div>
        )
    }
}
