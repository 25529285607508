const CardBinSearchList = [
    {
        id: 1,
        idVal: "bin",        //This is the searchField in the database
        value: "Card Bin",
        itemName: "Card Bin",
    },
    {
        id: 2,
        idVal: "institution",
        value: "Institution",
        itemName: "Institution",
    },
]

export default CardBinSearchList
