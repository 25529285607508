import React from 'react'
import { useFetch } from '../../../hooks/useFetch';
import ApiPath from "../../../admin/assets/App/api-path";
import objectToCSV from '../../../admin/assets/App/object-to-csv';
import ExportToCSVFile from '../../../admin/assets/App/ExportToCSVFile';
import { ShortDateToCoded, SplitDate } from '../../../admin/assets/App/split-date';
import GetServices from '../../../admin/assets/App/GetServices';

export const DownloadEClipsBillingsDialog = props => {

    const { displayDownloadDialog,
        closeForm,
        inputData,
        ShowToast,
        setIsLoaderVisible
    } = props;

    const fileTitle = `${SplitDate(inputData?.createdAt).codedDate} ${GetServices(inputData?.service?.name)} ` +
        `${ShortDateToCoded(inputData?.startDate?.substring(0, 10))}-${ShortDateToCoded(inputData?.endDate?.substring(0, 10))}`;


    const downloadData = input => {
        // Depeneding on the input data...
        if (input.report.length === 0) {
            ShowToast("No data to download. Number or rows of data is zero (0).", "exclamation");
            return;
        }

        // Else, proceed...
        const thisData = input.report?.map(item => {
            return ({
                "Date": item.endDAte,
                "Product_Code": item.productCode,
                "Amount": item.billingAmount,
                "Payee_Beneficiary": item.institutionName,
                "Narration": item?.narration,
            })
        })

        const csvData = objectToCSV(thisData);
        ExportToCSVFile(csvData, fileTitle + " - billing-file.csv")

    }

    const apiRequest = "api/billing/eclip-billing-request/";
    const apiViews = "api/billing/eclip-billing-view/";

    const { isLoading: isRequestLoading, data: requestsData, error: requestsError } = useFetch(`${ApiPath}${apiRequest}${inputData.slug}`);
    const { isLoading: isViewLoading, data: viewsData, error: viewsError } = useFetch(`${ApiPath}${apiViews}${inputData.slug}`);

    (isViewLoading || isRequestLoading) ? setIsLoaderVisible(true) : setIsLoaderVisible(false);
    (requestsError || viewsError) && ShowToast(requestsError || viewsError, "exclmation");

    const dialogClass = displayDownloadDialog ? "modal fade show" : "modal fade"

    return (
        <>
            <div className={dialogClass}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="nully"><i className="icofont-download"></i> Download Billings</h2>
                        </div>
                        <div className="modal-body">
                            {/* Create tabs here for each type of download
                        --Remember to use document - Download Billings - item 6... */}

                            <div className="dialog-table-header">
                                <i className="icofont-ui-file" />
                                <span>{fileTitle}</span>
                            </div>
                            <table className="dialog-table">
                                <thead>
                                    <tr>
                                        <th scope="col" className="align-left">TITLE</th>
                                        <th scope="col" className="align-center">ACTION</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>eClips Views</td>
                                        <td className="align-center">
                                            <button
                                                id="btnDownloadEClipsViews"
                                                className="btn-grey"
                                                title="Download Views"
                                                onClick={() => downloadData(viewsData)}>
                                                <i className="icofont-download"></i> Download
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>eClips Requests</td>
                                        <td className="align-center">
                                            <button
                                                id="btnDownloadEClipsRequests"
                                                className="btn-grey"
                                                title="Download Requests"
                                                onClick={() => downloadData(requestsData)}>
                                                <i className="icofont-download"></i> Download
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <hr className="golden-rule" />
                        </div>
                        <div className="modal-footer">
                            <button
                                id="btnClose"
                                name="btnClose"
                                className="btn-grey"
                                onClick={closeForm}
                            ><i className="icofont-exit"></i> Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
