import ApiPath from "../../admin/assets/App/api-path";
import { SplitDate } from "../../admin/assets/App/split-date"
import { useState } from 'react';

export const EDMMSCompaniesTableRows = props => {

    // Set the headers in an unchanging state...
    const [headers] = useState({
        "Content-Type": "application/json",
        "Authorization": `Bearer ${sessionStorage.getItem("session_token")}`,
        'Cache-Control': 'public, max-age=0'
    })

    const toggleStatus = () => {
        /**
         * Toggles the status
         * of the item...
         */

        const itemSlug = props.apiData.slug;
        const endpoint = props.apiData.status === 'active' ? 'api/edmms-company/disable-edmms-company' : 'api/edmms-company/enable-edmms-company';

        // Set any require variable as options...
        const toggleOptions = {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ slug: itemSlug })
        }

        props.setIsLoaderVisible(true);

        // Go ahead and fire the API...
        fetch(`${ApiPath}${endpoint}`, toggleOptions)
            ?.then(reply => (reply.json()))
            ?.then(response => {
                // close loader first...
                props.setIsLoaderVisible(false);

                // Check the response...
                if (response && response.ok) {
                    // Update was successful...
                    props.ShowToast("Updated status successfully!", "success");
                    props.createSuccessful();

                } else {
                    props.ShowToast(response?.message, "exclamation");
                }
            })
            .catch(errMsg => {
                props.setIsLoaderVisible(false);
                props.ShowToast(errMsg.message, "exclamation");
            })

    }

    const eyeImage = (props.apiData.status === "active") ? 'icofont-eye-blocked' : 'icofont-eye'
    const activeIcon = props.apiData.status === "active" ? 'icofont-play-alt-1' : 'icofont-stop';
    const styleColor = props.apiData.status === "active" ? 'badge-success' : 'badge-danger';

    return (
        <tr>
            <td className="align-center">{props.counter}.</td>
            <td>{props.apiData.companyNumber}</td>
            <td>{props.apiData.companyName}</td>
            <td>{props.apiData.rcNo}</td>
            <td>{props.apiData.registrar?.name}</td>
            <td>{SplitDate(props.apiData.createdAt).dateTime}</td>
            <td className="align-center"><span className={styleColor}><i className={activeIcon} /></span></td>
            <td className="align-center">
                <button
                    id="btnEdit"
                    onClick={() => props.editRowItem(props.apiData.slug)}
                    className="btn-grey mr-1">
                    <i className="icofont-edit" /> Edit
                </button>
                <button
                    id="btnToggle"
                    className="btn-grey"
                    onClick={toggleStatus}
                >
                    <i className={eyeImage} style={{ fontSize: 15 }} />
                </button>
            </td>
        </tr>
    )
}
