import React from "react";
import ApiPath from "../../admin/assets/App/api-path"

const ConfigureBinTableRows = (props) => {
 /**
     * This configure card bin table
     * basically
     * allows us to have
     * a quick view of
     * application shortcuts.
     *
     * We need to add the
     * table and the
     * table rows to
     * follow the
     * expected
     * description on
     * the requirements
     * document...
     */

    const DeleteItem = () => {

        const confirmed = window.confirm("Are you sure you wish to delete the selected item?");

        if (confirmed) {
            //Go ahead and implement the delete functionality...
            const deleteSlug = { slug: props.data.slug }

            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                    'Cache-Control': 'public, max-age=0'
                },
                body: JSON.stringify(deleteSlug),
            }

            fetch(`${ApiPath}api/bin/delete-bin`, options)
                ?.then(response => {
                    props.setIsLoaderVisible(false)
                    return response.json();
                })
                .then(response => {
                    props.setIsLoaderVisible(false)
                    if (response) {
                        if (response.ok === true) {
                            props.ShowToast("Card Bin record deleted successfully!", "success")
                            props.setActionSuccessful(true)
                        } else {
                            props.ShowToast(response.message, "exclamation")
                        }
                    }
                })
                .catch(error => {
                    props.ShowToast(error.message, "exclamation")
                })
        }
    }

    return (
        <tr>
            <td className="align-center">{props.counter}.</td>
            <td>{props.data.bin}</td>
            <td>{props.data.institution?.name}</td>
            <td className="align-center">
                <button
                    id="btnDelete"
                    className="btn-danger"
                    onClick={DeleteItem}
                > <i className="icofont-ui-delete"></i> Delete</button>
            </td>
        </tr>
    );

}

export default ConfigureBinTableRows;
