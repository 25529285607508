// Convert file to base64 string
/**
 * Converts the input file object
 * to its base64 equivalent and
 * outputs the result
 * as a promise...
 */

export const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
};
