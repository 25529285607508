import React from "react"
import ExportToCSVFile from "../../admin/assets/App/ExportToCSVFile";
import objectToCSV from "../../admin/assets/App/object-to-csv";
import ApiPath from "../../admin/assets/App/api-path";
import SearchComponent from "../../admin/assets/App/SearchComponent";
import Pagination from "../../admin/assets/App/Pagination";
import TrappedCardsReportSearchList from "../../admin/assets/data/TrappedCardsReportSearchList";
import TrappedCardsReportsTableRows from "./trapped-cards-reports-table-rows";
import CapitalizeFirstLetter from "../../admin/assets/App/CapitalizeFirstLetter";
import { SplitDate } from "../../admin/assets/App/split-date";
import DynamicSort from "../../admin/assets/App/DynamicSort";
import TrappedCardsReportsSummary from "./trapped-cards-reports-summary"
import MaskCardNumber from "../../admin/assets/App/MaskCardNumber";
import { DateRange } from "../../components/date-range";

export default class TrappedCardsReportsForm extends React.Component {

    constructor() {
        super()
        this.state = {
            apiData: [],
            metaData: {},
            allData: [],
            exportData: [],
            institutionsArray: [],
            fetchSuccessful: true, //Change later...
            actionSuccessful: false,

            //Summary data
            summaryInfo: [],

            //Search parameters
            searchField: "",
            searchValue: "",
            searchValue2: "",
            displayApproveDialog: false,
            startDate: '',
            endDate: '',

            //Pagination values
            currentSelectedPage: 1,
            arrayPages: [],
            lastPage: 1,
            perPage: localStorage.getItem("items_per_page") || 20,
            totalItems: 0,
        }

        this.headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
            'Cache-Control': 'public, max-age=0'
        }
    }

    ExportData = () => {
        /**
         * Export data
         * to MSExcel file
         * after filtering
         * ensure, make sure
         * the values sent are
         * all rows of filtered data
         */
        const previousPerPage = this.state.perPage;

        this.setState({ perPage: 20000 }, () => {
            this.getTrappedCardsData();

            //Work on the export proper here...
            const dataToExport = [];
            this.state.apiData.forEach(item => {
                //get individual data;
                const treatedBy = item.status === 'accepted' ? item.trappedCardLog?.acceptedByUser?.email : item.trappedCardLog?.rejectedByUser?.email;
                let thisObject = {
                    "Log Issue ID": `${item.trappedCardNo}\t`,
                    "Card Number": `${MaskCardNumber(item.cardNumber)}\t`,
                    "Name on Card": item.nameOnCard,
                    "Issuing Institution": item.issuingInstitution?.name,
                    "Acquiring Institution": item.acquiringInstitution?.name,
                    "Branch": item.branch || "-",
                    "Card Scheme": CapitalizeFirstLetter(item.cardScheme),
                    "Initiate Date": SplitDate(item.createdAt).dateTime,
                    "Treated By": treatedBy || "-",
                    "Status": item.status,
                }

                dataToExport.push(thisObject);

            })

            const csvData = objectToCSV(dataToExport);
            ExportToCSVFile(csvData, "Trapped Cards Reports.csv");

            this.props.ShowToast("Trapped cards report exported successfully! Open downloads folder to preview...", "information");

            //Return the number of items per page
            this.setState({ perPage: previousPerPage }, () => this.getTrappedCardsData())

        });

    }

    fetchInstitutions = () => {
        const options = {
            method: 'GET',
            headers: this.headers,
        }

        fetch(ApiPath + 'api/institution/fetch-institutions', options)
            ?.then(response => {
                return response.json();
            })
            .then(response => {
                if (response.ok) {
                    this.setState({
                        institutionsArray: response.body.data.institutions.sort(DynamicSort("name")),
                    })
                }
            })
    }

    getAllData = () => {
        const options = {
            method: 'GET',
            headers: this.headers,
        }
        fetch(`${ApiPath}api/trapped-card/fetch-trapped-card-report?limit=20000`, options)
            ?.then(response => { return response.json() })
            .then(res => {
                if (res && res.ok) {
                    this.setState({ allData: res.body.trappedCards?.trappedCards.data })
                }
            })
    }

    fetchSummaryInfo = () => {
        // Do nothing...
        console.log('Doesnt work anymore');
    }

    setQueryStringOutput = () => {
        let queryString = "";
        const dateRangeFilter = `&startDate=${this.state.startDate}&endDate=${this.state.endDate}`;

        if (this.state.searchField === "issuingInstitution" || this.state.searchField === "acquiringInstitution") {
            //Set the slug as the required searchField...
            const institutionSlug = this.state.institutionsArray.find(item => item.name === this.state.searchValue)?.slug;

            queryString = `${ApiPath}api/trapped-card/fetch-trapped-card-report?page=${this.state.currentSelectedPage}` +
                `&${this.state.searchField}=${institutionSlug}&limit=${this.state.perPage}${dateRangeFilter}`;

        } else {
            queryString = `${ApiPath}api/trapped-card/fetch-trapped-card-report?page=${this.state.currentSelectedPage}` +
                `&${this.state.searchField}=${this.state.searchValue}&limit=${this.state.perPage}${dateRangeFilter}`;
        }

        return queryString;
    }

    getTrappedCardsData = () => {

        /**
         * Set the endpoints to match the cards later...
         */
        this.props.setIsLoaderVisible(true)
        const options = {
            method: 'GET',
            headers: this.headers,
        }

        const queryString = this.setQueryStringOutput();

        fetch(queryString, options)
            ?.then(response => {
                return response.json();
            })
            .then(serverRes => {
                this.props.setIsLoaderVisible(false)
                if (serverRes && serverRes.ok === true) {
                    this?.setState({
                        apiData: serverRes.body.trappedCards.trappedCards.data,
                        metaData: serverRes.body.trappedCards.trappedCards.meta,
                        summaryInfo: serverRes.body.trappedCards.summary,
                        fetchSuccessful: true,
                        actionSuccessful: false,
                        arrayPages: [],
                    }, () => {
                        const pagArray = [];
                        this.setState({
                            currentSelectedPage: this.state.metaData?.current_page,
                            lastPage: this.state.metaData?.last_page,
                            totalItems: this.state.metaData?.total,
                        }, () => {
                            //Set this array for the looping
                            for (let i = 1; i <= this.state.lastPage; i++) {
                                pagArray.push({ "id": i, "name": "Item " + i });
                            }

                            this.setState({ arrayPages: pagArray })
                        })
                    })
                } else {
                    this.props.setIsLoaderVisible(false);
                    this.props.ShowToast(serverRes?.message, "exclamation");
                }
            })
    }

    componentDidMount() {
        this.fetchInstitutions();
        this.getTrappedCardsData();
        this.getAllData();
        this.fetchSummaryInfo();
    }

    RefreshForm = () => {
        window.location.reload();
    }

    filterRecords = () => {
        this.getTrappedCardsData();
        this.fetchSummaryInfo();
        this.getTrappedCardsData();
    }

    render() {

        let counter = 0;
        const reportsTableRows = this.state.fetchSuccessful && this.state.apiData?.map(item => {
            counter++;
            const itemCounter = (this.state.currentSelectedPage - 1) * this.state.perPage + counter

            return (<TrappedCardsReportsTableRows
                key={counter}
                counter={itemCounter}
                item={item}
                setIsLoaderVisible={this.props.setIsLoaderVisible}
                ShowToast={this.props.ShowToast}

            />)
        })

        if (this.state.actionSuccessful) {
            this.getTrappedCardsData()
            this.setState({
                actionSuccessful: false,
            })
        }

        const trappedCardNo = [];
        const cardNumber = [];

        this.state.allData.forEach(item => {
            trappedCardNo.push({ item_name: item.trappedCardNo })
            cardNumber.push({ item_name: item.cardNumber })
        })

        const status = [{ item_name: "Pending" }, { item_name: "Accepted" }, { item_name: "Rejected" }]

        const institutionNames = this.state.institutionsArray.map(item => {
            return (
                { item_name: item.name }
            )
        })

        return (
            <>
                <div className="title">
                    <DateRange
                        callback={this.filterRecords}
                        setDateFrom={val => this.setState({ startDate: val })}
                        setDateTo={val => this.setState({ endDate: val })}
                        ShowToast={this.props.ShowToast}
                    />
                    <SearchComponent
                        setSearchValue={val => this.setState({ searchValue: val })}
                        setSearchValue2={val => this.setState({ searchValue2: val })}
                        ReferenceSearchList={TrappedCardsReportSearchList}
                        setSearchField={val => this.setState({ searchField: val })}
                        inputList={{ status, institutionNames, cardNumber, trappedCardNo }}
                        filterRecords={this.filterRecords}
                        ShowToast={this.props.ShowToast}
                    />
                    <div className="buttons">
                        <button
                            id="btnRefresh"
                            data-title="Refresh"
                            type="button"
                            className="btn-grey t-tip mr-1"
                            onClick={this.RefreshForm}>
                            <i className="icofont-refresh"></i> Refresh
                        </button>
                        <button
                            className="btn-green t-tip"
                            id="btnExport"
                            data-title="Export records to MsExcel file"
                            type="button"
                            onClick={this.ExportData}>
                            <i className="icofont-airplane-alt"></i> Export</button>
                    </div>
                </div>
                <div className="table-container">
                    <table className="bordered-table some-class">
                        <caption></caption>
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col" className="align-left">NAME ON CARD</th>
                                <th scope="col" className="align-left">TRAPPED CARD NO</th>
                                <th scope="col" className="align-left">CARD NO</th>
                                <th scope="col" className="align-left">ISSUING-BANK</th>
                                <th scope="col" className="align-left">ACQUIRING-BANK</th>
                                <th scope="col" className="align-center">BRANCH</th>
                                <th scope="col" className="align-center">CARD-SCHEME</th>
                                <th scope="col" className="align-center">DATE-INITIATED</th>
                                <th scope="col" className="align-center">TREATED BY</th>
                                <th scope="col" className="align-center" width="120">STATUS</th>
                            </tr>
                        </thead>
                        <tbody id="trapped_cards_report_table">
                            {reportsTableRows}
                        </tbody>
                    </table>
                </div>
                <Pagination
                    setRefreshForm={value => this.setState({ actionSuccessful: value })}
                    perPage={this.state.perPage}
                    setCurrentSelectedPage={value => this.setState({ currentSelectedPage: value })}
                    setPerPage={value => this.setState({ perPage: value })}
                    currentSelectedPage={this.state.currentSelectedPage}
                    lastPage={this.state.lastPage}
                    arrayPages={this.state.arrayPages}
                />
                <TrappedCardsReportsSummary
                    summaryInfo={this.state.summaryInfo}
                />
            </>
        )
    }
}
