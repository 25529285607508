import ApiPath from "../../admin/assets/App/api-path";
import { SplitDate } from "../../admin/assets/App/split-date";

const InstitutionUsersTableRows = (props) => {

    const handleEditRowClicked = value => {
        props.EditUser(value)
    }

    const handleEnableDisable = () => {
        const status = props.apiData.status === "active" ? "deactivate" : "activate";
        const confirmaAction = window.confirm(`Are you sure you wish to ${status} the selected user?`);

        if (confirmaAction) {
            const data = { "slug": props.apiData.slug }
            const options = {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                    'Content-Type': 'application/json',
                    'Cache-Control': 'public, max-age=0'
                },
                body: JSON.stringify(data),
            }

            fetch(ApiPath + "api/user/change-user-status", options)
                .then(response => {
                    props.setIsLoaderVisible(false)
                    return response.json();
                })
                .then(resp => {
                    props.setIsLoaderVisible(false)
                    if (resp) {
                        if (resp.ok === true) {
                            //Success
                            props.ShowToast(`User ${status}d successfully!`, "success")
                            props.setIsCreateSuccessful(true)
                        } else {
                            props.ShowToast(resp.message, "exclamation")
                            return;
                        }
                    } else {
                        props.ShowToast("Invalid input value(s). Please enter correct values.", "exclamation")
                    }
                })
                .catch(error => {
                    props.setIsLoaderVisible(false)
                    props.ShowToast(error.message, "exclamation")
                })
        }
    }

    const isNibssUser = sessionStorage.getItem("is_nibss_user")?.indexOf("NIBSS") > -1 ? true : false;
    const badgeType = (props.apiData.status === "active") ? 'badge-success' : 'badge-danger'
    const icofontImage = (props.apiData.status === "active") ? 'icofont-play-alt-1' : 'icofont-stop'
    const eyeImage = (props.apiData.status === "active") ? 'icofont-eye-blocked' : 'icofont-eye'

    const buttonDisplay = (!isNibssUser && props.apiData.user_type === "admin") ? "btn-grey d-none" : "btn-grey mr-1";

    //Get the institution from it's branch data...
    const institutionName = props.apiData.institution?.name
        ? props.apiData.institution?.name
        : props.branchList.find(item => item.slug === props.apiData.branch?.slug)?.institution?.name;

    return (
        <tr>
            <td className="align-center">{props.counter}.</td>
            <td>{props.apiData.email}</td>
            <td>{props.apiData.firstName || props.apiData.first_name}</td>
            <td>{props.apiData.lastName || props.apiData.last_name}</td>
            <td className="align-left">{institutionName}</td>
            <td>{props.apiData.branch?.branchName || "Central"}</td>
            <td>{props.apiData.user_type}</td>
            <td>{props.apiData.userRole?.name}</td>
            <td>{SplitDate(props.apiData?.lastLoginDate).dateTime}</td>
            <td title={props.apiData.status}><span className={badgeType}><i className={icofontImage}></i></span></td>
            <td className="align-center">
                <button
                    id="editBtnRow"
                    className={buttonDisplay}
                    onClick={() => handleEditRowClicked(props.apiData.slug)}>
                    <i className="icofont-edit"></i> Edit
                </button>
                <button
                    id="btnEnableDisable"
                    className={buttonDisplay}
                    title="Enable/Disable"
                    onClick={handleEnableDisable}>
                    <i className={eyeImage} style={{ fontSize: '1.4em' }}></i>
                </button>
            </td>
        </tr>
    );
}

export default InstitutionUsersTableRows;
