import { useState } from "react";

const InstitutionsDialog = (props) => {

    /**
     * This is an institutionsDialog,
     * specially made for selecting institutions
     * and to avoid unnecessary repeats.
     * These ones have the privilege
     * of predictive texting...
     */
    const [cbxInstitution, setCbxInstitution] = useState(props.selectedInstitution);
    const displayClass = props.showDialog ? "dialog-background fade" : "dialog-background";

    const setInstitution = () => {
        props.setInstitution(cbxInstitution);
        props.hideInstitutionsDialog();
    }

    const institutionsList = props.institutions.map(item => {
        return (
            <option value={item.slug} key={item.slug}>{item.name}</option>
        )
    })

    return (
        <div className={displayClass}>
            <div className="lock-container">
                <div className="dialog-header">
                    <h3 className="nully"><i className="icofont-institution"></i> Choose Institution</h3>
                </div>
                <div className="dialog-body">
                    <p>Select from list:</p>
                    <div className="form-row-3-1 mb-1">
                        <div className="input-group">
                            <select
                                className="custom-select"
                                id="cbxInstitution"
                                name="cbxInstitution"
                                value={cbxInstitution}
                                onChange={e => setCbxInstitution(e.target.value)}
                            >
                                <option value=""></option>
                                {institutionsList}
                            </select>

                        </div>
                    </div>
                </div>
                <div className="dialog-footer">
                    <button
                        id="btnSelect"
                        name="btnSelect"
                        onClick={setInstitution}
                        className="btn-green mr-1">
                        <i className="icofont-tick-mark"></i> Select
                    </button>
                    <button
                        onClick={() => props.hideInstitutionsDialog()}
                        id="btnCancel"
                        className="btn-grey">
                        <i className="icofont-close"></i> Cancel
                    </button>
                </div>
            </div>
        </div>
    )
}

export default InstitutionsDialog
