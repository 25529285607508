import React from 'react'
import ImageDialog from '../create-reference/image-dialog'
import ApiPath from '../../../admin/assets/App/api-path'
import { fileToBase64 } from '../../../admin/assets/App/file-to-base64'
import { MessageBoxType } from '../../../_common/constants'
import { Enums } from '../../../_common/enums'

export default class EditOutgoingReference extends React.Component {

    constructor() {
        super()
        this.state = {
            displayDialogClicked: false,
            data: [],
            cbxAccountType: "",
            txtAccountName: "",
            txtAccountNumber: "",
            cbxCustomerBranch: "",
            cbxRefereeBranchSlug: "",
            txtRefereeAccountNumber: "",
            txtRefereeAccountName: "",
            cbxUploadDocument: "",
            mandatePath: "",
            mandateExtension: "",
            fetchingAccountDetails: false,
            branchesArray: [],
            displayImage: false,
            referenceObject: "",
            imageSource: {},
            uploadedFile: {},
        }

        this.handleClose = this.handleClose.bind(this)
        this.headers = {
            'Content-Type': Enums.applicationJson,
            'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
            'Cache-Control': 'public, max-age=0'
        }

    }


    LoadDetails = () => {
        //Display the dialog
        const slug = this.props.data.slug;
        const options = {
            method: 'GET',
            headers: this.headers
        }

        fetch(`${ApiPath}api/e-reference/view-e-reference-detail/${slug}`, options)
            ?.then(response => response.json())
            .then(response => {
                if (response && response.ok) {
                    //this.props.setViewData(this.props.item)
                    this.setState({
                        mandatePath: response.body.data.mandatePath,
                        uploadedFile: response.body.data.mandatePath,
                    })
                }
            })

    }

    saveImageToBase64 = () => {
        const fileUpload = document.querySelector("#cbxUploadDocument")

        if (fileUpload?.files[0]?.size > 500152) {
            this.props.ShowToast("File size cannot be more than 500kb!", "exclamation")
            fileUpload?.classList.add(Enums.errorBorder);

            return;
        }

        if (fileUpload?.files[0]) {
            //Ensuring that there is a file to convert
            fileToBase64(fileUpload?.files[0])
                .then(response => {
                    this.setState({
                        referenceObject: response.toString().split(",")[1],
                        //This returns the actual base-64 object, disregarding the extension... This we have to store elsewhere...
                        // as referenceExtension...
                    })

                    return response.toString();
                });

        } else {
            this.setState({ referenceObject: "" })
        }
    }

    handleChange = e => {
        /**
         * set the state
         * of each parameter
         * according to the
         * value
         * on input.change
         * run...
         */
        if (this.state.displayDialogClicked) {
            return
        }

        this.setState({ [e?.target.name]: e?.target.value });
    }

    componentDidMount() {
        this.LoadDetails()
        this.fetchBranches();

        this.setState({
            cbxAccountType: this.props.data.customerAccountType,
            txtAccountName: this.props.data.customerAccountName,
            txtAccountNumber: this.props.data.customerAccountNumber,
            cbxCustomerBranch: this.props.data.customerBranch?.slug,
            cbxRefereeBranchSlug: this.props.data.receiverBranch?.slug,
            txtRefereeAccountNumber: this.props.data.refereeAccountNumber,
            txtRefereeAccountName: this.props.data.refereeAccountName,
            mandateExtension: this.props.data?.mandatePath?.split(".")[1],
        })

    }

    updateReference = () => {
        /**
         * As the name
         * implies
         * update the reference
         * after the necessary
         * fields change
         * or corrections
         */


        const data = {
            "slug": this.props.data.slug,
            "customerBranch": this.props.data.customerBranch?.slug,          //Always set to the same branch...
            "refereeBranch": this.state.cbxRefereeBranchSlug,
            "customerAccountName": this.state.txtAccountName,
            "customerAccountType": this.state.cbxAccountType,
            "refereeAccountName": this.state.txtRefereeAccountName,
            "refereeAccountNo": this.state.txtRefereeAccountNumber,
            "channel": "Other",
            "customerMandate": this.props.data.customerMandate,
            "referenceObject": this.state.referenceObject || this.state.mandatePath,

        }

        const options = {
            method: "POST",
            headers: this.headers,
            body: JSON.stringify(data),
        }

        //Try to update...
        this.props.setIsLoaderVisible(true);

        fetch(ApiPath + 'api/e-reference/update-e-reference', options)
            ?.then(response => {
                this.props.setIsLoaderVisible(false)
                return response.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false)

                if (response) {
                    if (response.ok === true) {
                        this.props.ShowToast("Reference updated successfully!", MessageBoxType.SUCCESS)
                        this.props.setActionSuccessful(true);
                        this.props.setDisplayEdit(false);

                    } else {
                        this.props.ShowToast(response.message, "exclamation")
                    }
                } else {
                    this.props.ShowToast("Invalid input variables.", MessageBoxType.EXCLAMATION)
                }
            })
            .catch(error => {
                this.props.ShowToast(error.message, MessageBoxType.EXCLAMATION)
            })

        //NB: also remember to do a few things
        // Close the field after updating...Do this by setting
        // 1. the setActionSuccessful to true
        // 2. Also close the form

    }

    handleClose() {
        this.props.setDisplayEdit(false);
    }

    displayImageDialog = () => {
        /**
         * This subroutine
         * is tasked with the sole
         * responsibility of
         * displaying the
         * actual
         * file
         * attached to
         * the form...
         */

        this.setState({
            displayImage: true,
        })

    }

    fetchBranches = () => {
        const options = {
            method: 'GET',
            headers: this.headers,
        }

        fetch(`${ApiPath}api/institution/fetch-institution-branches/${this.props.data?.receiverInstitution?.slug}`, options)
            ?.then(response => {
                return response.json();
            })
            .then(response => {
                if (response && response.ok) {
                    this.setState({
                        branchesArray: response.body.data.branches,
                    })
                }
            })
    }

    render() {
        const dialogClass = (this.props.displayEdit) ? "modal fade show" : "modal fade"

        const SetBranches = this.state.branchesArray && this.state.branchesArray.map(item => {
            return (
                <option key={item.slug} value={item.slug}>{item.branchName}</option>
            )
        })

        return (
            <>
                <div className={dialogClass}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h2 className="create" style={{ fontSize: 14 }}><i className="icofont-edit"></i> Edit Outgoing Reference - #{this.props.data?.referenceNo}</h2>
                            </div>
                            <div className="modal-body">
                                <table className="dialog-table mb-2"><caption></caption>
                                    <thead>
                                        <tr>
                                            <th scope="col" className="align-left">Fields</th>
                                            <th scope="col" className="align-left">Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Reference NO</td>
                                            <td>{this.props.data?.referenceNo}</td>
                                        </tr>
                                        <tr>
                                            <td>Customer Account Number</td>
                                            <td>{this.state.txtAccountNumber}</td>
                                        </tr>
                                        <tr>
                                            <td>Customer Account Name</td>
                                            <td className="input-group">
                                                <input
                                                    type="text"
                                                    id="txtAccountName"
                                                    name="txtAccountName"
                                                    value={this.state.txtAccountName}
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                    onKeyUp={this.handleKeyUp}
                                                    onBlur={this.handleBlur} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Customer Branch</td>
                                            <td className="input-group">
                                                {/* <select
                                                    className="form-control"
                                                    id="cbxCustomerBranch"
                                                    name="cbxCustomerBranch"
                                                    value={this.state.cbxCustomerBranch}
                                                    onChange={this.handleChange}
                                                    onBlur={this.handleBlur}>
                                                    <option value=""></option>
                                                    {SetBranches}
                                                </select> */}
                                                <input type="text" className="form-control" disabled
                                                    name="cbxCustomerBranch" id="cbxCustomerBranch" value={this.props.data?.customerBranch?.branchName} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Customer Account Type</td>
                                            <td className="input-group">
                                                <select
                                                    className="form-control"
                                                    id="cbxAccountType"
                                                    name="cbxAccountType"
                                                    value={this.state.cbxAccountType}
                                                    onBlur={this.handleBlur}
                                                    onChange={this.handleChange}>
                                                    <option value=""></option>
                                                    <option value="Individual">Individual</option>
                                                    <option value="Corporate">Corporate</option>
                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Referee's Institution</td>
                                            <td>{this.props.data?.receiverInstitution?.name}</td>
                                        </tr>
                                        <tr>
                                            <td>Referee's Branch</td>
                                            <td className="input-group">
                                                <select
                                                    className="form-control" id="cbxRefereeBranchSlug"
                                                    name="cbxRefereeBranchSlug" value={this.state.cbxRefereeBranchSlug}
                                                    onChange={this.handleChange} onBlur={this.handleBlur}>
                                                    <option value="Central">Central</option>
                                                    {SetBranches}
                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Referee's Account Number</td>
                                            <td className="input-group">
                                                <input
                                                    type="text"
                                                    id="txtRefereeAccountNumber"
                                                    name="txtRefereeAccountNumber"
                                                    value={this.state.txtRefereeAccountNumber}
                                                    onChange={this.handleChange}
                                                    className="form-control"
                                                    maxLength="10"
                                                    placeholder="XXXXXXXXXX"
                                                    onBlur={this.handleBlur}
                                                    required />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Referee's Account Name</td>
                                            <td className="input-group">
                                                <input
                                                    type="text"
                                                    id="txtRefereeAccountName"
                                                    name="txtRefereeAccountName"
                                                    value={this.state.txtRefereeAccountName}
                                                    onChange={this.handleChange}
                                                    className="form-control"
                                                    onBlur={this.handleBlur}
                                                    required />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Attached Reference Form</td>
                                            <td className="input-group">
                                                <button
                                                    type="button"
                                                    id="btnAttachment"
                                                    onClick={this.displayImageDialog}
                                                    className="btn-grey">
                                                    Previous Attachement
                                                </button>
                                                <br />
                                                <input
                                                    type="file"
                                                    id="cbxUploadDocument"
                                                    className="form-control"
                                                    onBlur={this.handleBlur}
                                                    onChange={this.saveImageToBase64}
                                                    accept="image/*,.pdf"
                                                />
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                            <div className="modal-footer">
                                <hr />
                                <button
                                    className="btn-green t-tip mr-1"
                                    id="btnCreateReference"
                                    data-title="Click to update the reference details"
                                    onClick={this.updateReference}>
                                    <i className="icofont-upload-alt"></i> Update Reference
                                </button>
                                <button
                                    onClick={this.handleClose}
                                    id="btnCancel"
                                    type="button"
                                    className="btn-grey mr-2">
                                    <i className="icofont-exit"></i> Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="mb-3" />
                {
                    this.state.displayImage && this.state.uploadedFile &&
                    <ImageDialog
                        src={this.state.uploadedFile}
                        isURL={false}
                        setImageDisplay={() => this.setState({ displayImage: false })}
                        mandateExtension={this.state.mandateExtension}
                    />
                }

            </>
        )
    }
}
