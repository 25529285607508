import { useState } from "react";

const DatePicker = (props) => {

    const [cbxDateFrom, setCbxDateFrom] = useState(new Date().toDateString());

    const displayClass = props.displayDatePicker ? "dialog-background fade" : "dialog-background";

    const SetDateDisplay = () => {
        props.setOutput(cbxDateFrom);
        props.setDisplayDatePicker(false)
    }

    return (
        <div className={displayClass}>
            <div className="lock-container">
                <div className="dialog-header">
                    <h3 className="nully"><i className="icofont-ui-calendar"></i> Date Picker</h3>
                </div>
                <div className="dialog-body">

                    <div className="form-row-3-1 mb-1">
                        <div className="input-group">
                            <label>Select End Date</label>
                            <input
                                type="date"
                                name="cbxDateFrom"
                                id="cbxDateFrom"
                                className="form-control"
                                value={cbxDateFrom}
                                onChange={e => setCbxDateFrom(e.target.value)} />
                        </div>
                    </div>
                </div>
                <div className="dialog-footer">
                    <button
                        id="btnSelect"
                        name="btnSelect"
                        onClick={SetDateDisplay}
                        className="btn-green mr-1">
                        <i className="icofont-tick-mark"></i> Select
                        </button>
                    <button
                        onClick={() => props.setDisplayDatePicker(false)}
                        id="btnCancel"
                        className="btn-grey">
                        <i className="icofont-close"></i> Cancel
                    </button>
                </div>
            </div>
        </div>
    )
}

export default DatePicker
