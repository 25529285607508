import React from 'react';
import ApiPath from '../../admin/assets/App/api-path';
import AdminEmailsTableRows from './admin-emails-table-rows';
import validateEmail from "../../admin/assets/App/validate-email"
import { Enums } from "../../_common/enums";

class AdminEmailsForm extends React.Component {
  constructor() {
    super();
    this.state = {
      txtAddEmail: "",
      apiData: [],
      fetchSuccessful: false,
      resetEmails: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleAddNewEmail = this.handleAddNewEmail.bind(this);
  }

  getData() {
    this?.props.setIsLoaderVisible(true);

    const options = {
      method: "GET",
      headers: {
        "Content-Type": Enums.applicationJson,
        Authorization: `Bearer ${sessionStorage.getItem("session_token")}`,
        'Cache-Control': 'public, max-age=0'
      },
    };

    fetch(ApiPath + "api/admin/group-email", options)
      ?.then((response) => {
        return response.json();
      })
      .then((response) => {
        this.props.setIsLoaderVisible(false);
        if (response) {
          if (response.ok === true) {
            this.setState({
              fetchSuccessful: true,
              apiData: response.body.data,
              resetEmails: false,
            });
          } else {
            this.props.ShowToast(
              "Unable to load table. Please check your network and if this continues, contact your admin.",
              "exclamation"
            );
            return;
          }
        } else {
          this.props.ShowToast("Invalid login credentials", "exclamation");
        }
      })
      .catch((error) => {
        this.props.setIsLoaderVisible(false);
        this.props.ShowToast(error.message, "exclamation");
      });
  }

  componentDidMount() {
    this.getData();
    this.setState({ resetEmails: false });
  }

  handleKeyUp(e) {
    this.setState({ [e.target.name]: e.target.value });

    if (validateEmail(this.state.txtAddEmail)) {
      document
        .getElementById(Enums.txtAddEmail)
        ?.classList.remove(Enums.errorBorder);
    } else {
      document.getElementById(Enums.txtAddEmail)?.classList.add(Enums.errorBorder);
    }
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleAddNewEmail() {
    //Updates the new email and then updates table

    if (
      this.state.txtAddEmail === "" ||
      !validateEmail(this.state.txtAddEmail)
    ) {
      document
        .getElementById(Enums.txtAddEmail)
        ?.classList.add(Enums.errorBorder);
      this.props.ShowToast("Enter valid email to proceed.", "exclamation");
      document.getElementById(Enums.txtAddEmail)?.focus();
      return;
    }

    //Else proceed
    this.props.setIsLoaderVisible(true);

    const data = { email: this.state.txtAddEmail };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": Enums.applicationJson,
        "Authorization": `Bearer ${sessionStorage.getItem("session_token")}`,
        'Cache-Control': 'public, max-age=0'
      },
      body: JSON.stringify(data),
    };

    fetch(ApiPath + "api/admin/group-email", options)
      ?.then((response) => {
        this.props.setIsLoaderVisible(false);
        return response.json();
      })
      .then((response) => {
        this.props.setIsLoaderVisible(false);
        if (response) {
          if (response.ok === true) {
            this.props.ShowToast("Email added successfully!", "success");
            this.setState({ txtAddEmail: "" });
            this.getData();
            document.getElementById(Enums.txtAddEmail).focus();
          } else {
            this.props.ShowToast(response.message, "exclamation");
            return;
          }
        } else {
          this.props.ShowToast("Network / Server Error.", "exclamation");
        }
      })
      .catch((error) => {
        this.props.setIsLoaderVisible(false);
        this.props.ShowToast(error, "exclamation");
      });
  }

  render() {
    const styles = {
      maxHeight: "400px",
      overflowY: "auto",
    };

    if (this.state.resetEmails) {
      this.getData()
    }

    return (
      <>
        <div className="form-row-3 mb-3">
          <div className="input-group">
            <label>
              <span>*</span>Add Email
            </label>
            <input
              type="email"
              id="txtAddEmail"
              name="txtAddEmail"
              className="form-control"
              onKeyUp={this.handleKeyUp}
              onChange={this.handleChange}
              value={this.state.txtAddEmail}
              required
            />
            <label className="control-info">
              <i className="icofont-warning-alt"></i> Enter valid email address
            </label>
          </div>
          <div className="input-group">
            <button
              type="button"
              className="btn-green special-button"
              id="btnAddNewEmail"
              onClick={this.handleAddNewEmail}
            >
              Add Email
            </button>
            <button id="testButton" onClick={this.getData}
            onChange={this.handleChange} className="d-none"></button>
          </div>
        </div>
        <div className="form-row">
          <div style={styles}>
            <table>
              <caption></caption>
              <thead>
                <tr>
                  <th scope="col" className="align-left">
                    EMAILS
                  </th>
                  <th scope="col" className="align-right">
                    ACTION
                  </th>
                </tr>
              </thead>
              <tbody id="tbl_admin_emails">
                {this.state.fetchSuccessful &&
                  this.state.apiData.map((item) => {
                    return (
                      <AdminEmailsTableRows
                        key={item.id}
                        keyID={item.id}
                        emailValue={item.email}
                        ShowToast={this.props.ShowToast}
                        setIsLoaderVisible={this.props.setIsLoaderVisible}
                        setResetEmails={val => this.setState({ resetEmails: val })}
                      />
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}

export default AdminEmailsForm
