export const PersonalInfoPartFormComponent = props => {
    return (
        <legend className="legend">
            <caption>Personal Information</caption>
            <div className="form-row-3 mb-2">
                <div className="input-group">
                    <label><span>*</span>Surname / Company Name</label>
                    <input
                        className="form-control"
                        id="txtSurname"
                        value={props.txtSurname}
                        onChange={props.handleChange}
                        type="text"
                    />
                </div>
                <div className="input-group">
                    <label>Othernames</label>
                    <input
                        className="form-control"
                        id="txtOthernames"
                        value={props.txtOthernames}
                        onChange={props.handleChange}
                        type="text"
                    />
                </div>
                <div className="input-group">
                    <label><span>*</span>Phone number</label>
                    <input
                        className="form-control"
                        id="txtPhone"
                        value={props.txtPhone}
                        onChange={props.handleChange}
                        type="tel"
                        maxLength={11}
                    />
                </div>
            </div>
            <div className="form-row-3">
                <div className="input-group">
                    <label>Email</label>
                    <input
                        className="form-control"
                        id="txtEmail"
                        value={props.txtEmail}
                        onChange={props.handleChange}
                        type="email"
                    />
                </div>
                <div className="input-group">
                    <label><span>*</span>BVN</label>
                    <input
                        className="form-control"
                        id="txtBVN"
                        value={props.txtBVN}
                        onChange={props.handleChange}
                        type="text"
                        maxLength={11}
                    />
                </div>
                <div className="input-group">
                    <label>Clearing House Number (CHN)</label>
                    <input
                        className="form-control"
                        id="txtClearingHouseNumber"
                        value={props.txtClearingHouseNumber}
                        onChange={props.handleChange}
                        type="text"
                    />
                </div>
            </div>
        </legend>

    )
}
