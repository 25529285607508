import ApiPath from "../../admin/assets/App/api-path";

const BranchManagementTableRows = (props) => {

    function handleEditRow() {
        props.setEditData(props.apiData);
        props.setEditClicked(true);
        document?.querySelector(".edit-branch")?.classList.add("fade");
    }

    function handleDeleteRow(slugForItemToDelete) {
        const question = window.confirm("Are you sure you wish to delete the selected branch?");
        if (question) {
            //Proceed to delete...
            props.setIsLoaderVisible(true);
            const data = {
                "slug": slugForItemToDelete,
            }

            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                    'Cache-Control': 'public, max-age=0'
                },
                body: JSON.stringify(data),
            }

            fetch(ApiPath + 'api/branch/delete-branch', options)
                .then(response => {
                    props.setIsLoaderVisible(false)
                    return response.json();
                })
                .then(response => {
                    props.setIsLoaderVisible(false)

                    if (response) {
                        if (response.ok === true) {
                            props.ShowToast("Branch deleted successfully!", "success")
                            props.setUpdateSuccessful(true)

                        } else {
                            props.ShowToast(response.message, "exclamation")
                        }
                    } else {
                        props.ShowToast("Invalid input variables.", "exclamation")
                    }
                })
                .catch(error => {
                    props.ShowToast(error.message, "exclamation")
                })
        }
    }

    const badgeType = (props.apiData.status === "active") ? 'badge-success' : 'badge-danger'
    const isNibssUser = sessionStorage.getItem("is_nibss_user")?.indexOf("NIBSS") > -1 ? true : false;
    const deleteButtonClassName = (props.apiData.meta?.usersCount > 0 || !isNibssUser) ? "btn-danger mr-1 d-none" : "btn-danger mr-1";
    const icofontImage = (props.apiData.status === "active") ? 'icofont-play-alt-1' : 'icofont-stop'


    return (
        <tr>
            <td className="align-center">{props.counter}.</td>
            <td>{props.apiData.institution.name}</td>
            <td>{props.apiData.branchName}</td>
            <td>{props.apiData.branchCode}</td>
            <td>{props.apiData.state}</td>
            <td>{props.apiData.lga}</td>
            <td className="align-center">{props.apiData.meta?.usersCount}</td>
            <td width="90" className="d-none"><span className={badgeType}><i className={icofontImage}></i></span></td>
            <td className="align-center">
                <button
                    type="button"
                    className="btn-grey mr-1"
                    title="View / Edit Branch"
                    onClick={() => handleEditRow()}>
                    <i className="icofont-edit"></i> View / Edit
                </button>
                <button
                    type="button"
                    className={deleteButtonClassName}
                    title="Delete Branch"
                    onClick={() => handleDeleteRow(props.apiData.slug)}>
                    <i className="icofont-ui-delete"></i> Delete
                </button>
            </td>
        </tr>

    );
}

export default BranchManagementTableRows;
