import { MessageBoxType } from "../../_common/constants";
import ApiPath from "../../admin/assets/App/api-path";
import React from 'react';
import UploadFile from "../../admin/assets/App/upload-file";
import { fileToBase64 } from "../../admin/assets/App/file-to-base64";

export class TreatEClipsDialog extends React.Component {

    constructor() {
        super()
        this.state = {
            txtComments: "",
            cbxOptions: "",
            cbxAttachmentType: "",
            uploadedFile: "",
            clearFileUpload: "",
            txtShortDescription: "",
            attachmentType: ".jpg, .jpeg, .png, .mp4",
            uploadFilesArray: [],
            base64File: "",
        }

        this.headersData = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
            'Cache-Control': 'public, max-age=0'
        }

    }

    handleChange = e => {
        const { id, value } = e.target;
        this.setState({
            [id]: value,
        }, () => {
            if (id === "cbxAttachmentType") {
                this.checkAttachmentType();
            }
        })
    }

    checkAttachmentType = () => {
        if (this.state.cbxAttachmentType === "Video") {
            this.setState({
                attachmentType: ".mp4, .m4u"
            })
        } else {
            this.setState({
                attachmentType: ".jpg, .jpeg, .png"
            })
        }
    }

    checkTreatInputs = () => {

        if (this.state.cbxOptions === "treat") {
            if (this.state.attachmentType === "") {
                return "Attachment type required!"

            } else if (this.state.uploadedFile === "" || this.state.uploadedFile.length === 0) {
                ////this.props.ShowToast("You must attach at least 1 file to proceed.", MessageBoxType.EXCLAMATION);
                // //     return;
                // // } else if (this.state.uploadedFile.length === 0) {
                // //     //We deleted the list of files as they were too large...
                return "Some of the attached files were too large (>25MB) or no files attached. Maximum allowable file size is 25MB.";

            } else if (this.state.txtShortDescription === "") {
                return "Short description required!";

            }

            else if (this.state.cbxOptions === "") {
                return "Select an option to treat before proceeding.";
            }

        } else if (this.state.cbxOptions === "") {
            return "You must select a treat option to proceed.";

        } else {
            //If it is not for the treat and submit option, additional comments are compulsory
            if (this.state.txtComments === "") {
                return "Additional Comments required!";
            }
        }

        return "";

    }

    treatClipRequest = () => {
        /** This subrouting handles the treatment of
         * a clip request, advancing it from
         * the pending stage...
         */

        //Validate some fields based on whether the
        //treat is selected...
        const treatString = this.checkTreatInputs();
        if (treatString !== "") {
            //Issue occured!
            this.props.ShowToast(treatString, MessageBoxType.EXCLAMATION);
            return;
        }

        const data = {
            slug: this.props.data?.slug,
            action: this.state.cbxOptions,
            comment: this.state.txtComments,
            shortDescription: this.state.txtShortDescription,
            attachments: [...this.state.uploadFilesArray]
        }

        const options = {
            method: "POST",
            headers: this.headersData,
            body: JSON.stringify(data),
        }

        this.props.setIsLoaderVisible(true);

        fetch(`${ApiPath}api/eclip/treat-eclip`, options)
            ?.then(response => {
                this.props.setIsLoaderVisible(false)
                return (response.json())
            })
            .then(response => {
                if (response) {
                    if (response.ok) {
                        //Go ahead and set the values for the textarea...
                        this.props.ShowToast("Clip treated successfully!", MessageBoxType.SUCCESS)
                        this.props.hideDisplayView();
                        this.props.setActionSuccessful();

                    } else {
                        this.props.ShowToast(response.message, MessageBoxType.EXCLAMATION)
                    }
                }
            })
            .catch(error => {
                this.props.setIsLoaderVisible(false)
                this.props.ShowToast(error.message, MessageBoxType.EXCLAMATION)
            })

    }

    saveFilesToBase64 = () => {
        /**
         * As the files have been uploaded, save the
         * copies to base 64, ready for
         * uploading...
         */

        const fileUpload = this.state.uploadedFile;
        const outputObject = [];
        // eslint-disable-next-line

        let counter = 0;
        while (counter < fileUpload.length) {

            /**
             * Add each of the files
             * to a an array of objects after converting
             * with the type and file itself...
             */
            if (fileUpload[counter].size > 25000000) {
                //Cannot be more than 25mB
                this.props.ShowToast("Each individual file must not exceed 25mb.", MessageBoxType.EXCLAMATION);
                this.setState({
                    uploadFilesArray: []
                })
                return;
            }

            const fileName = fileUpload[counter].name;

            //Else proceed to add the item to the uploadFilesArray...
            fileToBase64(fileUpload[counter])
                .then(response => {
                    outputObject.push({
                        type: this.state.cbxAttachmentType.toLowerCase(),
                        file: response.toString().split(",")[1],        //The actual base64 part of the object...
                        name: fileName
                    })

                })
                .catch(err => {
                    alert(err.toString())
                });

            //Alwasy remember to increment the value of counter
            //otherwise we result in an infinite loop!..
            counter++;
        }

        this.setState({
            uploadFilesArray: outputObject
        })

    }

    render() {

        const compulsory = this.state.cbxOptions === "treat" ? <span></span> : <span>*</span>;
        const hideNotTreatObjects = this.state.cbxOptions !== "treat" ? "input-group d-none" : "input-group";

        const displayClass = this.props.displayView ? "dialog-background fade" : "dialog-background";

        return (
            <div className={displayClass} >
                <div className="dialog-container">
                    <div className="dialog-header">
                        <h2 className="nully"><i className="icofont-video-clapper"></i> Treat e-Clip Request</h2>
                    </div>
                    <div className="dialog-body">
                        <div className="form-row-2-1 mb-2">
                            <div className="input-group">
                                <label>Treat Options</label>
                                <select
                                    id="cbxOptions"
                                    className="custom-select"
                                    value={this.state.cbxOptions}
                                    onChange={this.handleChange}
                                >
                                    <option value=""></option>
                                    <option value="treat">Treat and Submit</option>
                                    <option value="no-footage">Footage Not Available</option>
                                    <option value="reject">Reject Request</option>
                                </select>
                            </div>
                            <div className={hideNotTreatObjects}>
                                <label><span>*</span>Attachment type</label>
                                <select
                                    id="cbxAttachmentType"
                                    className="custom-select"
                                    value={this.state.cbxAttachmentType}
                                    onChange={this.handleChange}
                                >
                                    <option value=""></option>
                                    <option value="Image">Photo Image</option>
                                    <option value="Video">Video</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-row-1-2 mb-2">
                            <div className={hideNotTreatObjects}>
                                <UploadFile
                                    setUploadedFile={value => this.setState({ uploadedFile: value }, () => this.saveFilesToBase64())}
                                    clearFileUpload={this.state.clearFileUpload}
                                    acceptFormats={this.state.attachmentType}
                                    resetFileUpload={() => this.setState({ clearFileUpload: false })}
                                    multipleFiles={false}
                                />
                            </div>
                            <div className={hideNotTreatObjects}>
                                <label><span>*</span>Short description</label>
                                <textarea
                                    type="text"
                                    id="txtShortDescription"
                                    maxLength={200}
                                    rows="6"
                                    value={this.state.txtShortDescription}
                                    className="form-control"
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className="form-row-3x mb-2">
                            <div className="input-group">
                                <label>{compulsory}Additional Comments</label>
                                <textarea
                                    type="text"
                                    id="txtComments"
                                    rows="4"
                                    maxLength={300}
                                    value={this.state.txtComments}
                                    className="form-control"
                                    onChange={this.handleChange}
                                />
                            </div>

                        </div>
                    </div>
                    <div className="dialog-footer">
                        <button
                            className="btn-green mr-1"
                            id="btnTreat"
                            onClick={this.treatClipRequest}>
                            <i className="icofont-injection-syringe"></i> Treat eClip
                        </button>
                        <button
                            className="btn-grey"
                            id="btnCancel"
                            onClick={() => this.props.hideDisplayView()}>
                            <i className="ion-android-exit"></i> Close
                        </button>

                    </div>
                </div>
            </div>
        );
    }
}
