import { Link } from "react-router-dom";

const NavHeader = () => {
    return (
        <li>
            <Link to='/'>
                <div className="header">
                    <div className="header-details">
                        <img src="/assets/img/png/nibss-logo.png" alt="" />
                        <p>
                            <span>Document Hub</span> Portal
                        </p>
                    </div>
                </div>
            </Link>
        </li>
    )
}

export default NavHeader
