import React from "react";
import ValidateEmail from "../../../admin/assets/App/validate-email";
import { SplitDate, ShortDateToCoded } from '../../../admin/assets/App/split-date';
import GetServices from "../../../admin/assets/App/GetServices";
import ApiPath from "../../../admin/assets/App/api-path";
import { fileToBase64 } from "../../../admin/assets/App/file-to-base64";
import objectToCSV from "../../../admin/assets/App/object-to-csv";

class SendEmailDialog extends React.Component {

    constructor() {
        super()
        this.state = {
            txtEmail: "",
            txtMessage: "",
            summaryReport: [],
            billingReport: [],
            smartDet: [],
            summaryReportFile: {},
            billingReportFile: {},
            smartDetFile: {},
        }

        this.initialData = `Attached is the billing information for the subject period.
            The Following institution types are billed:

            Total Transaction Volume of Billing File:
            Total Transaction Value of Billing File:
            Total Transaction Volume of Smart Det:
            Total Transaction Value of Smart Det:
            Summary Report, Billing Report and Smart Det are attached.`

        this.summaryReport = "Summary Report"
        this.billingReport = "Billing Report"

    }

    componentDidMount() {
        //Create each report and prepare for sending...
        this.LoadReport(this.summaryReport)
        this.LoadReport("Smart Det")
        this.LoadReport(this.billingReport)

        // // this.fileTitle = `${SplitDate(this.props.data?.createdAt).codedDate} ${GetServices(this.props.data?.service?.name)} ` +
        // //     `${ShortDateToCoded(this.props.data?.startDate?.substring(0, 10))}-${ShortDateToCoded(this.props.data?.endDate?.substring(0, 10))}`;

        this.setState({
            txtMessage: this.initialData
        })
    }

    CreateReportFile = reportName => {

        let thisData;

        switch (reportName) {
            case this.summaryReport:
                thisData = this.state.summaryReport.report.map(item => {
                    //Returns an object of the required titles... then actual details...
                    return ({
                        "Institution Name": item.meta.name,
                        "Date": SplitDate(item.meta.CREATED_AT).dateTime,
                        "Volume": item.meta.total,
                    })
                })
                break;

            case this.billingReport:        //Change to the correct format afterwards...
                thisData = this.state.billingReport.report.map(item => {
                    //Returns an object of the required titles... then actual details...
                    return ({
                        "Account_Number": item.billingAccountNumber,
                        "Sort_Code": item.sortCode,
                        "Amount": item.billingAmount,
                        "Payee_Beneficiary": item.institutionName,
                        "Narration": item?.narration,
                    })
                })
                break;
            default:
                this.state.smartDet.report?.forEach(item => {
                    //Returns an object of the required titles... then actual details...
                    // The expected format for this is
                    const splitDate = item.endDAte?.split("-") || '2022-01-01';
                    const outputDate = `${splitDate[0]}${splitDate[1]}${splitDate[2]}`;

                    thisData += `${item.sortCode},${item.productCode},${outputDate},${item.billingAmount}\r`;
                })

        }

        //Create the csvFile...
        let csvData, type, blob;

        if (reportName === 'Smart Det') {
            type = { type: 'text/plain' }
            blob = new Blob([thisData], type);
        } else {
            csvData = objectToCSV(thisData);
            type = { type: 'text/csv' };
            blob = new Blob([csvData], type);
        }

        //Then finally convert to base64...
        fileToBase64(blob)
            .then(response => {
                switch (reportName) {
                    case this.summaryReport:
                        this.setState({ summaryReportFile: response.toString().split(",")[1] })
                        break;
                    case this.billingReport:
                        this.setState({ billingReportFile: response.toString().split(",")[1] })
                        break;
                    default: //Smart Det
                        this.setState({ smartDetFile: response.toString().split(",")[1] })
                        break;
                }

                return response.toString();

            });

    }

    handleChange = e => {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
        })
    }

    clearEmailFrom = () => {
        this.setState({
            txtEmail: "",
            txtMessage: "",
        })
    }


    LoadReport = type => {
        let api;

        switch (type) {
            case this.summaryReport:
                api = "/billing/summary-report/";
                break;
            case this.billingReport:
                api = "/billing/billing-file/"
                break;
            default:
                api = "/billing/smart-det/"
                break;
        }

        this.props.setIsLoaderVisible(true);
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                'Cache-Control': 'public, max-age=0'
            }
        }

        fetch(`${ApiPath}api${api}${this.props.data.slug}`, options)    //Change the endpoint when ready...
            ?.then(response => {
                this.props.setIsLoaderVisible(false)
                return response.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false)

                if (response && response.ok) {
                    if (type === this.summaryReport) {
                        this.setState({
                            summaryReport: response.body.billings
                        }, () => this.CreateReportFile(this.summaryReport))

                    } else if (type === this.billingReport) {
                        this.setState({
                            billingReport: response.body.billingFile
                        }, () => this.CreateReportFile(this.billingReport))

                    } else {
                        this.setState({
                            smartDet: response.body.smartDet
                        }, () => { this.CreateReportFile("Smart Det") })
                    }

                } else {
                    this.props.ShowToast(response.message, "exclamation")
                }

            })
            .catch(error => {
                this.props.ShowToast(error.message, "exclamation")
            })
    }

    SendEmails = () => {
        //Send emails to recipients
        //Go ahead and delete....
        this.props.setIsLoaderVisible(true);

        const billingEmailBody = {
            slug: this.props.data.slug,
            emailAddresses: this.state.txtEmail.split(","),     //Immediately converts it into an array...
            smartDet: this.state.smartDetFile,
            summaryReportFile: this.state.summaryReportFile,
            billingFile: this.state.billingReportFile
        }

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                'Cache-Control': 'public, max-age=0'
            },
            body: JSON.stringify(billingEmailBody)
        }

        fetch(`${ApiPath}api/billing/send-to-email`, options)    //Change the endpoint when ready...
            ?.then(response => {
                this.props.setIsLoaderVisible(false)
                return response.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false)

                if (response) {
                    if (response.ok === true) {
                        this.props.ShowToast("Email(s) sent successfully!", "success")
                        this.clearEmailFrom();
                    } else {
                        this.props.ShowToast(response.message, "exclamation")
                    }
                }
            })
            .catch(error => {
                this.props.setIsLoaderVisible(false)
                this.props.ShowToast(error.message, "exclamation")
            })

    }

    closeForm = () => {
        this.props.setDisplayEmailDialog(false)
    }

    render() {

        const dialogClass = (this.props.displayEmailDialog) ? "modal fade show" : "modal fade";
        const sendButtonClass = (this.state.txtEmail.length === 0 || !ValidateEmail(this.state.txtEmail)) ? "btn-green mr-1 d-none" : "btn-green mr-1";

        const emailSubject = `${SplitDate(this.props.data.createdAt).codedDate} ${GetServices(this.props.data.service?.name)} ` +
            `${ShortDateToCoded(this.props.data.startDate?.substring(0, 10))}-${ShortDateToCoded(this.props.data.endDate?.substring(0, 10))}`;

        return (
            <div className={dialogClass} >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="nully"><i className="icofont-email"></i> Send Billing Reports to Email(s)</h2>
                        </div>
                        <div className="modal-body">
                            <div className="form-row-3-1" style={{ marginBottom: 5 }}>
                                <div className="input-group">
                                    <label>Sender</label>
                                    <input
                                        className="form-control"
                                        id="txtSender"
                                        value="NIBSS"
                                        disabled />
                                </div>

                            </div>
                            <div className="form-row-3-1" style={{ marginBottom: 5 }}>
                                <div className="input-group">
                                    <label>Subject</label>
                                    <input
                                        className="form-control"
                                        id="txtSubject"
                                        name="txtSubject"
                                        value={emailSubject}
                                        disabled />
                                </div>

                            </div>
                            <div className="form-row-3-1 mb-2">
                                <div className="input-group">
                                    <label><span>*</span>Recipient Email(s)</label>
                                    <textarea
                                        className="form-control"
                                        id="txtEmail"
                                        name="txtEmail"
                                        onChange={this.handleChange}
                                        value={this.state.txtEmail}
                                        rows={6}
                                        placeholder="Multiple emails are comma-separated..."
                                    />
                                </div>
                            </div>
                            <div className="form-row-3-1 mb-2 d-none">
                                <div className="input-group">
                                    <label>Message Body</label>
                                    <textarea
                                        className="form-control"
                                        id="txtMessage"
                                        name="txtMessage"
                                        onChange={this.handleChange}
                                        value={this.state.txtMessage}
                                        rows="8"
                                        placeholder="Message Body..."
                                    />
                                </div>
                            </div>
                            <div className="form-row-3-1 mb-2">
                                <label style={{ marginRight: 5 }}>Attached Files</label>
                                <label className="send-mail t-tip" data-title="Download files from the Download row">Summary Report</label>|
                                <label className="send-mail t-tip" data-title="Download files from the Download row">Billing Report</label>|
                                <label className="send-mail t-tip" data-title="Download files from the Download row">Smart Det</label>
                            </div>
                        </div>
                        <div className="modal-footer mb-2">
                            <hr />
                            <button
                                className={sendButtonClass}
                                onClick={this.SendEmails}
                            >
                                <i className="icofont-paper-plane"></i> Send Email</button>
                            <button
                                type="button"
                                className="btn-grey mr-2"
                                onClick={this.closeForm}
                                id="btnCancel">
                                <i className="icofont-exit"></i> Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default SendEmailDialog
