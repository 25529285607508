const TrappedCardsReportsSummary = ({ summaryInfo }) => {

    //The table basically contains
    //the summary information
    return (
        <table className="outgoing-ref-table">
            <caption>Trapped Cards Reports Summary</caption>
            <thead></thead>
            <tbody>
                <tr>
                    <td>Number of Pending Incoming Items</td>
                    <td>{summaryInfo.noOfPendingIncomingTrappedCard || 0}</td>
                </tr>
                <tr>
                    <td>Number of Pending Outgoing Items</td>
                    <td>{summaryInfo.noOfPendingOutgoingTrappedCard || 0}</td>
                </tr>
                <tr>
                    <td>Number of Completed Incoming Items (Accpeted + Rejected)</td>
                    <td>{summaryInfo.noOfCompletedIncomingTrappedCard || 0}</td>
                </tr>
                <tr>
                    <td>Number of Completed Outgoing Items (Accpeted + Rejected)</td>
                    <td>{summaryInfo.noOfCompletedOutgoingTrappedCard || 0}</td>
                </tr>
            </tbody>
        </table>
    )
}

export default TrappedCardsReportsSummary
