import React from "react"
import ApiPath from '../../../admin/assets/App/api-path'
import CapitalizeFirstLetter from "../../../admin/assets/App/CapitalizeFirstLetter"
import Badges from "../../../admin/assets/App/Badges"
import AcceptRejectIncomingReference from "./accept-reject-incoming-reference"
import ApproveDisapproveIncomingReference from "./approve-disapprove-incoming-reference"
import ReassignIncomingReference from "./reassign-incoming-reference"

class IncomingReferencesTableRow extends React.Component {

    constructor() {
        super()
        this.state = {
            data: [],
            displayViewDialog: false,
            displayApproveDialog: false,
            displayAcceptDialog: false,
            displayReassignDialog: false,
            itemSlug: "",
        }

        this.ViewItem = this.ViewItem.bind(this)
        this.AcceptItem = this.AcceptItem.bind(this)
        this.ApproveItem = this.ApproveItem.bind(this)
        this.ReassignReference = this.ReassignReference.bind(this)

        this.getOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                'Cache-Control': 'public, max-age=0'
            }
        }
    }

    componentDidMount() {
        if (this.props.item) {
            this.setState({
                data: this.props.item,
                itemSlug: this.props.item.slug,
            })
        }
    }

    getReferenceComments = () => {
        // Fetch the comments
        // of the said reference,
        // and install in the comments place...

        //Trigger the API fetch for the required data...
        fetch(`${ApiPath}api/e-reference/get-comments/${this.props.item.slug}`, this.getOptions)
            ?.then(response => (response.json()))
            ?.then(response => {
                if (response && response.ok) {
                    this.props.setCommentsData(response.body.comments?.data)
                }
            })

    }

    ViewItem() {
        //First of all, get the
        // reference comments...
        this.getReferenceComments();

        //Display the dialog
        const slug = this.props.item.slug;
        const options = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                'Content-Type': 'application/json',
                'Cache-Control': 'public, max-age=0'
            },
        }

        fetch(`${ApiPath}api/e-reference/view-e-reference-detail/${slug}`, options)
            ?.then(res => res.json())
            .then(res => {
                if (res && res.ok) {
                    this.props.setViewData(res.body.data);
                    this.props.setDisplayView(true);
                }
            })


        //Ensure to set the mandate Extension to enable us
        //get a proper extension for the recreation of .pdf objects...
        this.props.setMandateExtension(this.props.item?.mandatePath?.split(".")[1]);


    }

    ReassignReference() {
        /**
         * Display the re-assign reference
         * dialog
         */
        this.setState({
            displayReassignDialog: true,
        })
    }

    AcceptItem() {
        /**
         * Accept an item by
         * showing a dialog...
         * t
         */

        this.setState({
            displayAcceptDialog: true,
        })
    }

    ApproveItem() {

        /**
         * Approving an item
         * will entail
         * selecting
         * the proper item
         * and then
         * validating the
         * action, to avoid
         * accidental deletes...
         */

        this.setState({
            displayApproveDialog: true,
            itemSlug: this.props.item.slug,
        })

    }

    render() {
        const statusClass = Badges(this.props.item?.actionStatus);
        const hideButtons = this.props.item?.actionStatus === "cancelled" ? "align-center d-none" : "align-center"

        const actionStatus = CapitalizeFirstLetter(this.props.item?.actionStatus);
        const isOverDue = CapitalizeFirstLetter(this.props.item.isOverDue);

        let displayAccept = this.props.item?.actionStatus === "pending" ? "icofont-tick-boxed mr-1" : "icofont-tick-boxed d-none";
        let displayApproval = (this.props.item?.actionStatus === "accepted") ? "icofont-checked mr-1" : "icofont-checked mr-1 d-none"
        const displayReassign = (this.props.item?.actionStatus === "pending") ? "icofont-reply-all" : "icofont-reply-all d-none"
        const serviceIds = sessionStorage.getItem("service_ids")?.split(",") || ['2', '5', '8'];

        if (serviceIds?.indexOf('1') > -1) {
            //Operator can never view the approve button...
            displayApproval = 'd-none';

        } else if (serviceIds?.indexOf('2') > -1) {
            //Approvers / Authorizers can never view the accept buttons....
            displayAccept = 'd-none';
        }

        return (
            <>
                <tr>
                    <td className="align-center">{this.props.counter}.</td>
                    <td title={this.props.item?.referenceNo}>{this.props.item?.referenceNo?.substring(0, 13)}...</td>
                    <td>{this.props.item?.customerAccountNumber}</td>
                    <td>{this.props.item?.customerAccountName}</td>
                    <td>{this.props.item?.receiverInstitution?.name}</td>
                    <td>{this.props.item?.receiverBranch?.branchName ?? 'Central'}</td>
                    <td>{this.props.item?.refereeAccountNumber}</td>
                    <td>{this.props.item?.refereeAccountName}</td>
                    <td className="align-center">{isOverDue}</td>
                    <td><span className={statusClass}>{actionStatus}</span></td>
                    <td className={hideButtons} width="100">
                        <i
                            id="btnView"
                            className="icofont-page mr-1"
                            style={{ color: "grey", fontSize: 20, cursor: 'pointer' }}
                            title="View Details"
                            onClick={() => this.ViewItem()}
                        ></i>
                        <i
                            id="btnAcceptReject"
                            className={displayAccept}
                            style={{ color: "var(--main-green)", fontSize: 20, cursor: 'pointer' }}
                            title="Accept / Reject Reference"
                            onClick={() => this.AcceptItem()}
                        ></i>
                        <i
                            id="btnApproveDisapprove"
                            className={displayApproval}
                            style={{ color: "var(--main-green)", fontSize: 20, cursor: 'pointer' }}
                            title="Approve / Disapprove Reference"
                            onClick={() => this.ApproveItem()}
                        ></i>
                    </td>
                    <td className="align-center">
                        <i
                            id="btnReassign"
                            className={displayReassign}
                            style={{ color: "dimgrey", fontSize: 24, cursor: 'pointer' }}
                            title="Re-assign Reference"
                            onClick={() => this.ReassignReference()}
                        ></i>
                    </td>

                </tr>

                {
                    this.state.displayAcceptDialog &&
                    <AcceptRejectIncomingReference
                        ShowToast={this.props.ShowToast}
                        setIsLoaderVisible={this.props.setIsLoaderVisible}
                        displayAcceptDialog={this.state.displayAcceptDialog}
                        setDisplayAcceptDialog={value => this.setState({ displayAcceptDialog: value })}
                        slug={this.state.itemSlug}
                        referenceNo={this.props.item.referenceNo}
                        setAcceptSuccessful={value => this.props.setActionSuccessful(value)}
                    />
                }

                {
                    this.state.displayApproveDialog &&
                    <ApproveDisapproveIncomingReference
                        ShowToast={this.props.ShowToast}
                        setIsLoaderVisible={this.props.setIsLoaderVisible}
                        displayApproveDialog={this.state.displayApproveDialog}
                        setDisplayApproveDialog={value => this.setState({ displayApproveDialog: value })}
                        slug={this.state.itemSlug}
                        referenceNo={this.props.item.referenceNo}
                        setApproveSuccessful={value => this.props.setActionSuccessful(value)}
                    />
                }
                {
                    this.state.displayReassignDialog &&
                    <ReassignIncomingReference
                        ShowToast={this.props.ShowToast}
                        setIsLoaderVisible={this.props.setIsLoaderVisible}
                        displayReassignDialog={this.state.displayReassignDialog}
                        setDisplayReassignDialog={value => this.setState({ displayReassignDialog: value })}
                        data={this.props.item}
                        referenceNo={this.props.item.referenceNo}
                        institution={this.props.item?.receiverInstitution?.slug}
                        setReassignSuccessful={value => this.props.setActionSuccessful(value)}
                    />
                }
            </>
        );
    }
}

export default IncomingReferencesTableRow
