import React from "react";
import { Enums } from "../../_common/enums";
import ApiPath from "../../admin/assets/App/api-path";
import Pagination from "../../admin/assets/App/Pagination";
import SearchComponent from "../../admin/assets/App/SearchComponent";
import { EClipsSearchList } from "../../admin/assets/data/EClipsSearchList";
import { OutgoingEClipsTableRows } from "./outgoing-e-clips-table-rows";
import DynamicSort from "../../admin/assets/App/DynamicSort";
import GeneratePublicURL from "./generate-public-url";
import { MessageBoxType } from "../../_common/constants"
import ViewEClipsDetailsDialog from "./view-e-clips-details-dialog";
import { TreatEClipsDialog } from "../incoming-e-clips/treat-e-clips-dialog";
import objectToCSV from "../../admin/assets/App/object-to-csv";
import ExportToCSVFile from "../../admin/assets/App/ExportToCSVFile";
import CapitalizeFirstLetter from "../../admin/assets/App/CapitalizeFirstLetter";
import { SplitDate } from "../../admin/assets/App/split-date";
import { DateRange } from "../../components/date-range";

export class OutgoingEClipsForm extends React.Component {
    constructor() {
        super();
        this.state = {
            actionSuccessful: false,
            apiData: [],
            metaData: {},
            institutionsArray: [],
            branches: [],
            fetchSuccessful: false,
            rowData: [],
            displayPublicURLDialog: false,
            viewDetailsDialog: false,
            displayTreatDialog: false,

            //Search parameters
            searchField: "",
            searchValue: "",
            startDate: "",
            endDate: "",

            //Pagination values
            lastPage: 1,
            currentSelectedPage: 1,
            arrayPages: [],
            perPage: localStorage.getItem("items_per_page") || 20,
            totalItems: 0,
        }

        this.token = sessionStorage.getItem("session_token");
    }

    ExportData = () => {
        /**
         * Export the output in
         * CSV format...
         */
       /**
         * Exports the data in pre-defined format...
         */
        const prevPerPage = this.state.perPage;
        this.setState({ perPage: 200000 }, () => this.fetchOutgoingEClips())

        const DataToExport = [];
        this.state.apiData.forEach((item, index) => {
            //First, get individual reference details

            const thisObject = {
                "S/N": index + 1,
                "Request ID": item.requestId,
                "Transaction Ref. Type": item.referenceType,
                "Acquiring Institution": item.acquiringInstitution?.name,
                "Created": SplitDate(item.createdAt).dateTime || "-",
                "Reference": item.reference,
                "Updated At": SplitDate(item.updatedAt).dateTime,
                "Is Overdue?": CapitalizeFirstLetter(item.isOverDue),
                "Status": CapitalizeFirstLetter(item.status),
            }

            DataToExport.push(thisObject)

        })

        const csvData = objectToCSV(DataToExport);
        ExportToCSVFile(csvData, "e-Clips.csv");

        this.props.ShowToast("Outgoing e-Clips exported successfully! Open downloads folder to preview...", "information");

        //Return the number of items per page
        this.setState({ perPage: prevPerPage })
        this.fetchOutgoingEClips();

    }

    fetchInstitutions = () => {
        const options = {
            method: 'GET',
            headers: Enums.headersData(this.token),
        }

        fetch(ApiPath + 'api/institution/fetch-institutions', options)
            ?.then(response => {
                return response.json();
            })
            .then(response => {
                if (response) {
                    if (response.ok) {
                        this.setState({
                            institutionsArray: response.body.data.institutions.sort(DynamicSort("name")),
                        })
                    } else {
                        this.props.ShowToast(response.message, MessageBoxType.EXCLAMATION)
                    }
                }
            })
    }

    fetchOutgoingEClips = () => {
        this.props.setIsLoaderVisible(true);
        const options = {
            method: 'GET',
            headers: Enums.headersData(this.token),
        }

        let searchValue = this.state.searchValue;

        if (this.state.searchField === "issuingInstitution") {
            searchValue = this.state.institutionsArray.find(item => item.name === this.state.searchValue)?.slug;

        } else if (this.state.searchField === "branch") {
            searchValue = this.state.branches.find(item => item.branchName === this.state.searchValue)?.slug;
        }

        const dateRangeFilter = (this.state.startDate !== '') ? `&startDate=${this.state.startDate}&endDate=${this.state.endDate}` : '';

        fetch(`${ApiPath}api/eclip/outgoing-eclip?page=${this.state.currentSelectedPage}` +
            `&${this.state.searchField}=${searchValue}&limit=${this.state.perPage}${dateRangeFilter}`, options)
            ?.then(response => {
                return response.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false)
                if (response && response.ok === true) {
                    this?.setState({
                        metaData: response.body.data?.meta,
                        apiData: response.body.data?.data,
                        actionSuccessful: false,
                        fetchSuccessful: true,
                        arrayPages: [],
                    }, () => {
                        const pArray = [];
                        this.setState({
                            currentSelectedPage: this.state.metaData?.current_page,
                            lastPage: this.state.metaData?.last_page,
                            totalItems: this.state.metaData?.total,
                        }, () => {
                            //Set this array for the looping
                            for (let i = 1; i <= this.state.lastPage; i++) {
                                pArray.push({ "id": i, "name": "Item " + i });
                            }

                            this.setState({ arrayPages: pArray })
                        })
                    })
                } else {
                    this.props.setIsLoaderVisible(false);
                    this.props.ShowToast(response?.message, "exclamation");
                }
            })
    }

    componentDidMount() {
        this.fetchOutgoingEClips();
        this.fetchInstitutions()
    }

    filterRecords = () => {
        this.fetchOutgoingEClips();
    }

    RefreshForm = () => {
        window.location.reload();
    }

    render() {

        let counter = 0;
        const tblRows = this.state.fetchSuccessful && this.state.apiData?.map(item => {
            counter++;
            const itemCounter = (this.state.currentSelectedPage - 1) * this.state.perPage + counter

            return (<OutgoingEClipsTableRows
                key={counter}
                counter={itemCounter}
                item={item}
                ShowToast={this.props.ShowToast}
                setIsLoaderVisible={this.props.setIsLoaderVisible}
                setActionSuccessful={value => this.setState({ actionSuccessful: value })}
                showTreatDialog={() => this.setState({ displayTreatDialog: true })}
                setRowData={value => this.setState({ rowData: value })}
                setDisplayPublicURL={val => this.setState({ displayPublicURLDialog: val })}
                showViewMoreDetails={() => this.setState({ viewDetailsDialog: true })}

            />)
        })

        if (this.state.actionSuccessful) {
            this.fetchOutgoingEClips()
            this.setState({
                actionSuccessful: false,
            })
        }

        //Craete search list items here later on...
        const status = [{ item_name: "Pending" }, { item_name: "Accepted" }, { item_name: "Rejected" }, { item_name: "Overdue"}]
        const institutionNames = this.state.institutionsArray.map(item => {
            return ({ item_name: item.name })
        })
        const disputeRef = this.state.apiData.map(item => {
            return ({ item_name: item.reference })
        })

        return (
            <>
                <div className="title">
                    <DateRange
                        ShowToast={this.props.ShowToast}
                        callback={this.filterRecords}
                        setDateTo={value => this.setState({ endDate: value })}
                        setDateFrom={value => this.setState({ startDate: value })}
                        maxDays={60}
                    />
                    <SearchComponent
                        ReferenceSearchList={EClipsSearchList} //Remove the createdAt / Date Range from this list...
                        setSearchField={val => this.setState({ searchField: val })}
                        setSearchValue={val => this.setState({ searchValue: val })}
                        filterRecords={this.filterRecords}
                        inputList={{ status, institutionNames, disputeRef }}
                        ShowToast={this.props.ShowToast}
                    />
                    <div className="buttons">
                        <button
                            id="btnRefresh"
                            data-title="Refresh"
                            type="button"
                            className="btn-grey t-tip mr-1"
                            onClick={this.RefreshForm}>
                            <i className="icofont-refresh"></i> Refresh
                        </button>
                        <button
                            type="button"
                            id="btnExport"
                            className="btn-green t-tip"
                            data-title="Export records as CSV file"
                            onClick={this.ExportData}>
                            <i className="icofont-airplane-alt"></i> Export
                        </button>
                    </div>
                </div>

                <div className="table-container">
                    <table className="bordered-table">
                        <caption></caption>
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col" className="align-left">REQUEST ID</th>
                                <th scope="col" className="align-left">TYPE</th>
                                <th scope="col" className="align-left">ACQUIRING BANK</th>
                                <th scope="col" className="align-left">CREATED</th>
                                <th scope="col" className="align-left">DISPUTE REFERENCE</th>
                                <th scope="col" className="align-center">LAST MODIFIED</th>
                                <th scope="col" className="align-center">OVERDUE</th>
                                <th scope="col" className="align-center" width="120">STATUS</th>
                                <th scope="col" className="align-center">ACTION</th>
                            </tr>
                        </thead>
                        <tbody id="outgoing_e_clips_table">
                            {tblRows}
                        </tbody>
                    </table>
                </div>
                <Pagination
                    setPerPage={value => this.setState({ perPage: value })}
                    perPage={this.state.perPage}
                    setRefreshForm={value => this.setState({ actionSuccessful: value })}
                    setCurrentSelectedPage={value => this.setState({ currentSelectedPage: value })}
                    currentSelectedPage={this.state.currentSelectedPage}
                    lastPage={this.state.lastPage}
                    arrayPages={this.state.arrayPages}
                />

                {
                    this.state.displayPublicURLDialog && <GeneratePublicURL
                        itemSlug={this.state.rowData.slug}
                        ShowToast={this.props.ShowToast}
                        setIsLoaderVisible={this.props.setIsLoaderVisible}
                        item={this.state.rowData}
                        displayForm={this.state.displayPublicURLDialog}
                        setDisplayPublicURL={value => this.setState({ displayPublicURLDialog: value })}
                        setActionSuccessful={() => this.setState({ actionSuccessful: true })}

                    />
                }

                {
                    this.state.viewDetailsDialog && <ViewEClipsDetailsDialog
                        displayView={this.state.viewDetailsDialog}
                        hideDisplayView={() => this.setState({ viewDetailsDialog: false })}
                        data={this.state.rowData}
                    />
                }

                {
                    this.state.displayTreatDialog && <TreatEClipsDialog
                        displayView={this.state.displayTreatDialog}
                        hideDisplayView={() => this.setState({ displayTreatDialog: false })}
                        setActionSuccessful={() => this.setState({ actionSuccessful: true })}
                        data={this.state.rowData}
                        ShowToast={this.props.ShowToast}
                        setIsLoaderVisible={this.props.setIsLoaderVisible}

                    />
                }

            </>
        )
    }
}
