import NavBar from "../admin/assets/App/nav-bar"
import TopBar from "../admin/assets/App/topbar"
import LockedScreen from "../admin/assets/App/LockedScreen";
import BulkActionsUsersForm from "./bulk-actions-users-form";
import Footer from "../admin/assets/App/footer";
import { useState } from 'react';
import { Helmet } from "react-helmet";

export const BulkActionUsers = (props) => {
    /**
     * THIS IS MORE OR LESS
     * THE HOST OF THE
     * BULK ACTIONS CONTAINER
     * FORM, CONTAINING
     * THE PAGE'S BASIC STRUCTURE
     * AND COMPONENTS
     */

    /**
     * This is the bulk actions container
     * for the main module....
     */
    const [mainClass, setMainClass] = useState("main");

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Bulk Actions | NIBSS Document Management Hub Portal</title>
            </Helmet>
            <LockedScreen
                setIsLoaderVisible={props.setIsLoaderVisible}
                ShowToast={props.ShowToast}
            />

            <div className="cont-ainer">
                <NavBar sender="Bulk Actions" />
                <div className={mainClass}>
                    <TopBar
                        setMainClass={value => setMainClass(value)}
                    />
                    <div className="page-title">
                        <h2><i className="icofont-mr icofont-addons"></i>Bulk Actions</h2>
                    </div>
                    <div className="branch-summaries">
                        <div className="summaries">

                            <BulkActionsUsersForm
                                ShowToast={props.ShowToast}
                                setIsLoaderVisible={props.setIsLoaderVisible}
                            />
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>

        </>
    )
}
