import { useState } from "react";

import "./admin/assets/css/calendar.css";
import "./admin/assets/css/dashboard.css";
import "./admin/assets/css/define.css";
import "./admin/assets/css/dialog.css";
import "./admin/assets/css/general.css";
import "./admin/assets/css/loading.css";
import "./admin/assets/css/lock-screen.css";
import "./admin/assets/css/main.css";
import "./admin/assets/css/toast.css";
import "./admin/assets/css/scrollbar.css";
import "./admin/assets/css/modal.css";
import "./admin/assets/css/quick-access.css";
import "./admin/assets/css/controls.css"

import "./admin/assets/200/ionicons/css/ionicons.min.css";
import "./admin/assets/200/boxicons/css/boxicons.min.css";
import "./admin/assets/200/icofont/icofont.min.css";
import Toast from "./admin/assets/App/toast";
import Loader from "./admin/assets/App/loader";
import SubApp from "./SubApp";

const App = () => {
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [toastVis, setToastVis] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [msgBoxType, setMsgBoxType] = useState("success");

  const ShowToast = (message, messageBoxType) => {
    setToastMsg(message);
    setMsgBoxType(messageBoxType);
    setToastVis(true);
  }

  return (
    <>
      <Toast
        toastMessage={toastMsg}
        msgBoxType={msgBoxType}
        toastVisibility={toastVis}
        setToastVisibility={setToastVis}
      />
      <Loader isVisible={isLoaderVisible} />

      <SubApp
        ShowToast={ShowToast}
        setIsLoaderVisible={setIsLoaderVisible}
      />

      {/* <button id="testButton" onClick={ShowToast} className="d-none"></button> */}

    </>
  );
}

export default App
