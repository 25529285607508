import { Link } from "react-router-dom"
import { useHistory } from "react-router";
import ApiPath from "./admin/assets/App/api-path";
import { Enums } from "./_common/enums"

const ErrorPage = () => {
    const history = useHistory();

    const PreviousPageClick = e => {
        e?.preventDefault();
        history?.goBack();
    }

    const token = sessionStorage.getItem("session_token");

    const SignOut = e => {
        e?.preventDefault();

        const options = {
            method: 'POST',
            headers: Enums.headersData(token),
        }

        //Capture signout in the audit logs...
        fetch(ApiPath + "api/auth/logout", options)
            ?.then(res => (res.json()))
            ?.then(response => {
                if (response.ok) {
                    /**
                     * Proceed to log out successfully...
                     *
                     */
                    const userType = sessionStorage.getItem("is_nibss_user");

                    sessionStorage.setItem("session_token_timestamp", null);
                    sessionStorage.setItem("session_valid_user", null);
                    sessionStorage.setItem("session_token", null);
                    sessionStorage.setItem("session_locked_state", null);
                    sessionStorage.setItem("session_first_name", null);
                    sessionStorage.setItem("session_last_name", null);
                    sessionStorage.setItem("session_user_type", null);
                    sessionStorage.setItem("institution", null);
                    sessionStorage.setItem("is_nibss_user", "-");
                    sessionStorage.setItem("customer_branch", null);
                    sessionStorage.setItem("customer_branch_slug", null);
                    sessionStorage.setItem("institution_slug", null);

                    //Sign out
                    /**
                     * This is determined by the kind of user logged in
                     * NIBSS user
                     * or
                     * regular institution admin/user...
                     */
                    if (userType?.indexOf("NIBSS") > -1) {
                        history?.push("/nibss-user-login")
                    } else {
                        history?.push("/");
                    }

                }
            })

    }

    return (
        <div className="page-background">
            <div className="error-page">
                <h1>404</h1>
                <h2>Document Management Hub Portal</h2>
                <h4>Oops! We can't seem to find the page you're looking for. While we look into it...</h4>

                <div className="links">
                    <Link to="/" id="prevPage" onClick={PreviousPageClick}>Go Back</Link>
                    <span className="spacer"></span>
                    <Link to="/" id="signOut" onClick={SignOut}>Sign Out</Link>
                </div>
                <img src="/assets/img/svg/not-found-404.svg" alt="" />

            </div>
        </div>

    );
}

export default ErrorPage;
