import NavBar from "../../admin/assets/App/nav-bar"
import TopBar from "../../admin/assets/App/topbar"
import LockedScreen from "../../admin/assets/App/LockedScreen";

import { Helmet } from "react-helmet";
import React, { useState } from 'react'
import InstitutionsForm from "./institutions-form";
import Footer from "../../admin/assets/App/footer";

export const Institutions = props => {

    /**
     * This is the institutions management
     * container
     * for the main module....
     */

    const [mainClass, setMainClass] = useState("main");

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Institutions Management | NIBSS Document Management Hub Portal</title>
            </Helmet>
            <LockedScreen
                setIsLoaderVisible={props.setIsLoaderVisible}
                ShowToast={props.ShowToast}
            />

            <div className="cont-ainer">
                <NavBar sender="Institution Management" />
                <div className={mainClass}>
                    <TopBar
                        setMainClass={value => setMainClass(value)}
                    />
                    <div className="page-title">
                        <h2><i className="icofont-mr icofont-institution"></i>Institution Management</h2>
                    </div>
                    <div className="branch-summaries">
                        <div className="summaries">
                            <InstitutionsForm
                                ShowToast={props.ShowToast}
                                setIsLoaderVisible={props.setIsLoaderVisible}
                            />

                        </div>
                    </div>
                    <Footer />
                </div>

            </div>

        </>
    )
}
