import ApiPath from '../../admin/assets/App/api-path'
import React from 'react'
import CreateInstitutionsForm from "./CreateInstitutionsForm"
import EditInstitutionsForm from "./edit-institutions-form"
import InstitutionFormRows from './institution-form-rows';
import Pagination from '../../admin/assets/App/Pagination';

class InstitutionsForm extends React.Component {

    constructor() {
        super()
        this.state = {
            apiData: [],
            fetchSuccessful: false,
            createSuccessful: false,
            rowData: [],
            metaData: {},
            editClicked: false,
            displayCreateDialog: false,
            currentSelectedPage: 1,
            arrayPages: [],
            lastPage: 1,
            perPage: localStorage.getItem("items_per_page") || 20,
        }
    }

    EditInstitution = itemArray => {
        /**
         * Select a particular row of
         * interest for the purpose of
         * edititng...
         * We have decided to use the
         * slug parameter for the
         * filtering...
         */
        this.setState({
            rowData: itemArray,
            editClicked: true,
        })

    }

    RefreshForm = () => {
        window.location.reload();
    }

    getData = () => {
        /**
         * Fetch
         * the data
         * for the individual
         * institutions
         */
        this.props.setIsLoaderVisible(true)

        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                'Cache-Control': 'public, max-age=0'
            },
        }

        fetch(`${ApiPath}api/institution/fetch-institutions?page=
            ${this.state.currentSelectedPage}&limit=${this.state.perPage}`, options)
            ?.then(response => {
                return response.json();
            })
            ?.then(response => {
                this.props.setIsLoaderVisible(false)
                if (response) {
                    if (response.ok === true) {
                        this.setState({
                            apiData: response.body.data.institutions,
                            metaData: response.body.data.meta,
                            fetchSuccessful: true,
                            createSuccessful: false,
                            arrayPages: [],

                        }, () => {
                            const arrayPages = [];

                            this.setState({
                                lastPage: this.state.metaData?.last_page,
                                currentSelectedPage: this.state.metaData?.current_page,

                            }, () => {
                                //Set this array for the looping
                                for (let i = 1; i <= this.state.lastPage; i++) {
                                    arrayPages.push({ "id": i, "name": "Item " + i });
                                }

                                this.setState({
                                    arrayPages: arrayPages,
                                })
                            })

                        })

                        //Set other parameters
                    } else {
                        this.props.ShowToast("Unable to load table. Please check your network and if this continues, contact your admin.", "exclamation")
                        return;
                    }
                } else {
                    this.props.ShowToast("Invalid login credentials", "exclamation")
                }
            })
            .catch(error => {
                this.props.setIsLoaderVisible(false)
                this.props.ShowToast(error.message, "exclamation")
            })
    }

    componentDidMount() {
        this.getData()
    }

    render() {
        /**
         * This ensures that the table
         * refreshes
         * after each time
         * a record
         * is created
         * edited
         * or deleted
         */
        if (this.state.createSuccessful) {
            this.getData();
            this.setState({
                createSuccessful: false,
            })
        }

        //Load the table rows each time the table refreshes
        let counter = 0;
        const TableRows = this.state.fetchSuccessful && this.state.apiData.map(item => {
            counter++;
            //This next item ensures that a proper counter is established
            // taking into account, the pagination
            const itemCounter = (this.state.currentSelectedPage - 1) * this.state.perPage + counter

            return (
                <InstitutionFormRows
                    key={item.slug}
                    item={item}
                    counter={itemCounter}
                    EditInstitution={this.EditInstitution}
                    ShowToast={this.props.ShowToast}
                    setIsLoaderVisible={this.props.setIsLoaderVisible}
                    actionSuccessful={val => this.setState({ createSuccessful: val })}
                />
            )
        })

        return (
            <>
                <div className="title">
                    <h3>Institutions</h3>
                    <div className="buttons">
                        <button
                            id="btnCreateInstitution"
                            type="button"
                            className="btn-grey t-tip mr-1"
                            data-title="Create New Institution"
                            onClick={() => this.setState({ displayCreateDialog: true })}>
                            <i className="icofont-plus"></i> Create Institution
                        </button>
                        <button
                            id="btnExport"
                            type="button"
                            className="btn-green t-tip mr-1 d-none"
                            data-title="Export records to MsExcel file">
                            <i className="icofont-airplane-alt"></i> Export
                        </button>
                        <button
                            id="btnRefresh"
                            type="button"
                            className="btn-grey t-tip" data-title="Refresh Form"
                            onClick={this.RefreshForm}>
                            <i className="icofont-refresh"></i> Refresh
                        </button>
                        <button
                            id="btnDownloadSampleFile"
                            type="button"
                            className="btn-grey t-tip mr-1 d-none" data-title="Download schema for Bulk Upload"><i className="icofont-download"></i>
                        </button>

                    </div>
                </div>

                <div className="table-container">
                    <table className="bordered-table">
                        <caption></caption>
                        <thead>
                            <tr>
                                <th scope="col" className="align-center">#</th>
                                <th scope="col" className="align-left">Institution Code</th>
                                <th scope="col" className="align-left">Institution Name</th>
                                <th scope="col" className="align-left">Institution Type</th>
                                <th scope="col" className="align-left">Date Created</th>
                                <th scope="col" className="align-left">Billing Type</th>
                                <th scope="col" className="align-center">Status</th>
                                <th scope="col" className="align-center">Action</th>
                            </tr>
                        </thead>
                        <tbody id="tbl_institutions">
                            {TableRows}
                        </tbody>
                    </table>
                </div>

                <Pagination
                    perPage={this.state.perPage}
                    lastPage={this.state.lastPage}
                    setRefreshForm={value => this.setState({ refreshForm: value })}
                    arrayPages={this.state.arrayPages}
                    setPerPage={value => this.setState({ perPage: value })}
                    currentSelectedPage={this.state.currentSelectedPage}
                    setCurrentSelectedPage={value => this.setState({ currentSelectedPage: value })}
                />

                <CreateInstitutionsForm
                    setDisplayCreateDialog={val => this.setState({ displayCreateDialog: val })}
                    displayCreateDialog={this.state.displayCreateDialog}
                    ShowToast={this.props.ShowToast}
                    setIsLoaderVisible={this.props.setIsLoaderVisible}
                    setIsCreateSuccessful={value => this.setState({ createSuccessful: value })}

                />

                {this.state.editClicked && <EditInstitutionsForm
                    ShowToast={this.props.ShowToast}
                    setIsLoaderVisible={this.props.setIsLoaderVisible}
                    setIsEditSuccessful={editState => this.setState({ createSuccessful: editState })}
                    rowData={this.state.rowData}
                    editClicked={this.state.editClicked}
                    setEditClicked={value => this.setState({ editClicked: value })}
                />}
            </>
        )
    }
}
export default InstitutionsForm;
