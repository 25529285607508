import React from "react";
import ApiPath from "../../../admin/assets/App/api-path";
import ExportToCSVFile from "../../../admin/assets/App/ExportToCSVFile";
import objectToCSV from "../../../admin/assets/App/object-to-csv";
import { SplitDate, ShortDateToCoded } from "../../../admin/assets/App/split-date";
import GetServices from "../../../admin/assets/App/GetServices";

class DownloadBillingsDialog extends React.Component {

    constructor() {
        super()
        this.state = {
            summaryReport: [],
            billingReport: [],
            smartDet: [],
        }

        this.summaryReport = "Summary Report"
        this.billingReport = "Billing Report"

        this.fileTitle = "";

        this.donwloadError = "No data to download. Number or rows of data is zero (0)."
    }

    componentDidMount() {
        //Load the default values of the individual
        //Types to make it easy for download...
        this.LoadReport(this.summaryReport)
        this.LoadReport("Smart Det")
        this.LoadReport(this.billingReport)

        this.fileTitle = `${SplitDate(this.props.data?.createdAt).codedDate} ${GetServices(this.props.data?.service?.name)} ` +
            `${ShortDateToCoded(this.props.data?.startDate?.substring(0, 10))}-${ShortDateToCoded(this.props.data?.endDate?.substring(0, 10))}`;

    }

    downloadSummaryReport = () => {
        /**
         * Download the
         * summary report file
         * to downloads folder
         */

        //Test if there is data or not... Return a message box if there is no data for the period...
        if (this.state.summaryReport.report?.length === 0) {
            this.props.ShowToast(this.donwloadError, "exclamation");
            return;
        }

        const thisData = this.state.summaryReport.report?.map(item => {
            //Returns an object of the required titles... then actual details...
            return ({
                "Institution Name": item.meta.name,
                "Date": SplitDate(item.meta.CREATED_AT).date,
                "Volume": item.meta.total,
            })
        })

        const csvData = objectToCSV(thisData);
        ExportToCSVFile(csvData, this.fileTitle + " - Summary-report.csv")

    }

    downloadBillingReport = () => {
        /**
         * Download the
         * summary report file
         * to downloads folder
         */

        //Test if there is data or not... Return a message box if there is no data for the period...
        if (this.state.billingReport.report?.length === 0) {
            this.props.ShowToast(this.donwloadError, "exclamation");
            return;
        }

        const thisData = this.state.billingReport.report?.map(item => {
            //Returns an object of the required titles... then actual details...
            return ({
                // // "Institution": item.institutionName,
                "Account_Number": item.billingAccountNumber,
                "Sort_Code": item.sortCode,
                "Amount": item.billingAmount,
                "Payee_Beneficiary": item.institutionName,
                "Narration": item?.narration,
            })
        })

        const csvData = objectToCSV(thisData);
        ExportToCSVFile(csvData, this.fileTitle + " - Billing-File.csv")

    }

    downloadSmartDet = () => {
        /**
         * Download the
         * smart det file
         * to downloads folder
         *
         */

        //Test if there is data or not... Return a message box if there is no data for the period...
        if (this.state.smartDet.report?.length === 0) {
            this.props.ShowToast(this.donwloadError, "exclamation");
            return;
        }

        let outputData = "";
        this.state.smartDet.report?.forEach(item => {
            //Returns an object of the required titles... then actual details...
            // The expected format for this is
            const splitDate = item.endDAte?.split("-");
            const outputDate = `${splitDate[0]}${splitDate[1]}${splitDate[2]}`;

            outputData += `${item.sortCode},${item.productCode},${outputDate},${item.billingAmount}\r`;
        })

        //Export and download this file as a .txt
        ExportToCSVFile(outputData, this.fileTitle + " - SmartDet.txt", "text/plain")

    }

    LoadReport = type => {
        let api;

        switch (type) {
            case this.billingReport:
                api = "/billing/billing-file/"
                break;
            case this.summaryReport:
                api = "/billing/summary-report/";
                break;
            default:
                api = "/billing/smart-det/"
                break;

        }

        this.props.setIsLoaderVisible(true);
        const options = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                'Content-Type': 'application/json',
                'Cache-Control': 'public, max-age=0'
            }
        }

        fetch(`${ApiPath}api${api}${this.props.data.slug}`, options)    //Change the endpoint when ready...
            ?.then(response => {
                this.props.setIsLoaderVisible(false)
                return response.json();
            })
            .then(response => {
                if (response && response.ok) {
                    switch (type) {
                        case this.billingReport:
                            this.setState({ billingReport: response.body.billingFile })
                            break;
                        case this.summaryReport:
                            this.setState({ summaryReport: response.body.billings })
                            break;
                        default:
                            this.setState({ smartDet: response.body.smartDet })
                            break;
                    }
                } else {
                    this.props.ShowToast(response.message, "exclamation")
                }
            })
            .catch(error => {
                this.props.ShowToast(error.message, "exclamation")
            })
    }

    closeForm = () => {
        this.props.setDisplayDownloadDialog(false)
    }

    render() {
        const dialogClass = this.props.displayDownloadDialog ? "modal fade show" : "modal fade"

        return (
            <>
                <div className={dialogClass}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h2 className="nully"><i className="icofont-download"></i> Download Billings</h2>
                            </div>
                            <div className="modal-body">
                                {/* Create tabs here for each type of download
                        --Remember to use document - Download Billings - item 6... */}

                                <div className="dialog-table-header">
                                    <i className="icofont-ui-file" />
                                    <span>{this.fileTitle}</span>
                                </div>
                                <table className="dialog-table">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="align-left">TITLE</th>
                                            <th scope="col" className="align-center">ACTION</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Summary Report</td>
                                            <td className="align-center">
                                                <button
                                                    id="btnDownloadSummaryReport"
                                                    className="btn-grey"
                                                    title="Download Summary Report"
                                                    onClick={this.downloadSummaryReport}>
                                                    <i className="icofont-download"></i> Download
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Billing File</td>
                                            <td className="align-center">
                                                <button
                                                    id="btnDownloadBilingReport"
                                                    className="btn-grey"
                                                    title="Download Billing File"
                                                    onClick={this.downloadBillingReport}>
                                                    <i className="icofont-download"></i> Download
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Smart Det</td>
                                            <td className="align-center">
                                                <button
                                                    id="btnDownloadSmartDet"
                                                    className="btn-grey"
                                                    title="Download SmartDet File"
                                                    onClick={this.downloadSmartDet}>
                                                    <i className="icofont-download"></i> Download
                                                </button>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                                <hr className="golden-rule" />
                            </div>
                            <div className="modal-footer">
                                <button
                                    id="btnClose"
                                    name="btnClose"
                                    className="btn-grey"
                                    onClick={this.closeForm}
                                ><i className="icofont-exit"></i> Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}

export default DownloadBillingsDialog;
