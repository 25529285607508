const EDMMSRoleGroup = (props) => {

    const setOperatorChanges = e => {
        props.setEDMMS(e.target.id);
        props.setEDMMSCode(9);
    }

    const setApproverChanges = e => {
        props.setEDMMS(e.target.id);
        props.setEDMMSCode(10);
    }

    const clearValues = () => {
        props.setEDMMS('');
        props.setEDMMSCode('');
    }

    return (
        <div className="role-group">
            <h3 className="role-group-title">e-DMMS</h3>
            <div className="role-group-inputs">
                <label>
                    <input
                        type="radio"
                        id="eDMMSOperator"
                        name="e_DMMS"
                        checked={props.eDMMS === "eDMMSOperator"}
                        onChange={setOperatorChanges}
                    /> Operator
                </label>
                <label>
                    <input
                        type="radio"
                        id="Approver"
                        name="e_DMMS"
                        checked={props.eDMMS === "Approver"}
                        onChange={setApproverChanges}
                    /> Approver
                </label>
                <button
                    className="clear-radios btn-grey"
                    id="btnClearEDMMS"
                    onClick={clearValues}>
                    <i className="icofont-mop"></i> Clear
                </button>
            </div>
        </div>
    );
}

export default EDMMSRoleGroup;
