import CapitalizeFirstLetter from "../../admin/assets/App/CapitalizeFirstLetter";
import Badges from "../../admin/assets/App/Badges";
import MaskCardNumber from "../../admin/assets/App/MaskCardNumber";
import { SplitDate } from "../../admin/assets/App/split-date";

const TrappedCardsReportsTableRows = (props) => {

    const actionStatus = CapitalizeFirstLetter(props.item.status);
    const statusClass = Badges(props.item.status);
    const cardSchemeImageURL = () => {
        let retVal = "";
        switch (props.item.cardScheme) {
            case "visa":
                retVal = "/assets/img/jpg/v-card.jpg";
                break;
            case "mastercard":
                retVal = "/assets/img/jpg/m-card.jpg";
                break;
            case "afrigo":
                retVal = "/assets/img/jpg/afri-go-card.jpg";
                break;
            default:
                retVal = "/assets/img/jpg/ve-card.jpg";
                break;
        }

        return retVal;
    }

    return (
        <tr>
            <td className="align-center">{props.counter}.</td>
            <td>{props.item.nameOnCard}</td>
            <td>{props.item.trappedCardNo}</td>
            <td>{MaskCardNumber(props.item.cardNumber)}</td>
            <td>{props.item.issuingInstitution?.name}</td>
            <td>{props.item.acquiringInstitution?.name}</td>
            <td>{props.item.createdByUser?.branch?.branchName || "-"}</td>
            <td className="align-center"><img src={cardSchemeImageURL()} alt="" width={40} /></td>
            <td>{SplitDate(props.item.createdAt).dateTime}</td>
            <td>{props.item.trappedCardLog?.acceptedByUser?.email
                || props.item.trappedCardLog?.rejectedByUser?.email || "-"}</td>
            <td><span className={statusClass}>{actionStatus}</span></td>

        </tr>
    )
}

export default TrappedCardsReportsTableRows;
