import React, { Component } from 'react'
import ApiPath from '../../admin/assets/App/api-path';

export default class RejectOutgoingEDMMSMandate extends Component {

    constructor() {
        super();
        this.state = {
            rejectReason: ""
        }

        this.postOptions = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                'Cache-Control': 'public, max-age=0'
            },
            method: 'POST',
            body: '',
        }
    }

    handleChange = e => {
        const { id, value } = e.target;
        this.setState({
            [id]: value
        })
    }

    rejectMandate = () => {
        // Reject the mandate, making sure to provide the reason...

        if (this.state.rejectReason === "") {
            this.props.ShowToast("You must enter a reject reason to proceed.", "exclamation");
            return;
        }

        this.postOptions.body = JSON.stringify({ slug: this.props.data?.slug, comment: this.state.rejectReason })

        // Else, we can go ahead and update....
        this.props.setIsLoaderVisible(true);

        fetch(ApiPath + "api/edmms-mandate/reject-outgoing-mandate", this.postOptions)
            ?.then(response => (response.json()))
            .then(output => {
                // Promise ended. Hide loader...
                this.props.setIsLoaderVisible(false);

                if (output && output.ok) {
                    // Rejected successfully...
                    this.props.ShowToast("Rejected successfully!", "success");
                    this.props.setActionSuccessful();

                    // Hide the form...
                    this.props.closeForm();

                } else {
                    // Some issue occured...
                    this.props.ShowToast(output.message, "exclamation");

                }
            })
            .catch(error => {
                this.props.setIsLoaderVisible(false);
                this.props.ShowToast(error.message, "exclamation");
            })
    }

    render() {
        return (
            <div className={this.props.displayReject ? "modal fade show" : "modal fade"}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="create" style={{ fontSize: 14 }}>
                                <i className='icofont-not-allowed'></i> Reject Mandate
                            </h2>
                        </div>
                        <div className="modal-body">
                            <div className="form-row-2 mb-1">
                                <div className="input-group">
                                    <label>Reject reason</label>
                                    <textarea
                                        id="rejectReason"
                                        value={this.state.rejectReason}
                                        onChange={this.handleChange}
                                        className="form-control"
                                        rows={5}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                id="btnReject"
                                onClick={this.rejectMandate}
                                className="btn-green mr-1"
                            >Reject Mandate</button>
                            <button
                                type="button"
                                id="btnCancel"
                                onClick={this.props.closeForm}
                                className="btn-grey"
                            >Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
