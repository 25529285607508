import React, { useEffect, useState } from 'react'
import ApiPath from '../../admin/assets/App/api-path'
import Badges from '../../admin/assets/App/Badges'
import CapitalizeFirstLetter from '../../admin/assets/App/CapitalizeFirstLetter'
import { SplitDate } from '../../admin/assets/App/split-date'
import ImageDialog from '../../e-reference/App/create-reference/image-dialog'

export const ViewEDMMSMandateDetails = props => {

    const [data, setData] = useState({});
    const [mandateExtension, ] = useState('')
    const [displayImage, setDisplayImage] = useState(false);

    const fetchData = async () => {
        // Set the required default values
        // in the fields...
        const slug = props.data?.slug;
        const url = props.sender === 'eDMMS Reports'
            ? `${ApiPath}api/edmms-report/fetch-mandate-details/${slug}`
            : `${ApiPath}api/edmms-mandate/fetch-mandate-details/${slug}`;

        const getOptions = {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                'Content-Type': 'application/json',
                'Cache-Control': 'public, max-age=0'
            },
        }

        try {
            props.setIsLoaderVisible(true);

            const getData = await fetch(url, getOptions);
            const response = await getData.json();

            props.setIsLoaderVisible(false);

            if (response && response.ok) {
                // Fetched successfully...
                setData(response.body.mandate);
            }

        } catch (error) {
            props.setIsLoaderVisible(false);
            props.ShowToast(error.message, "exclamation");
        }

    }

    useEffect(() => {
        fetchData();
        //eslint-disable-next-line
    }, [])

    return (
        <>
            <div className={props.displayView ? "modal fade show" : "modal fade"}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="create" style={{ fontSize: 14 }}>
                                <i className='icofont-letter'></i> Details - {props.sender} - #{props.data?.mandateCode}
                            </h2>
                        </div>
                        <div className="modal-body">
                            <table className="dialog-table mb-2">
                                <caption></caption>
                                <thead>
                                    <tr>
                                        <th scope='col' className="align-left">Fields</th>
                                        <th scope='col' className="align-left">Details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Mandate Code</td>
                                        <td>{data?.mandateCode}</td>
                                    </tr>
                                    <tr>
                                        <td>Created At</td>
                                        <td>{SplitDate(data?.createdAt).dateTime}</td>
                                    </tr>
                                    <tr>
                                        <td>Stock Holdings</td>
                                        <td>
                                            {
                                                data?.stockHoldings?.map(item => (
                                                    <div key={item.slug}>
                                                        <span>{item.company?.companyName} - {item.registrar?.name}</span>
                                                        <br />
                                                    </div>
                                                ))
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Institution</td>
                                        <td>{data?.institution?.name}</td>
                                    </tr>
                                    <tr>
                                        <td>Branch</td>
                                        <td>{data?.branch?.branchName}</td>
                                    </tr>
                                    <tr>
                                        <td>Company Name / Fullname</td>
                                        <td>{data?.othernames === "" ? data?.companyName : data.companyName + " " + data.othernames}</td>
                                    </tr>
                                    <tr>
                                        <td>Status</td>
                                        <td><span className={Badges(data?.status)}>{CapitalizeFirstLetter(data?.status)}</span></td>
                                    </tr>
                                    <tr>
                                        <td>Mandate</td>
                                        <td>
                                            <button
                                                type="button"
                                                className='btn btn-grey'
                                                onClick={() => setDisplayImage(true)}
                                                id="btnDisplay"
                                            >Preview...</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer">
                            <hr />
                            <button
                                type="button"
                                onClick={props.hideDialog}
                                className="btn-grey mr-2"
                                id="btnClose">
                                <i className="icofont-exit"></i> Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {
                displayImage && <ImageDialog
                    isURL={false}
                    src={data?.mandatePath}
                    mandateExtension={mandateExtension}
                    setImageDisplay={val => setDisplayImage(val)}
                />
            }
        </>

    )
}
