import ValidateEmail from '../../../admin/assets/App/validate-email';
import ApiPath from '../../../admin/assets/App/api-path';
import React from 'react';

class ScheduledBillingFormData extends React.Component {

    constructor() {
        super()
        this.state = {
            cbxService: "",
            cbxServiceSelectedIndex: 0,
            cbxBillingType: "",
            cbxEClipsType: "",
            cbxRunDate: "",
            chkSendEmail: false,
            txtSpecialEmail: "",
            disableRunDate: true,
            boxContent: "Selected Emails",
            roleServices: [],
        }

        this.AccountUser = `${sessionStorage.getItem("session_first_name")} ${sessionStorage.getItem("session_last_name")}`;

    }

    fetchService = () => {
        const Options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                'Cache-Control': 'public, max-age=0'
            },
        }
        fetch(ApiPath + 'api/institution-type-service/fetch-institution-type-service', Options)
            ?.then(response => {
                return response.json();
            })
            ?.then(response => {
                if (response && response.ok) {
                    this.setState({
                        roleServices: response.body.services,
                    })
                }
            })
    }

    handleKeyDown = e => {
        if (e.keyCode === 188) {
            //Do the e-mail parsing...
            //each time a comma (,)
            //is typed
            this.createEmailSpans();
        } else if (e.keyCode === 13) {
            this.handleSubmit();
        }
    }

    handleChange = e => {
        const { name, value, type, checked } = e?.target;
        type !== "checkbox"
            ? this.setState({ [name]: value }, () => {
                //Set related actions for each item...
                if (name === "cbxBillingType" && this.state.cbxBillingType === "monthly") {
                    //Set the state for the rundate
                    this.setState({
                        disableRunDate: true,
                        cbxRunDate: "",
                    })
                } else if (name === "cbxBillingType" && this.state.cbxBillingType === "weekly") {
                    this.setState({
                        disableRunDate: false
                    })
                }
            })
            : this.setState({ [name]: checked })
    }

    createEmailSpans = () => {
        /** as the textbox blurs,
         * create a list of
         * emails with special colorizing
         * and close items...

        After clearing the container, fix the emails...*/
        const emails = this.state.txtSpecialEmail.split(",");
        const txtSpecialEmail = document.getElementById("txtSpecialEmail")
        txtSpecialEmail?.classList.remove("error-border");

        const emailContent = emails.map((item, index) => {
            if (!ValidateEmail(item)) {
                this.props.ShowToast(`Invalid email in selected emails list - '${item}', "exclamation`);
                txtSpecialEmail?.classList.add("error-border");
                txtSpecialEmail?.focus();
            }

            return (
                <span key={index}>{item.trim()}</span>
            )
        })

        this.setState({
            boxContent: emailContent || "Selected Emails"
        });

    }

    componentDidMount() {
        this.fetchService();

        if (this.props.data) {

            let emails = this.props.data?.emailAddresses || "{emails: ['email@mailer.com', 'mailer@mail.com']}"     //Again, for unit testing sake...
            if (emails.indexOf(":") > -1) {
                //Some unnecessary details are there... trim...
                //Due to Kola's unexpected data return...
                //WE can delete this later, after his fix...
                const subArray = emails.split(":");
                const final = subArray[1].split("}");
                emails = final[0];
            }

            this.setState({
                cbxService: this.props.data?.billingService?.slug,
                cbxBillingType: this.props.data?.billingType,
                cbxRunDate: this.props.data?.runDate,
                cbxEClipsType: this.props.data.eClipRequestType,
                txtSpecialEmail: (emails !== "")
                    ? JSON.parse(emails).join(", ")
                    : "",
                chkSendEmail: (emails !== "") ? true : false,
                disableRunDate: this.props.data?.billingType === "weekly" ? false : true,
            })
        }
    }

    validate = () => {
        //Perform checks and set the return value
        let returnFailed = false;

        if (this.state.cbxService === "") {
            this.props.ShowToast("Billing Service required. Select from list.", "exclamation");
            returnFailed = true;
        } else if (this.state.cbxBillingType === "") {
            this.props.ShowToast("Billing Type required!", "exclamation");
            returnFailed = true;
        } else if (this.state.cbxBillingType === "weekly" && this.state.cbxRunDate === "") {
            this.props.ShowToast("Run Date required for Weekly Billing Type.", "exclamation")
            returnFailed = true;
        } else if (this.state.chkSendEmail && this.state.txtSpecialEmail === "") {
            this.props.ShowToast("Email required in list. Uncheck 'Send Email' option if no emails are required.", "exclamation");
            returnFailed = true;
        } else if (this.state.cbxServiceSelectedIndex === 4 && this.state.cbxEClipsType === "") {
            this.props.ShowToast("e-Clips type required!", "exclamation");
            returnFailed = true;
        }

        return returnFailed;
    }

    handleSubmit = () => {
        /**
         * Creates a
         * new billing
         * record after
         * validating the
         * inputs
         */

        if (this.validate()) {
            return;
        }

        //Else proceed to update...
        this.props.setIsLoaderVisible(true);
        const emailAddresses = []
        this.state.txtSpecialEmail.split(",").forEach(item => {
            emailAddresses.push(item.trim());
        })

        const updateData = {
            slug: this.props.data?.slug,
            service: this.state.cbxService,
            billing: this.state.cbxService,
            billingType: this.state.cbxBillingType,
            runDate: this.state.cbxRunDate,
            emailAddresses: emailAddresses,
            eClipRequestType: this.state.cbxEClipsType,         //Remember to change this variable at the componentDidMount later, according to the endpoint specs...
        }

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                'Cache-Control': 'public, max-age=0'
            },
            body: JSON.stringify(updateData),
        }

        const api = (this.props.nameTag === "Edit")
            ? 'api/scheduled-billing/edit-scheduled-billing'
            : 'api/scheduled-billing/create-billing-schedule';

        fetch(ApiPath + api, options)    //Change the endpoint when ready...
            ?.then(response => {
                this.props.setIsLoaderVisible(false)
                return response.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false)

                if (response && response.ok === true) {
                    this.props.nameTag === "Edit" ? this.props.setDisplayEditDialog(false) : this.props.setDisplayCreateDialog(false);
                    this.props.ShowToast("Scheduled Billing Record updated successfully!", "success")
                    this.props.setActionSuccessful(true)

                } else {
                    this.props.ShowToast(response.message, "exclamation")
                }

            })
            .catch(error => {
                this.props.ShowToast(error.message, "exclamation")
            })
    }

    render() {

        const Services = this.state.roleServices && this.state.roleServices.map(item => {

            return (
                <option
                    key={item.slug}
                    value={item.slug}>{item.name}</option>
            )
        })

        const eClipsServiceType = this.state.cbxServiceSelectedIndex === 4 ? "input-group" : "d-none";

        return (

            <div className="dialog-container" onKeyDown={this.handleKeyDown}>
                <div className="dialog-header">
                    <h2 id="dialog-title" className={this.props.nameTag?.toLowerCase()}><i className="bx bxs-timer"></i> {this.props.nameTag} Scheduled Billing</h2>
                </div>
                <div className="dialog-body">
                    <div className="form-row-2-1">
                        <div className="input-group">
                            <label><i className="icofont-user-alt-3"></i> Account User</label>
                            <input type="text" name="txtAccountUser" className="form-control"
                                value={this.AccountUser} disabled />
                        </div>
                    </div>
                    <hr className="golden-rule" /><h3 className="row-title">Billing Details</h3>
                    <div className="form-row-2-1-1 mb-2">
                        <div className="input-group col-md">
                            <label><span>*</span>Billing Service</label>
                            <select
                                id="cbxService"
                                name="cbxService"
                                className="custom-select"
                                value={this.state.cbxService}
                                onChange={e => {
                                    this.handleChange(e);
                                    this.setState({
                                        cbxServiceSelectedIndex: e.target.selectedIndex,
                                    })
                                }}>
                                <option value=""></option>
                                {Services}
                            </select>
                            <label className="control-info"><i className="icofont-warning-alt"></i> Select Service from list</label>
                        </div>
                        <div className={eClipsServiceType}>
                            <label><span>*</span>Clips Type</label>
                            <select
                                className="custom-select"
                                name="cbxEClipsType"
                                id="cbxEClipsType"
                                value={this.state.cbxEClipsType}
                                onChange={this.handleChange}
                            >
                                <option value=""></option>
                                <option value="Request">Request</option>
                                <option value="View">View</option>
                            </select>
                            <label className="control-info"><i className="icofont-warning-alt"></i> Select type from list</label>
                        </div>
                    </div>
                    <div className="form-row mb-2">
                        <div className="input-group col-md">
                            <label><span>*</span>Billing Type</label>
                            <select
                                id="cbxBillingType"
                                name="cbxBillingType"
                                className="custom-select"
                                onChange={this.handleChange}
                                value={this.state.cbxBillingType}
                            >
                                <option value=""></option>
                                <option value="weekly">Weekly</option>
                                <option value="monthly">Monthly</option>
                            </select>
                            <label className="control-info"><i className="icofont-warning-alt"></i> Select Billing Type</label>
                        </div>
                        <div className="input-group col-md">
                            <label>Run Date</label>
                            <select
                                id="cbxRunDate"
                                name="cbxRunDate"
                                className="custom-select"
                                onChange={this.handleChange}
                                value={this.state.cbxRunDate}
                                disabled={this.state.disableRunDate}
                            >
                                <option value=""></option>
                                <option value="0">Sunday</option>
                                <option value="1">Monday</option>
                                <option value="2">Tuesday</option>
                                <option value="3">Wednesday</option>
                                <option value="4">Thursday</option>
                                <option value="5">Friday</option>
                                <option value="6">Saturday</option>
                            </select>
                            <label className="control-info"><i className="icofont-warning-alt"></i> Select Day of Week</label>
                        </div>
                    </div>
                    <div className="form-row-3">
                        <div className="inline-input-group">
                            <label>
                                <input
                                    type="checkbox"
                                    name="chkSendEmail"
                                    id="chkSendEmail"
                                    checked={this.state.chkSendEmail}
                                    onChange={this.handleChange}
                                /> Send Email
                            </label>
                        </div>
                    </div>
                    <div className="form-row-3x mb-1">
                        <div className="input-group">
                            <textarea
                                type="text"
                                id="txtSpecialEmail"
                                name="txtSpecialEmail"
                                className="form-control"
                                rows="5"
                                onKeyDown={this.handleKeyDown}
                                value={this.state.txtSpecialEmail}
                                onChange={this.handleChange}
                                onBlur={this.createEmailSpans}
                                disabled={!this.state.chkSendEmail}
                            />
                            <label className="control-info">
                                <i className="icofont-warning-alt"></i> Separate Multiple Emails by commas
                            </label>
                        </div>
                    </div>
                    <div className="form-row-3x mb-3">
                        <div className="emails-box">{this.state.boxContent}</div>
                    </div>
                </div>

                <hr className="mb-2" />
                <div className="dialog-footer">
                    <button
                        className="btn-green mr-1"
                        name="btnUpdateInstitution"
                        type="submit"
                        onClick={this.handleSubmit}
                        title={this.props.nameTag}>
                        <i className="icofont-upload-alt"></i> {this.props.nameTag === "Edit" ? "Update" : this.props.nameTag} Scheduled Billing
                    </button>
                    <button
                        type="button"
                        className="btn-grey mr-2"
                        id="btnCancel"
                        onClick={this.props.closeForm}>
                        <i className="icofont-exit" title="Cancel"></i> Cancel
                    </button>
                </div>


                {/* For testing alone... Doesn't display... */}
                <input
                    type="checkbox"
                    className="d-none"
                    id="chkTest"
                    onChange={this.handleChange}
                />
                <input
                    type="text"
                    className="d-none"
                    id="txtTest"
                    name="cbxBillingType"
                    onChange={this.handleChange}
                />
            </div>

        );
    }

}

export default ScheduledBillingFormData
