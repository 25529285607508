const ProductPicker = (props) => {
    const displayClass = props.displayProductPicker ? "dialog-background fade" : "dialog-background";

    const SetDateDisplay = () => {
        props.setOutput(props.cbxProduct);
        props.setDisplayProductPicker(false)
    }

    return (
        <div className={displayClass}>
            <div className="lock-container">
                <div className="dialog-header">
                    <h3 className="create"><i className="bx bx-check-circle"></i> Choose Product</h3>
                </div>
                <div className="dialog-body">
                    <p>Select from list:</p>
                    <div className="form-row-3-1 mb-1">
                        <div className="input-group">
                            <select
                                className="custom-select"
                                id="cbxProduct"
                                name="cbxProduct"
                                value={props.cbxProduct}
                                onChange={props.handleChange}
                            >
                                <option value=""></option>
                                <option value="E-reference">e-Reference</option>
                                <option value="Trapped-cards">Trapped Cards</option>
                                <option value="EDMMS">e-DMMS</option>
                                <option value="Eclips">e-Clips</option>
                            </select>

                        </div>
                    </div>
                </div>
                <div className="dialog-footer">
                    <button
                        id="btnSelect"
                        name="btnSelect"
                        onClick={SetDateDisplay}
                        className="btn-green mr-1">
                        <i className="icofont-tick-mark"></i> Select
                        </button>
                    <button
                        onClick={() => props.setDisplayProductPicker(false)}
                        id="btnCancel"
                        className="btn-grey">
                        <i className="icofont-close"></i> Cancel
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ProductPicker
