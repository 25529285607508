import React from "react"
import ApproveOutgoingReference from "./approve-outgoing-reference"
import ApiPath from '../../../admin/assets/App/api-path'
import CapitalizeFirstLetter from "../../../admin/assets/App/CapitalizeFirstLetter"
import Badges from "../../../admin/assets/App/Badges"

class OutgoingReferencesTableRow extends React.Component {

    constructor() {
        super()
        this.state = {
            data: [],
            displayViewDialog: false,
            displayApproveDialog: false,
            itemSlug: "",
        }

        this.ViewItem = this.ViewItem.bind(this)
        this.EditItem = this.EditItem.bind(this)
        this.ApproveItem = this.ApproveItem.bind(this)
        this.DeleteItem = this.DeleteItem.bind(this)

        this.getOptions = {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                'Content-Type': 'application/json',
                'Cache-Control': 'public, max-age=0'
            },
            method: 'GET'
        }
    }

    componentDidMount() {
        if (this.props.item) {
            this.setState({
                data: this.props.item,
                itemSlug: this.props.item.slug,
            })
        }
    }

    EditItem() {
        this.props.setEditData(this.props.item);
        this.props.setDisplayEdit(true);
    }

    getReferenceComments = () => {
        // Fetch the comments
        // of the said reference,
        // and install in the comments place...

        //Trigger the API fetch for the required data...
        fetch(`${ApiPath}api/e-reference/get-comments/${this.props.item.slug}`, this.getOptions)
            ?.then(res => (res.json()))
            ?.then(answer => {
                if (answer && answer.ok) {
                    this.props.setCommentsData(answer.body.comments?.data)
                }
            })

    }

    ViewItem() {
        //First, fetch the reference comments for the dialog...
        this.getReferenceComments();

        //Display the dialog
        const slug = this.props.item.slug;
        const options = this.getOptions;

        fetch(`${ApiPath}api/e-reference/view-e-reference-detail/${slug}`, options)
            ?.then(response => response.json())
            .then(response => {
                if (response && response.ok) {
                    //this.props.setViewData(this.props.item)
                    this.props.setViewData(response.body.data);
                    this.props.setDisplayView(true);
                }
            })

        //Ensure to set the mandate Extension to enable us
        //get a proper extension for the recreation of .pdf objects...
        this.props.setMandateExtension(this.props.item?.mandatePath?.split(".")[1]);

    }

    cancelReference = () => {
        //Cancel the reference at any time
        this.props.setEditData(this.props.item);
        this.props.showCancelDialog(true);
    }

    ApproveItem() {

        /**
         * Approving an item
         * will entail
         * selecting
         * the proper item
         * and then
         * validating the
         * action, to avoid
         * accidental deletes...
         */

        this.setState({
            displayApproveDialog: true,
            itemSlug: this.props.item.slug,
        })

    }

    DeleteItem() {
        /**
         * Delete the selected
         * item from the list
         * of created references...
         */

        const consent = window.confirm("Do you really wish to delete the selected Reference?");

        if (consent) {
            this.props.setIsLoaderVisible(true);

            const data = { "slug": this.props.item?.slug }
            const options = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                    'Cache-Control': 'public, max-age=0'
                },
                method: 'POST',
                body: JSON.stringify(data),
            }

            const endpoint = "api/e-reference/delete-outgoing-e-reference";
            fetch(ApiPath + endpoint, options)
                ?.then(serverResponse => {
                    return serverResponse.json();
                })
                .then(response => {
                    this.props.setIsLoaderVisible(false)
                    if (response) {
                        if (response.ok) {
                            //Success
                            this.props.ShowToast("Outgoing Reference Deleted!", "success")
                            this.props.setActionSuccessful(true)

                        } else {
                            this.props.ShowToast(response.message, "exclamation")

                        }
                    } else {
                        this.props.ShowToast("Invalid input value(s).", "exclamation")
                    }
                })
                .catch(error => {
                    this.props.setIsLoaderVisible(false)
                    this.props.ShowToast(error.message, "exclamation")
                })

        }

    }

    render() {
        const statusClass = Badges(this.props.item?.actionStatus);
        const hideButtons = this.props.item?.actionStatus === "cancelled" ? "align-center d-none" : "align-center"
        const serviceIds = sessionStorage.getItem("service_ids")?.split(",") || ['2', '5', '8'];

        const actionStatus = CapitalizeFirstLetter(this.props.item?.actionStatus);
        const isOverDue = CapitalizeFirstLetter(this.props.item.isOverDue);

        let displayApproval = "icofont-checked mr-1";
        let displayEdit = "icofont-edit mr-1";
        let deleteButtonClass = "icofont-ui-delete";
        let cancelButtonClass = "icofont-ui-close";

        if (this.props.item?.stageStatus === "receiving") {
            displayApproval = "icofont-checked mr-1 d-none";
            displayEdit = "icofont-edit mr-1 d-none";
            deleteButtonClass = "icofont-ui-delete d-none";
            cancelButtonClass = "icofont-ui-close d-none";

        } else {
            //If the actionStatus has
            //proceeded beyond pending, then
            //Hide the Edit Button...
            if (actionStatus !== "New") {
                displayEdit = "icofont-edit mr-1 d-none";
                deleteButtonClass = "icofont-ui-delete d-none";
                cancelButtonClass = "icofont-ui-close d-none";
            }
        }

        //Check if the user is an approver or not...
        if (serviceIds?.indexOf('1') > -1) {
            //Is not an approver, thus hide the aprove button...
            displayApproval = 'd-none';
        } else if (serviceIds?.indexOf('2') > -1) {
            //An authorizer... hide the edit and delete buttons
            displayEdit = 'd-none';
            deleteButtonClass = 'd-none';
            cancelButtonClass = 'd-none';
        }

        return (
            <>
                <tr>
                    <td className="align-center">{this.props.counter}.</td>
                    <td title={this.props.item?.referenceNo}>{this.props.item?.referenceNo?.substring(0, 10)}...</td>
                    <td>{this.props.item?.customerAccountNumber}</td>
                    <td>{this.props.item?.customerAccountName}</td>
                    <td>{this.props.item?.receiverInstitution?.name}</td>
                    <td>{this.props.item?.receiverBranch?.branchName || "Central"}</td>
                    <td>{this.props.item?.refereeAccountNumber}</td>
                    <td>{this.props.item?.refereeAccountName}</td>
                    <td className="align-center">{isOverDue}</td>
                    <td><span className={statusClass}>{actionStatus}</span></td>
                    <td className={hideButtons} width="140">
                        <i
                            id="btnView"
                            className="icofont-page mr-1"
                            style={{ color: "grey", fontSize: 20, cursor: 'pointer' }}
                            title="View Details"
                            onClick={() => this.ViewItem()}
                        ></i>
                        <i
                            id="btnEdit"
                            className={displayEdit}
                            style={{ color: "grey", fontSize: 20, cursor: 'pointer' }}
                            title="Edit Reference"
                            onClick={() => this.EditItem()}
                        ></i>
                        <i
                            id="btnApprove"
                            className={displayApproval}
                            style={{ color: "green", fontSize: 20, cursor: 'pointer' }}
                            title="Approve Reference"
                            onClick={() => this.ApproveItem()}
                        ></i>
                        <i
                            id="btnDelete"
                            className={deleteButtonClass}
                            style={{ color: "maroon", fontSize: 20, cursor: 'pointer' }}
                            title="Delete Reference"
                            onClick={() => this.DeleteItem()}
                        ></i>

                    </td>
                    {serviceIds?.indexOf('2') === -1 && <td className="align-center">
                        <i
                            id="btnCancel"
                            className={cancelButtonClass}
                            style={{ color: "maroon", fontSize: 18, cursor: 'pointer' }}
                            title="Cancel Reference"
                            onClick={() => this.cancelReference()}
                        ></i>
                    </td>}
                </tr>

                {
                    this.state.displayApproveDialog &&
                    <ApproveOutgoingReference
                        ShowToast={this.props.ShowToast}
                        setIsLoaderVisible={this.props.setIsLoaderVisible}
                        displayApproveDialog={this.state.displayApproveDialog}
                        setDisplayApproveDialog={value => this.setState({ displayApproveDialog: value })}
                        slug={this.state.itemSlug}
                        referenceNo={this.props.item.referenceNo}
                        setApproveSuccessful={value => this.props.setActionSuccessful(value)}
                    />
                }
            </>
        );
    }
}

export default OutgoingReferencesTableRow
