const DayOfWeek = dayInteger => {
    let returnValue = "";

    switch (parseInt(dayInteger)) {
        case 0:
            returnValue = "Sundays";
            break;
        case 1:
            returnValue = "Mondays";
            break;
        case 2:
            returnValue = "Tuesdays";
            break;
        case 3:
            returnValue = "Wednesdays";
            break;
        case 4:
            returnValue = "Thursdays";
            break;
        case 5:
            returnValue = "Fridays";
            break;
        default:
            returnValue = "Saturdays";
            break;

    }

    return (returnValue);
}

export default DayOfWeek
