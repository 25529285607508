import ApiPath from "../admin/assets/App/api-path";
import React from "react";
import UploadFile from "../admin/assets/App/upload-file";
import objectToCSV from "../admin/assets/App/object-to-csv"
import ExportToCSVFile from "../admin/assets/App/ExportToCSVFile";
import { MessageBoxType as MsgBoxType } from "../_common/constants";
import { BulkDialog } from "./bulk-dialog";

class BulkActionsUsersForm extends React.Component {

    constructor() {
        super()
        this.state = {
            uploadedFile: "",
            cbxSelectAction: "0",
            cbxEnableDisable: "Enable",
            clearFileUpload: false,
            tableRowsArray: [],
            actionValue: "Action",
            rowTitle: "",
            actionArray: ["Enable", "Disable"],
            institutionTypes: [],
            institutionTypesArray: [],

            //After bulk update
            updateResponse: [],
            showBulkDialog: false,
        }

        this.headersData = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
            'Cache-Control': 'public, max-age=0'
        }
    }

    componentDidMount() {
        this.fetchInstitutionTypes()
    }

    fetchInstitutionTypes = () => {

        this.props.setIsLoaderVisible(true)
        const options = {
            method: 'GET',
            headers: this.headersData,
        }

        fetch(ApiPath + 'api/institution-type/fetch-institution-type', options)
            ?.then(response => {
                return response.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false)
                if (response) {
                    if (response.ok === true) {
                        this.setState({
                            institutionTypes: response.body.institutionTypes,
                        }, () => {
                            //Set the values to obtain the required array
                            this.state.institutionTypes.forEach(item => {
                                this.state.institutionTypesArray.push(item.name);
                            })
                        })
                    }
                }
            })

    }


    handleChange = e => {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
        }, () => {
            if (name === "cbxSelectAction") {
                switch (this.state.cbxSelectAction) {
                    case "0":
                        this.setState({ actionArray: ["Enable", "Disable"], actionValue: "Action" })
                        break;
                    case "1":
                        this.setState({ actionArray: ["Role ID", "Branch Code"], actionValue: "Update By" })
                        break;
                    case "3":
                        this.setState({ actionArray: this.state.institutionTypesArray, actionValue: "Institution Type" })
                        break;
                    default:
                        this.setState({ actionArray: ["Emails", "Code"], actionValue: "Action" })
                        break;
                }

                this.clearFileUpload();
            }
        })
    }

    downloadTemplate = () => {
        //Proceed to download template
        const fields = [];

        switch (this.state.cbxSelectAction) {
            case "0": //Bulk Enable/Disable Users
                fields.push({ "emails": "someemail@mailer.com" })
                break;
            case "1": //Bulk Update users
                fields.push({ "email address": "some_email@mailer.com", "Branch Code/Role ID": "" })
                break;
            case "2": //Bulk Create users
                fields.push({
                    "Institution Code": "002",
                    "Email Address": "validemail@mailer.com",
                    "First Name": "Firstname",
                    "Middle Name": "Middlename",
                    "Last Name": "Lastname",
                    "User Role ID": "1",
                    "Branch Code": "104",
                    "Phone Number": "08012345678",
                })
                break;
            default:   //Bulk Create Insitutions
                fields.push({
                    "Institution Code": "113",
                    "Institution Name": "FCMB PLC",
                    "Billing Type": "Bank",
                    "Billing Bank Code": "201",
                    "Biling Account Number": "1234567890",
                    "Revenue Bank Code": "101",
                    "Revenue Account Number": "1122334455",
                    "Address": "12, Some Address, Some Place, Some City, And Country",
                    "Contact Email": "validemail@email.com",
                    "Allow API Access?": "yes",
                })
                break;
        }

        const csvData = objectToCSV(fields);
        ExportToCSVFile(csvData, `bulk-actions_${parseInt(this.state.cbxSelectAction) + 1}.csv`);

        this.props.ShowToast("Check your downloads folder for the downloaded template file.", "information");

    }

    updateParameters = () => {

        //Else, proceed to update the file...
        let endpointValue = "";
        const users = [];
        const institutions = [];
        const action = this.state.cbxEnableDisable.toLowerCase();

        this.state.tableRowsArray.forEach(item => {
            //Create and array of the payLoad to send to the endpoint
            const itemSplit = item.split(",");

            switch (this.state.cbxSelectAction) {
                case "0":
                    //Bulk enable/disable users
                    endpointValue = "api/user/bulk-change-user-status"
                    users.push({
                        email: item.split("\r")[0],
                        action: action
                    })
                    break;

                case "1":
                    //Bulk update users
                    endpointValue = "api/user/bulk-update-institution-users"
                    const itemName = this.state.cbxEnableDisable === "Role ID" ? "userRole" : "branch";

                    users.push({
                        email: itemSplit[0],
                        [itemName]: itemSplit[1].split("\r")[0],            //THis is necessary in case of such characters as \r
                    })
                    break;

                case "2":
                    //Bulk create users
                    endpointValue = "api/user/bulk-create-institution-users"
                    users.push({
                        institutionCode: itemSplit[0],
                        email: itemSplit[1],
                        firstName: itemSplit[2],
                        middleName: itemSplit[3],
                        lastName: itemSplit[4],
                        userRole: itemSplit[5],
                        branch: itemSplit[6],
                        phoneNo: itemSplit[7].substring(0, 1) !== "0" ? "0" + itemSplit[7].split("\r")[0] : itemSplit[7].split("\r")[0]

                    })

                    break;

                default:
                    //This is the bulk-create institutions part
                    endpointValue = "api/institution/bulk-create-institutions";
                    institutions.push({
                        institutionCode: itemSplit[0],
                        institutionName: itemSplit[1],
                        billingType: itemSplit[2],
                        billingBankCode: itemSplit[3],
                        billingAccountNumber: itemSplit[4],
                        revenueBankCode: itemSplit[5],
                        revenueAccountNumber: itemSplit[6],
                        address: itemSplit[7],
                        contactEmail: itemSplit[8],
                        allowApi: itemSplit[9] === "yes" ? true : false,
                        notificationUrl: "",
                        institutionType: this.state.institutionTypes.find(thisItem => thisItem.name === this.state.cbxEnableDisable)?.code,

                    })

                    break;
            }
        })

        return {
            "endPointVal": endpointValue,
            "users": users,
            "institutions": institutions,
            "action": action
        }

    }

    submitForm = () => {
        //Submits the form when clicked
        if (this.state.tableRowsArray.length === 0) {
            this.props.ShowToast("No valid file selected!", MsgBoxType.EXCLAMATION);
            return;
        }
        if (this.state.cbxEnableDisable === "" && this.state.cbxSelectAction !== "2") {
            this.props.ShowToast("Select a valid action to proceed!", MsgBoxType.EXCLAMATION);
            return;
        }

        /**
         * With these settled, proceed to
         * update
         */

        const { endPointVal, users, institutions, action } = this.updateParameters();

        this.props.setIsLoaderVisible(true);
        const data = {
            users: users,
            institutions: institutions,
            institutionType: action,
            action: action,
        }

        const options = {
            method: 'POST',
            headers: this.headersData,
            body: JSON.stringify(data),
        }

        fetch(`${ApiPath}${endPointVal}`, options)
            ?.then(response => {
                this.props.setIsLoaderVisible(false);
                return response.json()
            })
            .then(res => {
                this.props.setIsLoaderVisible(false);

                if (res && res.ok) {
                    this.props.ShowToast("Updated successfully!", MsgBoxType.SUCCESS);
                    this.setState({
                        updateResponse: res.body.data,
                        showBulkDialog: true,
                    })
                } else {
                    this.props.ShowToast(res.message, MsgBoxType.EXCLAMATION)
                    this.setState({
                        updateResponse: res.body.error.data,
                        showBulkDialog: true,
                    })
                }

            }).catch(error => {
                this.props.setIsLoaderVisible(false);
                this.props.ShowToast(error.message, MsgBoxType.EXCLAMATION);
            })

    }

    clearFileUpload = () => {
        //Clears the input in the file upload
        this.setState({
            clearFileUpload: true,
        })
    }

    readFileData = () => {
        //read the data from the file and display in the table...
        const fileUpload = document.getElementById("uploadFile");

        if (this.state.uploadedFile && fileUpload?.files[0]) {
            const reader = new FileReader();
            reader.onload = e => {
                const rows = e.target?.result.split("\n");

                //Remove the title of the array, leaving only data
                //Also, remove the last item, which is just a new line.. thus...
                this.setState({ rowTitle: rows.shift() });
                rows.pop();

                this.setState({
                    tableRowsArray: rows,
                })
            }

            reader.readAsText(fileUpload?.files[0]);
        }
    }

    render() {

        const actionRowClass = (this.state.cbxSelectAction !== "2") ? "form-row-4 mb-3" : "d-none";

        const rowData = this.state.uploadedFile && this.state.tableRowsArray.map((item, index) => {
            if (this.state.cbxSelectAction === "0") {
                return (
                    <tr key={index}>
                        <td className="align-center">{index + 1}.</td>
                        <td>{item}</td>
                    </tr>
                )

            } else {
                const outputItem = item.split(",");
                return (
                    <tr key={index}>
                        <td className="align-center">{index + 1}.</td>
                        {outputItem.map((elt, indx) => {
                            return (<td key={indx}>{elt}</td>)
                        })}
                    </tr>
                )
            }

        })

        return (
            <div>
                <div className="form-row-4 mb-3">
                    <div className="input-group">
                        <label><span>*</span>Select Bulk Action</label>
                        <select
                            id="cbxSelectAction"
                            name="cbxSelectAction"
                            value={this.state.cbxSelectAction}
                            className="form-control"
                            onChange={this.handleChange}
                        >
                            <option value="0">Bulk Enable/Disable Users</option>
                            <option value="1">Bulk Update Users</option>
                            <option value="2">Bulk Create Users</option>
                            <option
                                value="3"
                                className={sessionStorage.getItem("is_nibss_user")?.indexOf("NIBSS") === -1 ? 'd-none' : ''}>
                                Bulk Create Institutions
                            </option>
                        </select>
                    </div>

                    <div className="input-group">
                        <button
                            className="btn-green mt-2"
                            onClick={this.downloadTemplate}
                        ><i className="icofont-download"></i> Download Template</button>
                    </div>
                </div>
                <div className="form-row-1-3 mb-3">
                    <div className="input-group">
                        <UploadFile
                            setUploadedFile={value => this.setState({ uploadedFile: value }, () => this.readFileData())}
                            clearFileUpload={this.state.clearFileUpload}
                            resetFileUpload={() => this.setState({ clearFileUpload: false })}

                        />
                    </div>
                    <div className="input-group" style={{ maxHeight: "500px", overflowY: "auto" }}>
                        {this.state.uploadedFile && <table className="bordered-table"><caption></caption>
                            <thead>
                                <tr>
                                    <th scope="row">#</th>
                                    {this.state.rowTitle.split(",")?.map((itm, indx) => {
                                        return (<th scope="row" key={indx} className="align-left">{itm}</th>)
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {rowData}
                            </tbody>
                        </table>}
                    </div>
                </div>
                <div className={actionRowClass}>
                    <div className="input-group">
                        <label><span>*</span>{this.state.actionValue}</label>
                        <select
                            id="cbxEnableDisable"
                            name="cbxEnableDisable"
                            value={this.state.cbxEnableDisable}
                            className="form-control"
                            onChange={this.handleChange}
                        >
                            <option value="">Select</option>
                            {
                                this.state.actionArray.map((elt, index) => {
                                    return (<option key={index} value={elt}>{elt}</option>)
                                })
                            }
                        </select>

                    </div>
                </div>
                <hr />
                <div className="form-row-4 mb-3">
                    <div className="input-group">
                        <button
                            id="btnSubmit"
                            className="btn-green mt-2"
                            onClick={this.submitForm}>
                            <i className="icofont-upload-alt"></i> Submit
                        </button>
                    </div>
                </div>
                {
                    this.state.showBulkDialog && <BulkDialog
                        hideBulkDialog={() => this.setState({ showBulkDialog: false })}
                        showBulkDialog={this.state.showBulkDialog}
                        data={this.state.updateResponse}
                    />
                }

            </div>
        );
    }

}

export default BulkActionsUsersForm;
