import React from 'react';
export class AddStockDialog extends React.Component {

    constructor() {
        super();
        this.state = {
            cbxQuotedCompany: "",
            cbxRegistrar: "",
            txtAccountNumber: "",
            registrars: [],
            companies: [],
        }
    }

    componentDidMount() {
        // Set the variables on form-load...
        this.setState({
            companies: this.props.companies,
            registrars: this.props.registrars,
        })
    }

    submitStockInformation = () => {
        //First, validate the fields...
        if (this.state.cbxQuotedCompany === "" || this.state.cbxRegistrar === "") {
            this.props.ShowToast("Ensure all the fields are properly filled before proceeding.", "exclamation");
            return;
        }

        if (this.state.txtAccountNumber.length > 0 && this.state.txtAccountNumber.length < 10) {
            this.props.ShowToast("Enter a valid account number to proceed", "exclamation");
            return;
        }

        //Else, good to go!
        const data = {
            company: this.state.cbxQuotedCompany,
            registrar: this.state.cbxRegistrar,
            shareHolderAccountNo: this.state.txtAccountNumber || ""
        }

        const stockData = [...this.props.stockData, data];
        this.props.setStockData(stockData);

        //Hide the dialog on success..
        this.props.hideDialog();

    }

    handleChange = e => {
        const { id, value } = e.target;
        this.setState({
            [id]: value,
        }, () => {
            if (id === 'cbxQuotedCompany') {
                // Set the current value of the registrar to match the quoted company...
                const selRegistrar = this.props.companies?.find(item => item.slug === value)?.registrar.slug;
                this.setState({ cbxRegistrar: selRegistrar })
            }
        })
    }

    render() {
        return (
            <div className="dialog-background fade">
                <div className="dialog-container">
                    <div className="dialog-header">
                        <h2 className="create"><i className="icofont-document-folder" /> Add Stock Holding Information</h2>
                    </div>
                    <div className="dialog-body">
                        <div>
                            <hr className='golden-rule'/><h3 className='row-title'>Stock Holding Info</h3>
                        </div>
                        <div className="form-row-2 mb-2">
                            <div className="input-group">
                                <label><span>*</span>Quoted Company</label>
                                <select
                                    className="form-control"
                                    id="cbxQuotedCompany"
                                    name="cbxQuotedCompany"
                                    value={this.state.cbxQuotedCompany}
                                    onChange={this.handleChange}
                                >
                                    <option value="">Select</option>
                                    {this.state.companies?.map(item => (<option value={item.slug} key={item.slug}>{item.companyName}</option>))}
                                </select>
                            </div>
                        </div>
                        <div className="form-row-2-1 mb-2">
                            <div className="input-group">
                                <label><span>*</span>Registrar</label>
                                <select
                                    className="form-control"
                                    id="cbxRegistrar"
                                    name="cbxRegistrar"
                                    value={this.state.cbxRegistrar}
                                    onChange={this.handleChange}
                                >
                                    <option value="">Select</option>
                                    {this.state.registrars?.map(item => (<option value={item.slug} key={item.slug}>{item.name}</option>))}
                                </select>
                            </div>
                            <div className="input-group">
                                <label>Shareholder Account No</label>
                                <input
                                    id="txtAccountNumber"
                                    placeholder='XXXXXXXXXX'
                                    maxLength={10}
                                    type="text"
                                    className="form-control"
                                    value={this.state.txtAccountNumber}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="dialog-footer">
                        <button
                            className="btn-green mr-1"
                            onClick={this.submitStockInformation}
                            id="btnSubmit"
                        >
                            <i className="icofont-plus" /> Add Stock
                        </button>
                        <button
                            id="btnClose"
                            className="btn-grey"
                            onClick={() => this.props.hideDialog()}
                        >
                            <i className="icofont-logout" /> Cancel
                        </button>
                    </div>
                </div>
            </div>
        )

    }
}
