import React from "react";
import ApiPath from "../../../admin/assets/App/api-path";
import SearchList from "../../../admin/assets/App/SearchList";

export default class ApproveOutgoingReference extends React.Component {

    constructor() {
        super()
        this.state = {
            rbtnApprove: true,
            txtComments: "",
            searchField: "rbtnApprove",
        }
    }

    changeComments = (e) => {
        this.setState({ txtComments: e?.target.value })
    }

    approveReference = () => {

        this.props.setIsLoaderVisible(true);

        const data = { "slug": this.props.slug, "comment": this.state.txtComments }
        const options = {
            body: JSON.stringify(data),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                'Cache-Control': 'public, max-age=0'
            },

        }

        fetch(ApiPath + "api/e-reference/submit-outgoing-e-reference", options)
            ?.then(response => {
                return response.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false)
                if (response) {
                    if (response.ok === true) {
                        //Success
                        this.props.ShowToast("Reference Approved!", "success")
                        this.props.setApproveSuccessful(true)
                        this.props.setDisplayApproveDialog(false)

                    } else {
                        this.props.ShowToast(response.message, "exclamation")
                        return;
                    }
                } else {
                    this.props.ShowToast("Invalid input value(s)", "exclamation")
                }
            })
            .catch(error => {
                this.props.setIsLoaderVisible(false)
                this.props.ShowToast(error.message, "exclamation")
            })

    }

    closeForm = () => {
        this.props.setDisplayApproveDialog(false)
    }

    render() {

        const dialogclass = this.props.displayApproveDialog ? "modal fade show" : "modal fade";
        return (
            <div className={dialogclass}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="create"><i className="icofont-tick-mark"></i> Approve Reference - #{this.props.referenceNo}</h2>
                        </div>
                        <div className="modal-body">
                            <div className="form-row-3-1">
                                <div className="input-group">
                                    <SearchList
                                        id="rbtnApprove"
                                        value="Approve"
                                        searchField={this.state.searchField}
                                        itemName="Approve Outgoing Reference"
                                    />
                                </div>
                                <div className="input-group">
                                    <textarea
                                        className="form-control"
                                        id="txtComments"
                                        name="txtComments"
                                        onChange={this.changeComments}
                                        value={this.state.txtComments}
                                        rows="4"
                                        placeholder="Comments"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                id="btnApproveReference"
                                className="btn-green mr-1"
                                onClick={this.approveReference}>
                                <i className="icofont-tick-mark"></i> Approve
                            </button>
                            <button
                                id="btnClose"
                                className="btn-grey"
                                onClick={this.closeForm}>
                                <i className="icofont-exit"></i> Cancel
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
