import React from "react";
import ApiPath from "../../../admin/assets/App/api-path";
import DateDiff from "../../../admin/assets/App/DateDiff";
import ValidateEmail from "../../../admin/assets/App/validate-email";
import { fileToBase64 } from '../../../admin/assets/App/file-to-base64';
import objectToCSV from '../../../admin/assets/App/object-to-csv'
import { SplitDate } from "../../../admin/assets/App/split-date";
import { Enums } from "../../../_common/enums"

class BillingsFormData extends React.Component {

    constructor() {
        super();
        this.state = {
            boxContent: "Selected Emails",
            txtSpecialEmail: "",
            cbxEClipsType: "",
            cbxServiceSelectedIndex: 0
        }


        this.summaryReport = "Summary Report"
        this.billingReport = "Billing Report"

        this.txtAccountUser = `${sessionStorage.getItem("session_first_name")} ${sessionStorage.getItem("session_last_name")}`;
    }

    createEmailSpans = () => {
        /** as the textbox blurs,
         * create a list of
         * emails with special colorizing
         * and close items...

        After clearing the container, fix the emails...*/
        const emails = this.state.txtSpecialEmail.split(",");
        const specialEmailText = document.getElementById("txtSpecialEmail")
        specialEmailText?.classList.remove("error-border");

        const emailContent = emails.map((item, index) => {
            if (!ValidateEmail(item)) {
                this.props.ShowToast(`Invalid email in selected emails list - '${item}', "exclamation`);
                specialEmailText?.classList.add("error-border");
                specialEmailText?.focus();
            }

            return (
                <span key={index}>{item.trim()}</span>
            )
        })

        this.setState({
            boxContent: emailContent || "Selected Emails"
        });

    }

    handleChange = e => {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
        })
    }

    checkAndValidate = () => {

        let errorMessage = "";

        if (this.props.cbxService === "") {
            errorMessage = "Billing Service required. Select from list.";
            return ({ errorOccured: true, errorMessage: errorMessage });
        }
        if (this.props.cbxDateFrom === "") {
            errorMessage = "'From' Date required!";
            return ({ errorOccured: true, errorMessage: errorMessage });
        }
        if (this.props.cbxDateTo === "") {
            errorMessage = "'To' Date required!";
            return ({ errorOccured: true, errorMessage: errorMessage });
        }

        //Also check the
        //Period
        if (DateDiff(this.props.cbxDateTo, this.props.cbxDateFrom).days > 31) {
            errorMessage = "Selected dates cannot be more than a maximum of 30/31 days apart";
            return ({ errorOccured: true, errorMessage: errorMessage });
        }

        if (new Date(this.props.cbxDateTo).getTime() < new Date(this.props.cbxDateFrom).getTime()) {
            errorMessage = "'To' date cannot be earlier than 'From' date.";
            return ({ errorOccured: true, errorMessage: errorMessage });
        }

        //The default return vlaue...
        return ({ errorOccured: false, errorMessage: "" });

    }

    handleSubmit = () => {
        /**
         * Creates a
         * new billing
         * record after
         * validating the
         * inputs
         */
        const { errorOccured, errorMessage } = this.checkAndValidate();

        if (errorOccured) {
            this.props.ShowToast(errorMessage, 'exclamation');
            return;
        }

        //Else proceed to update...
        // Populate the emailAddresses array...
        const emailAddresses = []
        this.state.txtSpecialEmail.split(",").forEach(item => {
            emailAddresses.push(item.trim());
        })

        this.props.setIsLoaderVisible(true);

        const updateData = {
            slug: this.props.data?.slug,
            service: this.props.cbxService,
            eClipRequestType: this.state.cbxEClipsType,
            startDate: this.props.cbxDateFrom,
            endDate: this.props.cbxDateTo,
            isMailable: this.props.chkSendEmail ? "true" : "false",
            emailAddresses: emailAddresses,
        }

        const options = { ...Enums.postOptions, body: JSON.stringify(updateData) }

        let api;
        let successMessage;

        if (this.props.nameTag === "Edit") {
            api = "api/billing/edit-billing";
            successMessage = "Billings Record updated successfully!"
        } else {
            api = "api/billing/create-billing";
            successMessage = "Billings Record generated successfully!";
        }

        fetch(`${ApiPath}${api}`, options)    //Change the endpoint when ready...
            ?.then(response => {
                this.props.setIsLoaderVisible(false)
                return response.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false)

                if (response.ok) {
                    this.props.ShowToast(successMessage, "success")

                    // Handle for create and send email cases..
                    this.handleCreateEmail(response, emailAddresses);

                } else {
                    // this.props.ShowToast(response?.message, "exclamation")
                }
            })
            .catch(error => {
                this.props.ShowToast(error.message, "exclamation")
            })
    }

    prepareEmailDownloads = async (emailSlug, filesList) => {
        //First, update the data via the API...
        // Destructure the array of files to be converted and uploaded...
        const [sumReptData, smartDetData, billingReptData] = filesList;

        // The data is already sent with the response,
        // So just create report...
        // First, ensure each of the fields don't return empty encoded files...
        const sdFile = smartDetData !== [] ? await this.CreateReportFile("Smart Det", smartDetData) : '';
        const bRFile = billingReptData !== [] ? await this.CreateReportFile(this.billingReport, billingReptData) : '';
        const sRFile = sumReptData !== [] ? await this.CreateReportFile(this.summaryReport, sumReptData) : '';

        //The above ensure that the files have been created...
        //Then, proceed to send the email with the necessary file attachments...
        this.props.setIsLoaderVisible(true);

        const emailBody = {
            slug: emailSlug,
            smartDet: sdFile,
            emailAddresses: this.state.txtSpecialEmail.split(","),     //Immediately converts it into an array...
            summaryReportFile: sRFile,
            billingFile: bRFile,
        }

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': Enums.applicationJson,
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                'Cache-Control': 'public, max-age=0'
            },
            body: JSON.stringify(emailBody),
        }

        // Go ahead and send the email notifications...
        fetch(`${ApiPath}api/billing/send-to-email`, options)    //Change the endpoint when ready...
            ?.then(postData => (postData.json()))
            .then(response => {
                this.props.setIsLoaderVisible(false)

                if (response && response.ok) {
                    return {
                        message: "Data updated and Email(s) sent successfully!",
                        status: true
                    }
                } else {
                    return {
                        message: response.message,
                        status: false
                    }
                }
            })
            .catch(err => {
                this.setIsLoaderVisible(false);
                return {
                    message: err.message,
                    status: false
                }
            })
    }

    handleCreateEmail = (response, emailAddresses) => {

        if (this.props.nameTag === "Create" && emailAddresses?.length > 0) {
            //Get and send email attachments, once there are email addresses...
            const emailSlug = response.body.result.billingSlug;
            const sdData = response.body.result.attachmentData.smartDet?.report || [];
            const brData = response.body.result.attachmentData.billingFile?.report || [];
            const srData = response.body.result.attachmentData.summaryReport?.report || [];

            const emailDataArray = [srData, sdData, brData]
            const { message, status } = this.prepareEmailDownloads(emailSlug, emailDataArray);

            // Still clear the loader
            this.props.setIsLoaderVisible(false);

            // Display any necessary error messages with the proper icon
            if (status) {
                this.props.ShowToast(message, "success")
            } else {
                //this.props.ShowToast(message, "exclamation")
            }

            this.props.setDisplayCreateDialog(false)

        } else {
            this.props.setDisplayEditDialog(false);
        }

        // Respond as all is successful...
        this.props.setActionSuccessful(true)

    }

    CreateReportFile = async (reportName, reportData) => {

        let thisData;

        switch (reportName) {
            case this.billingReport:        //Change to the correct format afterwards...
                thisData = reportData.map(item => {
                    //Returns an object of the required titles... then actual details...
                    return ({
                        "Account_Number": item.billingAccountNumber,
                        "Sort_Code": item.sortCode,
                        "Amount": item.billingAmount,
                        "Payee_Beneficiary": item.institutionName,
                        "Narration": item?.narration
                    })
                })
                break;
            case this.summaryReport:
                thisData = reportData?.map(item => {
                    //Returns an object of the required titles... then actual details...
                    return ({
                        "Institution Name": item.meta.name,
                        "Date": SplitDate(item.meta.CREATED_AT).dateTime,
                        "Volume": item.meta.total
                    })
                })
                break;

            default:
                // Smart Det report...
                let outputData = "";
                reportData.forEach(item => {
                    //Returns an object of the required titles... then actual details...
                    const splitDate = item.endDAte?.split("-");
                    const outputDate = `${splitDate[0]}${splitDate[1]}${splitDate[2]}`;

                    outputData += `${item.sortCode},${item.productCode},${outputDate},${item.billingAmount}\r`;

                })

                thisData = outputData;

                break;

        }

        //Create the csvFile...
        //And, also create the base64 objects of the file, first by converting to blob...
        const blob = (reportName !== "Smart Det") ? new Blob([objectToCSV(thisData)], { type: 'text/csv' }) : new Blob([objectToCSV(thisData)], { type: 'text/plain' });

        //Then finally convert to base64...
        const output = await fileToBase64(blob);
        return output.toString().split(",")[1];

    }

    render() {
        const eClipsServiceType = this.state.cbxServiceSelectedIndex === 4 ? "input-group" : "d-none";

        const Services = this.props.roleServices && this.props.roleServices?.map(item => {
            return (
                <option
                    key={item.id}
                    value={item.slug}>{item.name}</option>
            )
        })

        return (

            <div className="dialog-container">
                <div className="dialog-header">
                    <h2 id="dialog-title" className={this.props.nameTag?.toLowerCase()}><i className="bx bxs-receipt"></i> {this.props.nameTag} Billings</h2>
                </div>
                <div className="dialog-body">
                    <div className="form-row-2-1">
                        <div className="input-group">
                            <label><i className="icofont-user-alt-3"></i> Account User</label>
                            <input type="text" name="txtAccountUser" className="form-control"
                                value={this.txtAccountUser} disabled />
                        </div>
                    </div>
                    <hr className="golden-rule" /><h3 className="row-title">Billing Details</h3>
                    <div className="form-row-2-1-1 mb-2">
                        <div className="input-group col-md">
                            <label><span>*</span>Billing Service for</label>
                            <select
                                id="cbxService"
                                name="cbxService"
                                className="custom-select"
                                value={this.props.cbxService}
                                onChange={e => {
                                    this.props.handleChange(e);
                                    this.setState({
                                        cbxServiceSelectedIndex: e.target.selectedIndex,
                                    })
                                }}>
                                <option value=""></option>
                                {Services}
                            </select>
                            <label className="control-info"><i className="icofont-warning-alt"></i> Select Service from list</label>
                        </div>
                        <div className={eClipsServiceType}>
                            <label><span>*</span>Clips Type</label>
                            <select
                                id="cbxEClipsType"
                                name="cbxEClipsType"
                                className="custom-select"
                                value={this.state.cbxEClipsType}
                                onChange={this.handleChange}
                            >
                                <option value=""></option>
                                <option value="Request">Request</option>
                                <option value="View">View</option>
                            </select>
                            <label className="control-info"><i className="icofont-warning-alt"></i> Select type from list</label>
                        </div>
                    </div>
                    <div className="form-row mb-2">
                        <div className="input-group col-md">
                            <label><span>*</span>Date (from)</label>
                            <input
                                type="date"
                                id="cbxDateFrom"
                                name="cbxDateFrom"
                                className="form-control"
                                onChange={this.props.handleChange}
                                value={this.props.cbxDateFrom} />
                            <label className="control-info"><i className="icofont-warning-alt"></i> Select/Enter Start Date</label>
                        </div>
                        <div className="input-group col-md">
                            <label><span>*</span>Date (to)</label>
                            <input
                                type="date"
                                id="cbxDateTo"
                                name="cbxDateTo"
                                className="form-control"
                                onChange={this.props.handleChange}
                                value={this.props.cbxDateTo} />
                            <label className="control-info"><i className="icofont-warning-alt"></i> Select/Enter End Date</label>
                        </div>
                    </div>
                    <div className="form-row-3">
                        <div className="inline-input-group">
                            <label>
                                <input
                                    type="checkbox"
                                    name="chkSendEmail"
                                    id="chkSendEmail"
                                    className="form-control"
                                    checked={this.props.chkSendEmail}
                                    onChange={this.props.handleChange}
                                /> Send Emails
                            </label>
                        </div>
                    </div>
                    <div className="form-row-3x mb-1">
                        <div className="input-group">
                            <textarea
                                type="text"
                                id="txtSpecialEmail"
                                name="txtSpecialEmail"
                                className="form-control"
                                rows="5"
                                onKeyDown={this.handleKeyDown}
                                value={this.state.txtSpecialEmail}
                                onChange={this.handleChange}
                                onBlur={this.createEmailSpans}
                                disabled={!this.props.chkSendEmail}
                            />
                            <label className="control-info">
                                <i className="icofont-warning-alt"></i> Separate multiple emails by commas
                            </label>
                        </div>
                    </div>
                    <div className="form-row-3x mb-3">
                        <div className="emails-box">{this.state.boxContent}</div>
                    </div>
                </div>

                <hr className="mb-2" />
                <div className="dialog-footer">
                    <button className="btn-green mr-1" name="btnUpdateInstitution" type="button"
                        onClick={this.handleSubmit} title={this.props.nameTag}>
                        <i className="icofont-upload-alt"></i> {this.props.nameTag === "Edit" ? "Update" : this.props.nameTag} Billing
                    </button>
                    <button type="button" className="btn-grey mr-2" id="btnCancel" onClick={this.props.closeForm}>
                        <i className="icofont-exit" title="Cancel"></i> Cancel
                    </button>
                </div>
            </div>

        );
    }

}

export default BillingsFormData
