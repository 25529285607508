export const EClipsReportsList = [
    {
        id: 2,
        idVal: "status",        //This is the searchField in the database
        value: "Status",
        itemName: "Status",
    },
    {
        id: 3,
        idVal: "issuingInstitution",
        value: "Requesting Bank",
        itemName: "Requesting Bank",
    },
    {
        id: 4,
        idVal: "acquiringInstitution",
        value: "Acquiring Bank",
        itemName: "Acquiring Bank",
    },
    {
        id: 5,
        idVal: "isOverDue",
        value: "Is Overdue",
        itemName: "Is Overdue",
    },
    {
        id: 6,
        idVal: "requestId",
        value: "Request ID",
        itemName: "Request ID",
    },
    {
        id: 7,
        idVal: "referenceType",
        value: "Dispute Type",
        itemName: "Dispute Type",
    },
    {
        id: 8,
        idVal: "transactionDate",
        value: "Transaction Date",
        itemName: "Transaction Date",
    },
]
