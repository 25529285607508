export const Audits = () => {
    return (
        <div className="audits">
            <div className="cont">
                <div className="audit-title">
                    <h3>Audit Logs</h3>
                    <i className="icofont-settings-alt"></i>
                </div>
                <div className="table-container">
                    <table id="lab-table"><caption></caption>
                        <thead>
                            <tr>
                                <th scope="col" className="align-left">User</th>
                                <th scope="col" className="align-right">Time Stamp</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Hilo</td>
                                <td className="align-right">Hilo</td>
                            </tr>
                            <tr>
                                <td>Hilo</td>
                                <td className="align-right">Hilo</td>
                            </tr>
                            <tr>
                                <td>Hilo</td>
                                <td className="align-right">Hilo</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
