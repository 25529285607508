import React from 'react';
import ReferenceReportsTableRow from "./reference-reports-table-row"
import ApiPath from '../../../admin/assets/App/api-path';
import Pagination from '../../../admin/assets/App/Pagination';
import SearchComponent from '../../../admin/assets/App/SearchComponent';
import RefReportSearchList from '../../../admin/assets/data/RefReportSearchList';
import ReferenceReportsSummary from './reference-reports-summary';
import { LoadReferencesForExport } from '../../../admin/assets/functions/load-references';
import objectToCSV from "../../../admin/assets/App/object-to-csv";
import ExportToCSVFile from "../../../admin/assets/App/ExportToCSVFile";
import ViewReferenceDialog from '../outgoing-references/view-reference-dialog';
import { DateRange } from '../../../components/date-range';

export default class ReferenceReportsForm extends React.Component {

    constructor() {
        super()
        this.state = {
            apiData: [],
            metaData: [],
            institutions: [],
            summary: [],
            viewData: [],
            displayView: false,
            mandateExtension: "",
            refComments: [],

            //Search...
            searchField: "",
            searchValue: "",
            searchValue2: "",
            startDate: "",
            endDate: "",

            fetchSuccessful: false,
            actionSuccessful: false,
            currentSelectedPage: 1,
            perPage: localStorage.getItem("items_per_page") || 20,
            arrayPages: [],
            lastPage: 1,
            totalItems: 0,

        }

        this.getOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                'Cache-Control': 'public, max-age=0'
            }
        }

    }

    ExportDataToFile = async () => {
        /**
        * Export the data
        * to an MSExcel
        * file after
        * filtering and
        * saving in a pre-defined
        * format... Consult the
        * requirements document(s)
        * We need to obtain the data then do the necessary...
        */
        //always first set the items to 1000, then

        try {
            //revert it after the export is done...
            const thisPerPage = 200000;

            // Loading...
            this.props.setIsLoaderVisible(true);

            //Check for institutions...
            const refOutput = this.setReferenceValues(thisPerPage);
            if (refOutput.errorValue !== "") {
                return;
            }

            const getData = await fetch(refOutput.queryString, this.getOptions);
            const response = await getData.json();

            this.props.setIsLoaderVisible(false);

            // Align data and do the necessary...
            if (response && response.ok) {
                const responseOutput = response.body.data.references.data;
                const DataToExport = [];
                //Check to see if there's no data.... If so, then proceed to return an error message
                if (responseOutput.length === 0) {
                    //No data...
                    this.props.ShowToast('No data to export!', 'exclamation');
                    return;
                }

                //Else proceed...
                LoadReferencesForExport(responseOutput, DataToExport);

                const csvData = objectToCSV(DataToExport);
                ExportToCSVFile(csvData, "e-Reference.csv");

                this.props.ShowToast("References exported successfully! Open downloads folder to preview...", "information");

            }

        } catch (error) {
            this.props.setIsLoaderVisible(false);
            this.props.ShowToast(error.message);
        }

    }

    getInstitutions = () => {

        fetch(`${ApiPath}api/institution/fetch-institutions?limit=10000`, this.getOptions)
            ?.then(response => {
                return response.json();
            })
            ?.then(response => {
                if (response && response.ok) {
                    this.setState({
                        institutions: response.body.data.institutions,
                    })
                }
            })
    }

    setReferenceValues = (limit = this.state.perPage) => {
        let searchValue = this.state.searchValue;
        let queryString;
        const errorValue = "";
        const dateRangeFilter = (this.state.startDate !== '') ? `&createdAt=${this.state.startDate}&createdAt=${this.state.endDate}` : '';

        if (this.state.searchField === "customerInstitution" || this.state.searchField === "refereeInstitution") {
            searchValue = this.state.institutions?.find(item => this.state.searchValue === item.name)?.slug;
        }

        queryString = `${ApiPath}api/e-reference-report/get-e-reference-report?page=${this.state.currentSelectedPage}` +
            `&limit=${limit}&${this.state.searchField}=${searchValue}${dateRangeFilter}`


        return { queryString, errorValue }
    }

    getReferences = () => {
        /**
         * This contains
         * the API endpoint
         * for fetching
         * the outgoing
         * references for
         * any particular
         * institution
         */

        //Always set the action state
        //button to false
        //after fetching

        this.props.setIsLoaderVisible(true)

        //Check for institutions...
        const refOutput = this.setReferenceValues();
        if (refOutput.errorValue !== "") {
            return;
        }

        fetch(refOutput.queryString, this.getOptions)
            ?.then(response => {
                return response.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false)
                if (response && response.ok === true) {
                    this?.setState({
                        apiData: response.body.data.references.data,
                        summary: response.body.data.summary,
                        fetchSuccessful: true,
                        metaData: response.body.data.references.meta,
                        actionSuccessful: false,
                        arrayPages: [],
                    }, () => {
                        const pagesArr = [];
                        this.setState({
                            currentSelectedPage: this.state.metaData?.current_page,
                            lastPage: this.state.metaData?.last_page,
                            totalItems: this.state.metaData?.total,
                        }, () => {
                            //Set this array for the looping
                            for (let i = 1; i <= this.state.lastPage; i++) {
                                pagesArr.push({ "id": i, "name": "Item " + i });
                            }

                            this.setState({ arrayPages: pagesArr })
                        })
                    })
                } else {
                    this.props.ShowToast(response?.message, "exclamation")
                }
            })
            .catch(error => {
                this.props.setIsLoaderVisible(false)
                this.props.ShowToast(error.message, "exclamation")
            })
    }

    RefreshForm = () => {
        window.location.reload();
    }

    componentDidMount() {
        this.getReferences()
        this.getInstitutions();
    }

    filterRecords = () => {
        this.forceUpdate();     // Ensure that the date range issue is corrected...
        this.getReferences();
    }

    render() {

        const isNibssUser = sessionStorage.getItem("is_nibss_user")?.indexOf("NIBSS") > -1 ? true : false;

        let counter = 0;
        const TableRows = this.state.fetchSuccessful && this.state.apiData.map(item => {
            counter++;
            const itemCounter = (this.state.currentSelectedPage - 1) * this.state.perPage + counter

            return (<ReferenceReportsTableRow
                key={itemCounter}
                item={item}
                counter={itemCounter}
                setViewData={value => this.setState({ viewData: value })}
                setDisplayView={val => this.setState({ displayView: val })}
                setCommentsData={valu => this.setState({ refComments: valu })}
                ShowToast={this.props.ShowToast}
                setIsLoaderVisible={this.props.setIsLoaderVisible}
                setMandateExtension={value => this.setState({ mandateExtension: value })}
                setActionSuccessful={value => this.setState({ actionSuccessful: value })}
            />)
        })

        if (this.state.actionSuccessful) {
            this.getReferences()
            this.setState({
                actionSuccessful: false,
            })
        }

        const isOverDue = [{ "item_name": "Yes" }, { "item_name": "No" }];

        const customerAccountName = [];
        const customerAccountNo = [];
        const refereeAccountName = [];
        const refereeAccountNo = [];
        const institutionNames = [];

        const actionStatus = [
            { "item_name": "pending" },
            { "item_name": "accepted" },
            { "item_name": "rejected" },
            { "item_name": "approved" },
            { "item_name": "disapproved" },
        ]

        this.state.apiData.forEach(item => {
            customerAccountName.push({ item_name: item.customerAccountName })
            customerAccountNo.push({ item_name: item.customerAccountNumber })
            refereeAccountName.push({ item_name: item.refereeAccountName })
            refereeAccountNo.push({ item_name: item.refereeAccountNumber })
        })

        this.state.institutions.forEach(item => {
            institutionNames.push({ item_name: item.name })
        })

        return (
            <>
                <div className="title">
                    <DateRange
                        setDateFrom={val => this.setState({ startDate: val })}
                        setDateTo={val => this.setState({ endDate: val })}
                        callback={this.filterRecords}
                        ShowToast={this.props.ShowToast}
                    />
                    <SearchComponent
                        setSearchField={val => this.setState({ searchField: val })}
                        setSearchValue={val => this.setState({ searchValue: val })}
                        setSearchValue2={val => this.setState({ searchValue2: val })}
                        ReferenceSearchList={RefReportSearchList}
                        filterRecords={this.filterRecords}
                        ShowToast={this.props.ShowToast}
                        inputList={{
                            actionStatus,
                            isOverDue,
                            customerAccountName,
                            customerAccountNo,
                            refereeAccountName,
                            refereeAccountNo,
                            institutionNames
                        }}

                    />

                    <div className="buttons">
                        <button
                            id="btnRefresh"
                            type="button"
                            className="btn-grey t-tip mr-1"
                            data-title="Refresh"
                            onClick={this.RefreshForm}>
                            <i className="icofont-refresh"></i> Refresh
                        </button>
                        <button
                            id="btnExport"
                            type="button"
                            className="btn-green t-tip"
                            data-title="Export records to MsExcel file"
                            onClick={this.ExportDataToFile}>
                            <i className="icofont-airplane-alt"></i> Export
                        </button>
                    </div>
                </div>
                <div className="table-container" style={{ width: '100%' }}>
                    <table className="bordered-table some-class" style={{ width: '100%', overflowX: 'scroll' }}>
                        <caption></caption>
                        <thead>
                            <tr>
                                <th scope="col" title="">#</th>
                                <th scope="col" title="Reference Number">REF. NO</th>
                                <th scope="col" title="Status" width="100">STATUS</th>
                                <th scope="col" title="Customer Bank">CUST BANK</th>
                                <th scope="col" title="Customer Branch">CUST BRANCH</th>
                                <th scope="col" title="Referee Bank">REF BANK</th>
                                <th scope="col" title="Referee Branch">REF BRANCH</th>
                                <th scope="col" title="Referee Account Name">REF A/C NAME</th>
                                <th scope="col" title="Referee Account Number">REF A/C NO</th>
                                <th scope="col" title="Customer Creation Date">CUST CREATION DATE</th>
                                <th scope="col" title="Referee Creation Date">REF CREATION DATE</th>
                                <th scope="col" style={{ width: 100 }} title="Created by Presenting">CREATED BY PRESENTING</th>
                                <th scope="col" style={{ width: 100 }} title="Submitted by Presenting">SUB. BY PRESENTING</th>
                                <th scope="col" style={{ width: 100 }} title="Accepted/Rejected By Receiving">ACC/REJ BY RECEIVING</th>
                                <th scope="col" style={{ width: 100 }} title="Approved/Disapproved By Receiving">APP/DIS BY RECEIVING</th>
                                <th scope="col" title="Overdue">OVERDUE</th>
                                <th scope="col" title="Due Date">DUE DATE</th>
                                <th scope="col" title="Document/Attachment">DOC/ATTACH</th>
                            </tr>
                        </thead>
                        <tbody id="tbl_reference_reports">
                            {TableRows}
                        </tbody>
                    </table>
                </div>
                <Pagination
                    currentSelectedPage={this.state.currentSelectedPage}
                    setRefreshForm={value => this.setState({ actionSuccessful: value })}
                    setCurrentSelectedPage={value => this.setState({ currentSelectedPage: value })}
                    perPage={this.state.perPage}
                    setPerPage={value => this.setState({ perPage: value })}
                    lastPage={this.state.lastPage}
                    arrayPages={this.state.arrayPages}
                />
                {
                    !isNibssUser && <ReferenceReportsSummary
                        totalItems={this.state.perPage}
                        summary={this.state.summary}
                    />
                }

                {
                    this.state.displayView && <ViewReferenceDialog
                        data={this.state.viewData}
                        commentsData={this.state.refComments}
                        displayView={this.state.displayView}
                        ShowToast={this.props.ShowToast}
                        mandateExtension={this.state.mandateExtension}
                        setIsLoaderVisible={this.props.setIsLoaderVisible}
                        setDisplayView={value => this.setState({ displayView: value })}
                        setActionSuccessful={value => this.setState({ actionSuccessful: value })}
                        sender={"e-Reference Reports"}
                    />
                }
            </>
        )
    }

}
