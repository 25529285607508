const NIBSSUsersSearchList = [
    {
        id: 1,
        idVal: "firstName",
        value: "Firstname",
        itemName: "Firstname",
    },
    {
        id: 2,
        idVal: "lastName",
        value: "Lastname",
        itemName: "Lastname",
    },
    {
        id: 3,
        idVal: "status",
        value: "Status",
        itemName: "Status"
    },
    {
        id: 4,
        idVal: "email",
        value: "Contact Email",
        itemName: "Contact Email",
    },

]

export default NIBSSUsersSearchList
