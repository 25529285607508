import React from 'react'
import UserRoleCheckGroup from "./user-role-checkgroup"

const CreateUserRoleDialog = (props) => {

    return (
        <UserRoleCheckGroup
            ShowToast={props.ShowToast}
            setIsLoaderVisible={props.setIsLoaderVisible}
            sender={"Create"}
            displayClicked={props.createClicked}
            setDisplayClicked={props.setCreateClicked}
            setActionSuccessful={props.setIsCreateSuccessful}

        />
    )

}

export default CreateUserRoleDialog
