import { useState } from "react";

const DropDownInput = props => {

    const [listVisible, setListVisible] = useState(false);
    const [searchList, setSearchList] = useState(props.dropDownList);

    const hideFilterList = e => {
        if (e.keyCode === 27) {
            //Escape key is pressed
            setListVisible(false);
        }
    }

    const selectItem = itemValue => {
        //Set this value to that of the list
        props.setValue(itemValue);
        setListVisible(false);
    }

    const handleChange = e => {
        e.preventDefault();
        props.handleChange(e);
        setListVisible(true);

        const filterValue = e.target.value;

        const filteredList = props.dropDownList?.filter(item => item.toLowerCase().indexOf(filterValue.toLowerCase()) > -1);
        const sortedList = filteredList?.map((item, index) => {
            //Later, highlight/bolden the match...
            return (
                <div
                    key={index}
                    className="list-item"
                    onClick={() => selectItem(item)}
                >
                    {item}
                </div>
            )

        })

        setSearchList(sortedList);

    }

    return (
        <>
            <input
                type="text"
                id={props.name}
                name={props.name}
                value={props.value}
                className="form-control search-input"
                onChange={handleChange}
                onKeyDown={hideFilterList}
                autoComplete="off"
                placeholder="Start Typing"
            />
            <div
                className={listVisible ? "filter-list" : "filter-list d-none"}
                style={{ marginTop: 20 }}
            >
                { searchList }
            </div>
        </>
    );
}

export default DropDownInput;
