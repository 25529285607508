import React from 'react'
import ApiPath from '../../admin/assets/App/api-path'
import { Enums } from "../../_common/enums";

class ConfigureVATForm extends React.Component {
  constructor() {
    super();
    this.state = {
      txtVATAmount: "",
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.headersData = {
      "Content-Type": Enums.applicationJson,
      Authorization: `Bearer ${sessionStorage.getItem("session_token")}`,
      'Cache-Control': 'public, max-age=0'
    }
  }

  componentDidMount() {
    this.getData();
  }

  getData() {

    this?.props.setIsLoaderVisible(true);
    const options = {
      method: "GET",
      headers: this?.headersData,
    };

    fetch(ApiPath + "api/admin/vat/latest-vat", options)
      ?.then((response) => {
        this?.props.setIsLoaderVisible(false);
        return response.json();
      })
      ?.then((response) => {
        this?.props.setIsLoaderVisible(false);
        if (response) {
          if (response.ok === true) {
            //Success
            this.setState({ txtVATAmount: response.body.data.value });
          } else {
            this.props.ShowToast(response.message, "exclamation");
          }
        }
      })
      ?.catch((error) => {
        this?.props.setIsLoaderVisible(false);
        this?.props.ShowToast(error.messsage, "exclamation");
      });

  }

  handleClick() {

    const txtVATAmount = document.getElementById("txtVATAmount")

    try {
      if (parseInt(this.state.txtVATAmount) <= 0 || this.state.txtVATAmount === "") {
        this.props.ShowToast("You must enter a numeric value more than zero (0) to proceed", "exclamation");
        txtVATAmount.classList.add(Enums.errorBorder);
        txtVATAmount.focus();
        return;
      }

      //Else, good to go
      this.props.setIsLoaderVisible(true);

      const data = { value: this.state.txtVATAmount };
      const options = {
        method: "POST",
        headers: this?.headersData,
        body: JSON.stringify(data),
      };

      fetch(ApiPath + "api/admin/vat", options)
        .then((response) => {
          this.props.setIsLoaderVisible(false);
          return response.json();
        })
        .then((response) => {
          this.props.setIsLoaderVisible(false);
          if (response) {
            if (response.ok === true) {
              //Success
              this.props.ShowToast(
                "VAT amount updated successfully!",
                "success"
              );
            } else {
              this.props.ShowToast(response.message, "exclamation");
              return;
            }
          } else {
            this.props.ShowToast("Invalid input value", "exclamation");
          }
        })
        .catch((error) => {
          this.props.setIsLoaderVisible(false);
          this.props.ShowToast(error.message, "exclamation");
        });
    } catch (err) {
      this.props.setIsLoaderVisible(false);
      this.props.ShowToast(err.toString(), "exclamation");
    }
  }

  handleChange(e) {
    this.setState({
      txtVATAmount: e?.target.value
    }, () => document.getElementById("txtVATAmount")?.classList.remove(Enums.errorBorder));

  }

  render() {
    return (
      <>
        <div className="form-row-4 mb-2">
          <div className="input-group">
            <label>Enter VAT Amount</label>
            <input
              type="number"
              id="txtVATAmount"
              name="txtVATAmount"
              className="form-control"
              onChange={e => this.handleChange(e)}
              value={this.state.txtVATAmount}
            />
            <label className="control-info">
              <i className="icofont-warning-alt"></i> VAT Amount to be set
            </label>
          </div>
          <div className="input-group">
            <button
              type="button"
              id="btnSetVAT"
              className="btn-green t-tip special-button"
              data-title="Update New VAT Amount"
              onClick={this.handleClick}
            >
              Set VAT
            </button>
          </div>
          <div className="input-group">
            <button
              type="button"
              id="refresh"
              className="btn-grey t-tip d-none"
              data-title="Refresh"
              onClick={this.getData}
            >
              <i className="icofont-refresh"></i>
              Refresh
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default ConfigureVATForm
