const BillingsSearchList = [
    {
        id: 1,
        idVal: "endDate",
        value: "End Date",
        itemName: "End Date"
    },
    {
        id: 2,
        idVal: "service",
        value: "Service",
        itemName: "Service"
    },
]

export default BillingsSearchList
