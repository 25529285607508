import React from "react";
import { loadEDMMSExportTable } from "../outgoing-e-dmms-mandates/load-edmms-export-table";
import ApiPath from "../../admin/assets/App/api-path";
import objectToCSV from "../../admin/assets/App/object-to-csv";
import ExportToCSVFile from "../../admin/assets/App/ExportToCSVFile";
import eDMMSSearchList from "../../admin/assets/data/e-DMMSSearchList";
import SearchComponent from "../../admin/assets/App/SearchComponent";
import Pagination from "../../admin/assets/App/Pagination";
import IncomingEDMMSMandateTableRow from "./incoming-edmms-mandates-table-row";
import { ViewEDMMSMandateDetails } from "../outgoing-e-dmms-mandates/view-edmms-mandate-details";
import RejectIncomingEDMMSMandates from "./reject-incoming-edmms-mandates";
import DynamicSort from "../../admin/assets/App/DynamicSort";
import { EDMMSTableColumns } from "../e-dmms-report/e-dmms-table-columns";
import { DateRange } from "../../components/date-range";

export default class IncomingEDMMSMandatesForm extends React.Component {
    constructor() {
        super();
        this.state = {
            actionSuccessful: false,
            apiStaticData: [],
            apiData: [],
            metaData: {},
            viewData: [],
            editData: [],
            exportData: [],
            institutions: [],
            fetchSuccessful: true,

            displayReject: false,
            displayDetails: false,

            searchField: "",
            searchValue1: "",
            searchValue2: "",
            startDate: "",
            endDate: "",

            currentSelectedPage: 1,
            arrayPages: [],
            lastPage: 1,
            perPage: localStorage.getItem("items_per_page") || 20,
            totalItems: 0,
        }

        this.getOptions = {
            headers: {
                'Cache-Control': 'public, max-age=0',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
            },
            method: "GET"
        }
    }

    fetchInstitutions = () => {
        /**
         * This function fetches the institutions
         * for the search querries...
         */
        fetch(`${ApiPath}api/institution/fetch-institutions`, this.getOptions)
            ?.then(response => (response.json()))
            .then(res => {
                if (res) {
                    if (res.ok) {
                        this.setState({
                            institutions: res.body.data.institutions?.sort(DynamicSort("name")),
                        })
                    }
                }
            })
    }

    fetchAllMandates = () => {
        /**
         * This one fetches all the
         * mandates and makes them
         * available for the
         * search box...
         */
        fetch(`${ApiPath}api/edmms-mandate/fetch-incoming-mandates?page=1` +
            `&limit=200000`, this.getOptions)
            ?.then(output => (output.json()))
            ?.then(incomingResponse => {
                if (incomingResponse && incomingResponse.ok) {
                    this?.setState({
                        apiStaticData: incomingResponse.body.data,
                    })
                }
            })
    }

    applyFilters = () => {

        const searchField = this.state.searchField;
        const searchPeriod = (this.state.startDate && this.state.endDate)
            ? `&createdAt=${this.state.startDate}&createdAt=${this.state.endDate}`
            : '';

        const searchValue = (searchField === 'institution' && this.state.institutions.length > 0)
            ? this.state.institutions.find(institution => institution.name === this.state.searchValue1).slug
            : this.state.searchValue1;

        const searchInstitution = (searchField === 'institution')
            ? `&institution=${searchValue}`
            : `&${searchField}=${searchValue}`;

        return (`${ApiPath}api/edmms-mandate/fetch-incoming-mandates?page=${this.state.currentSelectedPage}` +
        `${searchInstitution}&limit=${this.state.perPage}${searchPeriod}`)
    }

    fetchIncomingEDMMSMandates = () => {
        /**
         * Get the eDMMS mandates,
         * assigned to your institution,
         * either as a registrar,
         * or as a FI
         */
        this.props.setIsLoaderVisible(true);
        const query = this.applyFilters();

        fetch(query, this.getOptions)
            ?.then(out => (out.json()))
            ?.then(incomingResponse => {
                this.props.setIsLoaderVisible(false)

                if (incomingResponse && incomingResponse.ok === true) {
                    this?.setState({
                        metaData: incomingResponse.body.meta,
                        apiData: incomingResponse.body.data,
                        fetchSuccessful: true,
                        actionSuccessful: false,
                        arrayPages: []
                    })

                    // Set other parameters...
                    const pagesArray = [];
                    const mData = incomingResponse.body.meta;
                    const lastPage = mData?.last_page;

                    // Set other params...
                    this.setState({
                        totalItems: mData?.total,
                        lastPage: mData?.last_page,
                        currentSelectedPage: mData?.current_page,
                    })

                    // Then, set the array of pages...
                    // By running a loop..
                    for (let n = 1; n <= lastPage; n++) {
                        pagesArray.push({ "id": n, "name": "Item " + n });
                    }

                    // Set the variable at the end...
                    this.setState({ arrayPages: pagesArray });

                } else {
                    // An issue occured... Show the user...
                    this.props.setIsLoaderVisible(false);
                    this.props.ShowToast(incomingResponse?.message, "exclamation");
                }
            })

    }

    exportIncomingData = () => {
        /**
         * the eDMMS mandates data
         * (incoming), is exported
         * as a .CSV file
         * here...
         */
        this.props.setIsLoaderVisible(true);
        const query = this.applyFilters();

        fetch(query.replace('limit=' + this.state.perPage, 'limit=20000'), this.getOptions)
            ?.then(response => (response.json()))
            .then(resp => {
                this.props.setIsLoaderVisible(false)
                if (resp && resp.ok) {
                    const apiOutput = resp.body.data

                    //The export proper...
                    const DataToExport = loadEDMMSExportTable(apiOutput);

                    // Set into csv format, then export
                    const csvData = objectToCSV(DataToExport);
                    ExportToCSVFile(csvData, "incoming-mandates.csv");

                    this.props.ShowToast("Mandates exported successfully! Open downloads folder to preview...", "information");

                } else {
                    this.props.ShowToast(resp.message, "exclamation");
                }

            })
            .catch(err => {
                // Hide the loader and show the issue, if there's one...
                this.props.setIsLoaderVisible(false);
                this.props.ShowToast(err.message, "exclamation");
            })
    }

    componentDidMount() {
        this.fetchIncomingEDMMSMandates();
        this.fetchAllMandates();
        this.fetchInstitutions();
    }

    filterRecords = () => {
        // Just applying the changes as we filter...
        this.fetchIncomingEDMMSMandates();
    }

    render() {

        const tblRows = this.state.fetchSuccessful && this.state.apiData?.map((item, index) => {
            const itemCounter = (this.state.currentSelectedPage - 1) * this.state.perPage + (index + 1);

            return (<IncomingEDMMSMandateTableRow
                key={index}
                counter={itemCounter}
                setIsLoaderVisible={this.props.setIsLoaderVisible}
                data={item}
                showRejectForm={() => this.setState({ displayReject: true, viewData: item })}
                showDetailsForm={() => this.setState({ displayDetails: true })}
                ShowToast={this.props.ShowToast}
                setActionSuccessful={() => this.setState({ actionSuccessful: true })}
                setViewData={val => this.setState({ viewData: val })}
            />)
        })

        if (this.state.actionSuccessful) {
            // Anytime we complete the action, we
            // do the following...
            this.fetchIncomingEDMMSMandates();
            this.setState({ actionSuccessful: false })
        }

        // Search / filter data...
        const status = [
            { "item_name": "Submitted" },
            { "item_name": "Approved" },
            { "item_name": "Accepted" },
            { "item_name": "Payment Pending" },
            { "item_name": "Rejected" }
        ];
        const bvnArray = [];
        const chnArray = [];
        const institutionNames = [];
        const referenceNo = [];
        const refereeAccountNo = [];

        this.state.apiStaticData?.forEach(item => {
            referenceNo.push({ "item_name": item.mandateCode });
            bvnArray.push({ "item_name": item.bvn || "-" });
            chnArray.push({ "item_name": item.chn || "-" });
            refereeAccountNo.push({ "item_name": item.paymentAccountNo });
        })

        // The institutions array...
        this.state.institutions?.forEach(item => {
            institutionNames.push({ "item_name": item.name })
        })

        return (
            <>
                <div className="title">
                    <DateRange
                        setDateFrom={val => this.setState({ startDate: val })}
                        callback={this.filterRecords}
                        ShowToast={this.props.ShowToast}
                        setDateTo={val => this.setState({ endDate: val })}
                    />
                    <SearchComponent
                        filterRecords={this.filterRecords}
                        inputList={{ status, institutionNames, referenceNo, bvnArray, chnArray, refereeAccountNo }}
                        ShowToast={this.props.ShowToast}
                        setSearchValue2={val => this.setState({ searchValue2: val })}
                        setSearchField={val => this.setState({ searchField: val })}
                        setSearchValue={val => this.setState({ searchValue1: val })}
                        ReferenceSearchList={eDMMSSearchList}
                    />
                    <div className='buttons'>
                        <button
                            type='button'
                            className='btn-green t-tip'
                            id='btnExport'
                            data-title='Export eDMMS records as .csv'
                            onClick={this.exportIncomingData}
                        >
                            <i className='icofont-airplane-alt'></i> Export
                        </button>
                    </div>
                </div>
                <div className='table-container'>
                    <table className='bordered-table'>
                        <caption></caption>
                        <thead>
                            <tr>
                                <EDMMSTableColumns />
                                <th scope="col" className="align-center">STATUS</th>
                                <th scope="col" className="align-center">ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody>{tblRows}</tbody>
                    </table>
                </div>
                <Pagination
                    perPage={this.state.perPage}
                    setPerPage={val => this.setState({ perPage: val })}
                    setRefreshForm={val => this.setState({ actionSuccessful: val })}
                    currentSelectedPage={this.state.currentSelectedPage}
                    setCurrentSelectedPage={val => this.setState({ currentSelectedPage: val })}
                    lastPage={this.state.lastPage}
                    arrayPages={this.state.arrayPages}
                />
                {this.state.displayDetails && <ViewEDMMSMandateDetails
                    data={this.state.viewData}
                    hideDialog={() => this.setState({ displayDetails: false })}
                    sender="Incoming eDMMS Mandates"
                    displayView={this.state.displayDetails}
                    ShowToast={this.props.ShowToast}
                    setIsLoaderVisible={this.props.setIsLoaderVisible}
                />}
                <RejectIncomingEDMMSMandates
                    setActionSuccessful={() => this.setState({ actionSuccessful: true })}
                    data={this.state.viewData}
                    setIsLoaderVisible={this.props.setIsLoaderVisible}
                    ShowToast={this.props.ShowToast}
                    closeForm={() => this.setState({ displayReject: false })}
                    displayReject={this.state.displayReject}
                />

            </>

        )
    }
}
