import ApiPath from "../../admin/assets/App/api-path";
import { SplitDate } from "../../admin/assets/App/split-date";

const NibssUsersTableRows = (props) => {

    function handleEditRowClicked(value) {
        props.EditUser(value)
    }

    function handleEnableDisable(slug) {
        const status = props.apiData.status === "active" ? "deactivate" : "activate";
        const confirm = window.confirm(`Are you sure you wish to ${status} the selected user?`);

        if (confirm) {
            const data = { "slug": slug }
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                    'Cache-Control': 'public, max-age=0'
                },
                body: JSON.stringify(data),
            }

            const endpoint = "api/nibbs-user/change-user-status";
            fetch(ApiPath + endpoint, options)
            .then(response => {
                return response.json();
            })
            .then(response => {
                props.setIsLoaderVisible(false)
                if (response) {
                    if (response.ok === true) {
                        //Success
                        props.ShowToast(`User ${status}d successfully!`, "success")
                        props.setIsCreateSuccessful(true)
                    } else {
                        props.ShowToast(response.message, "exclamation")
                        return;
                    }
                } else {
                    props.ShowToast("Invalid input value(s)", "exclamation")
                }
            })
            .catch(error => {
                props.setIsLoaderVisible(false)
                props.ShowToast(error.message, "exclamation")
            })
        }
    }

    const badgeType = (props.apiData.status === "active") ? 'badge-success' : 'badge-danger'
    const icofontImage = (props.apiData.status === "active") ? 'icofont-play-alt-1' : 'icofont-stop'
    const eyeImage = (props.apiData.status === "active") ? 'icofont-eye-blocked' : 'icofont-eye'

    return (
        <tr>
            <td className="align-center">{ props.counter }.</td>
            <td>{ props.apiData.email}</td>
            <td>{ props.apiData.firstName}</td>
            <td>{ props.apiData.lastName}</td>
            <td>{ props.apiData.userRole?.name }</td>
            <td>{ SplitDate(props.apiData?.lastLoginDate).dateTime }</td>
            <td title={ props.apiData.status }><span className={ badgeType }><i className={ icofontImage }></i></span></td>
            <td className="align-center">
                <button
                    id="editBtnRow"
                    className="btn-grey mr-1"
                    onClick={() => handleEditRowClicked(props.apiData.slug)}>
                    <i className="icofont-edit"></i> Edit
                </button>
                <button
                    id="btnEnableDisable"
                    className="btn-grey"
                    title="Enable/Disable"
                    onClick={() => handleEnableDisable(props.apiData.slug)}>
                        <i className={ eyeImage } style={{ fontSize: '1.4em'}}></i>
                </button>
            </td>
        </tr>

    );
}

export default NibssUsersTableRows;
