import NavBar from "../../admin/assets/App/nav-bar"
import TopBar from "../../admin/assets/App/topbar"
import LockedScreen from "../../admin/assets/App/LockedScreen";
import Footer from "../../admin/assets/App/footer";
import React, { useState} from 'react'
import { Helmet } from "react-helmet";
import ConfigureVATForm from "./configure-vat-form";

export const ConfigureVAT = (props) => {
  const [mainClass, setMainClass] = useState("main");

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Configuration - Configure VAT | NIBSS Document Management Hub Portal
          </title>
        </Helmet>

        <LockedScreen
          setIsLoaderVisible={props.setIsLoaderVisible}
          ShowToast={props.ShowToast}
        />
        <div className="cont-ainer">
          <NavBar sender="Configure VAT" />
          <div className={mainClass}>
            <TopBar
                setMainClass={value => setMainClass(value)}
            />
            <div className="page-title">
              <h2>
                <i className="icofont-mr icofont-ui-settings"></i>Configuration
                - Configure VAT
              </h2>
            </div>
            <div className="branch-summaries">
              <div className="summaries">
                <div className="title">
                  <h3>Set VAT value</h3>
                </div>
                <ConfigureVATForm
                  setIsLoaderVisible={props.setIsLoaderVisible}
                  ShowToast={props.ShowToast}
                />
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </>
    );
}
