import NavBar from "../../admin/assets/App/nav-bar"
import TopBar from "../../admin/assets/App/topbar"
import LockedScreen from "../../admin/assets/App/LockedScreen";
import OutgoingCardsForm from "./outgoing-cards-form";
import Footer from "../../admin/assets/App/footer";
import { useState } from 'react';
import { Helmet } from "react-helmet";

export const OutgoingCards = props => {
    const [mainClass, setMainClass] = useState("main");

     /**
     * This outgoing cards form
     * basically
     * allows us to have
     * a quick view of
     * application shortcuts.
     *
     * We need to add the
     * table and the
     * table rows to
     * follow the
     * expected
     * description on
     * the requirements
     * document...
     */

     /**
     * This outgoing cards form
     * basically
     * allows us to have
     * a quick view of
     * application shortcuts.
     *
     * We need to add the
     * table and the
     * table rows to
     * follow the
     * expected
     * description on
     * the requirements
     * document...
     */
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Traped Cards/Outgoing Trapped Cards | NIBSS Document Management Hub Portal</title>
            </Helmet>
            <LockedScreen
                setIsLoaderVisible={props.setIsLoaderVisible}
                ShowToast={props.ShowToast}
            />

            <div className="cont-ainer">
                <NavBar sender="Outgoing Trapped Cards" />
                <div className={mainClass}>
                    <TopBar
                        setMainClass={value => setMainClass(value)}
                    />
                    <div className="page-title">
                        <h2><i className="icofont-mr bx bx-credit-card"></i>Outgoing Trapped Cards</h2>
                    </div>
                    <div className="branch-summaries">
                        <div className="summaries">
                            <OutgoingCardsForm
                                ShowToast={props.ShowToast}
                                setIsLoaderVisible={props.setIsLoaderVisible}
                            />
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>

        </>
    )
}

/**
     * This create trapped cards form
     * basically
     * allows us to have
     * a quick view of
     * application shortcuts.
     *
     * We need to add the
     * table and the
     * table rows to
     * follow the
     * expected
     * description on
     * the requirements
     * document...
     */
     /**
     * This create trapped cards form
     * basically
     * allows us to have
     * a quick view of
     * application shortcuts.
     *
     * We need to add the
     * table and the
     * table rows to
     * follow the
     * expected
     * description on
     * the requirements
     * document...
     */
     /**
     * This create trapped cards form
     * basically
     * allows us to have
     * a quick view of
     * application shortcuts.
     *
     * We need to add the
     * table and the
     * table rows to
     * follow the
     * expected
     * description on
     * the requirements
     * document...
     */
