import { SplitDate } from "../../admin/assets/App/split-date";
import CapitalizeFirstLetter from "../../admin/assets/App/CapitalizeFirstLetter"
import Badges from "../../admin/assets/App/Badges";

export const IncomingEClipsTableRows = (props) => {

    const displayPublicURLForm = () => {
        /**
         * Set the display state
         * of the form in question to
         * true
         */
        props.setRowData(props.item);
        props.setDisplayPublicURL(true);

    }

    const viewMoreDetails = () => {
        props.setRowData(props.item);
        props.showViewMoreDetails();
    }

    const copyRequestID = async () => {
        //Set the text in the textarea to
        //the clipboard
        props.ShowToast('Request ID copied', 'information');
        await navigator.clipboard.writeText(props.item.requestId);

    }

    const treatRequest = () => {
        //Show request dialog...
        props.setRowData(props.item);
        props.showTreatDialog();
    }

    const statusClass = Badges(props.item.status);
    const actionStatus = CapitalizeFirstLetter(props.item.status);

    return (
        <tr>
            <td>{props.counter}.</td>
            <td id='td' onClick={copyRequestID} style={{ cursor: "pointer" }} title="Click to copy">{props.item.requestId}</td>
            <td>{props.item.referenceType}</td>
            <td>{props.item.acquiringInstitution?.name}</td>
            <td>{SplitDate(props.item.createdAt).dateTime}</td>
            <td>{props.item.reference}</td>
            <td>{SplitDate(props.item.updatedAt).dateTime}</td>
            <td className="align-center">{CapitalizeFirstLetter(props.item.isOverDue)}</td>
            <td><span className={statusClass}>{actionStatus}</span></td>
            <td className="align-center" style={{ width: 120 }}>
                <i
                    id="btnDisplay"
                    className="icofont-wifi-router mr-1"
                    style={{ color: "darkgreen", fontSize: 20, cursor: 'pointer', textDecoration: 'underline' }}
                    title="Public URL"
                    onClick={displayPublicURLForm}
                ></i>
                <i
                    id="btnMoreDetails"
                    className="icofont-list mr-1"
                    title="View details..."
                    style={{ color: "darkblue", fontSize: 20, cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={viewMoreDetails}
                ></i>
                <i
                    id="btnTreateRequest"
                    className={props.item.status === "pending" ? "icofont-video-clapper" : "d-none"}
                    title="Treat Request..."
                    style={{ color: "#777", fontSize: 20, cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={treatRequest}
                ></i>
            </td>

        </tr>
     );
}
