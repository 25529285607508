import React from "react";
import IncomingReferencesTableRow from "./incoming-references-table-row";
import ApiPath from "../../../admin/assets/App/api-path";
import objectToCSV from "../../../admin/assets/App/object-to-csv";
import ExportToCSVFile from "../../../admin/assets/App/ExportToCSVFile";
import ReferenceSearchList from "../../../admin/assets/data/ReferenceSearchList"
import Pagination from "../../../admin/assets/App/Pagination";
import ViewReferenceDialog from "../../App/outgoing-references/view-reference-dialog"
import SearchComponent from "../../../admin/assets/App/SearchComponent";
import { LoadReferencesForExport } from "../../../admin/assets/functions/load-references";
import { DateRange } from "../../../components/date-range";

class IncomingReferenceForm extends React.Component {
    constructor() {
        super()
        this.state = {
            actionSuccessful: false,
            fetchSuccessful: false,

            //Search
            searchField: "",
            searchValue: "",
            startDate: "",
            endDate: "",

            //Data
            displayView: false,
            displayEdit: false,
            apiData: [],
            refComments: [],
            metaData: {},
            viewData: [],
            editData: [],
            exportData: [],
            mandateExtension: "",

            //pagination
            currentSelectedPage: 1,
            arrayPages: [],
            lastPage: 1,
            perPage: localStorage.getItem("items_per_page") || 20,
            totalItems: 0,
        }

        this.RefreshForm = this.RefreshForm.bind(this)
        this.options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("session_token")}`,
                'Cache-Control': 'public, max-age=0'
            },
        };

    }

    ExportData = () => {
        /**
         * Export the data
         * to an MSExcel
         * file after
         * filtering and
         * saving in a pre-defined
         * format... Consult the
         * requirements document(s)
         * We need to obtain the data then do the necessary...
         *
         * First, ensure that the
         * items per page state is set to a very
         * high value
         */

        const dataToExport = [];
        const previousPerPage = this.state.perPage;
        let apiOutput;

        this.setState({ perPage: 20000 }, () => {

            this.props.setIsLoaderVisible(true);
            const dateRangeFilter = (this.state.startDate !== '') ? `&createdAt=${this.state.startDate}&createdAt=${this.state.endDate}` : '';

            fetch(`${ApiPath}api/e-reference/view-incoming-e-reference?page=${this.state.currentSelectedPage}` +
                `&${this.state.searchField}=${this.state.searchValue}&limit=${this.state.perPage}${dateRangeFilter}`, this.options)
                ?.then(response => {
                    return response.json();
                })
                .then(response => {
                    this.props.setIsLoaderVisible(false)
                    if (response && response.ok === true) {
                        apiOutput = response.body.references.data;

                        LoadReferencesForExport(apiOutput, dataToExport);

                        const csvData = objectToCSV(dataToExport);
                        ExportToCSVFile(csvData, "incoming-references.csv");

                        this.props.ShowToast("Incoming References exported successfully! Open downloads folder to preview...", "information");

                        //Restore the former value...
                        this.setState({ perPage: previousPerPage }, () => this.getIncomingReferences());

                    }
                })
        })

    }

    getIncomingReferences = () => {
        /**
         * This contains
         * the API endpoint
         * for fetching
         * the outgoing
         * references for
         * any particular
         * institution
         */

        this.props.setIsLoaderVisible(true);
        const dateRangeFilter = (this.state.startDate !== '') ? `&createdAt=${this.state.startDate}&createdAt=${this.state.endDate}` : '';

        fetch(`${ApiPath}api/e-reference/view-incoming-e-reference?page=${this.state.currentSelectedPage}` +
            `&${this.state.searchField}=${this.state.searchValue}&limit=${this.state.perPage}${dateRangeFilter}`, this.options)
            ?.then(response => {
                return response.json();
            })
            .then(response => {
                this.props.setIsLoaderVisible(false)
                if (response && response.ok === true) {
                    //Filter the data...
                    const filteredRefData = response.body.references.data;           ////.filter(item => item.receiverInstitution?.name === sessionStorage.getItem("institution"));

                    this?.setState({
                        metaData: response.body.references.meta,
                        apiData: filteredRefData,
                        fetchSuccessful: true,
                        actionSuccessful: false,
                        arrayPages: []

                    }, () => {
                        const pagesArray = [];
                        this.setState({
                            currentSelectedPage: this.state.metaData?.current_page,
                            lastPage: this.state.metaData?.last_page,
                            totalItems: this.state.metaData?.total

                        }, () => {
                            //Set this array for the looping
                            for (let i = 1; i <= this.state.lastPage; i++) {
                                pagesArray.push({ "id": i, "name": "Item " + i, "each": i + " name" });
                            }

                            this.setState({ arrayPages: pagesArray })
                        })
                    })
                }
            })
    }

    RefreshForm() {
        this.getIncomingReferences()
    }

    componentDidMount() {
        this.getIncomingReferences()
    }

    filterRecords = () => {
        this.getIncomingReferences();
    }

    render() {

        let counter = 0;
        const TableRows = this.state.fetchSuccessful && this.state.apiData.map(item => {
            counter++;
            const itemCounter = (this.state.currentSelectedPage - 1) * this.state.perPage + counter

            return (<IncomingReferencesTableRow
                key={counter}
                counter={itemCounter}
                ShowToast={this.props.ShowToast}
                setIsLoaderVisible={this.props.setIsLoaderVisible}
                item={item}
                setActionSuccessful={value => this.setState({ actionSuccessful: value })}
                setDisplayEdit={value => this.setState({ displayEdit: value })}
                setMandateExtension={value => this.setState({ mandateExtension: value })}
                setViewData={value => this.setState({ viewData: value })}
                setEditData={value => this.setState({ editData: value })}
                setDisplayView={value => this.setState({ displayView: value })}
                setCommentsData={value => this.setState({ refComments: value })}

            />)
        })

        if (this.state.actionSuccessful) {
            this.getIncomingReferences()
            this.setState({
                actionSuccessful: false,
            })
        }

        const referenceNo = [];
        const customerAccountNo = [];
        const customerAccountName = [];
        const refereeAccountName = [];
        const refereeAccountNo = [];

        this.state.apiData.forEach(item => {
            referenceNo.push({ item_name: item.referenceNo })
            customerAccountNo.push({ item_name: item.customerAccountNumber })
            customerAccountName.push({ item_name: item.customerAccountName })
            refereeAccountName.push({ item_name: item.refereeAccountName })
            refereeAccountNo.push({ item_name: item.refereeAccountNumber })
        })

        const actionStatus = [
            { "item_name": "rejected" },
            { "item_name": "accepted" },
            { "item_name": "approved" },
            { "item_name": "pending" },
            { "item_name": "disapproved" },
        ]
        return (
            <>
                <div className="title">
                    <DateRange
                        callback={this.filterRecords}
                        ShowToast={this.props.ShowToast}
                        setDateFrom={val => this.setState({ startDate: val })}
                        setDateTo={val => this.setState({ endDate: val })}
                    />
                    <SearchComponent
                        ReferenceSearchList={ReferenceSearchList}
                        filterRecords={this.filterRecords}
                        setSearchField={val => this.setState({ searchField: val })}
                        setSearchValue={val => this.setState({ searchValue: val })}
                        ShowToast={this.props.ShowToast}
                        inputList={{
                            referenceNo,
                            refereeAccountName,
                            customerAccountName,
                            customerAccountNo,
                            refereeAccountNo,
                            actionStatus
                        }}
                    />
                    <div className="buttons">
                        <button
                            id="btnRefresh"
                            className="btn-grey t-tip mr-1"
                            type="button"
                            data-title="Refresh"
                            onClick={this.RefreshForm}>
                            <i className="icofont-refresh"></i> Refresh
                        </button>
                        <button
                            className="btn-green t-tip"
                            id="btnExport"
                            type="button"
                            data-title="Export to MsExcel/CSV file"
                            onClick={this.ExportData}>
                            <i className="icofont-airplane-alt"></i> Export
                        </button>
                    </div>
                </div>
                <div className="table-container">
                    <table className="bordered-table" id="outgoing_ref_table">
                        <caption></caption>
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col" className="align-left">REFERENCE NO</th>
                                <th scope="col" className="align-left">CUSTOMER A/C NO</th>
                                <th scope="col" className="align-left">CUSTOMER A/C NAME</th>
                                <th scope="col" className="align-left">REF. INSTITUTION</th>
                                <th scope="col" className="align-left">REF. BRANCH</th>
                                <th scope="col" className="align-left">REF. A/C NO</th>
                                <th scope="col" className="align-left">REF. A/C NAME</th>
                                <th scope="col" className="align-center">OVERDUE?</th>
                                <th scope="col" className="align-center" width="120">STATUS</th>
                                <th scope="col" className="align-center" width="100">ACTION</th>
                                <th scope="col" className="align-center" width="100">REASSIGN</th>

                            </tr>
                        </thead>
                        <tbody id="tbl_incoming_references">
                            {TableRows}
                        </tbody>
                    </table>
                </div>
                <Pagination
                    setRefreshForm={value => this.setState({ actionSuccessful: value })}
                    currentSelectedPage={this.state.currentSelectedPage}
                    setCurrentSelectedPage={value => this.setState({ currentSelectedPage: value })}
                    setPerPage={value => this.setState({ perPage: value })}
                    perPage={this.state.perPage}
                    lastPage={this.state.lastPage}
                    arrayPages={this.state.arrayPages}
                />
                {
                    this.state.displayView && <ViewReferenceDialog
                        data={this.state.viewData}
                        displayView={this.state.displayView}
                        ShowToast={this.props.ShowToast}
                        mandateExtension={this.state.mandateExtension}
                        setIsLoaderVisible={this.props.setIsLoaderVisible}
                        setActionSuccessful={value => this.setState({ actionSuccessful: value })}
                        setDisplayView={value => this.setState({ displayView: value })}
                        sender={"Incoming Reference"}
                        commentsData={this.state.refComments}
                    />
                }

            </>
        )
    }
}

export default IncomingReferenceForm;
